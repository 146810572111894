import styled from "styled-components";

export default styled.div`
    padding: ${(props) => (props.padding ? props.padding : "30px")};
    background: ${(props) =>
        props.background ? props.background : "rgba(142, 153, 243, 15%)"};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "50%"};
    width: ${(props) => (props.width ? props.width : "32px")};
    height: ${(props) => (props.height ? props.height : "32px")};

    @media only screen and (max-width: 1280px) {
        ${(props) =>
            props.type === "dashboard" &&
            `width: 28px;
        height: 28px;
        padding: 20px; `}
    }
    @media all and (max-width: 900px) {
        ${(props) => props.type === "dashboard" && `width:24px; height:24px`}
    }
    /*
  @media only screen and (max-width: 1200px) {
    padding: 15px;
  } */
`;
