import React from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import "./style.css";

function TotalleadListItem({
    ID,
    name,
    email,
    phone,
    assignto,
    country,
    source,
    date,
    id,
    handleClick,
    isChecked,
    item,
}) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "20px 30px",
                alignItems: "center",
            }}
            className="item"
        >
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="3%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                <input
                    type="checkbox"
                    style={{ width: "50%" }}
                    onChange={handleClick}
                    checked={isChecked}
                    id={id}
                />
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="5%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                #{ID}
            </TD>

            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="12%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {name}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item?.lead_age.days} days
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="12%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {email}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="10%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {phone}
            </TD>
            {/* <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item?.university}
            </TD> */}
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item?.course}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {country}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item?.nationality_name}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="8%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {/* <img
					style={{ width: 35, height: 35, borderRadius: 10 }}
					src={source}
				/> */}
                {source}
            </TD>

            {/* <TD width="11%" color="#000"
            fontWeight="400" fontSize="16px">
				{date}
			</TD> */}
        </div>
    );
}

export default TotalleadListItem;
