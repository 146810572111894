import React from "react";
import Text from "../Text";
import PaginationIcon from "../../assets/images/pagination-icon.svg";
import "./style.css";
import styled from "styled-components";

const Pagination = ({
    postsPerPage,
    totalPosts,
    paginate,
    currentPage,
    currentPosts,
    hasNext,
}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <nav
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 20px",
                alignItems: "center",
                marginTop: 30,
            }}
        >
            <TextPagination fontSize="15px" fontWeight="400" color="#8C8888">
                showing {currentPosts.length} of {totalPosts} results
            </TextPagination>
            <ul style={{ display: "flex", justifyContent: "space-between" }}>
                <li style={{ display: "flex", alignItems: "center" }}>
                    <img src={PaginationIcon} alt="Previous" />
                    <a
                        style={{ fontSize: "18px", marginRight: "15px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            paginate(currentPage - 1);
                        }}
                        href="#"
                    >
                        <TextPagination
                            fontSize="18px"
                            fontWeight="500"
                            style={{ marginLeft: "10px" }}
                        >
                            Previous
                        </TextPagination>
                    </a>
                </li>
                {pageNumbers.map((number) => (
                    <li
                        style={
                            currentPage === number
                                ? {
                                      display: "flex",
                                      alignItems: "center",
                                      background: "#522E92",
                                      padding: "15px 20px",
                                      marginRight: "15px",
                                      borderRadius: "10px",
                                  }
                                : {
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "15px",
                                  }
                        }
                        key={number}
                    >
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                paginate(number);
                            }}
                            href
                        >
                            <TextPagination
                                fontSize="18px"
                                fontWeight="500"
                                style={
                                    currentPage === number
                                        ? { color: "#fff" }
                                        : null
                                }
                            >
                                {number}
                            </TextPagination>
                        </a>
                    </li>
                ))}
                {hasNext && (
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <a
                            style={{ fontSize: "18px", marginRight: "15px" }}
                            onClick={(e) => {
                                e.preventDefault();
                                paginate(currentPage + 1);
                            }}
                            href="#"
                        >
                            <TextPagination
                                fontSize="18px"
                                fontWeight="500"
                                style={{ marginLeft: "10px" }}
                            >
                                Next
                            </TextPagination>
                        </a>
                        <img
                            src={PaginationIcon}
                            alt="Previous"
                            style={{ transform: "rotate(180deg)" }}
                        />
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;

const TextPagination = styled.h3`
    @media only screen and (max-width: 1280px) {
        font-size: 17px;
    }
`;
