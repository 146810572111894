const DashboardListData = [
  {
    studentId: "1230470672",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1798184935",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "851030918",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "4275483581",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3022270903",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3252520121",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1591067333",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2943343193",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1749723972",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2382632861",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "172786306",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2170224047",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "4287771703",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "86600118",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3451994660",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2043832688",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "992825631",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3117373709",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1073309232",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1873261466",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "4233518855",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "4062501930",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "346798978",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1141773161",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "481500519",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3190718079",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3185162355",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2282590031",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "2909331135",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "1790926554",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3789517639",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "3168741380",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
  {
    studentId: "736016786",
    date: "03 April 2021, 11:05 PM",
    name: "Jomana Ah",
    course: "Programming IT0025",
    specialisation: "Computer Science Eng.",
    batch: "205",
  },
];
export default DashboardListData;
