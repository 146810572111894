import ApiService from "../utils/fetch";

export const GetSuperAdminDashboardService = async (data) => {
	let response = await ApiService.get(
		`/general/super-admin-dashboard/`,
		data
	);
	return response;
};

export const GetLeadGeneratorDashboardService = async (data) => {
	let response = await ApiService.get(
		`/general/lead-generator-dashboard/`,
		data
	);
	return response;
};
