import React, { useState, useEffect } from "react";
import styled from "styled-components";

export default function WelcomeMessage() {
    const myDate = new Date();
    const hrs = myDate.getHours();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (hrs < 12) {
            setMessage("Let's start the day off right");
        } else if (hrs >= 12 && hrs < 16) {
            setMessage("Time to be on race with quick actions");
        } else if (hrs >= 16 && hrs <= 24) {
            setMessage("Missed the daily activity, Don't worry you still can");
        }
    }, []);

    return <MessageText>{message}</MessageText>;
}

const MessageText = styled.h6`
    font-size: 15px;
    font-weight: 400;
    /* margin: 6px 0 20px 0; */
    margin-top: 4px;
    @media all and (max-width: 980px){
        min-width: 350px;
}
    @media all and (max-width: 768px){
        font-size: 13px;
}
@media all and (max-width: 640px){
    font-size: 13px;
    min-width: 315px;
}
@media all and (max-width: 480px){
    font-size: 12px;
}
@media all and (max-width: 360px){
    font-size: 10px;
}
`;
