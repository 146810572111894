import React, { useEffect, useState, useContext } from "react";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import addbutton from "../../assets/images/addbutton.svg";
import add from "../../assets/images/add.svg";
import filter from "../../assets/images/filters.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import leadListData from "../../components/leadGeneratorAdmin/leadListData";
import LeadListItem from "../../components/leadGeneratorAdmin/leadListItem";
import Pagination from "../../components/pagination/pagination";
import moment from "moment";

import {
    AddSourceService,
    ListLeadsService,
    ListSourceService,
} from "../../service/leads";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import FilterBox from "../../components/includes/FilterBox";
import DeleteModal from "../../components/includes/DeleteModal";
import MainPagination from "../../components/includes/MainPagination";
import NoDataFound from "../../components/noRecords/NoDataFound";
import ViewMoreModal from "../teamLeader/ViewMoreModal";

function Leads() {
    // const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [isFilterBox, setFilterBox] = useState(false);
    const [universityList, setUniversityList] = useState([]);
    const [sortValue, setSortValue] = useState();
    const [status, setStatus] = useState(1);
    const [universityValues, setUniversityValues] = useState();
    const [deleteId, setDeleteId] = useState("");
    const [isModal, setModal] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [search, setsearch] = useState("");
    const { leadsURL } = useParams();
    const [selectedLead, setSelectedLead] = useState();
    const history = useHistory();

    const [isViewMore, setIsViewMore] = useState(false);

    // Get current posts
    // const indexOfLastPost = currentPage * count;
    // const indexOfFirstPost = indexOfLastPost - count;
    // const currentPosts = leadListData.slice(indexOfFirstPost, indexOfLastPost);



    const [leads, setLeads] = useState([]);

    const getLeadSource = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;

        const url =
            leadsURL === "list-leads"
                ? `/leads/lead/`
                : `leads/duplicate-leads/`;

        baseConfig
            .get(url, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    status: status,
                    ordering: sortValue,
                    university: universityValues,
                    page: currentPage,
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeads(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        // getData();
        getLeadSource();

    }, [currentPage, search, leadsURL]);

    const handleDeleteData = (delID) => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/leads/lead/${delID}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    getLeadSource();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getLeadSource();
                }
            })
            .catch((error) => {});
    };

    function disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }
    function enableScrolling() {
        window.onscroll = function () {};
    }

    useEffect(() => {
        if (isFilterBox === true) {
            disableScrolling();
        }
        if (isFilterBox === false) {
            enableScrolling();
        }
    }, [isFilterBox]);

    return (
        <Wrapper className="leads">
            <div
                className="leads-headbar"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Title className="leads-title" fontSize="30px" fontWeight="700">
                    Leads
                </Title>
                <div
                className="leads-list"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        // width: "45%",
                    }}
                >
                    <SearchDiv>
                        <Form className="search-container">
                            <SearchIcon
                                src={
                                    require("./../../assets/images/search.svg")
                                        .default
                                }
                            />
                            <SearchField
                                className="input-box"
                                type="text"
                                name="search"
                                placeholder="Search here"
                                onChange={(e) => setsearch(e.target.value)}
                            />
                        </Form>
                    </SearchDiv>
                    {leadsURL === "list-leads" && (
                        <TopRight
                            onClick={() => {
                                history.push("/leadgenerator/add-leads/");
                            }}
                        >
                            <img
                                src={
                                    require("../../assets/images/addbutton.svg")
                                        .default
                                }
                                alt="icon"
                            />
                            <Text
                                 className="list-text"
                                fontSize="15px"
                                fontWeight="400"
                                color="#fff"
                                style={{ marginLeft: "5px" }}
                            >
                                Add Lead
                            </Text>
                        </TopRight>
                    )}
                    <Button
                    className="leads-filter"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 20px",
                            height: "50px",
                        }}
                        backgroundColor="#FFF"
                        fontSize="15px"
                        // href="/superadmin/add-campus"
                        onClick={() => {
                            setFilterBox(true);
                        }}
                    >
                        <img className="filter"src={filter} alt="Logo" />
                        <Text
                            className="list-text"
                            style={{ marginLeft: "12px" }}
                            color="#0FA76F"
                            fontSize="15px"
                        >
                            Filter
                        </Text>
                    </Button>
                </div>
            </div>
            {leadsURL === "list-leads" ? (
                <Text
                    className="list-text"
                    fontSize="20px"
                    fontWeight="400"
                    color="#000"
                    // style={{ marginTop: "10px" }}
                >
                    List of Leads ({paginationData.count})
                </Text>
            ) : (
                <Text
                    className="list-text"
                    fontSize="20px"
                    fontWeight="400"
                    color="#000"
                    // style={{ marginTop: "10px" }}
                >
                    List of Duplicate Leads ({paginationData.count})
                </Text>
            )}
            <div className="table">
            <Table margin="30px 0" borderRadius="30px" padding="30px 0">
                <div
                    
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E6EFF5",
                        padding: "0 30px 20px",
                    }}
                    className="table-head"
                >
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="105px"
                        // width="10%"
                        width="9%"
                    >
                        ID No.
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="15%"
                        width="13%"
                    >
                        Lead Name
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="150px"
                        // width="15%"
                        width="13%"
                    >
                        Phone
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="20%"
                        width="18%"
                    >
                        Email
                    </TD>
                    {/* <TD color="#808080" fontWeight="500" fontSize="16px">
						Country
					</TD> */}
                    {/* <TD color="#808080" fontWeight="500" fontSize="16px" width="175px">
            Country
          </TD>
          <TD color="#808080" fontWeight="500" fontSize="16px" width="150px">
            Source
          </TD> */}
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="150px"
                        // width="15%"
                        width="13%"
                    >
                        Lead Age
                    </TD>
                    {/* <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="15%"
                    >
                        Campaign Name
                    </TD> */}
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="18%"
                        width="15%"
                    >
                        Country
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="15%"
                        width="13%"
                    >
                        Lead Source
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="15%"
                        width="13%"
                    >
                        Assign To
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        // width="15%"
                        width="11%"
                    >
                        Stage
                    </TD>
                    <div style={{ width: "120px" }} />
                </div>
                {isLoading === false ? (
                    leads.length > 0 ? (
                        <div className="table-body">
                            {leads.map((item) => {
                                return (
                                    <LeadListItem
                                        ID={item.lead_id}
                                        name={item.name}
                                        university={item.university_data}
                                        course={item.course_data}
                                        country={item.country_name}
                                        phone={item.phone}
                                        source={item.lead_source}
                                        qualification={item.qualification}
                                        key={item.id}
                                        sourceImage={
                                            item.lead_source_data?.image
                                        }
                                        setModal={setModal}
                                        id={item.id}
                                        setDeleteId={setDeleteId}
                                        selectedLead={selectedLead}
                                        setSelectedLead={setSelectedLead}
                                        setIsViewMore={setIsViewMore}
                                        item={item}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <NoDataFound />
                    )
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </Table>
            </div>
            <MainPagination
                paginationData={paginationData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
            {isFilterBox && (
                <FilterBox
                    isFilterBox={isFilterBox}
                    setFilterBox={setFilterBox}
                    universityList={universityList}
                    setSortValue={setSortValue}
                    sortValue={sortValue}
                    getAssignedLeads={getLeadSource}
                    universityValues={universityValues}
                    setUniversityValues={setUniversityValues}
                />
            )}

            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                    // archive={true}
                />
            )}
            {isViewMore && (
                <ViewMoreModal
                    setIsViewMore={setIsViewMore}
                    selectedLead={selectedLead}
                />
            )}
        </Wrapper>
    );
}

export default Leads;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;

const SearchDiv = styled.div`
    margin-right: 20px;
    width: 400px;
    @media (max-width: 980px) {
    width: 270px;
  }
  @media (max-width: 640px) {
    width: 150px;
    margin-right: 10px;
  }
  @media (max-width: 480px) {
    width: 70%;
    /* margin-right: 0px; */
  }

`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #3ccb7f;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
    @media (max-width: 980px) {
    width: 270px;
  }
  @media (max-width: 768px) {
    height: 40px;
  }
  @media (max-width: 640px) {
    width: 150px;
    padding: 0 10px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 35px;
  }
`;
const SearchIcon = styled.img`
    margin-right: 10px;
    @media (max-width: 1080px) {
    width: 6%;
  }
  @media (max-width: 980px) {
    width: 8%;
  }
  @media (max-width: 768px) {
    width: 7%;
  }
  @media (max-width: 640px) {
    margin-right: 5px;
    width: 10%;
  }
  @media (max-width: 480px) {
    width: 6%;
  }
 
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;

const TopRight = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #0FA76F;
    border-radius: 8px;
    padding: 0px 20px;
    margin-right: 20px;
    cursor: pointer;
    @media (max-width: 768px) {
        padding: 0px 16px;
    height: 40px;
    }
    @media (max-width: 640px) {
    margin-right: 10px;
  }
  @media (max-width: 480px) {
    padding: 0px 11px;
    height: 35px;
  }
`;
