import React from "react";
import Text from "../../../components/Text";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import editIcon from "./../../../assets/images/edit.svg";
import Delete from "./../../../assets/images/delete.svg";
import styled from "styled-components";

function Program({
	width,
	fontSize,
	add,
	leadProgram,
	setSelectedProgram,
	selectedProgram,
	data,
	setModal,
	setDeleteId,
}) {
	const history = useHistory();
	return (
		<TextLink>
			<div
				style={{
					backgroundColor: "#F9F9F9",
					padding: " 20px ",
					width: width ? width : "100%",
					boxSizing: "border-box",
					// margin: margin ? margin : "55px auto",
					border: add ? "2px dashed #DFEAF2" : "none",
					borderRadius: "10px",
					textAlign: "center",
					marginBottom: "10px",
					position: "relative",
					alignItems: "center",
				}}
				className="box"
			>
				<Text
					onClick={() => {
						history.push(`/superadmin/add-stage/${data.id}/`);
					}}
					className="program"
					style={{
						fontSize: fontSize ? fontSize : "10px",
						marginLeft: "15px",
						fontFamily: "Inter",
						color: "#3ccb7f",
						cursor: "pointer",
					}}
				>
					{data.name}
				</Text>
				<img
					onClick={(e) => {
						setModal(true);
						setDeleteId(data.id);
					}}
					style={{
						cursor: "pointer",
						position: "absolute",
						right: "15px",
						top: "20px",
					}}
					src={Delete}
				/>
			</div>
		</TextLink>
	);
}
export default Program;

const TextLink = styled.div``;
