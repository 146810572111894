import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Close from "./../../assets/images/close.svg";

function FilterBox({
	isFilterBox,
	setFilterBox,
	universityList,
	setSortValue,
	sortValue,
	getAssignedLeads,
	setUniversityValues,
	universityValues,
}) {
	function disableScrolling() {
		var x = window.scrollX;
		var y = window.scrollY;
		window.onscroll = function () {
			window.scrollTo(x, y);
		};
	}

	useEffect(() => {
		// disableScrolling();
	}, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setFilterBox(false);
				}}
			></Overlay>
			<Modal>
				<Container>
					<Div>
						<Title>Filter by</Title>
						<img
							onClick={() => {
								setFilterBox(false);
							}}
							style={{ cursor: "pointer" }}
							src={
								require("./../../assets/images/close.svg")
									.default
							}
							alt="icon"
						/>
					</Div>
					<div style={{ height: "185px", overflow: "scroll" }}>
						<Body borderBottom="6px solid #f2f2f2">
							<Heading>Name</Heading>
							<div
								onClick={() => {
									if (sortValue === "name") {
										setSortValue("");
									} else {
										setSortValue("name");
									}
								}}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<img
									src={
										require("./../../assets/images/a-z.svg")
											.default
									}
									alt="icon"
								/>
								<SubHeading
									style={{
										color:
											sortValue === "name"
												? "#552E91"
												: "#515151",
									}}
								>
									From A to Z
								</SubHeading>
								{sortValue === "name" && (
									<img
										src={
											require("./../../assets/images/tick.svg")
												.default
										}
										alt="icon"
									/>
								)}
							</div>
							<div
								onClick={() => {
									if (sortValue === "-name") {
										setSortValue("");
									} else {
										setSortValue("-name");
									}
								}}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<img
									src={
										require("./../../assets/images/z-a.svg")
											.default
									}
									alt="icon"
								/>
								<SubHeading
									style={{
										color:
											sortValue === "-name"
												? "#552E91"
												: "#515151",
									}}
								>
									From Z to A
								</SubHeading>
								{sortValue === "-name" && (
									<img
										src={
											require("./../../assets/images/tick.svg")
												.default
										}
										alt="icon"
									/>
								)}
							</div>
						</Body>
						{/* <Body borderBottom="6px solid #f2f2f2">
                            <Heading>University</Heading>
                            {universityList.map((data) => (
                                <div
                                    onClick={() => {
                                        setUniversityValues(data.id);
                                        if (universityValues === data.id) {
                                            setUniversityValues("");
                                        }
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "10px 0 0",
                                        cursor: "pointer",
                                    }}
                                >
                                    <SubHeading
                                        style={{
                                            color:
                                                universityValues === data.id
                                                    ? "#552E91"
                                                    : "#515151",
                                        }}
                                    >
                                        {data.name}
                                    </SubHeading>
                                    {universityValues === data.id && (
                                        <img
                                            src={
                                                require("./../../assets/images/tick.svg")
                                                    .default
                                            }
                                            alt="icon"
                                        />
                                    )}
                                </div>
                            ))}
                        </Body> */}
						<Body>
							{/* <Heading>Source</Heading>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<SubHeading>From A to Z</SubHeading>
								<img
									src={
										require("./../../assets/images/tick.svg")
											.default
									}
									alt="icon"
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<SubHeading>From Z to A</SubHeading>
								<img
									src={
										require("./../../assets/images/tick.svg")
											.default
									}
									alt="icon"
								/>
							</div> */}

							<Button
								onClick={() => {
									getAssignedLeads();
									setFilterBox(false);
								}}
								style={{
									// display: "flex",
									alignItems: "center",
								}}
								backgroundColor="#0FA76F"
								fontSize="15px"
							>
								<ButtonText
									// style={{ marginLeft: "12px" }}
									color="white"
									fontSize="15px"
								>
									Apply Filters
								</ButtonText>
							</Button>
						</Body>
					</div>
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default FilterBox;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	// background: rgba(0, 0, 0, 0.2);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	right: 2.5%;
	/* top: 115px; */
	position: absolute;
	border-radius: 5px;
	transition: 0.5s;
	z-index: 101;
	box-shadow: -1.41438px 1.41438px 25px rgba(0, 0, 0, 0.16);
`;
const Container = styled.div`
	// height: 130px;
	width: 300px;
	// padding: 30px 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Title = styled.h4`
	font-size: 20px;
	color: #808080;
`;
const Div = styled.div`
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	background-color: #f1f8fe;
	padding: 20px 15px;
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
	background-color: #fff;
	padding: 15px;
	// border-radius 0 0 5px 5px;
	overflow: scroll;
	border-bottom: ${(props) =>
		props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
	font-size: 16px;
	color: #515151;
	font-weight: 600;
`;
const SubHeading = styled.h4`
	font-size: 13px;
	color: "#515151";
	margin: 0 10px;
`;
const Button = styled.div`
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#522e92"};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "white")};
	// padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
	border: none;
	border-radius: 8px;
	text-align: center;
	display: inline-block;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	// width: 200px;
	cursor: pointer;
	// margin-top: 20px;
`;
const ButtonText = styled.h4`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
