import React, { useState, useEffect, useContext } from "react";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import MainPagination from "../../components/includes/MainPagination";
import LeadListItem from "../../components/leadGeneratorAdmin/leadListItem";
import NoDataFound from "../../components/noRecords/NoDataFound";
import DeleteModal from "../../components/includes/DeleteModal";
import LeadListSP from "./LeadListSP";
export default function DuplicateLeads() {
    const [leads, setLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [selectedLead, setSelectedLead] = useState();
    const [isModal, setModal] = useState(false);

    const getDupliLeads = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("leads/duplicate-leads/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    // status: status,
                    // ordering: sortValue,
                    // university: universityValues,
                    page: currentPage,
                    // search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeads(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    const handleDeleteData = (delID) => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/leads/lead/${delID}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    getDupliLeads();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getDupliLeads();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        // getData();
        getDupliLeads();
        // getUniversity();
    }, [currentPage]);
    return (
        <Wrapper className="leads">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Title fontSize="30px" fontWeight="700">
                    Duplicate Leads
                </Title>
            </div>
            <Table margin="30px 0" borderRadius="30px" padding="30px 0">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E6EFF5",
                        padding: "0 30px 20px",
                    }}
                    className="table-head"
                >
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="105px"
                        width="10%"
                    >
                        ID No.
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="15%"
                    >
                        Lead Name
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="150px"
                        width="15%"
                    >
                        Phone
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="20%"
                    >
                        Email
                    </TD>
                    {/* <TD color="#808080" fontWeight="500" fontSize="16px">
						Country
					</TD> */}
                    {/* <TD color="#808080" fontWeight="500" fontSize="16px" width="175px">
            Country
          </TD>
          <TD color="#808080" fontWeight="500" fontSize="16px" width="150px">
            Source
          </TD> */}
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="150px"
                        width="15%"
                    >
                        Lead Age
                    </TD>
                    {/* <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="15%"
                    >
                        Campaign Name
                    </TD> */}
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="15%"
                    >
                        Country
                    </TD>
                    <TD
                        color="#808080"
                        fontWeight="500"
                        fontSize="16px"
                        // width="175px"
                        width="15%"
                    >
                        Lead Source
                    </TD>
                    <div style={{ width: "70px" }} />
                </div>
                {isLoading === false ? (
                    leads.length > 0 ? (
                        <div className="table-body">
                            {leads.map((item) => {
                                return (
                                    <LeadListSP
                                        ID={item.lead_id}
                                        key={item.id}
                                        setModal={setModal}
                                        id={item.id}
                                        setDeleteId={setDeleteId}
                                        selectedLead={selectedLead}
                                        setSelectedLead={setSelectedLead}
                                        item={item}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <NoDataFound />
                    )
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </Table>

            <MainPagination
                paginationData={paginationData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                    // archive={true}
                />
            )}
        </Wrapper>
    );
}

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
