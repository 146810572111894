import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import DeleteImage from "../../assets/images/delete.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Text from "../../components/Text";
import edit from "../../assets/images/edit.svg";
import visible from "../../assets/images/archive.svg";
import searchIcon from "../../assets/images/header-search.svg";
import {
	DeleteSalesPersonService,
	ListSalesPersonService,
} from "../../service/salesPerson";
import { useHistory } from "react-router-dom";
import "./style.css";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import DeleteModal from "./../../components/includes/DeleteModal";
import { baseConfig } from "./../../utils/config";
import MainPagination from "../../components/includes/MainPagination";
import AdminsListContainer from "./AdminsListContainer";
import AdminListCard from "./AdminListCard";

function SalesPersonadmin() {
	const [salesPersons, setSalesPersons] = useState([]);
	const history = useHistory();
	const [searchItem, setSearchItem] = useState("");
	const [filterItem, setFilterItem] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const { state } = useContext(Context);
	const [isModal, setModal] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [archiveModal, setArchiveModal] = useState(false);
	const [selectedData, setSelectedData] = useState({});

	const [search, setsearch] = useState("");
	const [paginationData, setPaginationData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		if (salesPersons.length !== 0) {
			setFilterItem(
				salesPersons.filter((items) => {
					return items?.name
						.toLowerCase()
						.includes(searchItem.toLowerCase());
				})
			);
		}
	}, [searchItem, salesPersons]);

	// const getSalesPersonList = async () => {
	// 	setLoading(true);
	// 	let data = await ListSalesPersonService(state);
	// 	if (data.StatusCode === 6000) {
	// 		setLoading(false);
	// 		setSalesPersons(data.data?.data);
	// 	} else {
	// 		alert(data.data.message);
	// 		setLoading(false);
	// 	}
	// };

	const getSalesPersonList = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;

		const url = `/sales-persons/sales-persons/`;

		baseConfig
			.get(url, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					page: currentPage,
					search: search,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSalesPersons(data.data);
					setPaginationData(data.paginator);
					setLoading(false);
				} else {
					setLoading(false);
					alert(data.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				alert(error.response.data.data.message);
			});
	};

	const handleDeleteData = () => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.delete(`/sales-persons/sales-person/${deleteId}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setModal(false);
					getSalesPersonList();
				} else if (StatusCode == 6001) {
					alert(data.data.message);
					getSalesPersonList();
				}
			})
			.catch((error) => {});
	};

	const handleArchivePerson = (val) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.put(
				`/sales-persons/sales-person-activation/${deleteId}/`,
				{
					status: val,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode } = response.data;
				if (StatusCode == 6000) {
					setArchiveModal(false);
					getSalesPersonList();
				} else if (StatusCode == 6001) {
					getSalesPersonList();
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getSalesPersonList();
	}, [currentPage, search]);

	return (
		<>
			<Wrapper>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "30px",
					}}
					className="top-title-wrapper"
				>
					<div>
						<Title className="head" fontSize="35px">
							Admission Consultant
						</Title>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
						className="top-title-large-right"
					>
						<div
							style={{
								marginRight: "32px",
							}}
						>
							<form className="search-container">
								<img src={searchIcon} alt="search" />
								<input
									className="input-box"
									type="text"
									name="search"
									placeholder="Search for something"
									onChange={(e) => {
										setsearch(e.target.value);
									}}
								/>
							</form>
						</div>

						<Button
							onClick={(e) => {
								history.push(
									"/superadmin/add-admission-consultant/"
								);
							}}
							style={{
								display: "flex",
								alignItems: "center",
							}}
							bacerkgroundColor="#5A2D8F"
							fontSize="15px"
							className="sales-button"
						>
							<img src={addbutton} alt="Logo" />
							<Text
								style={{ marginLeft: "12px" }}
								color="white"
								fontSize="15px"
							>
								Add Admin
							</Text>
						</Button>

						<Button
							className="refresh-button-large"
							onClick={() => getSalesPersonList()}
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0 0 0 22px",
								cursor: "pointer",
							}}
							backgroundColor="white"
							fontSize="15px"
							padding="10px 50px"
						>
							<Text color="#0FA76F" fontSize="15px">
								Refresh
							</Text>
						</Button>
					</div>
				</div>
				{isLoading === false ? (
					<>
						{filterItem.length !== 0 ? (
							<AdminsListContainer
							// style={{
							//     display: "flex",
							//     justifyContent: "space-between",
							//     flexWrap: "wrap",
							// }}
							// className="university-admin-container-wrapper"
							>
								{filterItem?.map((salesPerson) => {
									return (
										<AdminListCard
											key={salesPerson.id}
											// width="48%"
											// className="university-admin-container"
										>
											<div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
														justifyContent:
															"space-between",
													}}
												>
													<Text className="name" fontSize="27px">
														{" "}
														{salesPerson?.name}
													</Text>
													<div
														style={{
															display: "flex",
															alignItems:
																"center",
														}}
													>
														{salesPerson.is_disabled ? (
															<Text
																fontSize="15px"
																style={{
																	cursor: "pointer",
																}}
																onClick={() => {
																	setArchiveModal(
																		true
																	);
																	setDeleteId(
																		salesPerson?.id
																	);
																	setSelectedData(
																		salesPerson
																	);
																}}
															>
																Restore
															</Text>
														) : (
															<DeleteIcon
																src={visible}
																alt="Logo"
																onClick={() => {
																	setArchiveModal(
																		true
																	);
																	setDeleteId(
																		salesPerson?.id
																	);
																	setSelectedData(
																		salesPerson
																	);
																}}
															/>
														)}
														<DeleteIcon
															src={DeleteImage}
															alt="Logo"
															onClick={() => {
																setModal(true);
																setDeleteId(
																	salesPerson?.id
																);
															}}
														/>
													</div>
												</div>{" "}
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Mail ID
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{
															salesPerson
																?.user_data
																?.email
														}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Username
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{
															salesPerson
																?.user_data
																?.username
														}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Country
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{salesPerson?.country}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Target
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{salesPerson?.target}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Target Achieved
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{
															salesPerson?.target_achieved
														}
														%
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Phone
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{salesPerson?.phone}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
													}}
												>
													<div
														style={{
															display: "flex",
															marginTop: "10px",
														}}
													>
														<Text fontSize="15px">
															Password
														</Text>
														<h3
															style={{
																fontSize:
																	"14px",
																color: "grey",
																marginLeft:
																	"10px",
																marginTop:
																	"4px",
															}}
														>
															********
															{/* {salesPerson?.user_data?.password} */}
														</h3>
													</div>

													<Button
														onClick={() =>
															history.push(
																"/superadmin/edit-admission-consultant/",
																salesPerson
															)
														}
														backgroundColor="#0FA76F"
														fontSize="15px"
														style={{
															cursor: "pointer",
														}}
														className="university-admin-update-button"
													>
														Update
													</Button>
												</div>
											</div>
										</AdminListCard>
									);
								})}
							</AdminsListContainer>
						) : (
							<div className="no-result">
								<text className="no-result-text">
									No results found{" "}
								</text>
							</div>
						)}

						<MainPagination
							paginationData={paginationData}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</>
				) : (
					<Div>
						<LoadingGif
							src={
								require("./../../assets/images/loading-buffering.gif")
									.default
							}
							alt="Loading"
						/>
					</Div>
				)}
			</Wrapper>

			{isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)}
			{archiveModal && (
				<DeleteModal
					setModal={setArchiveModal}
					deleteId={deleteId}
					handleDeleteData={handleArchivePerson}
					archive={selectedData.is_disabled ? "restore" : "true"}
				/>
			)}
		</>
	);
}

export default SalesPersonadmin;

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;

const DeleteIcon = styled.img`
	height: 22px;
	width: 22px;
	cursor: pointer;
	margin-left: 15px;
	&:first-child {
		margin-left: 0px;
	}
`;
