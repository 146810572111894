import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ReactCrop, { Crop } from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";

function ProfilePicture({
    setFile,
    file,
    cropImage,
    setCropImage,
    // fetchedImage,
    // setFetchedImage,
    // setCropSize,
}) {
    const [image, setImage] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);
    // const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({
        unit: "px",
        width: 300,
        height: 300,
        x: 80,
        y: 40,
    });
    const [editModal, setEditModal] = useState(false);
    const { state } = useContext(Context);

    const handleFileChange = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    };

    const onImageChange = (e) => {
        e.preventDefault();
        setEditModal(true);
        setFetchedImage(e.target.files[0]);
        handleFileChange(e);
    };

    const handleCrop = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("image", fetchedImage);
        formdata.append("crop", JSON.stringify(crop));

        baseConfig
            .post(`/general/article-image/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFile(data.image_name);
                    setCropImage(data.data.image);
                    setFetchedImage(null);
                    setEditModal(false);
                } else {
                    alert("crop failed");
                }
            })
            .catch((error) => {
                const { StatusCode, data } = error.response.data;
                alert("crop failed");
            });
    };

    return (
        <MainContainer>
            <ImageContainer htmlFor="image-file">
                {cropImage === null ? (
                    <Image
                        src={
                            require("./../../assets/images/profile-pic.svg")
                                .default
                        }
                        alt="Profile"
                    />
                ) : (
                    <Image src={cropImage} alt="Profile" />
                )}
            </ImageContainer>
            <EditDiv htmlFor="image-file">
                <EditIcon
                    // src={
                    //     require("./../../assets/icons/edit-icon-white.svg")
                    //         .default
                    // }
                    src={
                        require("./../../assets/images/edit-icon-white.svg")
                            .default
                    }
                    alt="icon"
                />
            </EditDiv>
            <input
                type="file"
                id="image-file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                onChange={onImageChange}
                onClick={(e) => {
                    e.target.value = null;
                }}
            />
            {editModal && (
                <BackContainer>
                    <Overlay
                        onClick={() => {
                            setEditModal(false);
                            setCrop({
                                unit: "px",
                                width: 300,
                                height: 300,
                                x: 80,
                                y: 40,
                            });
                        }}
                    ></Overlay>
                    <Modal>
                        <Container>
                            <ImageDiv>
                                {image && (
                                    <ReactCrop
                                        id="sample"
                                        src={image}
                                        crop={crop}
                                        onChange={(crop, percentCrop) => {
                                            setCrop(crop);
                                        }}
                                        aspect={1}
                                    >
                                        <UrlDiv>
                                            <ImageURL
                                                src={image}
                                                id="source"
                                                alt="img"
                                            />
                                        </UrlDiv>
                                    </ReactCrop>
                                )}
                            </ImageDiv>
                            <CropButtonDiv>
                                <CropButton
                                    color="#522e92"
                                    background="#fff"
                                    onClick={() => {
                                        setEditModal(false);
                                        setCrop({
                                            unit: "px",
                                            width: 300,
                                            height: 300,
                                            x: 80,
                                            y: 40,
                                        });
                                    }}
                                >
                                    Cancel
                                </CropButton>
                                <CropButton
                                    color="#fff"
                                    background="#522e92"
                                    onClick={() => {
                                        handleCrop();
                                    }}
                                >
                                    Crop
                                </CropButton>
                            </CropButtonDiv>
                        </Container>
                    </Modal>
                </BackContainer>
            )}
        </MainContainer>
    );
}

export default ProfilePicture;
const MainContainer = styled.div`
    position: relative;
    width: fit-content;
`;
const ImageContainer = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 50%;
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
`;
const EditDiv = styled.label`
    background-color: #8561c6;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const EditIcon = styled.img`
    display: inline-block;
    height: 20px;
    width: 20px;
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;

    /* width: 80vw; */
    /* display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; */
`;
const Container = styled.div`
    /* width: 400px;
	height: 400px; */
    padding: 20px;
    width: 80vw;
    height: 100%;
    overflow: scroll;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
`;
const ImageDiv = styled.div`
    height: 75vh;
    overflow: scroll;
    border-radius: 16px;
`;
const CropButtonDiv = styled.div`
    /* height: 250px;
	width: 100%; */
    /* height: 40px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    height: 40px;
`;
const CropButton = styled.div`
    height: 100%;
    width: fit-content;
    padding: 0 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background: ${(props) => (props.background ? props.background : "#fff")};
    color: ${(props) => (props.color ? props.color : "#fff")};
    border: 1 solid;
    border-color: ${(props) => (props.background ? props.background : "#fff")};
    cursor: pointer;
    margin-right: 15px;
    :last-child {
        margin-right: 0;
    }
`;
const ImageURL = styled.img`
    /* height: 350px;
	width: 400px; */
    border-radius: 4px;
    /* object-fit: contain; */
    /* height: 80vh; */
    /* width: 100%; */
    height: 100%;
    /* height: 80vh; */
`;
const UrlDiv = styled.div`
    /* display: flex;
	align-items: center;
	justify-content: center; */
    /* width: 400px; */
    /* height: 400px; */
    /* height: 80vh; */
    /* width: 60%; */

    /* height: 80vh; */
    border-radius: 4px;
`;
