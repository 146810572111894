import React, { useState } from "react";
import Delete from "../../assets/images/delete.svg";
import View from "../../assets/images/visible.svg";
import Edit from "../../assets/images/edit.svg";
import Image from "../Image";
import TD from "../TD";
import Text from "../Text";
import "./style.css";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import downArrow from "../../assets/images/down-arrow.svg";

function LeadListItem({
    ID,
    name,
    university,
    course,
    country,
    phone,
    source,
    qualification,
    sourceImage,
    setModal,
    setDeleteId,
    id,
    selectedLead,
    setSelectedLead,
    setIsViewMore,
    item,
    type,
}) {
    const history = useHistory();

    return (
        <>
            <MainDiv
                className="item"
                selected={selectedLead === item.id ? true : false}
            >
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                    width="9%"
                >
                    #{ID}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    // width="15%"
                    width="13%"
                    style={{wordBreak:"normal"}}
                >
                    {item.name}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="150px"
                    // width="15%"
                    width="13%"
                >
                    {item.phone}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="18%"
                    style={{ wordBreak: "break-all" }}
                >
                    {item.email}
                </TD>
                {/* <TD color="#000" fontSize="14px" fontWeight="500">
				{country}
			</TD> */}
                {/* <TD color="#000" fontSize="14px" fontWeight="500" width="175px">
          country_name
        </TD>
        <TD color="#000" fontSize="14px" fontWeight="500" width="150px">
          {source ? (
            <Text
              color="#000"
              fontSize="14px"
              fontWeight="500"
              // style={{ marginLeft: "20px" }}
            >
              {source}
            </Text>
          ) : (
            <img
              src={sourceImage}
              style={{ width: 35, height: 35, borderRadius: 10 }}
              alt="source"
            />
          )}
        </TD> */}
                <TD
                    color="#000"
                    fontWeight="500"
                    fontSize="14px"
                    // width="150px"
                    // width="15%"
                    width="13%"
                >
                    {item.lead_age.total_days} days
                </TD>
                {/* <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="15%"
                >
                    {item.lead_campaign}
                </TD> */}
                <TD
                    color="#000"
                    fontWeight="500"
                    fontSize="14px"
                    // width="150px"
                    // width="18%"
                    width="15%"
                >
                    {item.country_name}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    // width="15%"
                    width="13%"
                >
                    {item.lead_source}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    // width="15%"
                    width="13%"
                    style={{wordBreak:"normal"}}
                >
                    {item.assign_to ? item.assign_to : `---`}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    // width="15%"
                    width="11%"
                >
                    {item.lead_stage ? item.lead_stage : `---`}
                </TD>
                <TD width="120px" style={{ display: "flex" }}>
                    {/* eye icon for view data */}
                    <IconContainer
                        onClick={(e) => {
                            e.preventDefault();
                            setIsViewMore(true);
                            setSelectedLead(item);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Icon src={View} alt="Icon" />
                    </IconContainer>

                    <IconContainer
                        onClick={(e) => {
                            e.preventDefault();

                            history.push(
                                `/leadgenerator/edit-leads/${item.id}/`
                            );
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Icon src={Edit} alt="Icon" />
                    </IconContainer>

                    <IconContainer
                        onClick={(e) => {
                            e.preventDefault();
                            setModal(true);
                            setDeleteId(id);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Icon src={Delete} alt="Icon" />
                    </IconContainer>
                </TD>

                {/* <TD
        color="#000"
        fontSize="16px"
        fontWeight="500"
        width="13%"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {qualification}
      </TD> */}
                {/* <TD width="4%">
        <a
          onClick={(e) => {
            e.preventDefault();
            // history.push("/leadgenerator/edit-leads/", item);
            history.push(`/leadgenerator/edit-leads/${item.id}/`);
          }}
          // to={{
          // 	pathname: "/leadgenerator/edit-leads/",
          // 	state: {
          // 		leadID: id,
          // 	},
          // }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={Edit}
            style={{ display: "block", width: "15px" }}
            alt="Icon"
          />
        </a>
      </TD> */}
                {/* <TD width="1%">
        <a
          onClick={(e) => {
            e.preventDefault();
            setModal(true);
            setDeleteId(id);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={Delete}
            style={{ display: "block", width: "15px" }}
            alt="Icon"
          />
        </a>
      </TD> */}
            </MainDiv>
            {/* {selectedLead === item.id && (
				<ExtraBox>
					{item.country_name && (
						<DetailBox>
							<DetailTitle>Country:</DetailTitle>
							<DetailText>{item.country_name}</DetailText>
						</DetailBox>
					)}
					<DetailBox>
						<DetailTitle>Lead Source:</DetailTitle>
						<DetailText>{item.lead_source}</DetailText>
					</DetailBox>
					<DetailBox>
						<IconContainer
							onClick={(e) => {
								e.preventDefault();

								history.push(
									`/leadgenerator/edit-leads/${item.id}/`
								);
							}}
							style={{ cursor: "pointer" }}
						>
							<Icon src={Edit} alt="Icon" />
						</IconContainer>

						<IconContainer
              onClick={(e) => {
                // e.preventDefault();
                setModal(true);
                setDeleteId(id);
              }}
              style={{ cursor: "pointer" }}
            >
              <Icon src={Delete} alt="Icon" />
            </IconContainer>
					</DetailBox>
				</ExtraBox>
			)} */}
        </>
    );
}

export default LeadListItem;

const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: ${(props) => (props.selected ? null : "1px solid #E6EFF5")};
    padding: 20px 30px;
    align-items: center;
`;

const DownIcon = styled.img`
    transform: ${(props) => (props.selected ? "rotate(180deg)" : null)};
    cursor: pointer;
`;
const ExtraBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px 20px 30px;
    border-bottom: 1px solid #e6eff5;
`;
const DetailBox = styled.div`
    display: flex;
    align-items: center;
`;
const DetailTitle = styled.div`
    color: #808080;
    font-size: 17px;
    font-weight: 500;
`;
const DetailText = styled.div`
    color: #000;
    font-size: 16px;
    margin-left: 4px;
    font-weight: 500;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:nth-child(1) {
        width: 25px;
    }
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 80%;
    display: block;
    object-fit: cover;
`;
