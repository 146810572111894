import { useEffect, useState } from "react";
import "./App.css";
import SuperAdmin from "./pages/superAdmin/index";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import LeadGeneratorAdmin from "./pages/leadGeneratorAdmin/index";
import LeadManagerAdmin from "./pages/leadManagerAdmin/index";
import SalesPerson from "./pages/salesPerson/index";
import Login from "./pages/login";
import Store from "./contexts/Store";

import MainRouter from "./routing/routers/MainRouter";

function App() {

	return (
		<Store>
			<MainRouter />
		</Store>
	);
}

export default App;
