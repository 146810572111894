import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import logo from "../../assets/images/logo.svg";
import NavBarItem from "./navBarItem";

function NavBar({ data }) {
	const [link, setLink] = useState(window.location.pathname);

	return (
		<div className="navBar">
			<div className="background.container">
			<div className="top">
				<img src={logo} alt="logo" />
			</div>
			</div>
			<div className="bottom">
				<ul>
					{data?.map((item) => {
						let index = null;
						if (item?.inner) {
							item.inner.forEach((item, num) => {
								if (
									link === item.link ||
									link === item.addLink
								) {
									index = num;
								}
							});
						}
						return (
							<>
								{item.inner ? (
									<NavBarItem
										item={item}
										key={item.name}
										active={index}
										setLink={setLink}
									/>
								) : (
									<NavBarItem
										item={item}
										key={item.name}
										active={
											link === item.link ||
											`${link}/` === item.link
												? "true"
												: "false"
										}
										setLink={setLink}
									/>
								)}
							</>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default NavBar;
