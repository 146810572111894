import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";
import MainDashboard from "./mainDashboard";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import AddNewLead from "./addNewLead";
import Source from "./source";
import Leads from "./leads";
import EditLead from "./EditLead";

function LeadGeneratorAdmin() {
	const [openNav, setOpenNav] = useState(false);
	return (
		<div className="body">
			<NavBar
				openNav={openNav}
				data={navBarData}
				setOpenNav={setOpenNav}
			/>
			<div className="body-container">
				<Header setOpenNav={setOpenNav} />
				<Switch>
					<Route
						path="/leadgenerator/"
						exact
						component={MainDashboard}
					/>

					<Route
						path="/leadgenerator/add-leads/"
						component={AddNewLead}
					/>
					<Route
						path="/leadgenerator/:leadsURL/"
						exact
						component={Leads}
					/>
					<Route
						path="/leadgenerator/edit-leads/:leadID/"
						exact
						component={AddNewLead}
					/>
					{/* <Route
						path="/leadgenerator/:leads/"
						exact
						component={Leads}
					/> */}
					{/* <Route path="/leadgenerator/sources/" component={Source} /> */}
				</Switch>
			</div>
		</div>
	);
}

export default LeadGeneratorAdmin;
