import axios from "axios";

//Demo

export const API = "https://developers-leads-admin.talrop.works/api/v1";

export const baseConfig = axios.create({
    baseURL: "https://developers-leads-admin.talrop.works/api/v1",
});



//Live

// export const API = "https://crm-api.talrop.works/api/v1";

// export const baseConfig = axios.create({
//     baseURL: "https://crm-api.talrop.works/api/v1",
// });
// Local

// export const API = "http://localhost:8000/api/v1";

// export const baseConfig = axios.create({
//     baseURL: "http://localhost:8000/api/v1",
// });

//Server

// export const API = "http://localhost:8000/api/v1";

// export const baseConfig = axios.create({
//     baseURL: "http://localhost:8000/api/v1",
// });

// for all
export default {
    API_ENDPOINT: API,
};
