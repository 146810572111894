import dashboard from "../../assets/images/dashboard-menu.svg";
import campus from "../../assets/images/campus-menu.svg";
import admin from "../../assets/images/admin-menu.svg";
import Program from "../../assets/images/program.svg"
import university from "../../assets/images/university.svg";
import webinar from "../../assets/images/webinar.svg";
import notices from "../../assets/images/notices.svg";
// import notices from "../../assets/images/help.png";

const navBarData = [
    {
        img: dashboard,
        text: "Dashboard",
        link: "/superadmin/",
    },
    { img: Program,
        text: "Add Program",
        link: "/superadmin/add-program/",
        add: true,
        addLink: "/superadmin/add-program/",
    },

    {
        img: admin,
        text: "Admins",
        inner: [
            {
                text: "Sales Manager",
                link: "/superadmin/sales-manager/",
                add: true,
                addLink: "/superadmin/add-sales-manager/",
            },
            {
                text: "Lead Generator",
                link: "/superadmin/lead-generator/",
                add: true,
                addLink: "/superadmin/add-lead-generator/",
            },
            {
                text: "Admission Consultant",
                link: "/superadmin/admission-consultant/",
                add: true,
                addLink: "/superadmin/add-admission-consultant/",
            },
         
        ],
    },
];
export default navBarData;
