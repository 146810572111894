import React, { useEffect, useState } from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import styled from "styled-components";

function AssignedListItem({
    studentId,
    name,
    email,
    phone,
    source,
    status,
    assign_to,
    item,
    setIsViewMore,
    setSelectedLead,
}) {
    const [statusLabel, setStatusLabel] = useState("Not Intrested");
    const [statusBackground, setStatusBackground] = useState();
    const [statusColor, setStatusColor] = useState();

    useEffect(() => {
        if (status === "Not interested") {
            setStatusLabel("Not Intrested");
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        } else if (status === "Interested" || status === "Need Followup") {
            setStatusLabel(status);
            setStatusBackground("#FFBB5426");
            setStatusColor("#FFBB54");
        } else if (status === "Approved" || status === "Student" || status === "Converted") {
            setStatusLabel(status);
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
        } else {
            setStatusLabel(status);
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        }
    }, [status]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "20px 0",
                alignItems: "center",
            }}
        >
            <TD style={{}} width="5%" color="#000" fontSize="16px" fontWeight="400">
                {studentId}
            </TD>

            <TD style={{}} width="12%" color="#000" fontSize="16px" fontWeight="400">
                {name}
            </TD>
            {/* <TD
                width="12%"
                color="#000"
                fontWeight="400" fontSize="16px"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
                {email}
            </TD> */}
            <TD width="13%" color="#000" fontSize="16px" fontWeight="400">
                {phone}
            </TD>

            <TD style={{ overflow: "hidden", textOverflow: "ellipsis" }} width="10%" color="#000" fontSize="16px" fontWeight="400">
                {source}
            </TD>
            <TD style={{ overflow: "hidden", textOverflow: "ellipsis" }} width="12%" color="#000" fontSize="16px" fontWeight="400">
                {item?.nationality_name}
            </TD>
            <TD style={{ overflow: "hidden", textOverflow: "ellipsis" }} width="12%" color="#000" fontSize="16px" fontWeight="400">
                {item?.leader_name}
            </TD>
            <TD style={{ overflow: "hidden", textOverflow: "ellipsis" }} width="12%" color="#000" fontSize="16px" fontWeight="400">
                {assign_to}
            </TD>
            <TD style={{ overflow: "hidden", textOverflow: "ellipsis" }} width="10%" color="#000" fontSize="16px" fontWeight="400">
                {item?.lead_stage}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                }}
                width="100px"
                // color="#000"
                fontWeight="400" color="#3ccb7f"
                fontSize="15px"
                onClick={() => {
                    setIsViewMore(true);
                    setSelectedLead(item);
                }}
            >
                View More
            </TD>

            {/* <TD width="5%">
				<a
					onClick={(e) => {
						e.preventDefault();
					}}
					href="#"
				>
					<img
						src={Icon}
						style={{ display: "block", transform: "rotate(90deg)" }}
						alt="Icon"
					/>
				</a>
			</TD> */}
        </div>
    );
}

export default AssignedListItem;

const StatusText = styled.p`
    /* color: ${(props) => (props.color ? props.color : "#fff")};
	background: ${(props) => (props.background ? props.background : "#fff")}; */
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-weight: 600;
    font-size: 16px;
`;
