import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Bar, Pie } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TD from "../../components/TD";
import moment from "moment";

function FollowupTable({ width, image, title, background, data }) {
	return (
		<MainContainer width={width}>
			<Div>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="10%"
					style={{ marginLeft: "20px" }}
				>
					ID No.
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Lead Name
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Status
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Follow Up Medium
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Last Update
				</TH>
			</Div>
			{data && (
				<>
					{data.map((item) => (
						<Body key={item?.id}>
							<TH
								color="#7B6294"
								fontSize="15px"
								height="60px"
								fontWeight="500"
								width="10%"
								style={{ marginLeft: "20px" }}
							>
								{item?.lead_id}
							</TH>
							<TH
								color="#7B6294"
								fontSize="15px"
								height="60px"
								fontWeight="500"
								width="20%"
								// style={{ background: "red" }}
							>
								{item?.name}
							</TH>
							<TH
								color="#7B6294"
								fontSize="15px"
								height="60px"
								fontWeight="500"
								width="20%"
								// style={{ background: "red" }}
							>
								{item?.status}
							</TH>
							<TH
								color="#7B6294"
								fontSize="15px"
								height="60px"
								fontWeight="500"
								width="20%"
								// style={{ background: "red" }}
							>
								{item?.lead_source_data?.name}
							</TH>
							<TH
								color="#7B6294"
								fontSize="15px"
								height="60px"
								fontWeight="500"
								width="20%"
								// style={{ background: "red" }}
							>
								{moment(item.date_updated).format("DD-MM-yyyy")}
							</TH>
						</Body>
					))}
				</>
			)}
		</MainContainer>
	);
}

export default FollowupTable;

const MainContainer = styled.div`
	background-color: #fff;
	// width: ${(props) => (props.width ? props.width : "100%")};
	box-sizing: border-box;
	box-shadow: -1.41438px 1.41438px 25px rgba(0, 0, 0, 0.05);
	overflow: scroll;
	border-radius: 10px;
	margin-top: 15px;
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f1f8fe;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #cce7ff;
`;

const TH = styled.div`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: left;
	width: ${(props) => (props.width ? props.width : "15%")};
	// padding: 10px 0;
	height: ${(props) => (props.height ? props.height : "60px")};
	display: flex;
	align-items: center;
`;

const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #e7e7e7;
`;

const Right = styled.div`
	width: 30%;
`;
const ImageDiv = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	display: inline-block;
	padding: 8px;
	border-radius: 8px;
`;
const TitleDiv = styled.div`
	margin: 15px 0;
`;

const Image = styled.img``;
const SubDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${(props) => (props.margin ? props.margin : "0")};
`;
