import React from "react";
import Container from "../../components/Container";
import Image from "../../components/Image";
import Text from "../../components/Text";
import ImageContainer from "../../components/ImageContainer";
import styled from "styled-components";

const DashboardItem = ({ images, title, num }) => {
    return (
        <Container
            style={{ display: "flex", alignItems: "center" }}
            width="auto"
            margin="0"
            type="dashboard"
        >
            <ImageContainer
                style={{
                    marginRight: "10px",
                }}
                padding="25px"
                width="32px"
                height="32px"
                type="dashboard"
            >
                <Image src={images} alt="title" />
            </ImageContainer>
            <div
                style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "20px 0",
                }}
            >
                <Text type="dashboardItemNumber">{num}</Text>
                <CategoryText>{title}</CategoryText>
            </div>
        </Container>
    );
};

export default DashboardItem;

const CategoryText = styled.h3`
    color: #8c8888;
    font-size: 20px;
    font-weight: 400;
    @media all and (max-width: 1380px){
        font-size: 18px;
}
    @media only screen and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 1080px){
        font-size: 15px;
}
    @media all and (max-width: 980px){
        font-size: 14px;

}
@media all and (max-width: 360px){
    font-size: 13px;
}
`;
