import React from "react";
import styled from "styled-components";

export default function ReportTable({ data, selectedFields }) {
    return (
        <StyledTableWrapper>
            <StyledTable>
                <thead>
                    <tr>
                        {Object.keys(data[0]).map((key) => (
                            <StyledTh key={key}>
                                {key !== "id" &&
                                    key
                                        .replace(/[_-]/g, " ")
                                        .replace(/\b\w/g, (c) =>
                                            c.toUpperCase()
                                        )}
                            </StyledTh>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            {Object.keys(data[0]).map((key) => (
                                <StyledTd
                                    key={`${index}-${key}`}
                                    // style={{ width: columnWidths[key] }}
                                    // style={{ maxWidth: "300px" }}
                                >
                                    {item[key] ? item[key] : `---`}
                                </StyledTd>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </StyledTableWrapper>
    );
}
const StyledTableWrapper = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    overflow: auto;
`;

const StyledTable = styled.table`
    min-width: 100%;
    width: auto;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    text-transform: capitalize;
`;

const StyledTh = styled.th`
    font-family: "Inter", sans-serif;
    background-color: #edf5fe;
    border-top: 1px solid #e7e7e7;
    text-align: left;
    color: #404040;
    font-weight: 600;
    &:first-child {
        border-top-left-radius: 16px;
    }
    &:last-child {
        border-top-right-radius: 16px;
    }
`;

const StyledTd = styled.td`
    font-family: "Inter", sans-serif;
    border-top: 1px solid #e7e7e7;
    &:first-child {
        border-left: 1px solid #e7e7e7;
    }
    &:last-child {
        border-right: 1px solid #e7e7e7;
    }
`;
