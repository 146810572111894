import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";

import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title";
import TD from "../../components/TD";
import Table from "../../components/Table";
import loadingGif from "../../assets/lottie/loading.gif";
import MainPagination from "../../components/includes/MainPagination";
import SalesReportListItem from "./SalesReportListItem";

export default function SalesReport() {
    const { state } = useContext(Context);

    const [search, setSearch] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);

    const [SalesReport, setSalesReport] = useState([]);
    const [isTeamData, setIsTeamData] = useState(true);

    const getSalesReport = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-report/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                // if(isTeamData){
                params: isTeamData && { team_data: isTeamData },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesReport(data.reports);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
                setLoading(false);
            });
    };
    useEffect(() => {
        getSalesReport();
    }, [isTeamData]);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Title fontSize="30px" fontWeight="700">
                    Sales Report
                </Title>
                {/* <SearchDiv>
                    <Form className="search-container">
                        <SearchIcon
                            src={
                                require("./../../assets/images/search.svg")
                                    .default
                            }
                        />
                        <SearchField
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for something"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Form>
                </SearchDiv> */}
            </div>
            <SelectDiv>
                <DashboardButtonDiv>
                    <DashboardButton
                        background={isTeamData === true ? "#f0f8fe" : "#fff"}
                        onClick={() => {
                            setIsTeamData(true);
                        }}
                    >
                        <ButtonName color={isTeamData === true ? "#542c8f" : "#9d9d9d"}>Team</ButtonName>
                    </DashboardButton>
                    <DashboardButton
                        background={isTeamData === false ? "#f0f8fe" : "#fff"}
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            setIsTeamData(false);
                        }}
                    >
                        <ButtonName color={isTeamData === false ? "#542c8f" : "#9d9d9d"}>Sales Person</ButtonName>
                    </DashboardButton>
                </DashboardButtonDiv>
            </SelectDiv>

            {!isLoading ? (
                <>
                    {SalesReport.length > 0 ? (
                        <>
                            <Table margin="30px 0" borderRadius="30px" padding="30px 0">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "0 20px 20px 20px",
                                    }}
                                    className="table-head"
                                >
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="105px"
                                        // width="10%"
                                    >
                                        Date
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="15%"
                                    >
                                        Name
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        // width="15%"
                                    >
                                        Total Leads
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        // width="15%"
                                    >
                                        Total Admissions
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="14%"
                                    >
                                        Target
                                    </TD>

                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="14%"
                                    >
                                        Target Achieved
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        Revenue Generated
                                    </TD>

                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        No. of Admissions
                                    </TD> */}
                                </div>
                                <div className="table-body">
                                    {SalesReport.map((item) => {
                                        return <SalesReportListItem item={item} />;
                                    })}
                                </div>
                            </Table>
                        </>
                    ) : (
                        <LoaderWrapper>
                            <EmptyText>No data found</EmptyText>
                        </LoaderWrapper>
                    )}
                </>
            ) : (
                <LoaderWrapper>
                    <LoadingIcon src={loadingGif} alt="icon" />
                </LoaderWrapper>
            )}

            <MainPagination paginationData={paginationData} setCurrentPage={setCurrentPage} currentPage={currentPage} />
        </Wrapper>
    );
}
const SearchDiv = styled.div`
    /* margin-right: 20px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #aea7fd;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;

const EmptyDiv = styled.div``;
const LoaderWrapper = styled.div`
    /* border: 1px solid #000; */
    height: calc(100vh - 230px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingIcon = styled.img`
    width: 70px;
    display: block;
    object-fit: cover;
`;
const EmptyText = styled.div`
    font-size: 26px;
    opacity: 0.6;
    font-weight: 500;
`;
const SelectDiv = styled.div`
    display: flex;
    margin-top: 15px;
`;
const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 8px;
    // justify-content: space-between;
`;
const DashboardButton = styled.div`
    background-color: ${(props) => (props.background ? props.background : "#fff")};
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
`;
