import React from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import moment from "moment";

function UnassignedListItem({
    setAssignModal,
    setIsCheck,
    item,
    setSlectedLead,
}) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "20px 0",
                // marginBottom: "20px",
                alignItems: "center",
            }}
        >
            <TD style={{}} width="10%" color="#505887" fontSize="16px">
                {item.lead_id}
            </TD>

            <TD style={{}} width="15%" color="#505887" fontSize="16px">
                {item.name}
            </TD>
            <TD width="20%" color="#505887" fontSize="16px">
                {item.email}
            </TD>
            <TD width="15%" color="#505887" fontSize="16px">
                {item.phone}
            </TD>

            <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                width="15%"
                color="#505887"
                fontSize="16px"
            >
                {item.lead_source}
            </TD>

            {/* <TD width="11%" color="#505887" fontSize="16px">
				{country}
			</TD> */}
            <TD width="15%" color="#505887" fontSize="16px">
                {item?.lead_age?.total_days} days
            </TD>
            {/* <TD width="11%" color="#505887" fontSize="16px">
				{moment(date).format("DD-MM-yyyy")}
			</TD> */}
            <TD
                width="10%"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setAssignModal(true);
                    setIsCheck([item.id]);
                    setSlectedLead(item);
                }}
            >
                <a
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <img src={Line} style={{ display: "block" }} alt="Icon" />
                </a>
            </TD>

            {/* <TD width="5%">
				<a
					onClick={(e) => {
						e.preventDefault();
					}}
				>
					<img src={Icon} style={{ display: "block" }} alt="Icon" />
				</a>
			</TD> */}
        </div>
    );
}

export default UnassignedListItem;
