export const validatePassword = (string) => {
	let rules_array = [
		{
			key: "character",
			status: false,
			text: "Should contain at least 8 characters",
		},
		{
			key: "small_letter",
			status: false,
			text: "Should contain a lowercase (small) letter (a -z)",
		},
		{
			key: "capital_letter",
			status: false,
			text: "Should contain a uppercase (capital) letter (A - Z)",
		},
		{
			key: "number",
			status: false,
			text: "Should contain at least one number (0-9)",
		},
		{
			key: "symbol",
			status: false,
			text: "Should contain at least one symbol ($,@,#,%,!,*,?,&)",
		},
	];

	let has_small_letter = /[a-z]/.test(string);
	let has_capital_letter = /[A-Z]/.test(string);
	let has_number = /\d/.test(string);
	let has_special_char = /[$@#%!*?&]/.test(string);
	let has_min_length = string.length >= 8;
	let is_strong_password =
		string.length > 0
			? has_small_letter &&
			  has_capital_letter &&
			  has_number &&
			  has_special_char &&
			  has_min_length
				? true
				: false
			: false;

	rules_array.find((item) => item.key === "character").status =
		has_min_length;
	rules_array.find((item) => item.key === "small_letter").status =
		has_small_letter;
	rules_array.find((item) => item.key === "capital_letter").status =
		has_capital_letter;
	rules_array.find((item) => item.key === "number").status = has_number;
	rules_array.find((item) => item.key === "symbol").status = has_special_char;

	let password_strength = {
		is_strong_password: is_strong_password,
		has_small_letter: has_small_letter,
		has_capital_letter: has_capital_letter,
		has_number: has_number,
		has_special_char: has_special_char,
		has_min_length: has_min_length,
	};
	return password_strength;
};

// string capitilization
export const capitalizeString = (string) => {
	let substrings = string.split(" ");
	substrings.forEach(
		(item, index) =>
			(substrings[index] = item.charAt(0).toUpperCase() + item.slice(1))
	);
	string = substrings.join(" ");
	return string;
};
