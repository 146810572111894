import dashboard from "../../assets/images/dashboard-menu.svg";

import leads from "../../assets/images/menu-leads.svg";
import team from "../../assets/images/menu-team.svg";
import sources from "../../assets/images/menu-sources.svg";
import sales from "../../assets/images/menu-sales.svg";
import notice from "../../assets/images/notices.svg";

const navBarData = [
    {
        img: dashboard,
        text: "Dashboard",
        link: "/salesmanager/",
    },
    {
        img: leads,
        text: "Leads",
        inner: [
            {
                text: "New Leads",
                link: "/salesmanager/totalleads/",
            },
            {
                text: "Lead Status",
                link: "/salesmanager/assignedleads/",
            },
            // {
            //     text: "Add Lead",
            //     link: "/salesmanager/assignedleads/",
            // },
            // {
            //     text: "Un-Assigned leads",
            //     link: "/salesmanager/unassignedleads/",
            // },
        ],
    },
    // {
    //     img: sales,
    //     text: "Admissions",
    //     // inner: [
    //     // {
    //     //     text: "All Admission Consultants",
    //     //     link: "/salesmanager/allsalesperson/",
    //     // },
    //     // {
    //     //     text: "Sales Teams",
    //     //     link: "/salesmanager/salesteams/",
    //     // },
    //     // {
    //     //     text: "Sales Report",
    //     //     link: "/salesmanager/sales-report/",
    //     // },
    //     // {
    //     //     text: "Ranking",
    //     //     link: "/salesmanager/ranking/",
    //     // },
    //     // {
    //     //     text: "Inactive Consultants",
    //     //     link: "/salesmanager/inactiveconsultants/",
    //     // },
    //     // ],
    // },
    {
        img: team,
        text: "Sales Team",
        inner: [
            // {
            //     text: "Sales Teams",
            //     link: "/salesmanager/salesteams/",
            // },
            {
                text: "Ranking",
                link: "/salesmanager/ranking/team-leader",
            },
            // {
            //     text: "Sales Report",
            //     link: "/salesmanager/sales-report/",
            // },
            // {
            //     text: "Ranking",
            //     link: "/salesmanager/ranking/",
            // },
            // {
            //     text: "Inactive Consultants",
            //     link: "/salesmanager/inactiveconsultants/",
            // },
        ],
    },

    {
        img: sales,
        text: "Admission Consultant",
        inner: [
            {
                text: "All Admission Consultants",
                link: "/salesmanager/allsalesperson/",
            },
            // {
            //     text: "Sales Teams",
            //     link: "/salesmanager/salesteams/",
            // },
            // {
            //     text: "Sales Report",
            //     link: "/salesmanager/sales-report/",
            // },
            {
                text: "Ranking",
                link: "/salesmanager/ranking/admission-consultant",
            },
            // {
            //     text: "Inactive Consultants",
            //     link: "/salesmanager/inactiveconsultants/",
            // },
        ],
    },
    {
        img: notice,
        text: "Reports",
        inner: [
            {
                text: "Sales Report",
                link: "/salesmanager/sales-report/",
            },
            // {
            //     text: "Sales Teams",
            //     link: "/salesmanager/salesteams/",
            // },
            // {
            //     text: "Sales Report",
            //     link: "/salesmanager/sales-report/",
            // },
            // {
            //     text: "Ranking",
            //     link: "/salesmanager/ranking/",
            // },
            // {
            //     text: "Inactive Consultants",
            //     link: "/salesmanager/inactiveconsultants/",
            // },
        ],
    },
    {
        img: sources,
        text: "Managers Home",
        inner: [
            {
                text: "Sources",
                link: "/salesmanager/sources",
            },
            {
                text: "My Team",
                link: "/salesmanager/salesteams/",
            },
            {
                text: "Inactive Consultants",
                link: "/salesmanager/inactiveconsultants/",
            },

            // {
            //     text: "Sales Report",
            //     link: "/salesmanager/sales-report/",
            // },
            // {
            //     text: "Ranking",
            //     link: "/salesmanager/ranking/",
            // },
        ],
    },
];
export default navBarData;
