import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import styled from "styled-components";

function AlertBox({ message, severity, autoClose, onClose, setMessage }) {
    const [open, setOpen] = useState(!!message);

    useEffect(() => {
        setOpen(!!message);
        if (autoClose) {
            const timer = setTimeout(() => {
                setOpen(false);
                setMessage(null);
                onClose && onClose();
            }, 3000); // Adjust the timeout as needed
            return () => clearTimeout(timer);
        }
    }, [message, autoClose, onClose]);
    return (
        <>
            {open && (
                <AlertWrapper>
                    <Alert
                        severity={severity}
                        closeText="Close"
                        onClose={() => {
                            setOpen(false);
                            setMessage(null);
                        }}
                    >
                        {message}
                    </Alert>
                </AlertWrapper>
            )}
        </>
    );
}

export default AlertBox;

const AlertWrapper = styled.div`
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    min-width: 400px;
    max-width: 70%;
    min-height: 40px;
`;
