import ApiService from "../utils/fetch";

export const ListSalesPersonService = async (data) => {
    let response = await ApiService.get(`/sales-persons/sales-persons/`, data);
    return response;
};

export const GetSalesPersonService = async (id, data) => {
    let response = await ApiService.get(`/sales-persons/sales-person/${id}/`, data);
    return response;
};

export const AddSalesPersonService = async (data) => {
    let state = data.state.user_details.access_token;
    var formdata = new FormData();
    formdata.append("username", data.userName);
    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("country", data.country);
    formdata.append("phone", data.phone);
    formdata.append("employee_id", data.employeeID);
    formdata.append("target", data.target);
    formdata.append("lead_manager", data.salesManagerName);
    // formdata.append("crop", data.cropSize);

    if (data.profile !== null) {
        formdata.append("image", data.profile);
    }

    let response = await ApiService.post(`/sales-persons/sales-persons/`, {
        body: formdata,
        state: state,
    });
    return response;
};

export const UpdateSalesPersonService = async (data) => {
    var formdata = new FormData();
    formdata.append("username", data.username);
    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("address", data.address);
    formdata.append("phone", data.phone);
    let response = await ApiService.put(`/sales-persons/sales-person/${data.id}/`, { body: formdata });
    return response;
};

export const DeleteSalesPersonService = async (id) => {
    let response = await ApiService.delete(`/sales-persons/sales-person/${id}/`);
    return response;
};
