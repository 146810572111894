import styled from "styled-components";

export default styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    /* max-width: 100%; */

    @media all and (max-width: 880px) {
        grid-template-columns: 1fr;
    }

    /* @media only screen and (max-width: 1440px) {
    width: 30%;
    margin-right: 5%;
    :nth-child(3n) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 15px;
  }
  @media only screen and (max-width: 980px) {
    width: 48%;
    margin-right: 2%;
    :nth-child(2n) {
      margin-right: 0;
    }
    :nth-child(3n) {
      margin-right: 2%;
    }
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    margin-right: 0%;
  } */
`;
