const TotalLeadListData = [
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
  {
    studentId: "001",
    name: "Jomana Ah",
    assignto: "Ahmad",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    country: "Dubai",
    source: "Facebook",
    date: "12-5-21",
  },
];
export default TotalLeadListData;
