import "./style.css";
import Text from "../../../components/Text";
import { useHistory } from "react-router-dom";
import Delete from "./../../../assets/images/delete.svg";
import editIcon from "./../../../assets/images/edit.svg";

function SocialMedia({
    data,
    setModal,
    width,
    fontSize,
    add,
    setDeleteId,
    setEditId,
    handleOpen,
}) {
    const history = useHistory();
    return (
        <div
            style={{
                backgroundColor: "#F9F9F9",
                padding: " 20px ",
                width: width ? width : "100%",
                boxSizing: "border-box",
                // margin: margin ? margin : "55px auto",
                border: add ? "2px dashed #DFEAF2" : "none",
                borderRadius: "10px",
                textAlign: "center",
                marginBottom: "10px",
                position: "relative",
                alignItems: "center",
            }}
            className="box"
            onClick={() => {
                if (add) {
                    history.push("/leadgenerator/sources/");
                }
            }}
        >
            {add ? (
                <img style={{ margin: "14px 15px" }} src={data.image} />
            ) : (
                <div
                class="white-background"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                    }}
                >
                    <img
                     class="image"
                        style={{
                            margin: "14px 14px",
                            objectFit: "contain",
                            width: "60px",
                            height: "60px",
                        }}
                        src={data.image}
                    />
                </div>
            )}
            <Text
            className="social-media"
                style={{
                    fontSize: fontSize ? fontSize : "10px",
                    marginLeft: "15px",
                    fontFamily: "Inter",
                }}
            >
                {data.name}
            </Text>
            <img
           
                onClick={() => {
                    setEditId(data.id);
                    handleOpen();
                }}
                style={{
                    width: "14px",
                    objectFit: "cover",
                    cursor: "pointer",
                    position: "absolute",
                    right: "45px",
                    top: "20px",
                }}
                src={editIcon}
            />
            <img
                onClick={() => {
                    setModal(true);
                    setDeleteId(data.id);
                }}
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "15px",
                    top: "20px",
                }}
                src={Delete}
            />
        </div>
    );
}

export default SocialMedia;
