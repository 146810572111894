import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import addbutton from "../../assets/images/addbutton.svg";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import { AddLeadsService } from "../../service/leads";
import { useHistory, useParams } from "react-router-dom";
import { AddSourceService, ListProgramService,ListSourceService } from "../../service/sources";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

function AddNewLead() {
    const location = useLocation();
    const history = useHistory();
    const [sources, setSources] = useState([]);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const { leadID } = useParams();
    const [alertStatus, setAlertStatus] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const [loading, setLoading] = useState(true);

    const clickFrom = location?.state?.keyy;

    // Field states
    const [leadName, setLeadName] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [secondaryPhone, setsecondaryPhone] = useState("");
    const [address, setAddress] = useState("");
    const [selectedSource, setSelectedSource] = useState("Not Specified");
    const [education, setEducation] = useState("");
    const [description, setDescription] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [gender, setGender] = useState("");
    const [feeAmount, setFeeAmount] = useState("");
    const [dateOfEnquiry, setDateOfEnquiry] = useState(
        new Date().toLocaleDateString("en-CA")
    );

    const [countrySearch, setCountrySearch] = useState("");
    const [countryCode, setCountryCode] = useState([]);
    const [program, setProgram] = useState([]);
    const [selectedNationalityCode, setSelectedNationalityCode] = useState(231);
    const [selectedCountryCode, setSelectedCountryCode] = useState(231);
    const [selectedProgram, setSelectedProgram] = useState("");
    const [form, setFormValues] = useState({});
    const { state } = useContext(Context);

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };
    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const leadSourceOptions = [
        {
            id: 2,
            title: "Reference",
        },
        {
            id: 4,
            title: "B2C",
        },
        {
            id: 6,
            title: "Cold Data",
        },
        {
            id: 8,
            title: "Lusha",
        },
        {
            id: 10,
            title: "LinkedIn",
        },
    ];

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        // if (ExcelFile !== null && typeof ExcelFile === "object") {
        // 	formdata.append("leads_excel_file", ExcelFile[0]);
        // }

        formdata.append("name", leadName);
        if (
            selectedCountryCode !== "" &&
            selectedCountryCode !== null &&
            selectedCountryCode !== "Select Country"
        ) {
            formdata.append("country", selectedCountryCode);
        }
        if (
            selectedNationalityCode !== "" &&
            selectedNationalityCode !== null &&
            selectedNationalityCode !== "Select Nationality"
        ) {
            formdata.append("nationality", selectedNationalityCode);
        }
        if (!leadID) {
           
            formdata.append("program", selectedProgram);
        }
        if (email !== "" && email !== null) {
            formdata.append("email", email);
        }
        if (phone !== "") {
            formdata.append("phone", phone);
        }
        if (secondaryPhone !== "") {
            formdata.append("phone_2", secondaryPhone);
        }
        if (!leadID) {
            formdata.append("enquiry_date", dateOfEnquiry);
            formdata.append("lead_source", selectedSource);
        }
        // formdata.append("qualification", education);
        if (education !== "" && education !== null) {
            formdata.append("lead_campaign", education);
        }
        if (feeAmount !== "") {
            formdata.append("fee_amount", feeAmount);
        }
        if (description !== "" && description !== null) {
            formdata.append("description", description);
        }

        var method = leadID ? baseConfig.put : baseConfig.post;
        var URL = leadID ? `/leads/lead/${leadID}/` : `/leads/lead/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
           
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    if (data.duplicate_lead) {
                        setAlertStatus("warning");
                        setIsAlert(true);
                        setAlertMessage("Duplicate entries found");
                        setTimeout(() => {
                            history.push(
                                "/admission-consultant/duplicate-leads/"
                            );
                        }, 1000);
                    } else {
                        setAlertStatus("success");
                        setIsAlert(true);
                        setAlertMessage("Lead added success");
                        setTimeout(() => {
                            history.push("/admission-consultant/list-leads/");
                        }, 1000);
                    }
                } else {
                    setAlertStatus("error");
                    setIsAlert(true);
                    setAlertMessage("Lead added failed");
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    const { data } = error.response.data;
                    setAlertStatus("error");
                    setIsAlert(true);
                    setAlertMessage(data.message);
                } else {
                    // Handle the error when response or response.data is undefined
                    console.error("Error response or data is undefined:", error);
                    // You can set a default error message or handle it in another way
                    setAlertStatus("error");
                    setIsAlert(true);
                    setAlertMessage("An error occurred.");
                }
            })
    };
    const getProgramData = async () => {
        let data = await ListProgramService (state);
        if (data.StatusCode === 6000) {
            setProgram(data?.data);
        } else {
            alert(data.data.message);
        }
    };

    const getData = async () => {
        let data = await ListSourceService(state);
        if (data.StatusCode === 6000) {
            setSources(data?.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    //get country code api
    const getCountryCode = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/countries/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: countrySearch,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setPrograms(data.data);
                    setCountryCode(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const handleClose = () => {
        setIsAlert(false);
        setAlertStatus("");
    };

    const AlertBox = ({ status }) => {
        return (
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={isAlert}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {/* {status === "success" ? (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added success — <strong>check it out!</strong>
						</Alert>
					) : (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added failed — <strong>check it out!</strong>
						</Alert>
					)} */}
                    <Alert
                        onClose={handleClose}
                        severity={status}
                        sx={{ width: "100%" }}
                    >
                        {alertMessage} — <strong>check it out!</strong>
                    </Alert>
                </Snackbar>
            </Stack>
        );
    };
    useEffect(() => {
        getProgramData();
    }, []);
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getCountryCode();
    }, [countrySearch]);

    // single Leads
    const getSingleLead = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/${leadID}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadName(data.data.name);
                    setsecondaryPhone(data.data.phone_2);
                    setCountry(data.data.country_name);
                    validate(data.data.email);
                    setPhone(data.data.phone);
                    setSelectedSource(data.data.lead_source);
                    setSelectedNationalityCode(data.data.nationality);
                    setSelectedProgram(data.name);
                    // setEducation(data.data.qualification);
                    setEducation(data.data.lead_campaign);

                    setDescription(data.data.description);
                    setFeeAmount(data.data.fee_amount);
                    setDateOfEnquiry(data.data.enquiry_date);
                    setSelectedCountryCode(data.data.country);
                    setLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        if (leadID) {
            getSingleLead();
        }
    }, [leadID]);

    return (
        <>
            {/* {!loading && ( */}
            <Wrapper>
                <AlertBox status={alertStatus} />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                    }}
                >
                    <div style={{ display: "flex", margin: "0 12px" }}>
                        {leadID ? (
                            <Title fontSize="35x">Edit Lead</Title>
                        ) : (
                            <Title fontSize="35x">Add New Lead</Title>
                        )}
                    </div>
                </div>
                {/* <div style={{ display: "flex", margin: "0 12px" }}>
                <Text color="#A3A3A3" fontSize="16px">
                    ID No :{" "}
                </Text>
                <Text style={{ margin: "0 6px" }} color="black" fontSize="19px">
                    #0021{" "}
                </Text>
            </div> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Container style={{ flexWrap: "wrap " }} width="100%">
                        <form id="form">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Input
                                    onChange={(value) => {
                                        setDateOfEnquiry(value);
                                        setError(false);
                                    }}
                                    type="date"
                                    // placeholder="+33 l 7 01 02 03 04"
                                    name="Date Of Enquiry"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    // onWheel={true}
                                    disabled={leadID ? true : false}
                                    value={dateOfEnquiry}
                                />
                                <Input
                                    onChange={(value) => {
                                        setLeadName(value);
                                        setError(false);
                                    }}
                                    type="name"
                                    name="Name"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={leadName}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            ></div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <Input
                                    onChange={(value) => {
                                        validate(value);
                                        setError(false);
                                    }}
                                    type="email"
                                    // placeholder="example@example.com"
                                    name="Email"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={email}
                                />
                                <Input
                                    onChange={(value) => {
                                        setPhone(value);
                                        setError(false);
                                    }}
                                    type="number"
                                    // placeholder="+33 l 7 01 02 03 04"
                                    name="Phone Number (Primary)"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={phone}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <Input
                                    onChange={(value) => {
                                        setsecondaryPhone(value);
                                        setError(false);
                                    }}
                                    type="number"
                                    // placeholder="+33 l 7 01 02 03 04"
                                    name="Phone Number (Secondary)"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={secondaryPhone}
                                    // disabled={phone ? false : true}
                                />

                                <SelectInput
                                    name="Nationality"
                                    type="text"
                                    placeholder="Select Nationality"
                                    onChange={(value) => {
                                        setSelectedNationalityCode(value);
                                        setError(false);
                                    }}
                                    defaultValue="Select Nationality"
                                    options={countryCode}
                                    selectValue={"id"}
                                    selectName={"name"}
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={selectedNationalityCode}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <SelectInput
                                    name="Country"
                                    type="text"
                                    placeholder="Select Country"
                                    onChange={(value) => {
                                        setSelectedCountryCode(value);
                                        setError(false);
                                    }}
                                    defaultValue="Select Country"
                                    options={countryCode}
                                    selectValue={"id"}
                                    selectName={"name"}
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={selectedCountryCode}
                                />
                                   <SelectInput
                                    name="Program"
                                    type="text"
                                    placeholder="Select Program"
                                    onChange={(value) => {
                                        setSelectedProgram(value);
                                        setError(false);
                                    }}
                                    defaultValue="Select Program"
                                    options={program}
                                    selectValue={"id"}
                                    selectName={"name"}
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={selectedProgram}
                                />
                          
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                      <SelectInput
                                    name="Lead Source"
                                    type="text"
                                    options={sources}
                                    selectName={"name"}
                                    selectValue={"id"}
                                    defaultValue="Choose Source"
                                    onChange={(value) => {
                                        setSelectedSource(value);
                                        setError(false);
                                    }}
                                    disabled={leadID ? true : false}
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={selectedSource}
                                />
                                    <Input
                                    onChange={(value) => {
                                        setDescription(value);
                                        setError(false);
                                    }}
                                    padding="20px 15px 90px 20px"
                                    type="text"
                                    placeholder="Write your description here..."
                                    name="Description"
                                    isError={isError}
                                    errorMessage={errorMessage}
                                    fieldName={fieldName}
                                    value={description}
                                />
                                </div>
                            {/* <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                            
                            </div> */}
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "21px",
                                }}
                            >
                                <Button
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    backgroundColor="#0FA76F"
                                    fontSize="15px"
                                    padding="21px 70px"
                                    onClick={(e) => {
                                        if (leadName === "") {
                                            setError(true);
                                            setFieldName("Name");
                                            setErrorMessage("Enter lead name");
                                            alert("Enter Lead Name");
                                        } else if (phone === "") {
                                            setError(true);
                                            setFieldName("Phone");
                                            setErrorMessage(
                                                "Please enter phone number"
                                            );
                                            alert("Please enter phone number");
                                        } else if (
                                            email !== "" &&
                                            email !== null &&
                                            emailValidation === false
                                        ) {
                                            setError(true);
                                            setFieldName("Email");
                                            setErrorMessage(
                                                "Please enter a valid email"
                                            );
                                            alert("Enter a valid  email id");
                                        } else if (
                                            selectedSource === "" ||
                                            selectedSource ===
                                                "Not Specified" ||
                                            selectedSource === "Choose Source"
                                        ) {
                                            setError(true);
                                            setFieldName("Lead Source");
                                            setErrorMessage(
                                                "Please select a valid source"
                                            );
                                            alert("select source");
                                        } else {
                                            e.preventDefault();
                                            // logAll();
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <Text color="white" fontSize="15px">
                                        Submit
                                    </Text>
                                </Button>

                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (clickFrom === "follow") {
                                            history.push(
                                                "/admission-consultant/follow-up/"
                                            );
                                        } else {
                                            history.push(
                                                "/admission-consultant/list-leads/"
                                            );
                                        }
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "0 22px",
                                    }}
                                    backgroundColor="#F4F4F4"
                                    fontSize="15px"
                                    padding="21px 70px"
                                >
                                    <Text color="#0FA76F" fontSize="15px">
                                        Cancel
                                    </Text>
                                </Button>
                            </div>
                        </form>
                    </Container>
                </div>
            </Wrapper>
            {/* )} */}
        </>
    );
}

export default AddNewLead;

const SmallBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    width: 49%;
`;
