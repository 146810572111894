import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title.js";
import search from "../../assets/images/header-search.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Program from "./Program/Program";
import DeleteModal from "../../components/includes/DeleteModal";
import { Modal } from "@material-ui/core";
import Input from "../../components/Input/input";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import Stage from "./stage/Stage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SelectInput from "../../components/selectInput/Selectinput";

function AddStage() {
	const { state } = useContext(Context);
	const [open, setOpen] = useState(false);
	const [leadStage, setLeadStage] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [searchItem, setSearchItem] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const [isModal, setModal] = useState(false);
	const [editId, setEditId] = useState("");
	const [stageName, setStageName] = useState("");
	const [getStage, setGetStage] = useState([]);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const param = useParams();
	console.log(param, "param -->");
	const handleDeleteData = (pk) => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.delete(`/leads/delete-lead-stage/${pk}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setModal(false);
					getLeadStage();
				} else if (StatusCode == 6001) {
					alert(data.message);
					getLeadStage();
				}
				//post
			})
			.catch((error) => {});
	};
	const handleSubmit = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("name", stageName);
		formdata.append("program", param.id);

		var method = editId ? baseConfig.put : baseConfig.post;
		var URL = editId
			? `/leads/create-lead-stage/${editId}/`
			: `/leads/create-lead-stage/`;

		method(URL, formdata, {
			headers: {
				Authorization: "Bearer " + accessToken,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode === 6000) {
					// history.push("/superadmin/list-campus/");
					getLeadStage();
					handleClose();
					setStageName("");
					if (editId) {
						setEditId("");
					}

					//post
				} else if (6001) {
					alert(data.data.message);
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					alert(error.response.data.message);
				} else {
					alert("An error occurred.");
				}
			});
	};

	const getLeadStage = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/leads/program-lead-stages/${param.id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setGetStage(data);
					setLoading(false);
					console.log(getStage, "getStage --> ");
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getLeadStage();
	}, []);
	return (
		<Wrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
				}}
				className="top-title-wrapper"
			>
				<div>
					<Title className="stagehead" fontSize="35px">
						Add Stages
					</Title>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					className="top-title-large-right"
				>
					<Button
						className="program"
						onClick={(e) => {
							e.preventDefault();
							handleOpen();
						}}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						backgroundColor="#0FA76F"
						fontSize="15px"
					>
						<img src={addbutton} alt="Logo" />
						<Text
							style={{ marginLeft: "12px" }}
							color="white"
							fontSize="15px"
						>
							Add Stage
						</Text>
					</Button>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				{!isLoading ? (
					<StageContainer
					// className="stage"
					// style={{
					// flexWrap: "wrap",
					// borderRadius: "23px",
					// display: "flex",
					// // height: "10% ",
					// 	display: "grid",
					// 	gridTemplateColumns: "1fr 1fr 1fr",
					// 	gridGap: "30px",
					// }}
					// width="100%"
					>
						{console.log(getStage, "getStage ->")}
						{getStage.length > 0 ? (
							getStage.map((stage) => (
								<Stage
									data={stage}
									// width="30%"
									fontSize="18px"
									setDeleteId={setDeleteId}
									setModal={setModal}
									setEditId={setEditId}
									handleOpen={handleOpen}
									stageName={stageName}
									getLeadStage={getLeadStage}
								/>
							))
						) : (
							<h1>No Data Found</h1>
						)}
					</StageContainer>
				) : (
					<Div>
						<LoadingGif
							src={
								require("./../../assets/images/loading-buffering.gif")
									.default
							}
							alt="Loading"
						/>
					</Div>
				)}
			</div>
			{isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)}
			<Modal open={open} onClose={handleClose}>
				<ModalContainer>
					<Container
						style={{ flexWrap: "wrap ", borderRadius: "23px" }}
						width="100%"
					>
						<form id="form">
							<div>
								<Title
									style={{ marginBottom: "10px" }}
									fontSize="20px"
								>
									Add new Stage
								</Title>

								<Text
									color="#808080"
									fontSize="15px"
									style={{ marginTop: "20px" }}
								>
									Stage Name
								</Text>
								<Input
									value={stageName}
									onChange={(value) => setStageName(value)}
									width="100%"
									type="name"
									placeholder="Enter Stage name here"
								/>
							</div>
							{/* <div style={{ display: "flex", marginTop: "2%" }}>
                        <Input onChange={(value) => handleChangeValue("isQuality", value)} width="26px" type="checkbox" />

                        <Text style={{ marginTop: "2%" }} color="#7B6294" fontSize="15px">Quality Source</Text>

                    </div> */}
							<div
								style={{
									display: "flex",
									marginTop: "21px",
									justifyContent: "space-between",
									gap: "10px",
								}}
							>
								<Button
									onClick={(e) => {
										e.preventDefault();
										handleSubmit();

										// document.getElementById("form").submit();
									}}
									style={{
										display: "flex",
										alignItems: "center",
										width: "40%",
									}}
									bacerkgroundColor="#5A2D8F"
									fontSize="15px"
									padding="15px 50px"
								>
									<Text color="white" fontSize="15px">
										Submit
									</Text>
								</Button>

								<Button
									onClick={() => {
										handleClose();
									}}
									style={{
										display: "flex",
										alignItems: "center",
										width: "40%",
									}}
									backgroundColor="#F4F4F4"
									fontSize="15px"
									padding="15px 50px"
								>
									<Text color="#0FA76F" fontSize="15px">
										Cancel
									</Text>
								</Button>
							</div>
						</form>
					</Container>
				</ModalContainer>
			</Modal>
		</Wrapper>
	);
}

export default AddStage;

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	margin-left: 500px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;
const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	max-height: 85vh;
	min-height: 40vh;
	overflow-y: scroll;
	background: #fff;
	border: 1px solid #fff;
	border-radius: 12px;
	box-shadow: 24px;
	padding: 20px;
	@media all and (max-width: 980px) {
		width: 430px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 300px;
		padding: 10px;
	}
	@media all and (max-width: 360px) {
		width: 286px;
		height: 50vh;
	}
`;
const StageContainer = styled.div`
	border-radius: 23px;
	display: grid;
	height: 10%;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	width: 100%;

	@media all and (max-width: 980px) {
		grid-template-columns: 1fr 1fr;
	}
	@media all and (max-width: 640px) {
		grid-template-columns: 1fr;
	}
`;
