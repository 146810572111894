import ApiService from "../utils/fetch";

export const ListLeadManagerService = async (data) => {
	let response = await ApiService.get(`/leads/lead-manager/`, data);
	return response;
};

export const GetLeadManagerService = async (id, data) => {
	let response = await ApiService.get(`/leads/lead-manager/${id}/`, data);
	return response;
};

export const AddLeadManagerService = async (data) => {
	let state = data.state.user_details.access_token;
	var formdata = new FormData();
	formdata.append("username", data.userName);
	formdata.append("name", data.name);
	formdata.append("email", data.email);
	formdata.append("password", data.password);
	formdata.append("employee_id", data.employeeID);
	formdata.append("target", data.target);
	if (data.profile !== null) {
		formdata.append("image", data.profile);
	}

	let response = await ApiService.post(`/leads/lead-manager/`, {
		body: formdata,
		state: state,
	});
	return response;
};

export const UpdateLeadManagerService = async (data) => {
	var formdata = new FormData();
	formdata.append("username", data.username);
	formdata.append("name", data.name);
	formdata.append("email", data.email);
	formdata.append("password", data.password);
	let response = await ApiService.put(`/leads/lead-manager/${data.id}/`, {
		body: formdata,
	});
	return response;
};

export const DeleteLeadManagerService = async (id) => {
	let response = await ApiService.delete(`/leads/lead-manager/${id}/`);
	return response;
};
