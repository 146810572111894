import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "./../../components/Button";
import Text from "./../../components/Text";

function ExcelDuplicateModal({ setModal, excelDuplicateData }) {
	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setModal(false);
				}}
			></Overlay>
			<Modal>
				<Container>
					<Title>Data duplication identified</Title>
					<Paragraph>
						Some of the data uploaded have{" "}
						{excelDuplicateData.total_failed_leads} dupliacte
						entries. Would you like to see which?
					</Paragraph>
					<Div>
						<Button
							onClick={() => {
								setModal(false);
							}}
							style={{
								display: "flex",
								alignItems: "center",
								width: "30%",
								height: "50px",
								padding: 0,
								marginRight: "10px",
							}}
							backgroundColor="#F4F4F4"
							fontSize="15px"
							className="delete-modal-btn"
						>
							<Text color=" #522e92" fontSize="15px">
								Cancel
							</Text>
						</Button>

						<Download
							href={excelDuplicateData.failed_leads_download}
							target="_blank"
						>
							<Text color=" white" fontSize="15px">
								Download Sheet
							</Text>
						</Download>
					</Div>
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default ExcelDuplicateModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 23px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	height: 200px;
	width: 500px;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media only screen and (max-width: 1280px) {
		width: 90%;
		margin: 0 auto;
	}
`;
const Title = styled.h4`
	text-align: center;
	font-size: 18px;
`;
const Div = styled.div`
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: flex-end;
`;
const Paragraph = styled.p`
	color: #000;
	font-size: 15px;
	padding: 0 20px;
`;
const Download = styled.a`
	background: #5a2d8f;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
	/* border: 1px solid #917afd; */
	border-radius: 8px;
	cursor: pointer;
	margin-right: 10px;
`;
