import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import Source from "../leadManagerAdmin/source";
import AddSalesPerson from "./allSalesPerson";
import Ranking from "./ranking";
import UnAssigned from "./unAssigned";
import Assigned from "./assignedLeads";
import MainDashboard from "./mainDashboard";
import TotalLeads from "./totalLeads";
import SalesTeams from "./salesTeam";
import AddSalesTeam from "./AddSalesTeam";
import SalesTeamSingle from "./SalesTeamSingle";
import DisabledConsultants from "./DisabledConsultants";
import SalesReport from "./SalesReport";
import ReportPage from "../../components/includes/ReportPage";

function LeadManagerAdmin() {
    const [openNav, setOpenNav] = useState(false);
    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/salesmanager/"
                        exact
                        component={MainDashboard}
                    />
                    <Route
                        path="/salesmanager/sources/"
                        exact
                        component={Source}
                    />
                    <Route
                        path="/salesmanager/allsalesperson/"
                        exact
                        component={AddSalesPerson}
                    />
                    <Route
                        path="/salesmanager/inactiveconsultants/"
                        exact
                        component={DisabledConsultants}
                    />
                    {/* <Route
                        path="/salesmanager/sales-report/"
                        exact
                        component={SalesReport}
                    /> */}
                    <Route
                        path="/salesmanager/sales-report/"
                        exact
                        component={ReportPage}
                    />
                    <Route
                        path="/salesmanager/ranking/:personType"
                        exact
                        component={Ranking}
                    />
                    <Route
                        path="/salesmanager/unassignedleads/"
                        exact
                        component={UnAssigned}
                    />
                    <Route
                        path="/salesmanager/assignedleads/"
                        exact
                        component={Assigned}
                    />
                    <Route
                        path="/salesmanager/totalleads/"
                        exact
                        component={TotalLeads}
                    />
                    <Route
                        path="/salesmanager/salesteams/"
                        exact
                        component={SalesTeams}
                    />
                    <Route
                        path="/salesmanager/salesteams/:id/"
                        exact
                        component={SalesTeamSingle}
                    />

                    <Route
                        path="/salesmanager/edit-salesteam/:edit_id/"
                        component={AddSalesTeam}
                    />
                    <Route
                        path="/salesmanager/add-salesteams/"
                        component={AddSalesTeam}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default LeadManagerAdmin;
