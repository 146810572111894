import React, { useState, useEffect, useContext } from "react";
import Button from "../../components/Button";
import Container from "../../components/Container";
import TotalLeadListData from "../../components/leadmanager/totalLeadListData";
import TotalleadListItem from "../../components/leadmanager/totalleadListItem";
import Pagination from "../../components/pagination/pagination";
import SelectInput from "../../components/selectInput/Selectinput";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Text from "../../components/Text";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import { ListLeadsService } from "../../service/leads";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import MainPagination from "../../components/includes/MainPagination";
import NoDataFound from "../../components/noRecords/NoDataFound";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AlertBox from "../../components/includes/AlertBox";
import searchBtn from "./../../assets/images/header-search.svg";

function TotalLeads() {
	const [chooseLead, setChooseLead] = useState();
	const [monthType, setMonthType] = useState(false);
	const [count, setCount] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(true);
	const [salesPerson, setSalesPerson] = useState([]);
	const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
	const [option, setOption] = useState("0");
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [bulkSelection] = useState([
		{ id: 1, count: 5 },
		{ id: 2, count: 10 },
		{ id: 3, count: 20 },
		{ id: 4, count: 50 },
	]);

	const [searchItem, setSearchItem] = useState("");

	// alert mui message
	const [message, setMessage] = useState(null); // Track the message state

	// Get current posts
	const indexOfLastPost = currentPage * count;
	const indexOfFirstPost = indexOfLastPost - count;
	const currentPosts = TotalLeadListData.slice(
		indexOfFirstPost,
		indexOfLastPost
	);

	const [leads, setLeads] = useState([]);

	// const getData = async () => {
	// 	let data = await ListLeadsService(state);
	// 	if (data.StatusCode === 6000) {
	// 		setLeads(data?.data?.data);
	// 		setPaginationData(data.data.paginator);
	// 	} else {
	// 		alert(data.data.message);
	// 	}
	// };

	const getData = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/leads/lead/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					page: currentPage,
					assigned: false,
					search: searchItem,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLeads(data.data);
					setPaginationData(data.paginator);
					setLoading(false);
				} else {
					setLoading(false);
					setMessage("Some error occured. please try again later");
				}
			})
			.catch((error) => {
				setMessage(error);
			});
	};

	const getSalesPerson = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig

			.get("/sales-persons/sales-person-data-table/", {
				// .get("/sales-persons/sales-persons-list", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					// is_disabled: false,
					choice: "assign",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSalesPerson(data);
					setLoading(false);
				} else {
					setMessage("Some error occured. please try again later");
					setLoading(false);
				}
			})
			.catch((error) => {
				setMessage(error);
			});
	};

	useEffect(() => {
		getData();
	}, [currentPage, searchItem]);

	useEffect(() => {
		getSalesPerson();
	}, []);

	function handleChange(event) {
		setOption(event.target.value);
	}

	const assignLead = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/leads/assign-sales-person/",
				{
					sales_person: selectedSalesPerson,
					leads: isCheck,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode, message, data } = response.data;
				if (StatusCode == 6000) {
					getSalesPerson();
					// setPeopleInfo([]);
					setIsCheck([]);
					setOption("0");
					setSelectedSalesPerson("");
					getData();

					setChooseLead("");
				} else {
					setMessage("Some error occured.Please try again later");
				}
			})
			.catch((error) => {
				setMessage(error);
			});
	};

	const handleSelectAll = (e) => {
		if (isLoading === false) {
			setIsCheckAll(!isCheckAll);
			setIsCheck(leads.map((li) => li.id));
			if (isCheckAll) {
				setIsCheck([]);
			}
		}
	};

	const handleBulkelect = (value) => {
		if (isLoading === false) {
			// setIsCheckAll(!isCheckAll);
			setIsCheck(leads.slice(0, value).map((li) => li.id));
		}
	};

	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
	};
	const handleLeadSelect = (event) => {
		setSelectedSalesPerson(event.target.value);
	};
	return (
		<>
			<AlertBox
				message={message}
				setMessage={setMessage}
				severity={
					message && message.includes("successful")
						? "success"
						: "error"
				}
				autoClose={true}
				onClose={() => setMessage(null)}
			/>
			{/* {!isLoading ? ( */}
			<Wrapper className="totalLeads">
				<Title>Assign Leads</Title>

				<div
					style={{
						marginTop: "20px",
						// display: "flex",
						// justifyContent: "space-between",
					}}
				>
					<Container
						margin="0"
						width="100%"
						style={{ overflow: "scroll", maxHeight: "600px" }}
					>
						<div>
							<h3 class="subhead"
								style={{
									fontWeight: 600,
									fontSize: "20px",
								}}
							>
								Admission Consultants
							</h3>
						</div>
				
						<TableContainer>
							<ACTable>
								<FirstRow>
									<TFH
										className="table-headings common-table"
										rowSpan="2"
									>
										Name
									</TFH>

									{/* <TFH
                                        className="table-headings common-table"
                                        rowSpan="2"
                                    >
                                        Total Leads
                                    </TFH> */}
									<TFH
										className="table-headings common-table"
										// rowSpan="2"
										colSpan="3"
									>
										Leads
									</TFH>
									{/* <TFH
                                            className="table-headings common-table"
                                            // rowSpan="2"
                                            colSpan="2"
                                        >
                                            Target
                                        </TFH>
                                        <TFH
                                            className="table-headings common-table"
                                            rowSpan="2"
                                        >
                                            Target Achieved
                                        </TFH> */}

									<TFH
										className="table-headings common-table"
										colSpan="2"
									>
										Quality Leads
									</TFH>

									{/* <TFH
										className="table-headings common-table"
										colSpan="2"
									>
										SSM Leads
									</TFH>
									<TFH
										className="table-headings common-table"
										colSpan="2"
									>
										OTHM Leads
									</TFH>
									<TFH
										className="table-headings common-table"
										colSpan="2"
									>
										Singhania Leads
									</TFH>
									<TFH
										className="table-headings common-table"
										colSpan="2"
									>
										DBA Leads
									</TFH> */}
								</FirstRow>
								<SecondRow>
									{/* <TSH
                                        className="table-sub-headings common-table"
                                        rowSpan="2"
                                    >
                                        Name
                                    </TSH>
                                    <TSH
                                        className="table-sub-headings common-table"
                                        rowSpan="2"
                                    >
                                        Target
                                    </TSH> */}
									<TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Actual
									</TSH>
									<TSH className="table-sub-headings common-table">
										Shared
									</TSH>
									{/* <TSH className="table-sub-headings common-table">
                                            Amount
                                        </TSH>
                                        <TSH className="table-sub-headings common-table">
                                            Percentage
                                        </TSH> */}

									<TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Percentage
									</TSH>

									{/* <TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Percentage
									</TSH>
									<TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Percentage
									</TSH>
									<TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Percentage
									</TSH>
									<TSH className="table-sub-headings common-table">
										Count
									</TSH>
									<TSH className="table-sub-headings common-table">
										Percentage
									</TSH> */}
									{/* <TSH className="table-sub-headings common-table"></TSH> */}
								</SecondRow>
								{salesPerson.map((item) => (
									<DetailRow>
										<TDR className="table-td common-table">
											{item.name}
										</TDR>

										<TDTR className="table-td common-table">
											{item.total_leads}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.actual_lead_share}%
										</TDTR>
										<TDTR className="table-td common-table">
											{/* {item.actual_lead_share}% */}
											{item.lead_shared}%
										</TDTR>

										{/* <TDTR className="table-td common-table">
                                                {item.my_target} AED
                                            </TDTR>
                                            <TDTR className="table-td common-table">
                                                {item.actual_lead_share}%
                                            </TDTR> */}
										{/* <TDTR className="table-td common-table">
                                            {item.total_leads}
                                        </TDTR> */}
										{/* <TDTR className="table-td common-table">
                                                {item.target_achieved}%
                                            </TDTR> */}

										<TDTR className="table-td common-table">
											{item.quality_leads_count}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.quality_leads}%
										</TDTR>

										{/* <TDTR className="table-td common-table">
											{item.total_ssm_leads}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.ssm_leads}%
										</TDTR> */}
										{/* OTHM */}
										{/* <TDTR className="table-td common-table">
											{item.total_othm_leads}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.othm_leads}%
										</TDTR> */}

										{/* Singania */}
										{/* <TDTR className="table-td common-table">
											{item.total_singhaniya_leads}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.singhaniya_leads}%
										</TDTR>

										<TDTR className="table-td common-table">
											{item.total_dba_leads}
										</TDTR>
										<TDTR className="table-td common-table">
											{item.dba_leads}%
										</TDTR> */}
									</DetailRow>
								))}
							</ACTable>
						</TableContainer>
					</Container>
					<Text
					className="table-subhead"
						style={{
							marginBottom: "20px",
							marginTop: "20px",
							marginLeft: "6px",
						}}
						
						color="#000"
						fontWeight="600"
					>
						Choose Admission Consultant :
					</Text>
					<AssignContainer>
						{/* <select
                                    className="select"
                                    name="option"
                                    onChange={handleChange}
                                >
                                    <option value="0">
                                        Select a Admission Consultant
                                    </option>
                                    {salesPerson.map((item) => (
                                        <option value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select> */}

						<SelectBox>
							<MuiBox>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">
										Admission Consultant
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={selectedSalesPerson}
										label="Admission Consultant"
										onChange={handleLeadSelect}
										sx={{
											"&:hover": {
											  "&& fieldset": {
												border: "1px solid #3ccb7f"
											  }
											}
										  }}
									>
										{salesPerson.map((item) => (
											<MenuItem value={item.id}>
												{item.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</MuiBox>
						</SelectBox>

						<AssignButton
		
							// style={{ marginRight: "20px" }}
							// fontSize="18px"
							// href="#"
							onClick={() => {
								if (!selectedSalesPerson) {
									setMessage("Select a consultant");
								} else if (isCheck.length == 0) {
									setMessage("Select lead");
								} else {
									assignLead();
								}
							}}
						>
							Assign
						</AssignButton>
					</AssignContainer>
				</div>

				<TableTopDiv>
					<Text
						color="#000000"
						fontSize="18px"
						fontWeight="600"
						style={{ marginBlock: "20px" }}
					>
						{leads.length} Leads
					</Text>

					<SearchDiv>
						<SearchForm className="search-container">
							<img src={searchBtn} alt="search" />
							<SeachInput
								className="input-box"
								type="text"
								name="search"
								placeholder="Search for something"
								onChange={(e) => setSearchItem(e.target.value)}
							/>
						</SearchForm>
					</SearchDiv>
				</TableTopDiv>
				{!isLoading ? (
					<>
					<div class="tableScroll">
						<Table margin="0" borderRadius="30px" padding="30px 0">
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									borderBottom: "1px solid #E6EFF5",
									padding: "0 30px 20px",
								}}
								className="table-head"
							>
								<TD
									width="3%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									<input
										type="checkbox"
										style={{ width: "50%" }}
										checked={isCheckAll}
										onChange={handleSelectAll}
										id="selectAll"
									/>
								</TD>
								<TD
									width="5%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									ID No.
								</TD>
								<TD
									width="12%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Lead Name{" "}
								</TD>
								<TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Lead Age
								</TD>
								<TD
									width="12%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Email
								</TD>
								<TD
									width="10%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Phone
								</TD>
								{/* <TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									University
								</TD> */}
								<TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Program
								</TD>
								<TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Country
								</TD>
								<TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Nationality
								</TD>

								<TD
									width="8%"
									color="#808080"
									fontWeight="500"
									fontSize="18px"
								>
									Source
								</TD>
								{/* <TD width="11%" color="#808080" fontWeight="500" fontSize="18px">
                        Date
                    </TD> */}
							</div>
							{leads.length > 0 ? (
								<div
									style={{ padding: "0" }}
									className="table-body"
								>
									{leads?.map((item) => {
										return (
											<TotalleadListItem
												ID={item.lead_id}
												name={item.name}
												phone={item.phone}
												country={item.country_name}
												date={item.date}
												email={item.email}
												assignto={item.assign_to}
												source={item.lead_source}
												key={item.id}
												id={item.id}
												isChecked={isCheck.includes(
													item.id
												)}
												handleClick={handleClick}
												item={item}
											/>
										);
									})}
								</div>
							) : (
								<NoDataFound />
							)}
						</Table>
						</div>
						<div style={{ marginTop: "20px" }}>
							<MainPagination
								paginationData={paginationData}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						</div>
					</>
				) : (
					<Div>
						<LoadingGif
							src={
								require("./../../assets/images/loading-buffering.gif")
									.default
							}
							alt="Loading"
						/>
					</Div>
				)}
			</Wrapper>
			{/* ) : ( */}

			{/* )} */}
		</>
	);
}

export default TotalLeads;

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 120px);
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;
const LeftDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 90%;
`;
const AssignButton = styled.div`
	background: #0FA76F;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 54px;
	font-size: 18px;
	font-weight: 500;
	/* margin: 0 auto; */
	color: #fff;
	border-radius: 4px;
	margin-left: 8px;
	cursor: pointer;
	@media all and (max-width: 480px){
		font-size: 12px;
		padding: 0 15px;
}
`;
const AssignContainer = styled.div`
	width: 800px;
	display: flex;
	align-items: center;
	margin-top: 10px;
	@media all and (max-width: 768px){
		width: 560px;
}
@media all and (max-width: 640px){
	width: 395px;
}
@media all and (max-width: 480px){
	width: 200px;
}
	
`;
const SelectBox = styled.div`
	margin-left: 10px;

	& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
		color: #3ccb7f !important;
	}
	& .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3ccb7f !important;
}
`;
const TableContainer = styled.div`
	overflow: scroll;
`;
const ACTable = styled.table`
	margin-top: 10px;
	margin-bottom: 20px;
	width: 100%;
	border-collapse: collapse;
	.common-table {
		border: 1px solid #e7e7e7;
		/* border-collapse: collapse; */
	}
`;
const FirstRow = styled.tr`
	background: #f9f9f9;
`;
const TFH = styled.th`
	text-align: center;
	vertical-align: middle;
	font-size: 14px;
	color: #808080;
	height: 40px;
`;
const SecondRow = styled.tr`
	background: #f9f9f9;
`;
const TSH = styled.th`
	text-align: center;
	vertical-align: middle;
	font-size: 12px;
	color: #808080;
	height: 30px;
`;
const DetailRow = styled.tr``;
const TDR = styled.td`
	padding: 9px 6px;
	font-size: 14px;
	color: #808080;
`;
const TDTR = styled.td`
	padding: 9px 6px;
	font-size: 14px;
	color: #808080;
	text-align: center;
`;
const TableTopDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top: 30px;
	@media all and (max-width: 480px){
		flex-wrap: wrap;
}

`;
const SearchDiv = styled.div``;
const SearchForm = styled.form`
	font-family: "Inter";
	font-size: 15px;
	/* padding: 9px 10px 14px 54px; */
	box-sizing: border-box;
	background: #f5f7fa;
	border-radius: 8px;
	width: 400px;
	border: 1px solid #3ccb7f ;
	height: 50px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	@media all and (max-width: 480px){
		height: 45px;
}
`;
const SeachInput = styled.input`
	outline: none;
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin-left: 5px;
	width: 100%;
	height: 95%;
	font-size: 15px;
`;
const MuiBox = styled(Box)`
	min-width: 450px;

	@media all and (max-width:640px){
		min-width: 300px;
	}
	@media all and (max-width:460px){
		min-width: 200px;
	}
`