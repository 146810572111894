import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import add from "../../assets/images/add.svg";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Progress_bar from "../../components/progressBar/progress";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import leadListData from "../../components/leadGeneratorAdmin/leadListData";
import Profile from "../../assets/images/profile-icon.svg";
import Profile1 from "../../assets/images/profile1.svg";
import Profile2 from "../../assets/images/profile2.svg";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import loadingIcon from "../../assets/lottie/loading.gif";
import { useParams } from "react-router-dom";
import MainPagination from "../../components/includes/MainPagination";

function Ranking() {
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const { personType } = useParams();
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [salesPerformance, setSalesPerformance] = useState([]);
    const [teamRanking, setTeamRanking] = useState([]);

    const [tableStatus] = useState([
        {
            id: 1,
            title: "Sales Person",
            value: "sales",
        },
        {
            id: 2,
            title: "Team Leader",
            value: "team",
        },
    ]);
    const [selectedDashboard, setSelectedDashboard] = useState(tableStatus[0].value);

    // Get current posts

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= Math.ceil(leadListData.length / count)) {
            setCurrentPage(pageNumber);
        }
    };

    const getSalesPerformance = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-persons/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    ranking: true,
                    leader_only: false,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerformance(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const getTeamRanking = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/teams/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    ranking: true,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamRanking(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        if (personType == "sales-person") {
            setSelectedDashboard("sales");
        } else {
            setSelectedDashboard("team");
        }
    }, [personType]);

    useEffect(() => {
        if (personType == "sales-person") {
            getSalesPerformance();
        } else {
            getTeamRanking();
        }

        // getSalesPerformance();
        // getTeamRanking();
    }, [personType]);

    // useEffect(() => {
    //     getSalesPerformance();
    //     // getTeamRanking();
    // }, [selectedDashboard === "sales"]);
    // useEffect(() => {
    //     // getSalesPerformance();
    //     getTeamRanking();
    // }, [selectedDashboard === "team"]);

    return (
        <Wrapper padding="20px 0.5%">
            <div style={{ marginBottom: "10px" }}>
                <div>
                    <Title fontSize="35px">Ranking</Title>
                </div>
                {/* <DashboardButtonDiv>
                    {tableStatus.map((data) => (
                        <DashboardButton
                            key={data.id}
                            background={
                                selectedDashboard === data.value
                                    ? "#8561C6"
                                    : "#fff"
                            }
                            onClick={() => {
                                setSelectedDashboard(data.value);
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedDashboard === data.value
                                        ? "#fff"
                                        : "#B4B4B4"
                                }
                            >
                                {data.title}
                            </ButtonName>
                        </DashboardButton>
                    ))}
                </DashboardButtonDiv> */}

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <Text
						style={{ marginTop: "10px", margin: "auto 30px" }}
						fontSize="15px"
					>
						Show
					</Text>
					<div className="number">
						<Text fontSize="18px" fontWeight="400" color="#522E92">
							{count}
						</Text>
						<div>
							<img
								onClick={() => {
									setCount(count + 1);
								}}
								src={add}
								alt="incerement"
							/>
							<img
								onClick={() => {
									if (count > 1) {
										setCount(count - 1);
									}
								}}
								src={add}
								alt="decrement"
								className="decrement"
							/>
						</div>
					</div>{" "}
					<Text style={{ margin: "auto 30px" }} fontSize="15px">
						Results
					</Text> */}
                    {/* <Button
            href="#"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            bacerkgroundColor="#5A2D8F"
            fontSize="15px"
          >
            <img style={{ width: "20px" }} src={filter} alt="Logo" />
            <Text style={{ marginLeft: "12px" }} color="white" fontSize="15px">
              Filter
            </Text>
          </Button> */}
                </div>
            </div>

            <div
                style={{
                    justifyContent: "space-between",
                    
                }}
                class="tableScroll"
            >
                {/* <div>
					<Text
						style={{ marginBottom: "30px", margin: "49px 19px" }}
						color="#522E92"
						fontSize="24px"
					>
						Sales persons weekly ranking
					</Text>
				</div> */}

                {!isLoading ? (
                    <>
                        {selectedDashboard === "sales" ? (
                            <>
                                {salesPerformance.map((data) => (
                                    <Container
                                        style={{
                               
                                            borderRadius: "23px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "100px",
                                            minWidth:"1280px",
                                       
                                        }}
                                        width="100%"
                                        padding="5px 10px"
                                        margin="0 0 15px"
                                    >
                                        {/* <div style={{ display: "flex" }}> */}
                                        <div
                                            style={{
                                                display: "flex",
                                              
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {data.image === null ? (
                                                    <ProfileImg src={Profile} alt="profile" />
                                                ) : (
                                                    <ProfileImg src={data.image} alt="profile" />
                                                )}
                                                <div
                                                    style={{
                                                        marginLeft: "12px",
                                                    }}
                                                >
                                                    <Text color="black" fontSize="18px">
                                                        {data.rank}
                                                    </Text>
                                                    <Text color="black" fontSize="16px">
                                                        {data.name}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Text
                                                        fontSize="15px"
                                                        color="black"
                                                        style={{
                                                            marginBottom: "8px",
                                                        }}
                                                    >
                                                        Target Achieved
                                                    </Text>
                                                    <Text fontSize="15px" color="black">
                                                        {data.target_achieved}%
                                                    </Text>
                                                </div>
                                                <Progress_bar
                                                    width="100%"
                                                    bgcolor=" #522E92"
                                                    progress={data.target_achieved}
                                                    height={10}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div
                                            style={{
                                             
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    // margin: "50px 30px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Target
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Revenue Generated
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Total Leads
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Leads from network
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Introduction
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Meeting
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Admission
                                                </Text>
                                                <Text
                                                    color="#808080"
                                                    fontSize="15px"
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Dead
                                                </Text>
                                                <Text
                                                    color="#808080"
                                                    fontSize="15px"
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Invalid
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    // margin: "0px 30px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.target}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.revenue_generated}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_leads}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.network_leads}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_introduction}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_meeting}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_admission}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_dead}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_invalid}
                                                </Text>
                                            </div>
                                        </div>
                                    </Container>
                                ))}
                                <div style={{ marginTop: "10px" }}>
                                    <MainPagination
                                        paginationData={paginationData}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                {teamRanking.map((data) => (
                                    <Container
                                        style={{
                                            flexWrap: "wrap ",
                                            borderRadius: "23px",
                                            display: "flex",
                                            minWidth:"1280px",
                                            height: "100px",
                                 
                                        }}
               
                                        padding="5px 20px"
                                        margin="0 0 15px"
                                    >
                                        {/* <div style={{ display: "flex" }}> */}
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "22%",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                //
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {data.image === null ? (
                                                    <ProfileImg src={Profile} alt="profile" />
                                                ) : (
                                                    <ProfileImg src={data.image} alt="profile" />
                                                )}
                                                <div
                                                    style={{
                                                        marginLeft: "12px",
                                                    }}
                                                >
                                                    <Text color="black" fontSize="18px">
                                                        {data.rank}
                                                    </Text>
                                                    <Text color="black" fontSize="16px">
                                                        {data.name}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                   
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Text
                                                        fontSize="15px"
                                                        color="black"
                                                        style={{
                                                            marginBottom: "8px",
                                                        }}
                                                    >
                                                        Target Achieved
                                                    </Text>
                                                    <Text fontSize="15px" color="black">
                                                        {/* &nbsp;&nbsp; */}
                                                        {data.target_achieved}%
                                                    </Text>
                                                </div>
                                                <Progress_bar
                                                    width="100%"
                                                    bgcolor=" #522E92"
                                                    progress={data.target_achieved}
                                                    height={10}
                                                />
                                            </div>
                                        </div>
                                        <hr display="block" margin="10px"/>
                                        <div
                                            style={{
                                                width:"77%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    // margin: "50px 30px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Target
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        minWidth:"16%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                
                                                >
                                                    Revenue Generated
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "23%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Total Leads
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        minWidth:"17%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                    min-width="17%"
                                                >
                                                    Leads from network
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Introduction
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Meeting
                                                </Text>
                                                <Text
                                                    // style={{ margin: "0px 30px" }}
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="#808080"
                                                    fontSize="15px"
                                                >
                                                    Admission
                                                </Text>
                                                <Text
                                                    color="#808080"
                                                    fontSize="15px"
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Dead
                                                </Text>
                                                <Text
                                                    color="#808080"
                                                    fontSize="15px"
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Invalid
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    // margin: "0px 30px",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.target}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        minWidth:"16%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                 
                                                >
                                                    {data.revenue_generated}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_assigned}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        minWidth:"17%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.network_leads}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_introduction}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_meeting}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_admission}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_dead}
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: "18%",
                                                        textAlign: "center",
                                                    }}
                                                    color="black"
                                                    fontSize="15px"
                                                >
                                                    {data.total_lead_invalid}
                                                </Text>
                                            </div>
                                        </div>
                                    </Container>
                                ))}
                                <div style={{ marginTop: "10px" }}>
                                    <MainPagination
                                        paginationData={paginationData}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                    />
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <LoaderContainer>
                        <LoadingGif src={loadingIcon} alt="icon" />
                    </LoaderContainer>
                )}
            </div>
        </Wrapper>
    );
}

export default Ranking;

const ProfileImg = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 50%;
`;

const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    width: fit-content;
    background: #fff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin: 10px 0 15px;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
    transition: 0.5s;
`;
const DashboardButton = styled.div`
    background-color: ${(props) => (props.background ? props.background : "#fff")};
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
        ${ButtonName} {
            transform: translateY(-2px) scale(1.005) translateZ(0);
            /* box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07); */
        }
    }
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
