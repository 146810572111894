import React from "react";

const Progress_bar = ({ bgcolor, progress, height, width }) => {
	let progressString = "";
	if (typeof progress !== "string") {
		progressString = progress.toString();
	} else {
		progressString = progress;
	}

	const Parentdiv = {
		height: height,
		width: width ? width : "65%",
		backgroundColor: "whitesmoke",
		borderRadius: 40,
	};

	const Childdiv = {
		height: "100%",
		width: `${
			progressString.slice(-1) !== "%"
				? progressString.concat("%")
				: progressString
		}`,
		maxWidth: "100%",
		backgroundColor: bgcolor,
		borderRadius: 40,
		textAlign: "right",
	};

	return (
		<div style={Parentdiv}>
			<div style={Childdiv}></div>
		</div>
	);
};

export default Progress_bar;
