import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Pie } from "react-chartjs-2";
import React, { useEffect, useState, useContext } from "react";

// mui imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";
import addSource from "../../assets/images/add-source.svg";
import Profile from "../../assets/images/profile-pic.svg";
import Progress_bar from "../../components/progressBar/progress";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";

import loadingGif from "../../assets/lottie/loading.gif";
import {
	BarChart,
	Bar,
	Brush,
	Cell,
	CartesianGrid,
	ReferenceLine,
	ReferenceArea,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ErrorBar,
	LabelList,
	Label,
	Rectangle,
	LineChart,
	Line,
	ResponsiveContainer,
} from "recharts";
import Welcome from "../../components/includes/Welcome";
import moment from "moment";
import WelcomeMessage from "../../components/includes/WelcomeMessage";

function MainDashboard() {
	const [monthType, setMonthType] = useState("day");
	// const [monthLeads, setMonthLeads] = useState();
	// const [yearLeads, setYearLeads] = useState();
	// const [yearList, setYearList] = useState();
	// const [yearLeadsCount, setYearLeadsCount] = useState();
	// const [monthConvertedLeads, setMonthConvertedLeads] = useState();
	// const [monthInterestedLeads, setMonthInterestedLeads] = useState();
	// const [monthNotInterestedLeads, setMonthNotInterestedLeads] = useState();
	const [salesPerformance, setSalesPerformance] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [dashboardDetails, setDashboardDetails] = useState({});
	const [teamData, setTeamData] = useState([]);
	const [isTeamDataLoading, setIsTeamDataLoading] = useState(false);
	const { state } = useContext(Context);
	const [lastYears, setLastYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(
		new Date().getMonth() + 1
	);
	// new Date().toLocaleString("default", { month: "long" })

	const selectedMonthAndYear = `${selectedYear}-${selectedMonth}-01`;

	// lead data
	const [yearlyLead, setYearlyLead] = useState([]);
	const [monthlyLead, setMonthlyLead] = useState([]);
	const [dailyLead, setDailylyLead] = useState([]);

	// max value
	const [maxYearValue, setMaxYearValue] = useState("");
	const [maxMonthValue, setMaxMonthValue] = useState("");
	const [maxDailyValue, setMaxDailyValue] = useState("");

	const months = [
		{ id: 1, title: "January" },
		{ id: 2, title: "February" },
		{ id: 3, title: "March" },
		{ id: 4, title: "April" },
		{ id: 5, title: "May" },
		{ id: 6, title: "June" },
		{ id: 7, title: "July" },
		{ id: 8, title: "August" },
		{ id: 9, title: "September" },
		{ id: 10, title: "October" },
		{ id: 11, title: "November" },
		{ id: 12, title: "December" },
	];

	useEffect(() => {
		let date = new Date(),
			years = [],
			year = date.getFullYear();

		for (var i = year; i > year - 10; i--) {
			if (i > 2020) {
				years.push(i);
				setLastYears(years);
			}
		}
	}, []);
	const getLeadsData = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/general/lead-manager-dashboard/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDashboardDetails(data);
					// setMonthLeads(data.monthly_reports);
					// setYearLeads(data.yearly_reports);
					// setTotalContribution(
					// 	data.lead_distribution.total_contributions
					// );
					// setMyContribution(data.lead_distribution.my_contributions);

					setYearlyLead(data.yearly_reports);
					setMonthlyLead(data.monthly_reports);
					setDailylyLead(data.daily_report);
					setSalesPerformance(data.sales_persons);
					handleMaxValue(data);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getTeamData = () => {
		setIsTeamDataLoading(true);
		let accessToken = state.user_details.access_token;
		
		baseConfig
			.get("/sales-persons/sales-team-performance", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					date: selectedMonth && selectedYear && selectedMonthAndYear,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setTeamData(data);
					setTimeout(() => {
						setIsTeamDataLoading(false);
					}, 150);
				} else {
					setIsTeamDataLoading(false);
				}
			})
			.catch((error) => {
				alert(error);
				setIsTeamDataLoading(false);
			});
	};

	const getSalesPerformance = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/sales-persons/sales-persons/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					leader_only: false,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					// setSalesPerformance(data.data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	const handleMaxValue = (data) => {
		setMaxYearValue(
			Math.max(...data.yearly_reports.map((item) => item.total_leads))
		);
		setMaxMonthValue(
			Math.max(...data.monthly_reports.map((item) => item.total_leads))
		);
		setMaxDailyValue(
			Math.max(...data.daily_report.map((item) => item.total_leads))
		);
	};

	useEffect(() => {
		getLeadsData();

		// getSalesPerformance();
	}, []);

	useEffect(() => {
		// if (selectedMonth && selectedYear) {
		//     getTeamData();
		// }
		getTeamData();
	}, [selectedMonth, selectedYear]);

	const handleSelectMonth = (event) => {
		setSelectedMonth(event.target.value);
	};
	const handleSelectYear = (event) => {
		setSelectedYear(event.target.value);
	};

	const pieData = (canvas) => {
		return {
			datasets: [
				{
					data: [
						dashboardDetails?.lead_distribution
							?.total_contributions -
							dashboardDetails?.lead_distribution
								?.my_contributions,
						dashboardDetails?.lead_distribution?.my_contributions,
					],
					backgroundColor: [
						`#0fa76f`,
						`#77C1D8`,
					],
					border: "none",
				},
			],
		};
	};

	const MonthContainer = ({ value, convert, xVariable, yMax, total }) => {
		return (
			<ResponsiveContainer height={400}>
				<BarChart
					data={value}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<XAxis dataKey={xVariable} />
					<YAxis domain={[0, yMax + 50]} />
					<Legend />

					<Bar
						dataKey={total}
						fill="#D2814B"
						isAnimationActive={false}
						barSize={40}
						barGap={2}
						barCategoryGap={2}
						minPointSize={5}
						name="No.of Leads"
					>
						<LabelList
							dataKey={total}
							position="top"
							style={{ fill: "#000" }}
						/>
					</Bar>
					<Bar
						dataKey={convert}
						fill="#8BC38F"
						isAnimationActive={false}
						barSize={40}
						barGap={2}
						barCategoryGap={2}
						minPointSize={5}
						name="Admissions"
					>
						<LabelList
							dataKey={convert}
							position="top"
							style={{ fill: "#000" }}
						/>
					</Bar>

					{/* <Bar dataKey="pv" fill="#8884d8" /> */}
					{/* <Bar dataKey="total" fill="#82ca9d" /> */}
				</BarChart>
			</ResponsiveContainer>
		);
	};

	const DetailCard = ({ text, icon, count, bg }) => {
		return (
			<SmallCard background={bg ? bg : "#f9f9f9"}>
				<SmallTop>
					<Count fontSize="26px">{count}</Count>
					<Img src={icon} alt="icon" />
				</SmallTop>
				<SmallBottom>
					<ContentText fontSize="18px">{text}</ContentText>
				</SmallBottom>
			</SmallCard>
		);
	};

	return (
		<Wrapper>
			{!isLoading ? (
				<>
					{/* <TitleWrapper>
                        <Welcome />
                        <Title
                            fontSize="32px"
                            fontWeight="500"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // marginBottom: "20px",
                                marginLeft: "6px",
                            }}
                        >
                            {dashboardDetails?.admin_name}
                        </Title>
                    </TitleWrapper>

                    <WelcomeMessage /> */}
					<CardsContainer>
						<DetailCard
							bg="#fff"
							text="Target Achieved"
							icon={
								require("./../../assets/images/goal.svg")
									.default
							}
							// count={`${
							//     Math.round(
							//         dashboardDetails.target_achieved * 100
							//     ) / 100
							// }%`}
							count={dashboardDetails.target_achieved}
						/>
						<DetailCard
							bg="#fff"
							text="Revenue Generated"
							icon={
								require("./../../assets/images/goal.svg")
									.default
							}
							// count={`${
							//     Math.round(
							//         data.target_achieved * 100
							//     ) / 100
							// }%`}
							count={`INR ${dashboardDetails.revenue_generated}`}
						/>
						<DetailCard
							bg="#fff"
							text="Target"
							icon={
								require("./../../assets/images/new-target.svg")
									.default
							}
							count={`INR ${dashboardDetails.target}`}
						/>
					</CardsContainer>
					<MiddleContainer>
						{!isTeamDataLoading ? (
							<>
								<TitleText>
									<Title fontSize="23px" className="teamperformance" margin="20px" color="#000000">
										Team Performance
									</Title>
									<FilterBoxesWrapper>
										<SelectBox>
											{/* <Box sx={{ minWidth: 150 }}> */}
											<FormControl
												sx={{ m: 1, minWidth: 140 }}
												size="small"
											>
												<InputLabel id="demo-simple-select-label">
													Month
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedMonth}
													label="Admission Consultant"
													onChange={handleSelectMonth}
												>
													{months?.map((item) => (
														<MenuItem
															value={item.id}
														>
															{item.title}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											{/* </Box> */}
										</SelectBox>
										<SelectBox>
											{/* <Box sx={{ m: 1, minWidth: 150 }}> */}
											<FormControl
												sx={{ m: 1, minWidth: 120 }}
												size="small"
											>
												<InputLabel id="demo-simple-select-label">
													Year
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedYear}
													label="Source"
													onChange={handleSelectYear}
												>
													{lastYears?.map((item) => (
														<MenuItem value={item}>
															{item}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											{/* </Box> */}
										</SelectBox>
										{/* <SelectBox>
                                <Box sx={{ minWidth: 250 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Stage
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedStage}
                                            label="Stage"
                                            onChange={handleStageSelect}
                                        >
                                            {stages?.map((item) => (
                                                <MenuItem value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </SelectBox> */}
									</FilterBoxesWrapper>
								</TitleText>
								<ResponsiveContainer width="95%" height={380}>
									<BarChart
										data={teamData}
										margin={{
											top: 20,
											right: 0,
											left: 0,
											bottom: 10,
										}}
									>
										<XAxis
											dataKey="name"
											tick={{ fontSize: 15 }}
											interval={0}
											// angle={-90}
											textAnchor="middle"
										/>
										<YAxis
											// dataKey="target_achieved"
											tick={{ fontSize: 15 }}
											domain={[0, 100]}
										/>
										<Legend
											wrapperStyle={{
												top: 0,
												left: 25,
											}}
										/>
										<Bar
											dataKey="target_achieved"
											fill="#3ccb7f"
											isAnimationActive={false}
											barSize={30}
											barGap={2}
											// barCategoryGap={2}
											minPointSize={5}
											name="Target Achieved"
										>
											<LabelList
												dataKey="target_achieved"
												// dataKey={
												//     Math.round(
												//         dashboardDetails.target_achieved *
												//             100
												//     ) / 100
												// }
												position="top"
												style={{
													fill: "#000",
													fontSize: 15,
												}}
											/>
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</>
						) : (
							<TeamLoaderContainer>
								<TeamLoadingIcon src={loadingGif} alt="icon" />
							</TeamLoaderContainer>
						)}
					</MiddleContainer>
					<LeadConvertedContainer>
						<Container
							className="lead-converted-container"
							width="100%"
							margin="0px 0px 25px 0"
						>
							<div
								class="converted"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									// marginBottom: "10px",
									// width: "100%",
									margin: "0 auto",
								}}
							>
								<Title className = "convertedh" fontSize="23px" color="#000000">
									Lead Converted
								</Title>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom:"20px",
									}}
								>
									<Text
										fontSize="16px"
										color="#8BC38F"
										fontWeight="400"
									>
										No of Leads
									</Text>
									<select
										onChange={(e) => {
											setMonthType(e.target.value);
										}}
										className="select-input"
										value={monthType}
									>
										{/* <option value="month">Per Month</option>
                                        <option value="year">Per Year</option>
                                        <option value="day">Per Day</option> */}
										<option value="month">Monthly</option>
										<option value="year">Yearly</option>
										<option value="day">Daily</option>
									</select>
								</div>
							</div>
							{/* <div style={{ height: "300px" }}>
                        <Bar
                            data={monthType ? data : yearData}
                            id="canvas"
                            options={options}
                        />
                    </div> */}
							<div>
								{monthType === "month" ? (
									<MonthContainer
										value={monthlyLead}
										convert="converted_leads"
										total="total_leads"
										xVariable="month"
										yMax={
											Math.round(maxMonthValue / 10) * 10
										}
									/>
								) : monthType === "year" ? (
									<MonthContainer
										value={yearlyLead}
										convert="converted_leads"
										total="total_leads"
										xVariable="year"
										yMax={
											Math.round(maxYearValue / 10) * 10
										}
									/>
								) : monthType === "day" ? (
									<MonthContainer
										value={dailyLead}
										convert="converted_leads"
										total="total_leads"
										xVariable="date"
										yMax={
											Math.round(maxDailyValue / 10) * 10
										}
									/>
								) : null}
							</div>
						</Container>
					</LeadConvertedContainer>
					<div 
						className="lead-distribution"
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Container
						className="leaddistribution"
							width="100%"
							padding="30px"
							height="620px"
							margin="0 10px 20px 0"
							// style={{ height: "75vh", overflow: "scroll" }}
							style={{
								display: "flex",
								justifyContent: "space-between",
								flexDirection: "column",
								boxSizing: "border-box",
							}}
						>
							<>
								<Title
									fontSize="25px"
									color="#000"
									margin="0 0 20px"
								>
									Lead Distribution
								</Title>
								<div
								class="distribution"
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginBottom: "20px",
										
									}}
								>
									<div
									class="pie"
										style={{ height: "100%", width: "100%" }}
									>
										<Pie
											data={pieData}
											options={{
												responsive: true,
												aspectRatio: 1,
											}}
										/>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
											alignSelf: "flex-end",
											justifyContent: "flex-end",
											padding: "50px 0",
											width: "90%",
										}}
									>
										<div
											style={{
												display: "flex",
												marginBottom: "20px",
											}}
										>
											<div
												style={{
													height: "20px",
													width: "20px",
													backgroundColor:
														"#8BC38F",
													marginRight: "10px",
												}}
											></div>
											<Text fontSize="15px" color="#000" style={{}}>
												Other Contribution
											</Text>
										</div>
										<div
											style={{
												display: "flex",
											}}
										>
											<div
												style={{
													height: "20px",
													width: "20px",
													backgroundColor:
														"#77C1D8",
													marginRight: "10px",
												}}
											></div>
											<Text fontSize="15px" color="#000"  style={{}}>
												My Contribution
											</Text>
										</div>
									</div>
								</div>
							</>
							<>
								<Title
									fontSize="25px"
									color="#000"
									margin="0 0 20px"
								>
									Target Status
								</Title>
								<SmallContainer>
									<SmallCard>
										<SmallTop>
											<Count>
												{
													dashboardDetails.target_achieved
												}
											</Count>
											<IconDiv>
												<RankIcon
													src={
														require("./../../assets/images/target.svg")
															.default
													}
													alt="icon"
												/>
											</IconDiv>
										</SmallTop>
										<SmallBottom>
											<ContentText>
												Target Achieved
											</ContentText>
										</SmallBottom>
									</SmallCard>
									<SmallCard>
										<SmallTop>
											<Count>
												{dashboardDetails.target}
											</Count>
											<IconDiv>
												<RankIcon
													src={
														require("./../../assets/images/goal.svg")
															.default
													}
													alt="icon"
												/>
											</IconDiv>
										</SmallTop>
										<SmallBottom>
											<ContentText>Target</ContentText>
										</SmallBottom>
									</SmallCard>
								</SmallContainer>
							</>
						</Container>
						<Container
						className="leaddistribution"
							width="100%"
							margin="0 0 0 10px"
							style={{
						
								// overflow: "scroll",
								justifyContent: "flex-end",
								boxSizing: "border-box",
							}}
						>
							<Title
								fontSize="25px"
								color="#000"
								margin="0 0 20px"
								className="performancehead"
							>
								Admission Consultant’s Performance
							</Title>
							{isLoading === false ? (
								<SalesPersonsContainer>
									{salesPerformance?.map((data) => (
										<Container
											style={{
												flexWrap: "wrap",
												borderRadius: "23px",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											width="100%"
											background="#F9F9F9"
											margin="0 0 20px"
										>
											<div
												style={{
													flexWrap: "wrap",
													display: "flex",
													alignItems: "center",
													marginBottom:"20px"
,												}}
											>
												<ProfileImage
													src={
														data?.image
															? data.image
															: Profile
													}
													alt="Logo"
													style={{
														marginRight: "8px",
													}}
												/>
												<div>
													<Text
														color="black"
														fontSize="16px"
													>
														{data.rank}
													</Text>
													<Text
														color="black"
														fontSize="14px"
													>
														{data.name}
													</Text>
												</div>
											</div>
											<div style={{ width: "55%" }}>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
														marginBottom: "20px",
													}}
												>
													<Text
													
														style={
															{
																// width: "100px",
															}
														}
														fontSize="15px"
														color="black"
													>
														Target Achieved
													</Text>
													<Text
														fontSize="15px"
														color="black"
													>
														{data.target_achieved}%
													</Text>
												</div>
												<Progress_bar
													bgcolor="#522E92"
													progress={
														data.target_achieved
													}
													height={10}
													width="100%"
												/>
											</div>
										</Container>
									))}
								</SalesPersonsContainer>
							) : null}
						</Container>
					</div>
				</>
			) : (
				<LoaderContainer>
					<LoadingIcon src={loadingGif} alt="icon" />
				</LoaderContainer>
			)}
		</Wrapper>
	);
}

export default MainDashboard;

const LoaderContainer = styled.div`
	/* border: 1px solid #000; */
	height: calc(100vh - 130px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
const LoadingIcon = styled.img`
	display: block;
	width: 65px;
	object-fit: cover;
`;

const SmallContainer = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap; */
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
	/* width: 40%; */
	margin-top: 15px;
	@media all and (max-width: 480px){
			grid-template-columns: 1fr;

}
`;
const SmallCard = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #c1c1c1;
	height: 90px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* width: 31%; */
`;
const SmallTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
	@media all and (max-width: 768px){
		margin-bottom: 30px;
}
`;

const Count = styled.h3`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
	color: #3ccb7f;
	margin-bottom: 5px;
	font-weight: 500;
	@media all and (max-width: 980px){
		font-size: 20px;
}
@media all and (max-width: 768px){
	font-size: 16px;
}
`;
const IconDiv = styled.div`
	height: 25px;
	width: 25px;
	background: #ebf5ff;
	border-radius: 50%;
	padding: 10px;
`;
const RankIcon = styled.img`
	/* margin-bottom: 20px; */
	height: 100%;
	width: 100%;
`;
const SmallBottom = styled.div``;
const ContentText = styled.p`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
	color: #000;
	@media all and (max-width: 980px){
	font-size:17px;
}
@media all and (max-width: 768px){
	font-size: 13px;
}
`;
const ProfileImage = styled.img`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: block;
	object-fit: cover;
`;

const GraphDiv = styled.div`
	margin-top: 20px;
	height: 300px;
`;
const Img = styled.img`
	height: 40px;
	width: 40px;
	@media all and (max-width: 768px){
		height: 30px;
		width: 30px;
}
`;
const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 5%;
	margin-bottom: 25px;
	/* padding: 10px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 12px; */
	@media all and (max-width: 640px){
			grid-template-columns: 1fr;
			margin-bottom: 70px;

}
`;
const MiddleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 20px;
	background: #fff;
	margin-bottom: 20px;
	border-radius: 8px;
	box-sizing: border-box;
	.recharts-legend-item {
		display: inline-flex !important;
		align-items: center !important;
	}
	@media all and (max-width: 480px){
		padding: 10px;
}
`;
const TitleText = styled.div`
	/* align-self: flex-start; */
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	@media all and (max-width: 640px){
		flex-wrap: wrap;
}
`;
const FilterBoxesWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	
`;
const SelectBox = styled.div`
	/* width: 250px; */
	margin-right: 10px;
	&:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 480px){
		margin-right: 0;
}
`;
const TeamLoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const TeamLoadingIcon = styled.img`
	display: block;
	width: 50px;
	object-fit: cover;
`;
const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;
// const MessageText = styled.div``;
const LeadConvertedContainer = styled.div`
	.lead-converted-container .recharts-legend-item {
		display: inline-flex !important;
		align-items: center !important;
	}
	@media all and (max-width: 980px){
		display: none;
}
`;
const SalesPersonsContainer = styled.div`
	/* border: 1px solid #000; */
	height: calc(100% - 40px);
	overflow-y: auto;
	border-radius: 16px;
	/* &::-webkit-scrollbar {
        display: block;
    } */
	/* ::-webkit-scrollbar {
        display: block;
        width: 3px;
        
    } */

	/* Style the thumb */
	/* ::-webkit-scrollbar-thumb {
        background-color: #ccc; 
    } */
`;
