import styled from "styled-components";

export default styled.h1`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#0FA76F")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    /* font-weight: 600; */
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};

    text-align: left;
    text-transform: capitalize;
    /* @media only screen and (max-width: 1200px) {
    font-size: 35px;
  } */

  @media all and (max-width: 1280px){
    font-size: ${({type})=>type === "admissionranking" ? "17px":""}; 

}
@media all and (max-width: 1080px){
  font-size: ${({type})=>type === "admissionranking" ? "16px": ""}; 
  font-size: ${(props)=>props.className === "head" ? "20px": ""}; 
}
@media all and (max-width: 980px){
  font-size: ${(props)=>props.className === "head" ? "23px": ""}; 
}

@media all and (max-width: 768px){
  font-size: ${(props) => (props.className === "teamperformance"? "20px": props.className === "programhead" ? "30px":props.className=== "head"? "22px !important":"35px")};  
  margin-bottom: ${(props)=>props.className === "head" ? "30px": ""}; 
  font-size: ${(props) => (props.className === "leads-title" ? "27px" :(props.fontSize ? props.fontSize : "16px"))};
}
  @media all and (max-width: 640px){
    font-size: ${(props) => (props.className === "statusheader" ? "30px" :props.className === "head" ? "15px !important":props.className === "admissionranking" ? "16px":props.className === "top-title-large" ? "22px":props.className === "salesmangerhead" ? "20px":props.className === "lead-name" ? "16px": props.className === "sales-head" ? "28px": props.className === "programhead" ? "20px":props.className === "performancehead" ? "17px ": props.className === "admission-consultant" ? "22px":(props.fontSize ? props.fontSize : "16px"))};
    margin-bottom: ${(props) => (props.className === "convertedh"? "20px" :"")}; 
    font-size: ${(props) => (props.className === "leads-title" ? "23px" :(props.fontSize ? props.fontSize : "16px"))}!important;
    font-size: ${(props) => (props.className === "statusheader" ? "30px" :(props.fontSize ? props.fontSize : "16px"))};
    display: ${(props) => (props.className === "leads-title" ? "block" : "inline")}!important;
  width: ${(props) => (props.className === "leads-title" ? "100%" : "auto")}!important;
  /* width: ${(props) => (props.className === "add-title" ? "100%" : "50%")}!important; */
  margin-bottom: ${(props) => (props.className === "leads-title" ? "20px" : "auto")}!important;
  font-size: ${({type})=>type === "admissionranking" ? "17px ! important":""}; 
}
@media all and (max-width: 480px) {
  font-size: ${(props) =>
    props.className === "statusheader"
      ? "26px"
      : props.className === "programhead"
      ? "20px"
      : props.className === "admission-consultant"
      ? "16px": 
      props.className === "welcome"
      ? "13px":
      props.className === "source"
      ? "24px"
      :
      props.className === "lead-name"
      ? "14px"
      :
      props.className === "head"
      ? "14px !important" 
      :
      props.className === "head-consultant-admin-add"
      ? "14px"
      :
      props.className === "edit-or-create"
      ? "16px"
      :
      props.className === "sales-head"
      ? "16px"
      :
      props.className === "top-title-large"
      ? "18px"
      :
      props.className === "username"
      ? "14px"
      : props.className === "performancehead"
      ? "14px"

      : props.fontSize || "16px"};
font-size: ${({type})=>type === "admissionranking" ? "14px ! important":""}; 
  min-width: ${(props) => (props.type === "admissionranking" ? "250px" : "")};
  margin-bottom: ${(props) =>(props.type === "salesmangerhead"? "20px":"")};
}

@media all and (max-width: 360px) {
  font-size: ${(props) =>
    props.className === "totalleads" ? "12px" : props.className === "head"
      ? "13px"
      : props.className === "admission-consultant" ? "14px" : ""};
      font-size: ${(props) => {
    let baseFontSize = "16px";
    if (props.className === "statusheader") {
        baseFontSize = "30px";
    } else if (props.className === "anotherclass") {
        baseFontSize = "20px"; 
    } else if (props.type === "totalleads") {
        baseFontSize = "12px"; 
    }
    return props.fontSize ? props.fontSize : baseFontSize;
}};
}
`;
