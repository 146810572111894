import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/list-icon.svg";
import TD from "../../components/TD";
import "./style.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";

function SalesTeamList({
    item,
    status,
    setSelectedMemberModal,
    setSelectedTeamData,
    setDeleteId,
    setModal,
}) {
    const [statusBackground, setStatusBackground] = useState();
    const [statusColor, setStatusColor] = useState();
    const [statusText, setStatusText] = useState("");
    const history = useHistory();

    useEffect(() => {
        if (status) {
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
            setStatusText("Active");
        } else {
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
            setStatusText("Inactive");
        }
    }, [status]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "20px 30px",
                alignItems: "center",
            }}
            className="item"
        >
            {/* <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="5%"
                color="#000"
                fontSize="16px"
            >
                {item.team_id}
            </TD> */}

            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                }}
                width="14%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item.name}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                }}
                width="11%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item.leader_name}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="12%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item.total_members}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="7%"
                color="#000"
                fontWeight="400"
                fontSize="16px"
            >
                {item.rank}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="10%"
                color="#000"
                fontSize="16px"
            >
                <StatusText color={statusColor} background={statusBackground}>
                    {statusText}
                </StatusText>
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="35%"
                color="#000"
                fontSize="16px"
            >
                <ActionDiv>
                    <ButtonDiv
                        bordeColor="#03AC5B"
                        onClick={() => {
                            history.push(
                                `/salesmanager/salesteams/${item?.id}/`
                            );
                        }}
                    >
                        <ButtonIcon
                            src={
                                require("./../../assets/images/green-eye.svg")
                                    .default
                            }
                            alt="icon"
                        />
                        <ButtonText color="#000">View</ButtonText>
                    </ButtonDiv>
                    <ButtonDiv
                        bordeColor="#3ccb7f"
                        onClick={() => {
                            setSelectedMemberModal(true);
                            setSelectedTeamData(item);
                        }}
                        // onClick={() => {
                        //     history.push(
                        //         `/leadmanager/edit-salesteam/${item.id}/`
                        //     );
                        // }}
                    >
                        <ButtonIcon
                            src={
                                require("./../../assets/images/Button.svg")
                                    .default
                            }
                            alt="icon"
                        />
                        <ButtonText color="#3ccb7f">Add</ButtonText>
                    </ButtonDiv>
                    <EditDiv
                        onClick={() => {
                            history.push(
                                `/salesmanager/edit-salesteam/${item.id}/`
                            );
                        }}
                    >
                        <EditIcon src={editIcon} alt="icon" />
                    </EditDiv>
                    <DeleteDiv
                        onClick={() => {
                            setDeleteId(item.id);
                            setModal(true);
                        }}
                    >
                        <EditIcon src={deleteIcon} alt="icon" />
                    </DeleteDiv>
                </ActionDiv>
            </TD>
        </div>
    );
}

export default SalesTeamList;

const StatusText = styled.p`
    color: ${(props) => (props.color ? props.color : "#fff")};
    background: ${(props) => (props.background ? props.background : "#fff")};
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ActionDiv = styled.div`
    display: flex;
    align-items: center;
`;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: ${(props) => (props.bordeColor ? props.bordeColor : "#fff")};
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    &:last-child {
        margin-right: 0;
    }
`;
const ButtonIcon = styled.img`
    margin-right: 4px;
`;
const ButtonText = styled.span`
    color: ${(props) => (props.color ? props.color : "#fff")};
`;
const EditDiv = styled.div`
    width: 50px;
    cursor: pointer;
    margin-left: 6px;
`;
const DeleteDiv = styled.div`
    width: 50px;
    cursor: pointer;
    margin-left: 6px;
`;
const EditIcon = styled.img`
    width: 16px;
    display: block;
    object-fit: cover;
`;
