import React, { useEffect, useState, useContext } from "react";
import Wrapper from "../../components/Wrapper";
import { useHistory } from "react-router-dom";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import TD from "../../components/TD";
import NewLeadList from "./NewLeadList";
import AssignModal from "../leadManagerAdmin/AssignModal";
import NoDataFound from "../../components/noRecords/NoDataFound";
import ViewMoreModal from "./ViewMoreModal";
import Container from "../../components/Container";
import loadingIcon from "../../assets/lottie/loading.gif";
import MainPagination from "../../components/includes/MainPagination";

function NewLeads() {
    const history = useHistory();
    const [search, setsearch] = useState("");
    const [isAssignModal, setAssignModal] = useState(false);
    const [option, setOption] = useState("0");
    const { state } = useContext(Context);
    const [selectedLead, setSelectedLead] = useState("");
    const [isViewMore, setIsViewMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});

    const [data, setData] = useState([]);

    const [salesPerson, setSalesPerson] = useState([]);

    const [isLeadDataLoading, setIsLeadDataLoading] = useState(false);
    const getLeadData = () => {
        setIsLeadDataLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/lead/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    // assign: false,
                    leader_assigned: false,
                    search: search,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data.data);
                    setPaginationData(data.paginator);

                    setTimeout(() => {
                        setIsLeadDataLoading(false);
                    }, 200);
                } else {
                    alert("Some error occured.Please try after sometime");
                    setTimeout(() => {
                        setIsLeadDataLoading(false);
                    }, 200);
                }
            })
            .catch((error) => {
                alert(error);
                setTimeout(() => {
                    setIsLeadDataLoading(false);
                }, 200);
            });
    };

    const [isSalesPersonLoading, setIsSalesPersonLoading] = useState(false);
    const listSalesPersons = () => {
        setIsSalesPersonLoading(true);
        let accessToken = state.user_details.access_token;
        let team_id = state.user_details.team_Id;
        baseConfig
            .get("/sales-persons/sales-person-data-table/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    team: team_id,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerson(data);
                    setTimeout(() => {
                        setIsSalesPersonLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setIsSalesPersonLoading(false);
                    }, 200);
                    alert("Some error occured.Please try after sometime");
                }
            })
            .catch((error) => {
                alert(error);
                setTimeout(() => {
                    setIsSalesPersonLoading(false);
                }, 200);
            });
    };

    const assignLead = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .post(
                "/leads/assign-sales-person/",
                {
                    sales_person: option,
                    leads: [selectedLead.id],
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getLeadData();
                    setAssignModal(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    function disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }
    function enableScrolling() {
        window.onscroll = function () {};
    }

    useEffect(() => {
        if (isViewMore === true) {
            disableScrolling();
        }
        if (isViewMore === false) {
            enableScrolling();
        }
    }, [isViewMore]);

    useEffect(() => {
        getLeadData();
    }, [search, currentPage]);

    useEffect(() => {
        listSalesPersons();
    }, []);

    return (
        <Wrapper>
            <TopContainer>
                <Left>
                    <H1>New Leads</H1>
                </Left>
                <Right></Right>
            </TopContainer>
            <Container
                width="100%"
                margin="0"
                padding="25px 20px 15px 20px"
                style={{
                    overflow: "scroll",
                    maxHeight: "600px",
                    marginTop: "10px",
                }}
            >
                <div>
                    <h3 class="consultant"
                        style={{
                            fontWeight: 600,
                            fontSize: "20px",
                        }}
                    >
                        Admission Consultants
                    </h3>
                </div>
                {!isSalesPersonLoading ? (
                    salesPerson.length > 0 ? (
                        <TableContainerr>
                            <ACTable>
                                <FirstRow>
                                    <TFH className="table-headings common-table" rowSpan="2">
                                        Name
                                    </TFH>

                                    {/* <TFH
                                        className="table-headings common-table"
                                        rowSpan="2"
                                    >
                                        Total Leads
                                    </TFH> */}
                                    <TFH
                                        className="table-headings common-table"
                                        // rowSpan="2"
                                        colSpan="2"
                                    >
                                        Leads
                                    </TFH>
                                    <TFH
                                        className="table-headings common-table"
                                        // rowSpan="2"
                                        colSpan="2"
                                    >
                                        Target
                                    </TFH>
                                    <TFH className="table-headings common-table" rowSpan="2">
                                        Target Achieved
                                    </TFH>

                                    {/* <TFH
                                        className="table-headings common-table"
                                        colSpan="2"
                                    >
                                        Quality Leads
                                    </TFH> */}

                                    {/* <TFH className="table-headings common-table" colSpan="2">
                                        SSM Leads
                                    </TFH>
                                    <TFH className="table-headings common-table" colSpan="2">
                                        DBA Leads
                                    </TFH> */}
                                </FirstRow>
                                <SecondRow>
                                    {/* <TSH
                                        className="table-sub-headings common-table"
                                        rowSpan="2"
                                    >
                                        Name
                                    </TSH>
                                    <TSH
                                        className="table-sub-headings common-table"
                                        rowSpan="2"
                                    >
                                        Target
                                    </TSH> */}
                                    <TSH className="table-sub-headings common-table">Count</TSH>
                                    <TSH className="table-sub-headings common-table">Percentage</TSH>
                                    <TSH className="table-sub-headings common-table">Amount</TSH>
                                    <TSH className="table-sub-headings common-table">Percentage</TSH>

                                    {/* <TSH className="table-sub-headings common-table">Count</TSH>
                                    <TSH className="table-sub-headings common-table">Percentage</TSH> */}

                                    {/* <TSH className="table-sub-headings common-table">
                                        Count
                                    </TSH>
                                    <TSH className="table-sub-headings common-table">
                                        Percentage
                                    </TSH> */}
                                    {/* <TSH className="table-sub-headings common-table">Count</TSH>
                                    <TSH className="table-sub-headings common-table">Percentage</TSH> */}
                                    {/* <TSH className="table-sub-headings common-table"></TSH> */}
                                </SecondRow>
                                {salesPerson.map((item) => (
                                    <DetailRow>
                                        <TDR className="table-td common-table">{item.name}</TDR>

                                        <TDTR className="table-td common-table">{item.total_leads}</TDTR>
                                        <TDTR className="table-td common-table">
                                            {/* {item.actual_lead_share}% */}
                                            {item.lead_shared}%
                                        </TDTR>

                                        <TDTR className="table-td common-table">{item.my_target} INR</TDTR>
                                        <TDTR className="table-td common-table">
                                            {/* {item.lead_shared}% */}
                                            {item.actual_lead_share}%
                                        </TDTR>
                                        {/* <TDTR className="table-td common-table">
                                            {item.total_leads}
                                        </TDTR> */}
                                        <TDTR className="table-td common-table">{item.target_achieved}%</TDTR>

                                        {/* <TDTR className="table-td common-table">
                                            {item.quality_leads_count}
                                        </TDTR>
                                        <TDTR className="table-td common-table">
                                            {item.quality_leads}%
                                        </TDTR> */}

                                        {/* <TDTR className="table-td common-table">{item.total_ssm_leads}</TDTR>
                                        <TDTR className="table-td common-table">{item.ssm_leads}%</TDTR>
                                        <TDTR className="table-td common-table">{item.total_dba_leads}</TDTR>
                                        <TDTR className="table-td common-table">{item.dba_leads}%</TDTR> */}
                                    </DetailRow>
                                ))}
                            </ACTable>
                        </TableContainerr>
                    ) : (
                        <NoDataFound hght="200px" />
                    )
                ) : (
                    <LoaderContainer>
                        <LoadingGif src={loadingIcon} alt="icon" />
                    </LoaderContainer>
                )}
            </Container>
            <SearchBoxContainer>
                <SearchDiv>
                    <Form className="search-container">
                        <SearchIcon src={require("./../../assets/images/search.svg").default} />
                        <SearchField
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search"
                            onChange={(e) => setsearch(e.target.value)}
                        />
                    </Form>
                </SearchDiv>
            </SearchBoxContainer>
            {!isLeadDataLoading ? (
                data.length > 0 ? (
                    <>
                                    <div class="scroll-bar">
                        <TableContainer>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #E6EFF5",
                                    padding: "0 10px 20px",
                                }}
                                className="table-head"
                            >
                                <TD width="6%" color="#808080" fontWeight="500" fontSize="16px">
                                    ID No.
                                </TD>
                                <TD width="16%" color="#808080" fontWeight="500" fontSize="16px">
                                    Lead Name
                                </TD>
                                <TD width="14%" color="#808080" fontWeight="500" fontSize="16px">
                                    Enq Date
                                </TD>
                                <TD width="14%" color="#808080" fontWeight="500" fontSize="16px">
                                    Phone
                                </TD>
                                <TD width="17%" color="#808080" fontWeight="500" fontSize="16px">
                                    Email
                                </TD>
                                {/* <TD width="16%" color="#808080" fontWeight="500" fontSize="16px">
                                    University
                                </TD> */}
                                <TD width="16%" color="#808080" fontWeight="500" fontSize="16px">
                                    Program
                                </TD>
                                <TD width="10%" color="#808080" fontWeight="500" fontSize="16px">
                                    Source
                                </TD>
                                <TD
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    width="11%"
                                    color="#808080"
                                    fontWeight="500"
                                    fontSize="16px"
                                >
                                    Action
                                </TD>
                                <TD
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    width="8%"
                                    color="#808080"
                                    fontWeight="500"
                                    fontSize="16px"
                                ></TD>
                            </div>
                            <div
                                style={{
                                    // padding: "0px 30px",
                                    borderBottom: "1px solid #E6EFF5",
                                }}
                                className="table-body"
                            >
                                {data.map((item) => {
                                    return (
                                        <NewLeadList
                                            key={item.id}
                                            setAssignModal={setAssignModal}
                                            item={item}
                                            setSelectedLead={setSelectedLead}
                                            setIsViewMore={setIsViewMore}
                                        />
                                    );
                                })}
                            </div>
                        </TableContainer>
                        </div>
                        <MainPagination
                            paginationData={paginationData}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </>
                ) : (
                    <NoDataFound hght="200px" />
                )
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}

            {isAssignModal && (
                <AssignModal
                    isAssignModal={isAssignModal}
                    setAssignModal={setAssignModal}
                    salesPerson={salesPerson}
                    setOption={setOption}
                    assignLead={assignLead}
                    option={option}
                    selectedLead={selectedLead}
                />
            )}
            {isViewMore && <ViewMoreModal setIsViewMore={setIsViewMore} selectedLead={selectedLead} />}
            {/* <MainPagination
                paginationData={paginationData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            /> */}
        </Wrapper>
    );
}

export default NewLeads;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Left = styled.div``;
const H1 = styled.h1`
    font-size: 28px;
    font-family: "Inter";
    font-weight: 500;
    @media all and (max-width: 980px){
        font-size: 18px;
}
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;
const SearchDiv = styled.div`
    /* margin-right: 30px; */
    width: 400px;
    @media all and (max-width: 980px){
        width: 46%;
}
@media all and (max-width: 768px){
    width: 54%;
}
@media all and (max-width: 640px){
    width: 77%;
   
}
@media all and (max-width: 480px){
    width: 100%;
}
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #3ccb7f;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
    @media all and (max-width: 640px){
        height: 40px;
}
@media all and (max-width: 480px){
    margin: 0 auto;
}
`;
const SearchIcon = styled.img`
    margin-right: 10px;
    @media all and (max-width: 640px){
        width: 20px;
        margin: 0 auto;
}
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;

const Button = styled.div`
    background: ${(props) => (props.background ? props.background : "#d6d3ec")};
    border: 1px solid;
    border-color: ${(props) => (props.border ? props.border : "#d6d3ec")};
    border-radius: 8px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 25px;
    margin-left: 20px;
`;
const ButtonText = styled.span`
    /* color: #fff; */
    font-size: 16px; // display: flex;
    // justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : "#8561C6")};
    font-weight: 500;
    /* margin-left: 10px; */
`;
const Plus = styled(ButtonText)`
    font-size: 22px; // display: flex;
`;
const TableContainer = styled.div`
    /* margin-top: 30px; */
    background-color: #fff;
    padding-top: 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    @media all and (max-width: 1280px){
        min-width: 1280px;
}
`;
const TableContainerr = styled.div`
    overflow: scroll;
`;
const ACTable = styled.table`
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    border-collapse: collapse;
    .common-table {
        border: 1px solid #e7e7e7;
        /* border-collapse: collapse; */
    }
`;
const FirstRow = styled.tr`
    background: #f9f9f9;
`;
const TFH = styled.th`
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    color: #808080;
    height: 40px;
`;
const SecondRow = styled.tr`
    background: #f9f9f9;
`;
const TSH = styled.th`
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    color: #808080;
    height: 30px;
`;
const DetailRow = styled.tr``;
const TDR = styled.td`
    padding: 9px 6px;
    font-size: 14px;
    color: #808080;
`;
const TDTR = styled.td`
    padding: 9px 6px;
    font-size: 14px;
    color: #808080;
    text-align: center;
 
`;
const SearchBoxContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0px 15px 0px;
`;
const LoaderContainer = styled.div`
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
