import styled from "styled-components";

export default styled.div`
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#0FA76F"};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "white")};
	// padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
	border: none;
	border-radius: 8px;
	text-align: center;
	display: inline-block;
   
	/* height: 36px; */
	/* height: 50px; */
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 200px; */
	/* width: 100px; */
	cursor: pointer;
	// preventDefault;

	padding: 12px 30px;
	@media all and (max-width: 768px){
		padding: ${(props) =>
  props.className === "programaddbutton"
    ? "8px 6px":""};
	height: 40px !important;
		padding: 0px 15px !important;

}
	@media all and (max-width: 640px){
		padding: ${(props) =>
  props.className === "salesmanageradd"
    ? "5px 8px":props.className === "admission-consultant"
    ? "12px 15px":props.className === "sales-button"
    ? "8px 7px":props.className === "program"
    ? "12px 15px" :""} 
}
@media all and (max-width: 480px) {
	height: 35px !important;
		padding: 0px 10px !important;
  padding: ${(props) =>
    props.className === "programaddbutton"
      ? "9px 8px"
      :props.className === "university-admin-update-button"
    ? "12px 10px":
	 props.className === "admission-consultant"
      ? "12px 10px"
      : props.className === "source"
      ? "7px 5px"
      :
	  props.className === "clear"
      ? "8px 10px"
      : ""};
  font-size: ${(props) => (props.className === "source" ? "12px" :props.className === "university-admin-update-button"
    ? "13px": "")};
}

@media all and (max-width: 360px){
	padding: ${(props) =>
  props.className === "university-admin-update-button"
    ? "12px 6px":""};
	font-size: ${(props) =>
  props.className === "university-admin-update-button"
    ? "12px":""};
	margin-top: ${(props) =>
  props.className === "claer-form"
    ? "20px":""};
	margin-left: ${(props) =>
  props.className === "clear"
    ? "30px":""};
}

`;
