import styled from "styled-components";

export default styled.h3`
    font-family: "Inter";

    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};

    color: ${(props) => (props.color ? props.color : "#0FA76F")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};

    @media only screen and (max-width: 1280px) {
        /* font-size: ${(props) =>
            (props.type === "buttonText" && "16px") ||
            (props.type === "dashboardItemNumber" && "22px")}; */
            font-size: ${({type})=>type === "buttonText" ? "16px" : type ==="dashboardItemNumber" ? "22px" : "18px"};
    }
    @media all and (max-width: 1080px){
      font-size: ${({type})=>type === "buttonText" ? "16px" : type ==="dashboardItemNumber" ? "22px" : "12px"};
      font-size: ${(props) => (props.className === "chart" ? "16px" : (props.fontSize ? props.fontSize : "18px"))};
}
@media all and (max-width: 980px){
  font-size: ${({type})=>type === "buttonText" ? "16px" : type ==="dashboardItemNumber" ? "22px" : "14px"};
}

@media all and (max-width: 480px){
  
  font-size: ${(props) =>
  props.className === "programaddbutton"
    ? "14px"
    : props.className === "programaddtext"
    ? "12px"
    : props.className === "filter"
    ? "12px"
    : props.className === "program"
    ? "15px"
    : props.className === "table-subhead"
    ? "16px"
    : "20px"};
font-size: ${(props) => {
    let baseFontSize = "16px";
    if (props.className === "chart") {
        baseFontSize = "16px";
    } else if (props.className === "list-text") {
        baseFontSize = "16px"; 
    }
    return props.fontSize ? props.fontSize : baseFontSize;
      
}} !important;
      padding: ${(props) =>
      props.className === "programaddbutton"
        ? "12px 16px":""}
    
}
@media all and (max-width: 768px){
  display: ${(props ) => (props.className === "list-text" ? "none" : (props.display ? props.display : "block"))};
  font-size: ${(props) =>
  props.className === "social-media"
    ? "15px":""}
    

}
@media all and (max-width: 640px){
  font-size: ${(props) =>
  props.className === "add"
    ? "12px": props.className === "programaddbutton"
    ? "12px":props.className === "program"
    ? "15px !important":""}

}
@media all and (max-width: 360px){
  font-size: ${({type})=>type === "buttonText" ? "16px" : type ==="dashboardItemNumber" ? "22px" : "12px"};
}
  /* @media only screen and (max-width: 1440px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  } */
`;
