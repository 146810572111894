import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";

// import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import CustomizedDialogs from "./popup";
import styled from "styled-components";
import FollowUpModal from "./FollowUpModal";
import Edit from "../../assets/images/edit.svg";
import { FormControl, Popover, TextField, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";

function AdmissionList({
    status,
    item,
    setselectedLead,
    selectedLead,
    universities,
    selectedUniversity,
    setSelectedUniversity,
    courses,
    selectedCourse,
    setSelectedCourse,
    updatedFee,
    setUpdatedFee,
    getData1,
}) {
    const { state } = useContext(Context);

    const history = useHistory();
    const [statusLabel, setStatusLabel] = useState("Not Intrested");
    const [statusBackground, setStatusBackground] = useState("#FF5B5B26");
    const [statusColor, setStatusColor] = useState("#FF5B5B");

    const [isModal, setIsModal] = useState(false);

    const handleUniversityChange = (event) => {
        setSelectedUniversity(event.target.value);
    };
    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    // useEffect(() => {
    //     if (status === "Not interested") {
    //         setStatusLabel("Not Intrested");
    //         setStatusBackground("#FF5B5B26");
    //         setStatusColor("#FF5B5B");
    //     } else if (status === "Converted" || status === "Need Followup") {
    //         setStatusLabel(status);
    //         setStatusBackground("#FFBB5426");
    //         setStatusColor("#FFBB54");
    //     } else if (
    //         status === "Interested" ||
    //         status === "Approved" ||
    //         status === "Student"
    //     ) {
    //         setStatusLabel(status);
    //         setStatusBackground("#2ED6A326");
    //         setStatusColor("#00A389");
    //     } else {
    //         setStatusLabel(status);
    //         setStatusBackground("#FF5B5B26");
    //         setStatusColor("#FF5B5B");
    //     }
    // }, [status]);

    // edit popover onClick states and functions
    const [editanchorEl, setEditAnchorEl] = React.useState(null);

    const handleClickEdit = (event, id) => {
        setEditAnchorEl(event.currentTarget);
        setselectedLead(id);
    };

    const handleCloseEdit = () => {
        setEditAnchorEl(null);
    };

    const openEdit = Boolean(editanchorEl);
    const editId = openEdit ? "simple-popover" : undefined;

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();

        if (selectedUniversity !== "" && selectedUniversity !== null) {
            formdata.append("university", selectedUniversity);
        }
        if (selectedCourse !== "" && selectedCourse !== null) {
            formdata.append("course", selectedCourse);
        }

        if (updatedFee !== "" && updatedFee !== null && updatedFee !== undefined) {
            formdata.append("fee_amount", updatedFee);
        }

        baseConfig
            .put(`/leads/lead/${selectedLead}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // alert("Successfully updated");
                    getData1();
                    handleCloseEdit();
                    setUpdatedFee("");
                    setSelectedCourse("");
                    setSelectedUniversity("");
                } else {
                    // setAlertStatus("error");
                    // setIsAlert(true);
                    // setAlertMessage("Lead added failed");
                    alert("Some error occured");
                }
            })
            .catch((error) => {
                const { data } = error.response.data;
                alert(data.message);
            });
    };

    return (
        <RowDiv
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "10px 20px",
                alignItems: "center",
            }}
            selected={selectedLead === item.id ? true : false}
            key={item.id}
        >
            <TD style={{}} width="55px" color="#3ccb7f" fontSize="14px">
                #{item.lead_id}
            </TD>

            <TD style={{}} color="#3ccb7f" fontSize="14px">
                {item.name}
            </TD>
            {/* <TD
					//  width="18%"
					color="#3ccb7f"
					fontSize="14px"
				>
					{item.email}
				</TD> */}
            <TD
                // width="14%"
                color="#3ccb7f"
                fontSize="14px"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
                {item.phone}
            </TD>
            {/* <TD
                // width="18%"
                fontSize="14px"
                color="#3ccb7f"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
                {item?.university}
            </TD> */}

            <TD
                // width="18%"
                fontSize="14px"
                color="#3ccb7f"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
                {item?.course_short_name}
            </TD>

            <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                // width="12%"
                color="#3ccb7f"
                fontSize="14px"
            >
                {item?.admission_date}
            </TD>
            <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                // width="11%"
                color="#3ccb7f"
                fontSize="14px"
            >
                {item?.fee_amount}
            </TD>

            {/* <TD width="15%" fontSize="14px">
                    <StatusText
                        color={statusColor}
                        background={statusBackground}
                    >
                        {statusLabel}
                    </StatusText>
                </TD> */}
            {/* <TD
					// width="14%"
					fontSize="14px"
					color="#3ccb7f"
					style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				>
					{item.lead_stage}
				</TD> */}

            <TLLD>
                {/* <FollowUpButton>Follow Up</FollowUpButton> */}
                <IconContainer
                    style={{ cursor: "pointer" }}
                    aria-describedby={editId}
                    variant="contained"
                    // onClick={handleClickEdit}
                    onClick={(event) => handleClickEdit(event, item.id)}
                >
                    <Icon src={Edit} alt="Icon" />
                </IconContainer>
                <Popover
                    id={editId}
                    open={openEdit}
                    anchorEl={editanchorEl}
                    onClose={handleCloseEdit}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <EditContainer>
                        {/* <FeeInput
                                type="number"
                                value={updatedFee}
                                onChange={(e) => setUpdatedFee(e.target.value)}
                            /> */}
                        <TextField
                            id="outlined-size-small"
                            label="Fee"
                            type="number"
                            size="small"
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "100%",
                            }}
                            // inputStyle={{ fontSize: "50px" }}
                            onChange={(e) => setUpdatedFee(parseInt(e.target.value))}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">INR</InputAdornment>,
                            }}
                        />
                        {/* <FormControl
                            sx={{ m: 1, width: "100%" }}
                            size="small"
                            style={{
                                boxSizing: "border-box",
                                margin: "8px 0px 0px 0px",
                            }}
                        >
                            <InputLabel id="demo-select-small">University</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={selectedUniversity}
                                label="University"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    paddingLeft: "4px",
                                }}
                                onChange={handleUniversityChange}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                {universities.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.id}
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>
                                                Twenty
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Thirty
                                            </MenuItem> */}
                            {/* </Select>
                        </FormControl> */} 
                        {/* <FormControl
                            sx={{ m: 1, width: "100%" }}
                            style={{
                                boxSizing: "border-box",
                                // margin: "4px 0px",
                                margin: "8px 0px 0px 0px",
                            }}
                            size="small"
                        >
                            <InputLabel id="demo-select-small">Course</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={selectedCourse}
                                label="Course"
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    paddingLeft: "4px",
                                }}
                                onChange={handleCourseChange}
                                disabled={selectedUniversity ? false : true}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                {courses.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.id}
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>
                                                Twenty
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Thirty
                                            </MenuItem> */}
                            {/* </Select>
                        </FormControl> */} 
                        <SubmitDiv>
                            <SubmitButton
                                onClick={() => {
                                    if (updatedFee === "" && selectedUniversity === "" && selectedCourse === "") {
                                        alert("No changes to update");
                                    } else if (selectedUniversity !== "" && selectedCourse === "") {
                                        alert("Select Course");
                                    } else {
                                        handleSubmit();
                                    }
                                }}
                            >
                                Submit
                            </SubmitButton>
                        </SubmitDiv>
                    </EditContainer>
                </Popover>
            </TLLD>
            {/* <TD
					// width="18%"
					fontSize="14px"
					color="#3ccb7f"
					style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				>
					----------
				</TD> */}
            {/* <TD
					// width="18%"
					fontSize="14px"
					color="#3ccb7f"
					style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				>
					----------
				</TD> */}
            {/* <TLLD>
					<FollowUpButton
						onClick={() => {
							setIsModal(true);
							setselectedLead(item.id);
						}}
					>
						Follow Up
					</FollowUpButton>
					<IconContainer
						onClick={(e) => {
							e.preventDefault();

							history.push(`/admission-consultant/edit-lead/${item.id}/`);
						}}
						style={{ cursor: "pointer" }}
					>
						<Icon src={Edit} alt="Icon" />
					</IconContainer>
				</TLLD> */}
        </RowDiv>
    );
}

export default AdmissionList;

const StatusText = styled.p`
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => (props.background ? props.background : "#000")};
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const FollowUpButton = styled.div`
    width: 85px;
    margin-right: 10px;
    height: 37px;
    color: rgb(255, 255, 255);
    text-transform: none;
    background-color: rgb(82, 46, 146);
    border-radius: 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const TLLD = styled.div`
    display: flex;
    width: 15px;
    align-items: center;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 14px;
    display: block;
    object-fit: cover;
`;
const EditContainer = styled.div`
    padding: 8px;
    width: 240px;
`;
const FeeInput = styled.input`
    padding: 0.2em;
    box-sizing: border-box;
    width: 100%;
    height: 28px;
    font-size: 14px;
    display: inline-block;
`;
const SubmitDiv = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 8px;
`;
const SubmitButton = styled.div`
    font-size: 14px;
    padding: 6.5px 10px;
    border-radius: 4px;
    background: #0FA76F;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const RowDiv = styled.div`
    /* background: ${(props) => (props.selected ? "#efefef" : "#fff")}; */
`;
