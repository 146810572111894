import "./style.css";
import Text from "../Text";
import visible from "../../assets/images/visible.svg";

import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";

import styled from "styled-components";

function Input({
    icon,
    name,
    placeholder,
    type,
    fontFamily,
    color,
    padding,
    img,
    width,
    value,
    disabled,
    onChange,
    style,
    invalid,
    onClickVisible,
    isError,
    errorMessage,
    fieldName,
    onFocus,
    forValue,
    onWheel,
}) {
    return (
        <div className="type" style={{ width: width ? width : "49%" }}>
            <Text color="#0FA76F" fontSize="15px">
                {name}
            </Text>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between ",
                    position: "relative",
                }}
            >
                <input className="add-input"
                    style={{
                        width: width ? width : "100%",
                        padding: padding ? padding : "20px 15px 20px 20px",
                        fontFamily: fontFamily ? fontFamily : "Inter",
                        ...style,
                        border:
                            isError === true &&
                            name === fieldName &&
                            "1px solid  red",
                    }}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    img={img}
                    color={color}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={onFocus}
                    id={forValue}
                    onWheel={(event) => {
                        if (onWheel) {
                            event.currentTarget.blur();
                        }
                    }}
                />
                {(name == "Admin Password" ||
                    name == "Confirm Admin Password" ||
                    name == "Password") && (
                    <div
                        onClick={onClickVisible}
                        style={{
                            // margin: "35px 30% ",
                            position: "absolute",
                            cursor: "pointer",
                            right: 20,
                            top: "45%",
                        }}
                    >
                        <img src={icon} alt="Logo" />
                    </div>
                )}
            </div>
            {isError === true && name === fieldName && (
                <ErrorText>{errorMessage}</ErrorText>
            )}
        </div>
    );
}

const ErrorText = styled.p`
    color: red;
`;
export default Input;
