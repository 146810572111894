import React, { useState } from 'react';
import Table from '../Table';
import Text from '../Text';
import Button from '../../components/Button';

function DeletePopup() {
	return (
		<Table style={{ width: '100%' }} margin="0" borderRadius="30px" padding="30px">
			<div style={{ padding: '130px 0px' }}>
				<Text style={{ textAlign: 'center' }} fontSize="23px">
					Do You Want To Delete The Team
				</Text>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', padding: '50px 20px' }}>
					<Button
						href="#"
						style={{
							display: 'flex',
							alignItems: 'center'
						}}
						bacerkgroundColor="#5A2D8F"
						fontSize="15px"
						padding="21px 55px"
					>
						<Text style={{ marginLeft: '12px' }} color="white" fontSize="15px">
							Yes
						</Text>
					</Button>
					<Button
						href="#"
						style={{
							display: 'flex',
							alignItems: 'center'
						}}
						bacerkgroundColor="#5A2D8F"
						fontSize="15px"
						padding="15px 55px"
					>
						<Text style={{ marginLeft: '12px' }} color="white" fontSize="15px">
							No
						</Text>
					</Button>
				</div>
			</div>
		</Table>
	);
}

export default DeletePopup;
