import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useState, useEffect, useContext } from "react";
import add from "../../assets/images/add.svg";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Progress_bar from "../../components/progressBar/progress";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import leadListData from "../../components/leadGeneratorAdmin/leadListData";
import Profile from "../../assets/images/profile.svg";
import TeamMembers from "./teamMembers";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import loadingGif from "../../assets/lottie/loading.gif";

function Ranking() {
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [myTeam, setMyTeam] = useState();
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    // Get current posts

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= Math.ceil(leadListData.length / count)) {
            setCurrentPage(pageNumber);
        }
    };

    const getSalesPerson = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/team/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setMyTeam(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getSalesPerson();
    }, []);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                }}
            >
                <div>
                    <Title fontSize="35px">My Team</Title>
                </div>
            </div>

            <>
                <div
                    style={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* <div>
							<Text
								style={{ marginBottom: "20px" }}
								color="black"
								fontSize="15px"
							>
								Sales team Ranking
							</Text>
						</div> */}
                    {!isLoading ? (
                        <Container
                            style={{
                                flexWrap: "wrap ",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "space-between",
                                // padding: "0px 52px",
                                marginBottom: "20px",
                                height: "100px",
                            }}
                            width="100%"
                            padding="0 30px"
                            margin="0"
                        >
                            {/* <div style={{ display: "flex" }}> */}
                            {/* <Div> */}
                            <div
                                style={{
                                    display: "flex",
                                    width: "49%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    //
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Profile} alt="Logo" />
                                    <div style={{ marginLeft: "5px" }}>
                                        <Text color="black" fontSize="20px">
                                            #{myTeam?.rank}
                                        </Text>
                                        <Text
                                            color="black"
                                            fontSize="17px"
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {myTeam?.name}
                                        </Text>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "50%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <Text fontSize="15px" color="black">
                                            Success Rate
                                        </Text>
                                        <Text fontSize="15px" color="black" style={{ marginLeft: "5px" }}>
                                            {myTeam?.success_rate}%
                                        </Text>
                                    </div>
                                    {/* <Progress_bar
										width="100%"
										bgcolor=" #522E92"
										progress={item.success_rate}
										height={10}
									/> */}
                                </div>
                            </div>
                            <hr />
                            <div
                                style={{
                                    width: "49%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        // margin: "50px 30px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text
                                        // style={{ margin: "0px 30px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="#808080"
                                        fontSize="15px"
                                    >
                                        Total Leads
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 30px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="#808080"
                                        fontSize="15px"
                                    >
                                        Interested
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 30px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="#808080"
                                        fontSize="15px"
                                    >
                                        Not Interested
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 30px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="#808080"
                                        fontSize="15px"
                                    >
                                        Follow up
                                    </Text>
                                    <Text
                                        color="#808080"
                                        fontSize="15px"
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                    >
                                        Converted
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        // margin: "0px 30px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text
                                        // style={{ margin: "0px 60px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="black"
                                        fontSize="15px"
                                    >
                                        {/* {item.total_lead_assigned} */}
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 60px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="black"
                                        fontSize="15px"
                                    >
                                        {/* {item.total_lead_interested} */}
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 60px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="black"
                                        fontSize="15px"
                                    >
                                        {/* {item.total_lead_not_interested} */}
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 60px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="black"
                                        fontSize="15px"
                                    >
                                        {/* {item.total_lead_need_followup} */}
                                    </Text>
                                    <Text
                                        // style={{ margin: "0px 20px" }}
                                        style={{
                                            width: "18%",
                                            textAlign: "center",
                                        }}
                                        color="black"
                                        fontSize="15px"
                                    >
                                        {/* {item.total_lead_converted} */}
                                    </Text>
                                </div>
                                {/* </div> */}
                            </div>
                            {/* </Div> */}
                        </Container>
                    ) : (
                        <LoaderContainer>
                            <LoadingIcon src={loadingGif} alt="icon" />
                        </LoaderContainer>
                    )}

                    {/* <div
							style={{
								marginBottom: "15px",
							}}
						>
							<Text
								fontSize="22px"
								color="#808080"
								fontWeight="500"
							>
								Team Members
							</Text>
						</div> */}
                </div>

                {/* <TeamMembers data={item.members_data} /> */}
            </>
        </Wrapper>
    );
}

export default Ranking;

const Div = styled.div``;
const LoaderContainer = styled.div`
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 220px);
`;
const LoadingIcon = styled.img`
    width: 65px;
    display: block;
    object-fit: cover;
`;
