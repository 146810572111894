import React from "react";
import Text from "../../../components/Text";
import { useHistory } from "react-router-dom";

import editIcon from "./../../../assets/images/edit.svg";
import Delete from "./../../../assets/images/delete.svg";

function Stageq({
	width,
	fontSize,
	add,
	leadStageq,
	data,
	setModal,
	setDeleteId,
}) {
	const history = useHistory();
	return (
		<div>
			{console.log(data, "dataname -->")}
			<div
				style={{
					backgroundColor: "#F9F9F9",
					padding: " 20px ",
					width: width ? width : "100%",
					boxSizing: "border-box",
					// margin: margin ? margin : "55px auto",
					border: add ? "2px dashed #DFEAF2" : "none",
					borderRadius: "10px",
					textAlign: "center",
					marginBottom: "10px",
					position: "relative",
					alignItems: "center",
				}}
				className="box"
				onClick={() => {
					if (add) {
						history.push("/leadgenerator/sources/");
					}
				}}
			>
				<Text
					style={{
						fontSize: fontSize ? fontSize : "10px",
						marginLeft: "15px",
						fontFamily: "Inter",
					}}
				>
					{data.name}
				</Text>
				<img
					onClick={() => {
						setModal(true);
						setDeleteId(data.id);
						console.log("hello its shi", data.id);
					}}
					style={{
						cursor: "pointer",
						position: "absolute",
						right: "15px",
						top: "20px",
					}}
					src={Delete}
				/>
			</div>
		</div>
	);
}
export default Stageq;
