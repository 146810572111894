import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";
import MainDashboard from "./mainDashboard";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import AddNewLead from "./addNewLead";
import Source from "./source";
import Leads from "./leads";
import Follow from "./followup";
import Meetings from "./Meetings";
import Admissions from "./Admissions";
import DuplicateLeads from "./DuplicateLeads";
import SalesReport from "./SalesReport";
import ReportPage from "../../components/includes/ReportPage";
function SalesPerson() {
    const [openNav, setOpenNav] = useState(false);
    return (
        <div className="body">
            <NavBar openNav={openNav} data={navBarData} setOpenNav={setOpenNav} />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route path="/admission-consultant/" exact component={MainDashboard} />
                    <Route path="/admission-consultant/list-leads/" component={Leads} />
                    <Route path="/admission-consultant/duplicate-leads/" component={DuplicateLeads} />

                    <Route path="/admission-consultant/follow-up/" component={Follow} />
                    <Route path="/admission-consultant/meetings/" component={Meetings} />
                    <Route path="/admission-consultant/admissions/" component={Admissions} />
                    <Route path="/admission-consultant/add-leads/" component={AddNewLead} />
                    <Route path="/admission-consultant/sales-report/" exact component={SalesReport} />
                    {/* <Route
                        path="/admission-consultant/sales-report/"
                        exact
                        component={ReportPage}
                    /> */}
                    <Route path="/admission-consultant/edit-lead/:leadID/" component={AddNewLead} />
                    <Route path="/admission-consultant/sources/" component={Source} />
                </Switch>
            </div>
        </div>
    );
}

export default SalesPerson;
