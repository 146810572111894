import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Pie } from "react-chartjs-2";
import React, { useEffect, useState, useContext } from "react";
import SocialMedia from "./socialMedia/socialMedia";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";
import addSource from "../../assets/images/add-source.svg";
import { GetLeadGeneratorDashboardService } from "../../service/dashboard";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import moment from "moment";
import {
    BarChart,
    Bar,
    Brush,
    Cell,
    CartesianGrid,
    ReferenceLine,
    ReferenceArea,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ErrorBar,
    LabelList,
    Label,
    Rectangle,
    LineChart,
    Line,
    ResponsiveContainer,
} from "recharts";
import Input from "../../components/Input/input";
import Welcome from "../../components/includes/Welcome";
import WelcomeMessage from "../../components/includes/WelcomeMessage";

// import {
// 	Chart as ChartJS,
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	// Title,
// 	Tooltip,
// 	Legend,
// } from "chart.js";
// ChartJS.register(
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	Title,
// 	Tooltip,
// 	Legend
// );

function MainDashboard() {
    const [monthType, setMonthType] = useState("month");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const minFromDateValue = moment(toDate).subtract(1, "M");
    const maxToDateValue = moment(fromDate).add(1, "M");
    // new Date().toLocaleDateString("en-CA")

    const [ma, seMA] = useState("");

    const { state } = useContext(Context);
    const [leadSource, setLeadSource] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState();

    // lead data
    const [yearlyLead, setYearlyLead] = useState([]);
    const [monthlyLead, setMonthlyLead] = useState([]);
    const [dailyLead, setDailylyLead] = useState([]);
    const [customLead, setCustomLead] = useState([]);

    // max value
    const [maxYearValue, setMaxYearValue] = useState("");
    const [maxMonthValue, setMaxMonthValue] = useState("");
    const [maxDailyValue, setMaxDailyValue] = useState("");

    // const getLeadSource = () => {
    // 	setLoading(true);
    // 	let accessToken = state.user_details.access_token;
    // 	baseConfig
    // 		.get("/leads/lead-source/", {
    // 			headers: {
    // 				Authorization: "Bearer " + accessToken,
    // 			},
    // 		})
    // 		.then((response) => {
    // 			const { StatusCode, data, leads } = response.data;
    // 			if (StatusCode === 6000) {
    // 				// setLeadSource(leads);
    // 				setLoading(false);
    // 			} else {
    // 				setLoading(false);
    // 			}
    // 		})
    // 		.catch((error) => {});
    // };

    const getDashboardData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/general/lead-generator-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    start_date: fromDate,
                    end_date: toDate,
                },
            })
            .then((response) => {
                const { StatusCode, data, leads } = response.data;
                if (StatusCode === 6000) {
                    setYearlyLead(data.yearly_reports);
                    setMonthlyLead(data.monthly_reports);
                    setDailylyLead(data.daily_report);
                    setCustomLead(data.custom_report);
                    setDashboardData(data);
                    handleMaxValue(data);
                    setLeadSource(data.lead_sources);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const handleMaxValue = (data) => {
        setMaxYearValue(
            Math.max(...data.yearly_reports.map((item) => item.no_of_leads))
        );
        setMaxMonthValue(
            Math.max(...data.monthly_reports.map((item) => item.no_of_leads))
        );
        setMaxDailyValue(
            Math.max(...data.daily_report.map((item) => item.no_of_leads))
        );
    };

    useEffect(() => {
        // getLeadSource();
        getDashboardData();
    }, []);

    useEffect(() => {
        if (toDate) {
            getDashboardData();
        }
    }, [fromDate, toDate]);

    const data = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            datasets: [
                {
                    label: "no.of Reports",
                    data: dashboardData?.monthly_reports?.map((da) => {
                        return da?.no_of_leads;
                    }),
                    backgroundColor: g,
                    border: "none",
                    barThickness: 60,
                },
            ],
        };
    };
    const yearData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: dashboardData?.yearly_reports?.map((da) => {
                return da?.year;
            }),
            datasets: [
                {
                    label: "no.of Reports",
                    data: dashboardData?.yearly_reports?.map((da) => {
                        return da?.no_of_leads;
                    }),
                    backgroundColor: g,
                    border: "none",
                    barThickness: 60,
                },
            ],
        };
    };

    const dayData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: dashboardData?.daily_report?.map((da) => {
                return da?.date;
            }),
            datasets: [
                {
                    label: "no.of Reports",
                    data: dashboardData?.daily_report?.map((da) => {
                        return da?.no_of_leads;
                    }),
                    backgroundColor: g,
                    border: "none",
                    barThickness: 30,
                },
            ],
        };
    };

    const pieData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g1 = ctx.createLinearGradient(0, 0, 0, 400);
        g1.addColorStop(1, "#77C1D8");
        g1.addColorStop(0, "#77C1D8");
        const g2 = ctx.createLinearGradient(0, 0, 0, 400);
        g2.addColorStop(1, "#8BC38F");
        g2.addColorStop(0, "#8BC38F");
        return {
            // labels: ["Other Leads", "My Leads"],
            datasets: [
                {
                    data: [
                        dashboardData?.lead_distribution?.total_contributions -
                            dashboardData?.lead_distribution?.my_contributions,
                        dashboardData?.lead_distribution?.my_contributions,
                    ],
                    // backgroundColor: [g1, g2],
                    backgroundColor: [
                        `#77C1D8`,
                        `#8BC38F`,
                    ],
                    border: "none",
                },
            ],
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                // position: 'top' as const,
            },
            tooltips: {
                enabled: false,
            },
            title: {
                display: true,
            },
        },
        scales: {
            y: {
                ticks: {
                    min: 200,
                    stepSize: 200,
                    max: 1000,
                    showLabelBackdrop: false,
                    beginAtZero: false,
                },
            },
            x: {
                grid: {
                    display: false,
                    barPercentage: 0.4,
                },
            },
        },
        // scales: {
        // 	xAxes: [
        // 		{
        // 			barThickness: 10,
        // 		},
        // 	],
        // },
    };

    const sourceOptions = {
        scales: {
            y: {
                ticks: {
                    min: 200,
                    stepSize: 200,
                    max: 1000,
                    showLabelBackdrop: false,
                },
            },
            x: {
                grid: {
                    display: false,
                    barPercentage: 0.4,
                    categoryPercentage: 1.0,
                },
            },
        },
    };

    const sourceData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: leadSource?.map((da) => {
                return da?.source;
            }),
            datasets: [
                {
                    label: "Source",
                    data: leadSource?.map((da) => {
                        return da?.count;
                    }),
                    backgroundColor: g,
                    border: "none",
                    // width: "100px",
                },
            ],
        };
    };

    const getDasboardData = async () => {
        let data = await GetLeadGeneratorDashboardService(state);
        if (data.StatusCode === 6000) {
            setDashboardData(data.data);
        } else {
            alert(data.data.message);
        }
    };

    useEffect(() => {
        // getDasboardData();
    }, []);

    const newData = [
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: "Page E",
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: "Page F",
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: "Page G",
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    const MonthContainer = ({ value, count, xVariable, yMax }) => {
        return (
            <ResponsiveContainer height={400}>
                <BarChart
                    data={value}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey={xVariable} interval={0} />
                    <YAxis domain={[0, yMax + 50]} />
                    <Legend />
                    <Bar
                        dataKey={count}
                        fill="#77C1D8"
                        isAnimationActive={false}
                        barSize={40}
                        barGap={0}
                        barCategoryGap={0}
                        // width={600}
                        // barCategoryGap={500}
                        minPointSize={5}
                        name="No.of Leads"
                    >
                        <LabelList
                            dataKey={count}
                            position="top"
                            style={{ fill: "#000" }}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    };

    return (
        <>
            {isLoading === false ? (
                <Wrapper>
                    {/* <TitleWrapper>
                        <Welcome />
                        <Title
                            fontSize="32px"
                            fontWeight="500"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // marginBottom: "20px",
                                marginLeft: "6px",
                            }}
                        >
                            {dashboardData?.admin_name}
                        </Title>
                    </TitleWrapper>

                    <WelcomeMessage /> */}
                    <Container className="chart-container" width="100%" margin="0 0 20px 0">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <Title fontSize="23px">Lead Reports</Title>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    className="chart"
                                    fontSize="16px"
                                    color="#000"
                                    fontWeight="400"
                                >
                                    No of Leads
                                </Text>
                                <select
                                    onChange={(e) => {
                                        setMonthType(e.target.value);
                                    }}
                                    style={{
                                        display: "-webkit-inline-box",
                                        paddingLeft: "15px",
                                    }}
                                    className="select-input"
                                    value={monthType}
                                >
                                    <option value="month">Per Month</option>
                                    <option value="year">Per Year</option>
                                    <option value="day">Per Day</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>
                        </div>
                        {/* <div style={{ height: "300px" }}>
							<Bar
								data={
									monthType === "month"
										? data
										: monthType === "year"
										? yearData
										: monthType === "day"
										? dayData
										: null
								}
								id="canvas"
								options={options}
							/>
						</div> */}
                        {monthType === "custom" && (
                            <CustomDateContainer>
                                <EmptyDiv></EmptyDiv>
                                <CustomDateFields>
                                    <DateFieldBox>
                                        <DateFieldText>From</DateFieldText>
                                        <DateInput
                                            onChange={(e) => {
                                                setFromDate(e.target.value);
                                                // setError(false);
                                            }}
                                            type="date"
                                            name="From"
                                            min={
                                                toDate
                                                    ? moment(
                                                          minFromDateValue
                                                      ).format("yyyy-MM-DD")
                                                    : null
                                            }
                                            max={toDate ? toDate : null}
                                            value={fromDate}
                                        />
                                    </DateFieldBox>
                                    <DateFieldBox>
                                        <DateFieldText>To</DateFieldText>
                                        <DateInput
                                            onChange={(e) => {
                                                setToDate(e.target.value);
                                            }}
                                            type="date"
                                            name="To"
                                            min={fromDate}
                                            max={moment(maxToDateValue).format(
                                                "yyyy-MM-DD"
                                            )}
                                            disabled={fromDate ? false : true}
                                            value={toDate}
                                        />
                                    </DateFieldBox>
                                </CustomDateFields>
                            </CustomDateContainer>
                        )}
                        <div className="chart">
                            {monthType === "month" ? (
                                <MonthContainer
                                    value={monthlyLead}
                                    count="no_of_leads"
                                    xVariable="month"
                                    yMax={Math.round(maxMonthValue / 10) * 10}
                                />
                            ) : monthType === "year" ? (
                                <MonthContainer
                                    value={yearlyLead}
                                    count="no_of_leads"
                                    xVariable="year"
                                    yMax={Math.round(maxYearValue / 10) * 10}
                                />
                            ) : monthType === "day" ? (
                                <MonthContainer
                                    value={dailyLead}
                                    count="no_of_leads"
                                    xVariable="date"
                                    yMax={Math.round(maxDailyValue / 10) * 10}
                                />
                            ) : monthType === "custom" ? (
                                fromDate &&
                                toDate && (
                                    <MonthContainer
                                        value={customLead}
                                        count="no_of_leads"
                                        xVariable="date"
                                        yMax={
                                            Math.round(maxDailyValue / 10) * 10
                                        }
                                    />
                                )
                            ) : null}
                        </div>
                    </Container>
                    {monthType !== "custom" && (
                        <div className="button-chart" style={{ display: "flex" }}>
                            <Container className="chart-1" width="40%" margin="0">
                                <Title className="distribution" fontSize="20px">Lead Distribution</Title>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        height: "100%",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        className="pie-chart"
                                        style={{ height: "60%", width: "60%" }}
                                    >
                                        <Pie
                                            data={pieData}
                                            options={{
                                                responsive: true,
                                                aspectRatio: 1,
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            alignSelf: "flex-end",
                                            padding: "50px 0",
                                            width: "30%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    backgroundColor:
                                                        "#77C1D8",
                                                    marginRight: "10px",
                                                }}
                                            ></div>
                                            <Text fontSize="15px">
                                                Other Leads
                                            </Text>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    backgroundColor:
                                                        "#8BC38F",
                                                    marginRight: "10px",
                                                }}
                                            ></div>
                                            <Text fontSize="15px">
                                                My Leads
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                            <Container
                                className="chart-container"
                                width="60%"
                                padding="20px"
                                margin="0 0 0 15px"
                            >
                                <Title fontSize="20px">Lead Sources</Title>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <ResponsiveContainer height={400}>
                                        <BarChart
                                            data={leadSource}
                                            // margin={{
                                            // 	top: 5,
                                            // 	right: 30,
                                            // 	left: 20,
                                            // 	bottom: 5,
                                            // }}
                                        >
                                            <XAxis
                                                dataKey="name"
                                                tick={{ fontSize: 11 }}
                                                interval={0}
                                            />
                                            <YAxis
                                                // dataKey="count"
                                                domain={[0, 100]}
                                            />
                                            <Legend />
                                            <Bar
                                                dataKey="count"
                                                fill="#8BC38F"
                                                isAnimationActive={false}
                                                barSize={30}
                                                barGap={2}
                                                // barCategoryGap={2}
                                                minPointSize={5}
                                                name="No.of Leads"
                                            >
                                                <LabelList
                                                    dataKey="count"
                                                    position="top"
                                                    style={{ fill: "#000" }}
                                                />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Container>
                        </div>
                    )}
                </Wrapper>
            ) : (
                <Div>
                    <LoadingGif
                        src={
                            require("./../../assets/images/loading-buffering.gif")
                                .default
                        }
                        alt="Loading"
                    />
                </Div>
            )}
        </>
    );
}

export default MainDashboard;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 120px);
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
const CustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const EmptyDiv = styled.div``;
const CustomDateFields = styled.div`
    display: flex;
    align-items: center;
`;
const DateInput = styled.input`
    /* display: inline-block !important;
    width: 200px !important;
    height: 42px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 8px !important; */
    width: 200px !important;
    padding: 6px;
    height: 42px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    -webkit-text-fill-color: #000;
    box-sizing: border-box;
    /* border: 1px solid #dfeaf2; */
    border: 1px solid rgb(102, 102, 102);
    border-radius: 8px;
    &:hover {
        background: #fbfbfb;
    }
    &:focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
`;
const DateFieldBox = styled.div`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const DateFieldText = styled.span`
    display: block;
    margin-bottom: 8px;
    color: #522e92;
    font-size: 16px;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
// const ABC = styled.div``;
// const ABC = styled.div``;
// const ABC = styled.div``;
// const ABC = styled.div``;
