import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

function CountryCodeModal({
    setCountryModal,
    countryCode,
    setSelectedCountryCode,
    setCountrySearch,
}) {
    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setCountryModal(false);
                }}
            ></Overlay>
            <Modal>
                <Container>
                    <Dropdown>
                        <SearchInput
                            placeholder="Search Country"
                            autoFocus
                            onChange={(e) => setCountrySearch(e.target.value)}
                        />
                        {countryCode.length > 0 ? (
                            <CountryList>
                                {countryCode.map((data) => (
                                    <SingleCountryDiv>
                                        <Flag>{data.flag}</Flag>
                                        <DropdownOption
                                            onClick={() => {
                                                setCountryModal(false);
                                                setSelectedCountryCode(data);
                                            }}
                                        >
                                            {data.name} ({data.phone_code})
                                        </DropdownOption>
                                    </SingleCountryDiv>
                                ))}
                            </CountryList>
                        ) : (
                            <div
                                className="no-result"
                                style={{
                                    // minHeight: "calc(100vh - 200px)",
                                    width: "100%",
                                    // background: "red",
                                }}
                            >
                                <text className="no-result-text">
                                    No results found{" "}
                                </text>
                            </div>
                        )}
                    </Dropdown>
                </Container>
            </Modal>
        </BackContainer>
    );
}
export default CountryCodeModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;
`;
const Container = styled.div`
    width: 500px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Dropdown = styled.div`
    background: #f8fafc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    top: 55px;
    z-index: 60;
    width: 100%;
    /* max-height: 400px;
	overflow-y: scroll; */
    height: 300px;
`;
const SearchInput = styled.input`
    /* background: red; */
    padding: 10px 15px;
    border: 1px solid #f8fafc;
    border-radius: 5px;
`;
const CountryList = styled.div`
    height: 300px;
    overflow-y: scroll;
`;

const SingleCountryDiv = styled.div`
    display: flex;
    align-items: center;
`;
const Flag = styled.h3`
    font-size: 24px;
`;
const DropdownOption = styled.h4`
    color: #000;
    font-size: 15px;
    font-family: "poppinsregular";
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 10px 15px;
    width: 100%;
    :hover {
        background-color: #fff;
    }
    :first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    :last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    @media (max-width: 1280px) {
        font-size: 14px;
    }
`;
