import "./style.css";
import Text from "../../../components/Text";
import { Link } from "react-router-dom";
function SocialMedia({ img, name, width, fontSize, margin, add }) {
    return (
        <Link
            style={{
                backgroundColor: "#F9F9F9",
                padding: " 20px 10px 20px 40px",
                width: width ? width : "101%",
                boxSizing: "border-box",
                margin: margin ? margin : "55px auto",
                border: add ? "2px dashed #DFEAF2" : "none",
                borderRadius: "10px",
                textAlign: "center",
            }}
            className="box"
        >
            {add ? (
                <img style={{ margin: "14px 15px" }} src={img} />
            ) : (
                <div
                    style={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        width: "58px",
                        height: "58px",
                        textAlign: "center",
                    }}
                >
                    <img style={{ margin: "14px 14px" }} src={img} />
                </div>
            )}
            <Text
                style={{
                    fontSize: fontSize ? fontSize : "10px",
                    margin: "20px auto",
                    fontFamily: "Inter",
                }}
            >
                {name}
            </Text>
        </Link>
    );
}

export default SocialMedia;
