import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";
import MainDashboard from "./mainDashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import Leadmanagementadmin from "./leadManagerAdmin";
import LeadGenerateadmin from "./leadGenerateAdmin";
import LeadSalesAdmin from "./leadSalesAdmin";
import AddLeadManagerAdmin from "./addLeadManagerAdmin";
import AddLeadGeneratorAdmin from "./addLeadGeneratorAdmin";
import AddSalesAdmin from "./addSalesAdmin";
import UpdateLeadManagerAdmin from "./updateLeadManager";
import UpdateLeadGeneratorAdmin from "./updateLeadGenerator";
import UpdateSalesPersonAdmin from "./updateSalesPerson";
import AddProgram from "./AddProgram";
import AddStage from "./AddStage";

function SuperAdmin() {
    const [openNav, setOpenNav] = useState(false);
    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/superadmin/"
                        exact
                        component={MainDashboard}
                    />
                    <Route
                        path="/superadmin/edit-lead-manager/:params/"
                        component={UpdateLeadManagerAdmin}
                    />
                    <Route
                        path="/superadmin/edit-lead-generator/:params/"
                        component={UpdateLeadGeneratorAdmin}
                    />
                    <Route
                        path="/superadmin/edit-admission-consultant/"
                        component={UpdateSalesPersonAdmin}
                    />

                    <Route
                        path="/superadmin/add-sales-manager/"
                        component={AddLeadManagerAdmin}
                    />
                    <Route
                        path="/superadmin/add-lead-generator/"
                        component={AddLeadGeneratorAdmin}
                    />
                    <Route
                        path="/superadmin/add-program/"
                        component={AddProgram}
                    />
                     <Route
                        path="/superadmin/add-stage/:id/"
                        component={AddStage}
                    />
                    <Route
                        path="/superadmin/add-admission-consultant/"
                        component={AddSalesAdmin}
                    />
                    <Route
                        path="/superadmin/sales-manager/"
                        component={Leadmanagementadmin}
                    />
                    <Route
                        path="/superadmin/lead-generator/"
                        component={LeadGenerateadmin}
                    />
                    <Route
                        path="/superadmin/admission-consultant/"
                        component={LeadSalesAdmin}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default SuperAdmin;
