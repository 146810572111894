import React from "react";
import Swal from "sweetalert2";

const SweetAlert = ({
    sweetAlertContent,
    setSweetAlertContent,
    // title,
    // message,
    // icon,
    // confirmButtonText,
    onConfirm,
    isSweetAlert,
    setIsSweetAlert,
}) => {
    const { title, message, icon, confirmButtonText, isCancelButton } =
        sweetAlertContent;
    const handleConfirm = () => {
        onConfirm();
        setIsSweetAlert(false);
    };

    if (isSweetAlert) {
        Swal.fire({
            title: title || "",
            text: message,
            icon: icon || "info",
            confirmButtonText: confirmButtonText || "OK",
            showCancelButton: isCancelButton,
            cancelButtonText: "Cancel",
            // title: message,
            // icon: icon,
            // confirmButtonText: confirmButtonText,
            // showCancelButton: false,
            // allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleConfirm();
                setIsSweetAlert(false);
                setSweetAlertContent({
                    title: "",
                    message: "",
                    icon: "",
                    confirmButtonText: "",
                });
            }
        });
    }
    return null;
};

export default SweetAlert;
// example of usage in assignedLeads.js under lead manager
