import dashboard from "../../assets/images/dashboard-menu.svg";
import lead from "../../assets/images/menu-leads.svg";
import admin from "../../assets/images/admin-menu.svg";
import sources from "../../assets/images/teams.svg";
import notices from "../../assets/images/notices.svg";

const navBarData = [
    {
        img: dashboard,
        text: "Dashboard",
        link: "/team-leader/",
    },
    {
        img: lead,
        text: "Leads",
        inner: [
            {
                text: "New Leads",
                link: "/team-leader/new-leads/",
            },
            {
                text: "Follow up",
                link: "/team-leader/followup/",
            },
            // {
            // 	text: "Meeting",
            // 	link: "/team-leader/meeting/",
            // },
            // {
            // 	text: "Add Leads",
            // 	link: "/team-leader/add-leads/",
            // },
        ],
    },
    {
        img: notices,
        text: "Sales Report",
        link: "/team-leader/sales-report/",
    },
];
export default navBarData;
