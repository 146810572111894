import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Facebook from "../../assets/images/facebook.svg";
import SocialMedia from "./socialMedia/socialMedia";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import UploadInputLead from "../../components/UploadInput/UploadInputLead";
import Button from "../../components/Button";
import { AddSourceService, ListSourceService } from "../../service/sources";
import { useHistory } from "react-router-dom";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";

function Source() {
    const history = useHistory();
    const [file, setFile] = useState(null);
    const [form, setFormValues] = useState({});
    const [sources, setSources] = useState([]);
    const { state } = useContext(Context);
    const [sourceName, setSourceName] = useState("");
    const [isLoading, setLoading] = useState(true);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const getData = async () => {
        setLoading(true);
        let data = await ListSourceService(state);
        if (data.StatusCode === 6000) {
            setSources(data?.data?.data);
            setLoading(false);
        } else {
            alert(data.data.message);
            setLoading(false);
        }
    };

    // const handleSubmit = async () => {
    // 	let data = await AddSourceService({
    // 		...form,
    // 		image: file ? file[0] : "",
    // 	});
    // 	if (data.StatusCode === 6000) {
    // 		alert("Success");
    // 		getData();
    // 		setFormValues({ name: "" });
    // 		setFile(null);
    // 	} else {
    // 		alert(data.data.message);
    // 	}
    // };

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("name", sourceName);
        if (file !== null) {
            formdata.append("image", file[0]);
        }

        baseConfig
            .post("/leads/lead-source/", formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/superadmin/list-campus/");
                    getData();
                    setSourceName("");
                    setFile(null);
                } else if (6001) {
                    alert(data.data.message);
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div style={{ display: "flex", margin: "0 12px" }}>
                    <Title fontSize="35x">Sources</Title>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {isLoading === false ? (
                    <Container
                        style={{
                            flexWrap: "wrap ",
                            borderRadius: "23px",
                            display: "flex",
                            justifyContent: "space-between",
                            height: "10% ",
                        }}
                        width="75%"
                    >
                        {sources?.map((source) => {
                            return (
                                <SocialMedia
                                    width="30%"
                                    fontSize="18px"
                                    img={source?.image}
                                    name={source?.name}
                                />
                            );
                        })}
                    </Container>
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}

                <Container
                    style={{ flexWrap: "wrap ", borderRadius: "23px" }}
                    width="30%"
                >
                    <form id="form">
                        <div>
                            <Title fontSize="20px">Add new sources</Title>
                            <Text
                                style={{
                                    color: "#808080",
                                    fontSize: "14px",
                                    marginTop: "3%",
                                }}
                            >
                                Upload source image
                            </Text>
                            <UploadInputLead file={file} setFile={setFile} />
                            <Text
                                color="#808080"
                                fontSize="15px"
                                style={{ marginTop: "20px" }}
                            >
                                Source Name
                            </Text>
                            <Input
                                value={sourceName}
                                onChange={(value) => setSourceName(value)}
                                width="100%"
                                type="name"
                                placeholder="Enter Source name here"
                            />
                        </div>
                        {/* <div style={{ display: "flex", marginTop: "2%" }}>
                        <Input onChange={(value) => handleChangeValue("isQuality", value)} width="26px" type="checkbox" />

                        <Text style={{ marginTop: "2%" }} color="#7B6294" fontSize="15px">Quality Source</Text>

                    </div> */}
                        <div
                            style={{
                                display: "flex",
                                marginTop: "21px",
                                justifyContent: "space-between",
                                gap: "8px",
                            }}
                        >
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    // document.getElementById("form").submit();
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "45%",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="15px 50px"
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "45%",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="15px 50px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default Source;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    margin-left: 500px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
