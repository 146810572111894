const SalesListData = [
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Active'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Inactive'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Active'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Inactive'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Active'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Inactive'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Active'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Inactive'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Active'
	},
	{
		studentId: '001',
		name: 'Jomana Ah',
		leader: 'Jomana',
		members: '4',
		rank: '#1',
		status: 'Inactive'
	}
];
export default SalesListData;
