import Title from "../../components/Title";
import React, { useEffect, useState, useContext } from "react";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Line from "../../assets/images/Line.svg";
import Pagination from "../../components/pagination/pagination";
import assignedListData from "../../components/salesPerson/assignedListData";
import AssignedListItem from "../../components/salesPerson/assignedListItem";
import {
    AddSourceService,
    ListLeadsService,
    ListSourceService,
} from "../../service/leads";
import { Context } from "./../../contexts/Store";
import styled from "styled-components";
import { baseConfig } from "./../../utils/config";
import MainPagination from "../../components/includes/MainPagination";
import loadingGif from "../../assets/lottie/loading.gif";
import { Popover } from "@mui/material";

function Follow() {
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // const [status, setStatus] = useState("all");
    const [leads, setLeads] = useState([]);
    const [data, setData] = useState(leads);
    const { state } = useContext(Context);
    const [selectedLead, setselectedLead] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);

    const [selectedDashboard, setSelectedDashboard] =
        useState("Lead Dashboard");

    const [listStatus] = useState([
        {
            id: 1,
            title: "All",
            value: "followup",
        },
        {
            id: 2,
            title: "Introduction",
            value: "introduction",
        },
        {
            id: 3,
            title: "Meeting",
            value: "meeting",
        },
        // {
        //   id: 4,
        //   title: "Admissions",
        //   value: "admission",
        // },
        {
            id: 5,
            title: "No Response",
            value: "no_response",
        },
        {
            id: 5,
            title: "Dead",
            value: "dead",
        },
    ]);
    const [status, setStatus] = useState(listStatus[0].value);

    const interested = [];
    const not_interested = [];
    const status_pending = [];
    const follow_up = [];
    const converted = [];

    // const getData = (item) => {
    //   if (item.status === "not_intrested") {
    //     not_interested.push(item);
    //   }
    //   if (item.status === "converted") {
    //     converted.push(item);
    //   }
    //   if (item.status === "interested") {
    //     interested.push(item);
    //   }
    //   if (item.status === "status_pending") {
    //     status_pending.push(item);
    //   }
    //   if (item.follow_up === "follow_up") {
    //     follow_up.push(item);
    //   }
    //   if (item.converted === "converted") {
    //     converted.push(item);
    //   }
    // };
    // assignedListData.map(getData);
    // Get current posts
    const indexOfLastPost = currentPage * count;
    const indexOfFirstPost = indexOfLastPost - count;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= Math.ceil(data.length / count)) {
            setCurrentPage(pageNumber);
        }
    };

    // For Filter Purpose
    const [sortValue, setSortValue] = useState("");
    const [universityValues, setUniversityValues] = useState("");
    const [courseValues, setCourseValues] = useState("");
    const [selectedStage, setSelectedStage] = useState("");

    const [dateOrder, setDateOrder] = useState("");

    const dateOrders = [
        { name: "New to old", value: "new" },
        { name: "Old to new", value: "old" },
    ];
    const [stages] = useState([
        { id: 1, value: "hot", name: "Hot" },
        { id: 2, value: "warm", name: "Warm" },
        { id: 3,value: "cold", name: "Cold" },
    ]);
    const [statusFilter] = useState(status, selectedStage);
    // edit popover onClick states and functions
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter
    const getData1 = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    start_date: fromDate,
                    end_date: toDate,
                    status: status,
                    // status: statusFilter,
                    search: search,
                    ordering: sortValue,
                    university: universityValues,
                    course: courseValues,
                    date_order: dateOrder,
                    stage: selectedStage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeads(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
   


    useEffect(() => {
        getData1();
    }, [
        status,
        currentPage,
        search,
        sortValue,
        universityValues,
        courseValues,
        dateOrder,
        selectedStage,
    ]);
    useEffect(() => {
        if (toDate) {
            getData1();
        }
    }, [fromDate, toDate]);
   

    useEffect(() => {
        setCourseValues("");
    }, [universityValues]);

    return (
        <Wrapper className="assigned">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                }}
            >
                <div>
                    <Title fontSize="35px">Follow ups</Title>
                </div>
                <CustomDateContainer>
                    <EmptyDiv></EmptyDiv>
                    <CustomDateFields>
                        <DateFieldBox>
                            <DateFieldText>From</DateFieldText>
                            <DateInput
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                    // setError(false);
                                }}
                                type="date"
                                name="From"
                                // min={
                                //   toDate ? moment(minFromDateValue).format("yyyy-MM-DD") : null
                                // }
                                max={
                                    toDate
                                        ? toDate
                                        : new Date().toLocaleDateString("en-CA")
                                }
                                value={fromDate}
                            />
                        </DateFieldBox>
                        <DateFieldBox>
                            <DateFieldText>To</DateFieldText>
                            <DateInput
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                                type="date"
                                name="To"
                                min={fromDate}
                                // max={moment(maxToDateValue).format("yyyy-MM-DD")}
                                max={new Date().toLocaleDateString("en-CA")}
                                disabled={fromDate ? false : true}
                                value={toDate}
                            />
                        </DateFieldBox>
                        {fromDate &&
                            toDate &&
                            fromDate !== "dd/mm/yyyy" &&
                            toDate !== "dd/mm/yyyy" && (
                                <ClearFilterText
                                    onClick={() => {
                                        setFromDate("dd/mm/yyyy");
                                        setToDate("dd/mm/yyyy");
                                        // setFromDate("");
                                        // setToDate("");
                                        getData1();
                                    }}
                                >
                                    Clear Filter
                                </ClearFilterText>
                            )}
                    </CustomDateFields>
                </CustomDateContainer>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    marginTop: "10px",
                }}
            >
                <SearchDiv>
                    <Form className="search-container">
                        <SearchIcon
                            src={
                                require("./../../assets/images/search.svg")
                                    .default
                            }
                        />
                        <SearchField
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for something"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Form>
                </SearchDiv>
                <FilterButtonWrapper>
                    <Button
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 20px",
                            height: "50px",
                            marginLeft: "10px",
                        }}
                        backgroundColor="#FFF"
                        fontSize="15px"
                        aria-describedby={filterAnchorId}
                        variant="contained"
                        // onClick={handleClickFilter}
                        onClick={handleClickFilter}
                        // href="/superadmin/add-campus"
                    >
                        <img
                            style={{
                                width: "18px",
                                filter: "brightness(0.5)",
                            }}
                            src={filter}
                            alt="Logo"
                        />
                        <Text
                            style={{
                                marginLeft: "12px",
                                fontWeight: "500",
                            }}
                            color="#0FA76F"
                            fontSize="16px"
                        >
                            Filter
                        </Text>
                    </Button>
                    <Popover
                        id={filterAnchorId}
                        open={openFilter}
                        anchorEl={filterAnchorEl}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Container>
                            <Div>
                                <Title fontSize="20px">Filter by</Title>
                                <img
                                    style={{
                                        cursor: "pointer",
                                        width: "16px",
                                    }}
                                    onClick={handleCloseFilter}
                                    src={
                                        require("./../../assets/images/close.svg")
                                            .default
                                    }
                                    alt="icon"
                                />
                            </Div>
                            <div
                                style={{
                                    minHeight: "250px",
                                    maxHeight: "70vh",
                                    overflow: "scroll",
                                }}
                            >
                                <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>Name</Heading>
                                    <div
                                        onClick={() => {
                                            if (sortValue === "name") {
                                                setSortValue("");
                                            } else {
                                                setSortValue("name");
                                            }
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px 0 0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={
                                                require("./../../assets/images/a-z.svg")
                                                    .default
                                            }
                                            alt="icon"
                                        />
                                        <SubHeading
                                            style={{
                                                color:
                                                    sortValue === "name"
                                                        ? "#552E91"
                                                        : "#515151",
                                            }}
                                        >
                                            From A to Z
                                        </SubHeading>
                                        {sortValue === "name" && (
                                            <img
                                                src={
                                                    require("./../../assets/images/tick.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                        )}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (sortValue === "-name") {
                                                setSortValue("");
                                            } else {
                                                setSortValue("-name");
                                            }
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px 0 0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={
                                                require("./../../assets/images/z-a.svg")
                                                    .default
                                            }
                                            alt="icon"
                                        />
                                        <SubHeading
                                            style={{
                                                color:
                                                    sortValue === "-name"
                                                        ? "#552E91"
                                                        : "#515151",
                                            }}
                                        >
                                            From Z to A
                                        </SubHeading>
                                        {sortValue === "-name" && (
                                            <img
                                                src={
                                                    require("./../../assets/images/tick.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                        )}
                                    </div>
                                </Body>
                                <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>Date</Heading>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {dateOrders.map((item) => (
                                            <div
                                                onClick={() => {
                                                    setDateOrder(item.value);
                                                    if (
                                                        dateOrder === item.value
                                                    ) {
                                                        setDateOrder("");
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px 0 0",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {/* <img
                                                    src={
                                                        require("./../../assets/images/a-z.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                /> */}
                                                <SubHeading
                                                    style={{
                                                        color:
                                                            dateOrder ===
                                                            item.value
                                                                ? "#0FA76F"
                                                                : "#515151",
                                                    }}
                                                >
                                                    {item.name}
                                                </SubHeading>
                                                {dateOrder === item.value && (
                                                    <img
                                                        src={
                                                            require("./../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="icon"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Body>
                                <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>Stage</Heading>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {stages.map((item) => (
                                            <div
                                                onClick={() => {
                                                    setSelectedStage(
                                                        item.value
                                                    );
                                                    if (
                                                        selectedStage ===
                                                        item.value
                                                    ) {
                                                        setSelectedStage("");
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px 0 0",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {/* <img
                                                    src={
                                                        require("./../../assets/images/a-z.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                /> */}
                                                <SubHeading
                                                    style={{
                                                        color:
                                                            selectedStage ===
                                                            item.value
                                                                ? "#0FA76F"
                                                                : "#515151",
                                                    }}
                                                >
                                                    {item.name}
                                                </SubHeading>
                                                {selectedStage ===
                                                    item.value && (
                                                    <img
                                                        src={
                                                            require("./../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="icon"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Body>
                                {/* <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>University</Heading>
                                    {universities.map((data) => (
                                        <div
                                            onClick={() => {
                                                setUniversityValues(data.id);
                                                if (
                                                    universityValues === data.id
                                                ) {
                                                    setUniversityValues("");
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "10px 0 0",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <SubHeading
                                                style={{
                                                    color:
                                                        universityValues ===
                                                        data.id
                                                            ? "#552E91"
                                                            : "#515151",
                                                }}
                                            >
                                                {data.name}
                                            </SubHeading>
                                            {universityValues === data.id && (
                                                <img
                                                    src={
                                                        require("./../../assets/images/tick.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Body>
                                {universityValues && courses && (
                                    <Body borderBottom="6px solid #f2f2f2">
                                        <Heading>Course</Heading>
                                        {courses.map((data) => (
                                            <div
                                                onClick={() => {
                                                    setCourseValues(data.id);
                                                    if (
                                                        courseValues === data.id
                                                    ) {
                                                        setCourseValues("");
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px 0 0",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <SubHeading
                                                    style={{
                                                        color:
                                                            courseValues ===
                                                            data.id
                                                                ? "#552E91"
                                                                : "#515151",
                                                    }}
                                                >
                                                    {data.name}
                                                </SubHeading>
                                                {courseValues === data.id && (
                                                    <img
                                                        src={
                                                            require("./../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="icon"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </Body>
                                )} */}

                                <ButtonsBody>
                                    <ResetButton
                                        onClick={() => {
                                            setSortValue("");
                                            setDateOrder("");
                                            setUniversityValues("");
                                            setCourseValues("");
                                            setSelectedStage("");
                                            // handleCloseFilter();
                                        }}
                                    >
                                        Reset
                                    </ResetButton>
                                    <CloseButton
                                        onClick={() => {
                                            handleCloseFilter();
                                        }}
                                    >
                                        Close
                                    </CloseButton>
                                </ButtonsBody>
                            </div>
                        </Container>
                    </Popover>
                </FilterButtonWrapper>
            </div>

            <div style={{ marginBottom: "10px" }}>
                {listStatus.length !== 0 && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            height: "50px",
                            borderRadius: "30px 30px 0 0",
                            // marginBottom: "15px",
                        }}
                    >
                        {listStatus.map((item, index) => (
                            <a
                                href="#"
                                className={
                                    item.value == status
                                        ? "menu-status"
                                        : "active"
                                }
                                onClick={() => {
                                    setStatus(item.value);
                                }}
                                style={{
                                    borderRadius:
                                        index == 0
                                            ? "30px 0 0 0"
                                            : index == listStatus.length
                                            ? "0 30px 0 0"
                                            : null,
                                }}
                            >
                                <Text
                                    fontSize="18px"
                                    fontWeight="400"
                                    color="#000"
                                >
                                    {item.title}
                                </Text>
                            </a>
                        ))}
                    </div>
                )}
                {!isLoading ? (
                    <>
                        {leads.length > 0 ? (
                            <Tablee>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "20px 20px 20px 20px",
                                    }}
                                    className="table-head"
                                >
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="15px"
                                        width="8%"
                                    >
                                        ID No.
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="13%"
                                    >
                                        Lead Name
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="14%"
                                    >
                                        Phone
                                    </TD>
                                    {/* <TD
										color="#808080"
										fontWeight="500"
										fontSize="16px"
										width="18%"
									>
										Email
									</TD> */}

                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="12%"
                                    >
                                        Enq. Date
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="11%"
                                    >
                                        Lead Age
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="12%"
                                    >
                                        Status
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="6%"
                                        style={{
                                            marginRight: "0.5%",
                                        }}
                                    >
                                        Stage
                                    </TD>

                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="14%"
                                        style={{
                                            marginRight: "0.5%",
                                        }}
                                    >
                                        University
                                    </TD> */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="11%"
                                    >
                                        Program
                                    </TD>
                                    {/* {status === "followup" && ( */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="13%"
                                    >
                                        Next F-Up
                                    </TD>
                                    {/* )} */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="12%"
                                    >
                                        Remarks
                                    </TD>

                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="140px"
                                    >
                                        Action
                                    </TD>
                                    {/* <div style={{ width: "5%" }}></div> */}
                                </div>
                                <div
                                    style={{ padding: "0 0px" }}
                                    className="table-body"
                                >
                                    {leads?.map((item) => {
                                        return (
                                            <AssignedListItem
                                                status={item.status}
                                                statusType={status}
                                                item={item}
                                                // statusValue={statusValue}
                                                setselectedLead={
                                                    setselectedLead
                                                }
                                                selectedLead={selectedLead}
                                                // setSelectedStatus={setSelectedStatus}
                                                // setRemarkText={setRemarkText}
                                                // submitFollowUp={submitFollowUp}
                                                getData1={getData1}
                                            />
                                        );
                                    })}
                                </div>
                            </Tablee>
                        ) : (
                            <LoaderContainer>
                                <EmptyTextDiv>No leads Found</EmptyTextDiv>
                            </LoaderContainer>
                        )}
                        <div style={{ marginTop: "10px" }}>
                            <MainPagination
                                paginationData={paginationData}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </div>
                    </>
                ) : (
                    <LoaderContainer>
                        <LoadingIcon src={loadingGif} alt="icon" />
                    </LoaderContainer>
                )}
            </div>
        </Wrapper>
    );
}

export default Follow;

const Tablee = styled.div`
    background: #fff;
    border-radius: 0px 30px 0 0;
`;

const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    justify-content: space-between;
`;
const DashboardButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#fff"};
    padding: 10px 30px;
    border-radius: 12px;
    cursor: pointer;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
`;
const LoaderContainer = styled.div`
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 220px);
`;
const LoadingIcon = styled.img`
    width: 65px;
    display: block;
    object-fit: cover;
`;
const CustomDateFields = styled.div`
    display: flex;
    align-items: center;
`;
const DateInput = styled.input`
    /* display: inline-block !important;
    width: 200px !important;
    height: 42px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 8px !important; */
    width: 200px !important;
    padding: 6px;
    height: 42px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    -webkit-text-fill-color: #000;
    box-sizing: border-box;
    /* border: 1px solid #dfeaf2; */
    border: 1px solid rgb(102, 102, 102);
    border-radius: 8px;
    &:hover {
        background: #fbfbfb;
    }
    &:focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #0FA76F;
    }
`;
const DateFieldBox = styled.div`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const DateFieldText = styled.span`
    display: block;
    margin-bottom: 8px;
    color:#0FA76F;
    font-size: 16px;
`;
const CustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const EmptyDiv = styled.div``;
const ClearFilterText = styled.div`
    align-self: flex-end;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0FA76F;
    width: 100px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
`;
const EmptyTextDiv = styled.div`
    width: 100%;
    padding: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
`;
const SearchDiv = styled.div`
    /* margin-right: 20px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #3ccb7f;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const FilterButtonWrapper = styled.div``;
const Container = styled.div`
    // height: 130px;
    width: 280px;
    max-height: 360px;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
// const Title = styled.h4`
//     font-size: 20px;
//     color: #808080;
// `;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 15px 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
    font-size: 16px;
    color: #515151;
    font-weight: 600;
`;
const SubHeading = styled.h4`
    font-size: 13px;
    color: "#515151";
    margin: 0 10px;
`;
const FButton = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "#522e92"};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "white")};
    // padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
    border: none;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 200px;
    cursor: pointer;
    // margin-top: 20px;
`;
const ButtonText = styled.h4`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#505887")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
const ResetButton = styled.div`
    width: 60%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #0FA76F;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #0FA76F;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
