import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

// import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import CustomizedDialogs from "./popup";
import styled from "styled-components";
import FollowUpModal from "./FollowUpModal";
import Edit from "../../assets/images/edit.svg";
import { Popover } from "@mui/material";
import moment from "moment";

function AssignedListItem({
    status,
    statusType,
    item,
    // statusValue,
    setselectedLead,
    selectedLead,
    // setSelectedStatus,
    // setRemarkText,
    // submitFollowUp,
    getData1,
}) {
    const history = useHistory();

    const [statusLabel, setStatusLabel] = useState("Not Intrested");
    const [statusBackground, setStatusBackground] = useState("#FF5B5B26");
    const [statusColor, setStatusColor] = useState("#FF5B5B");

    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        if (status === "Not interested") {
            setStatusLabel("Not Intrested");
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        } else if (status === "Converted" || status === "Need Followup") {
            setStatusLabel(status);
            setStatusBackground("#FFBB5426");
            setStatusColor("#FFBB54");
        } else if (status === "Interested" || status === "Approved" || status === "Student") {
            setStatusLabel(status);
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
        } else {
            setStatusLabel(status);
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        }
    }, [status]);

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event) => {
        setRemarkAnchorEl(event.currentTarget);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E6EFF5",
                    padding: "10px 20px",
                    alignItems: "center",
                }}
            >
                <TD style={{}} width="8%" color="#3ccb7f" fontSize="14px">
                    #{item.lead_id}
                </TD>

                <TD style={{}} width="13%" color="#3ccb7f" fontSize="14px">
                    {item.name}
                </TD>
                <TD
                    width="14%"
                    color="#3ccb7f"
                    fontSize="14px"
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                    {item.phone}
                </TD>
                {/* <TD
				width="15%"
				color="#3ccb7f"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{course}
			</TD> */}
                {/* <TD
				width="15%"
				color="#3ccb7f"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{country}
			</TD> */}
                {/* <TD width="18%" color="#3ccb7f" fontSize="14px">
          {item.email}
        </TD> */}

                {/* <TD
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          width="16%"
          color="#3ccb7f"
          fontSize="14px"
        >
          {item.country_name}
        </TD> */}
                <TD
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    width="12%"
                    color="#3ccb7f"
                    fontSize="14px"
                >
                    {moment(item?.enquiry_date).format("DD-MMM-YY")}
                </TD>
                <TD
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    width="11%"
                    color="#3ccb7f"
                    fontSize="14px"
                >
                    {item?.lead_age?.total_days} days
                </TD>

                {/* <TD width="15%" fontSize="14px">
                    <StatusText
                        color={statusColor}
                        background={statusBackground}
                    >
                        {statusLabel}
                    </StatusText>
                </TD> */}
                <TD
                    width="12%"
                    fontSize="14px"
                    // color="#3ccb7f"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.lead_stage}
                </TD>
                {/* {item.status === "hot" && (
                    <TD
                        width="9%"
                        fontSize="14px"
                        color="#3ccb7f"
                        color={
                            item.status === "hot"
                                ? "#f00"
                                : item.status === "warm"
                                ? "#0f0"
                                : item.status === "cold"
                                ? "#00f"
                                : null
                        }
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                        }}
                    >
                        <BgBox>{item.status}</BgBox>
                    </TD>
                )} */}
                <TD
                    width="6%"
                    fontSize="13px"
                    // color="#3ccb7f"
                    color={
                        item.status === "hot"
                            ? "#FC6D66"
                            : item.status === "warm"
                            ? "#87C655"
                            : item.status === "cold"
                            ? "#3ccb7f"
                            : null
                    }
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        height: "23px",
                        background:
                            item.status === "hot"
                                ? "#FFF0EF"
                                : item.status === "warm"
                                ? "#EEFFE1"
                                : item.status === "cold"
                                ? "#EFF0FF"
                                : null,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "24px",
                        marginRight: "0.5%",
                    }}
                >
                    {item.status}
                </TD>
                {/* <TD
                    width="7%"
                    fontSize="14px"
                    // color="#3ccb7f"
                    color={
                        item.status === "hot"
                            ? "#f00"
                            : item.status === "warm"
                            ? "#0f0"
                            : item.status === "cold"
                            ? "#00f"
                            : null
                    }
                >
                    {item.status}
                </TD> */}
                {/* <TD
                    width="14%"
                    fontSize="14px"
                    color="#3ccb7f"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        marginRight: "0.5%",
                    }}
                >
                    {item.university}
                </TD> */}
                <TD
                    width="11%"
                    fontSize="14px"
                    color="#3ccb7f"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                    }}
                >
                    {item.program}
                </TD>
                {/* {statusType === "followup" && ( */}
                <TD
                    width="13%"
                    fontSize="14px"
                    color="#3ccb7f"
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item.next_followup}
                </TD>
                {/* )} */}
                <TD
                    width="12%"
                    fontSize="14px"
                    color="#3ccb7f"
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    onMouseEnter={handleRemarkPopoverOpen}
                    onMouseLeave={handleRemarkPopoverClose}
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.remark}
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: "none",
                        }}
                        open={remarkOpen}
                        anchorEl={remarkAnchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handleRemarkPopoverClose}
                        disableRestoreFocus
                    >
                        <DetailedRemarkDiv>
                            <DetailedRemarkText>{item.remark}</DetailedRemarkText>
                        </DetailedRemarkDiv>
                    </Popover>
                </TD>

                <TLLD>
                    <FollowUpButton
                        onClick={() => {
                            setIsModal(true);
                            setselectedLead(item.id);
                        }}
                    >
                        Follow Up
                    </FollowUpButton>
                    {item.lead_stage !== "Admission" && (
                        <IconContainer
                            onClick={(e) => {
                                e.preventDefault();

                                history.push(`/admission-consultant/edit-lead/${item.id}/`, {
                                    keyy: "follow",
                                });
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon src={Edit} alt="Icon" />
                        </IconContainer>
                    )}
                </TLLD>
            </div>
            {isModal && (
                <FollowUpModal
                    isModal={isModal}
                    setIsModal={setIsModal}
                    selectedLead={selectedLead}
                    getData1={getData1}
                />
            )}
        </>
    );
}

export default AssignedListItem;

const StatusText = styled.p`
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => (props.background ? props.background : "#000")};
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const FollowUpButton = styled.div`
    width: 70px;
    margin-right: 10px;
    height: 34px;
    color: rgb(255, 255, 255);
    text-transform: none;
    background-color:#0FA76F;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const TLLD = styled.div`
    display: flex;
    width: 140px;
    align-items: center;
    box-sizing: border-box;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 14px;
    display: block;
    object-fit: cover;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 320px;
    padding: 10px;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const BgBox = styled.div`
    width: 50px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    border-radius: 8px;
    text-align: center;
`;
