import React from "react";
import styled from "styled-components";
import TD from "../../components/TD";

export default function InactiveACListItem({ item }) {
    return (
        <>
            <MainDiv
                className="item"
                // selected={selectedLead === item.id ? true : false}
                key={item.id}
            >
                <TD color="#000" fontSize="14px" fontWeight="400">
                    #{item.user_data.id}
                </TD>
                <TD color="#000" fontSize="14px" fontWeight="400">
                    {item.name}
                </TD>
                <TD
                    color="#000"
                    fontSize="14px"
                    fontWeight="400"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.user_data.email}
                </TD>
                <TD color="#000" fontSize="14px" fontWeight="400">
                    {item.phone}
                </TD>
                {/* <TD color="#000" fontSize="14px" fontWeight="400">
                    AED {item.target}
                </TD> */}
                {/* <TD color="#000" fontSize="14px" fontWeight="400">
                    AED {item.total_revenue_generated}
                </TD> */}
                {/* <TD color="#000" fontSize="14px" fontWeight="400">
                    ---
                </TD> */}
                <TD color="#000" fontSize="14px" fontWeight="400">
                    {item.total_lead_admission}
                </TD>
                <TD color="#000" fontSize="14px" fontWeight="400">
                    {item.total_lead_invalid}
                </TD>
                <TD color="#000" fontSize="14px" fontWeight="400">
                    {item.total_lead_dead}
                </TD>
            </MainDiv>
        </>
    );
}

const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: ${(props) => (props.selected ? null : "1px solid #E6EFF5")};
    padding: 10px 20px;
    align-items: center;
`;
