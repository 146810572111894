import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import MainDashboard from "./mainDashboard";
import NewLeads from "./NewLeads";
import AddNewLead from "../salesPerson/addNewLead";
import FollowUp from "./FollowUp";
import SalesReport from "./SalesReport";
import ReportPage from "../../components/includes/ReportPage";

function LeadManagerAdmin() {
    const [openNav, setOpenNav] = useState(false);
    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/team-leader/"
                        exact
                        component={MainDashboard}
                    />
                    <Route
                        path="/team-leader/new-leads/"
                        component={NewLeads}
                    />
                    {/* <Route
                        path="/team-leader/sales-report/"
                        exact
                        component={SalesReport}
                    /> */}
                    <Route
                        path="/team-leader/sales-report/"
                        exact
                        component={ReportPage}
                    />
                    {/* <Route
						path="/team-leader/add-leads/"
						component={AddNewLead}
					/> */}
                    <Route
                        path="/team-leader/:tabStatus/"
                        component={FollowUp}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default LeadManagerAdmin;
