import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import leadListData from "./leadListData";
import TD from "../TD";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import UploadInputLead from "../../components/UploadInput/UploadInputLead";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import moment from "moment";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function CustomizedDialogs({
    id,
    item,
    // statusValue,
    setselectedLead,
    selectedLead,
    getData1,
    // setSelectedStatus,
    // setRemarkText,
    // submitFollowUp,getData1
}) {
    const [open, setOpen] = React.useState(false);

    const [followDate, setFollowDate] = useState("");
    const [followTime, setFollowTime] = useState("");
    const [selectedMedium, setSelectedMedium] = useState("");
    const { state } = useContext(Context);
    const [leadData, setLeadData] = useState({});
    const [leadLoading, setLeadLoading] = useState(true);
    const [remarkText, setRemarkText] = useState(item?.remark);
    const [leadStageRemarkText, setLeadStageRemarkText] = useState();

    const [selectedStatus, setSelectedStatus] = useState("");
    const [leadStageOptons, setLeadStageOptions] = useState([]);
    const [selectedStage, setSelectedStage] = useState();
    const [leadNeedOptons, setLeadNeedOptions] = useState([]);
    const [fee, setFee] = useState();

    const [employer, setEmployer] = useState();
    const [designation, setDesignation] = useState();
    const [age, setAge] = useState();

    const [selectedCVOption, setSelectedCVOption] = useState();
    const [selectedEligibility, setSelectedEligibility] = useState();
    const [selectedAffordability, setSelectedAffordability] = useState();
    const [selectedNeedOption, setSelectedNeedOption] = useState();
    const [selectedIntend, setSelectedIntend] = useState();
    // const [selectedCVOption,setSelectedCVOption]=useState();

    const [statusValue] = useState([
        {
            id: 1,
            value: 0,
            text: "Pending",
        },
        {
            id: 2,
            value: 2,
            text: "Interested",
        },
        {
            id: 3,
            value: 8,
            text: "Need Follow up",
        },
        {
            id: 4,
            value: 10,
            text: "Converted",
        },
        {
            id: 7,
            value: 4,
            text: "Not Interested",
        },
        {
            id: 8,
            value: 6,
            text: "Lost",
        },
    ]);

    const [mediumValue] = useState([
        {
            id: 1,
            value: 2,
            text: "Call",
        },
        {
            id: 2,
            value: 4,
            text: "Message",
        },
        {
            id: 3,
            value: 6,
            text: "Email",
        },
        {
            id: 4,
            value: 8,
            text: "Direct",
        },
        {
            id: 7,
            value: 10,
            text: "Whatsapp",
        },
        {
            id: 8,
            value: 12,
            text: "Online",
        },
        {
            id: 9,
            value: 14,
            text: "Other",
        },
    ]);

    const CV_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "no",
            name: "No",
        },
        {
            id: 6,
            value: "linkedin",
            name: "Linkedin",
        },
    ];

    const ELIGIBILITY_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "yes missing dox",
            name: "Yes Missing docs",
        },
        {
            id: 6,
            value: "no",
            name: "No",
        },
    ];
    const AFFORDABILITY_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "no",
            name: "No",
        },
        {
            id: 6,
            value: "no data",
            name: "No Data",
        },
        {
            id: 8,
            value: "undetermined",
            name: "Undetermined",
        },
    ];
    const INTEND_CHOICE = [
        {
            id: 2,
            value: "strong",
            name: "Strong",
        },
        {
            id: 4,
            value: "unclear",
            name: "Unclear",
        },
        {
            id: 6,
            value: "weak",
            name: "Weak",
        },
        {
            id: 8,
            value: "neutral",
            name: "Neutral",
        },
    ];

    const handleClickOpen = (id) => {
        setOpen(true);
        setselectedLead(item.id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getLeadData = () => {
        setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/${item.id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadData(data.data);
                    setFee(data.fee_amount);
                    // setSelectedStatus(data.data.)
                    setLeadLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadStageOptions = () => {
        setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-stages/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadStageOptions(data);
                    // setSelectedStatus(data.data.)
                    setLeadLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadNeedOptions = () => {
        setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-needs/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadNeedOptions(data);
                    // setSelectedStatus(data.data.)
                    setLeadLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    // const submitFollowUp = () => {
    // 	let accessToken = state.user_details.access_token;
    // 	baseConfig
    // 		.post(
    // 			`sales-persons/follow-lead-status/${selectedLead}/`,
    // 			{
    // 				remark: remarkText,
    // 				status: selectedStatus,
    // 			},
    // 			{
    // 				headers: {
    // 					Authorization: "Bearer " + accessToken,
    // 				},
    // 			}
    // 		)
    // 		.then((response) => {
    // 			let { StatusCode, message, data } = response.data;
    // 			if (StatusCode == 6000) {
    // 				getData1();
    // 				setOpen(false);
    // 			} else {
    // 				alert(data?.message);
    // 			}
    // 		})
    // 		.catch((error) => {
    // 			alert(error);
    // 		});
    // };

    const submitFollowUp = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        formdata.append("lead", selectedLead);
        formdata.append("remark", remarkText);
        formdata.append("status", selectedStatus);

        formdata.append("lead_stage", selectedStage);
        formdata.append("lead_remark", leadStageRemarkText);

        formdata.append("employer", employer);
        formdata.append("designation", designation);
        formdata.append("age", age);
        formdata.append("cv", selectedCVOption);
        formdata.append("eligibility", selectedEligibility);
        formdata.append("affordability", selectedAffordability);
        formdata.append("need", selectedNeedOption);
        formdata.append("intend", selectedIntend);
        formdata.append("fee_amount", fee);
        // formdata.append("lead_remark", leadStageRemarkText);
        // formdata.append("lead_remark", leadStageRemarkText);

        if (selectedStatus == 8) {
            formdata.append("next_time", followTime);
            formdata.append("next_date", followDate);
            formdata.append("next_medium", selectedMedium);
        }

        baseConfig
            .post(`sales-persons/follow-up/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getData1();
                    setOpen(false);
                } else {
                    alert(data?.message);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        if (followTime !== "") {
            setFollowTime(moment(followTime, ["h:mm A"]).format("HH:mm:ss"));
        }
    }, [followTime]);

    useEffect(() => {
        if (selectedLead) {
            getLeadData();
            getLeadStageOptions();
            getLeadNeedOptions();
        }
    }, []);

    return (
        <div>
            <Button
                color="#5d4ba9"
                fontSize="16px"
                onClick={() => {
                    setselectedLead(item.id);
                    return handleClickOpen(id);
                }}
                style={{
                    height: "40px",
                    width: "60%",
                    color: "#fff",
                    textTransform: "none",
                    backgroundColor: "rgb(82, 46, 146)",
                    borderRadius: "10px",
                }}
                // onClick={() => setselectedLead(item.id)}
            >
                FollowUp
            </Button>
            <Dialog
                PaperProps={{ style: { borderRadius: "20px" } }}
                maxWidth="xl"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ color: "#5d4ba9" }}>
                    Follow Up
                </DialogTitle>
                {!leadLoading && (
                    <DialogContent>
                        <SectionsWrapper>
                            <SectionBox>
                                <SectionTitle>BasicLeadInfo</SectionTitle>
                                <FirstLine>
                                    <SectionDetail>
                                        <SectionDetailTitle>ID No:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.lead_id}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Enquiry Date:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.enquiry_date}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Lead Age:</SectionDetailTitle>
                                        <SectionDetailDetailed>
                                            {leadData?.lead_age?.total_days} days
                                        </SectionDetailDetailed>
                                    </SectionDetail>
                                </FirstLine>
                                <SectionDetailsBox>
                                    <SectionDetail>
                                        <SectionDetailTitle>Name:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.name}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Phone No:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.phone}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Email ID:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.email}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>University:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.university_name}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Program:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.course_name}</SectionDetailDetailed>
                                    </SectionDetail>
                                    <SectionDetail>
                                        <SectionDetailTitle>Country:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.country_name}</SectionDetailDetailed>
                                    </SectionDetail>
                                    {/* <SectionDetail>
                    <SectionDetailTitle>Nationality:</SectionDetailTitle>
                    <SectionDetailDetailed>
                      {leadData.nationality}
                    </SectionDetailDetailed>
                  </SectionDetail> */}
                                    <SectionDetail>
                                        <SectionDetailTitle>Lead Source:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData?.source_name}</SectionDetailDetailed>
                                    </SectionDetail>
                                    {/* <SectionDetail>
                    <SectionDetailTitle>Description:</SectionDetailTitle>
                    <SectionDetailDetailed>
                      {leadData.description}
                    </SectionDetailDetailed>
                  </SectionDetail> */}
                                </SectionDetailsBox>
                                <LastLine>
                                    <SectionDetail>
                                        <SectionDetailTitle>Description:</SectionDetailTitle>
                                        <SectionDetailDetailed>{leadData.description}</SectionDetailDetailed>
                                    </SectionDetail>
                                </LastLine>
                            </SectionBox>
                            <SectionBox>
                                <SectionTitle>Additional Information</SectionTitle>
                                <AdditionalInfoWrapper>
                                    <AddiInfoLine>
                                        <FieldName>Employer:</FieldName>
                                        <FieldInput
                                            type="text"
                                            onChange={(e) => setEmployer(e.target.value)}
                                            value={employer}
                                        />
                                    </AddiInfoLine>

                                    <AddiInfoLine>
                                        <FieldName>Designation:</FieldName>
                                        <FieldInput
                                            type="text"
                                            onChange={(e) => setDesignation(e.target.value)}
                                            value={designation}
                                        />
                                    </AddiInfoLine>
                                    <AddiInfoLine>
                                        <FieldName>Age:</FieldName>
                                        <FieldInput
                                            type="number"
                                            onChange={(e) => setAge(e.target.value)}
                                            value={age}
                                            onwheel="return false;"
                                        />
                                    </AddiInfoLine>

                                    <AddiInfoLine>
                                        <FieldName>CV:</FieldName>
                                        <select
                                            onChange={(e) => {
                                                setSelectedCVOption(e.target.value);
                                            }}
                                            value={selectedCVOption}
                                            style={{
                                                width: "calc(100% - 118px)",
                                                padding: "0 10px",
                                                height: "30px",
                                                // borderRadius: 10,
                                                backgroundColor: "#fff",
                                                // border: "1px solid #efefef",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {CV_CHOICE.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </AddiInfoLine>
                                    <AddiInfoLine>
                                        <FieldName>Eligibility:</FieldName>
                                        <select
                                            onChange={(e) => {
                                                setSelectedEligibility(e.target.value);
                                            }}
                                            value={selectedAffordability}
                                            style={{
                                                width: "calc(100% - 118px)",
                                                padding: "0 10px",
                                                height: "30px",
                                                // borderRadius: 10,
                                                backgroundColor: "#fff",
                                                // border: "1px solid #efefef",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {ELIGIBILITY_CHOICE.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </AddiInfoLine>

                                    <AddiInfoLine>
                                        <FieldName>Affordability:</FieldName>
                                        <select
                                            onChange={(e) => {
                                                setSelectedAffordability(e.target.value);
                                            }}
                                            value={selectedAffordability}
                                            style={{
                                                width: "calc(100% - 118px)",
                                                padding: "0 10px",
                                                height: "30px",
                                                // borderRadius: 10,
                                                backgroundColor: "#fff",
                                                // border: "1px solid #efefef",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {AFFORDABILITY_CHOICE.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </AddiInfoLine>
                                    <AddiInfoLine>
                                        <FieldName>Need:</FieldName>
                                        <select
                                            onChange={(e) => {
                                                setSelectedNeedOption(e.target.value);
                                            }}
                                            value={selectedNeedOption}
                                            style={{
                                                width: "calc(100% - 118px)",
                                                padding: "0 10px",
                                                height: "30px",
                                                // borderRadius: 10,
                                                backgroundColor: "#fff",
                                                // border: "1px solid #efefef",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {leadNeedOptons.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </AddiInfoLine>
                                    <AddiInfoLine>
                                        <FieldName>Intend:</FieldName>
                                        <select
                                            onChange={(e) => {
                                                setSelectedIntend(e.target.value);
                                            }}
                                            value={selectedIntend}
                                            style={{
                                                width: "calc(100% - 118px)",
                                                padding: "0 10px",
                                                height: "30px",
                                                // borderRadius: 10,
                                                backgroundColor: "#fff",
                                                // border: "1px solid #efefef",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {INTEND_CHOICE.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </AddiInfoLine>
                                </AdditionalInfoWrapper>
                            </SectionBox>
                            <SectionBox>
                                <SectionTitle>Lead Stage</SectionTitle>
                                <DetailedRemarksWrapper>
                                    <div style={{ width: "100%" }}>
                                        <p
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "16px",
                                                lineHeight: "160%",
                                                color: "#808080",
                                            }}
                                        >
                                            Choose the Stage
                                        </p>
                                        <select
                                            onChange={(e) => {
                                                setSelectedStage(e.target.value);
                                            }}
                                            value={selectedStage}
                                            style={{
                                                width: "100%",
                                                padding: "0 10px",
                                                marginTop: 10,
                                                height: "40px",
                                                borderRadius: 10,
                                                backgroundColor: "#fff",
                                                border: "1px solid #efefef",
                                            }}
                                        >
                                            <option value={null}>Choose the stage</option>
                                            {leadStageOptons.map((item) => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })}
                                        </select>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <p
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "16px",
                                                lineHeight: "160%",
                                                color: "#808080",
                                            }}
                                        >
                                            Remark
                                        </p>
                                        {/* <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    > */}
                                        <textarea
                                            onChange={(e) => {
                                                setLeadStageRemarkText(e.target.value);
                                            }}
                                            value={leadStageRemarkText}
                                            lineHeight={10}
                                            style={{
                                                display: "inline-block",
                                                width: "100%",
                                                marginTop: 10,
                                                height: 100,
                                            }}
                                        />
                                        {/* </p> */}
                                    </div>
                                </DetailedRemarksWrapper>
                            </SectionBox>
                            <SectionBox>
                                <SectionTitle>Follow up</SectionTitle>
                                <DetailedRemarksWrapper>
                                    <div style={{ width: "100%" }}>
                                        <p
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "16px",
                                                lineHeight: "160%",
                                                color: "#808080",
                                            }}
                                        >
                                            Remark
                                        </p>
                                        <p
                                            style={{
                                                color: "#7B6294",
                                                fontWeight: "normal",
                                                fontSize: "20px",
                                                lineHeight: "160%",
                                            }}
                                        >
                                            <textarea
                                                onChange={(e) => {
                                                    setRemarkText(e.target.value);
                                                }}
                                                value={remarkText}
                                                lineHeight={10}
                                                style={{
                                                    width: "100%",
                                                    marginTop: 10,
                                                    height: 100,
                                                }}
                                            />
                                        </p>
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <p
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "16px",
                                                lineHeight: "160%",
                                                color: "#808080",
                                            }}
                                        >
                                            Status
                                        </p>
                                        <select
                                            onChange={(e) => {
                                                setSelectedStatus(e.target.value);
                                            }}
                                            value={selectedStatus}
                                            style={{
                                                width: "100%",
                                                padding: "0 10px",
                                                marginTop: 10,
                                                marginBottom: 10,
                                                height: "40px",
                                                borderRadius: 10,
                                                backgroundColor: "#fff",
                                                border: "1px solid #000",
                                            }}
                                        >
                                            <option value={null}>Select status</option>
                                            {statusValue.map((item) => {
                                                return <option value={item.value}>{item.text}</option>;
                                            })}
                                        </select>
                                    </div>

                                    {selectedStatus == 8 && (
                                        <>
                                            <SectionTitle>Next Follow up</SectionTitle>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        padding: "0 5px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "normal",
                                                            fontSize: "16px",
                                                            lineHeight: "160%",
                                                            color: "#808080",
                                                        }}
                                                    >
                                                        Follow Up Medium
                                                    </p>
                                                    <select
                                                        onChange={(e) => {
                                                            setSelectedMedium(e.target.value);
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            padding: 10,
                                                            marginTop: 10,
                                                            height: "60px",
                                                            borderRadius: 10,
                                                            backgroundColor: "#fff",
                                                            border: "1px solid #efefef",
                                                        }}
                                                    >
                                                        <option value={null}>Select medium</option>
                                                        {mediumValue.map((item) => {
                                                            return <option value={item.value}>{item.text}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "40px",

                                                    padding: "0 5px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "normal",
                                                            fontSize: "16px",
                                                            lineHeight: "160%",
                                                            color: "#808080",
                                                        }}
                                                    >
                                                        Follow up date
                                                    </p>
                                                    <DateField
                                                        type="date"
                                                        onChange={(value) => {
                                                            setFollowDate(value.target.value);
                                                        }}
                                                        value={followDate}
                                                        max="9999-12-31"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "normal",
                                                            fontSize: "16px",
                                                            lineHeight: "160%",
                                                            color: "#808080",
                                                        }}
                                                    >
                                                        Follow up Time{" "}
                                                    </p>
                                                    <DateField
                                                        type="time"
                                                        onChange={(value) => {
                                                            setFollowTime(value.target.value);
                                                        }}
                                                        value={followTime}
                                                        max="9999-12-31"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <AddiInfoLine>
                                        <FieldName>Fee:</FieldName>
                                        <FieldInput
                                            type="number"
                                            value={fee}
                                            onChange={(e) => setFee(e.target.value)}
                                            onwheel="return false;"
                                        />
                                    </AddiInfoLine>
                                </DetailedRemarksWrapper>
                            </SectionBox>
                        </SectionsWrapper>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                margin: 20,
                            }}
                        >
                            <div
                                style={{
                                    // backgroundColor: "#F9F9F9",
                                    width: "633.59px",
                                    marginRight: 25,
                                }}
                            >
                                {/* <div
                  style={{
                    display: "flex",
                    backgroundColor: "#CEE1F2",
                    MozBorderRadiusTopleft: "15%",
                    MozBorderRadiusTopRight: "15%",
                    padding: 5,
                    fontSize: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Title
                      fontSize="18px"
                      color="black"
                      style={{
                        margin: "0 10px",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      Basic Lead Info
                    </Title>
                  </div>
                </div>
                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Name
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.name}
                    </p>
                  </div>
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Source
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.lead_source_data.name}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Email
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.email}
                    </p>
                  </div>
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Phone No
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.phone}
                    </p>
                  </div>
                </div> */}
                                {/* <div
                  style={{
                    display: "flex",
                    backgroundColor: "#CEE1F2",
                    MozBorderRadiusTopleft: "15%",
                    MozBorderRadiusTopRight: "15%",
                    padding: 5,
                    fontSize: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Title
                      fontSize="18px"
                      color="black"
                      style={{
                        margin: "0 10px",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      Basic Lead Info
                    </Title>
                  </div>
                </div>
                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Country
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.country}
                    </p>
                  </div>
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Gender
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.gender}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Address
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.address_line}{" "}
                    </p>
                  </div>
                  <div style={{ margin: 15, width: "50%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Education
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.qualification}
                    </p>
                  </div>
                </div> */}
                                {/* <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "100%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Description
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      {leadData.description}
                    </p>
                  </div>
                </div> */}
                            </div>

                            <div
                                style={{
                                    backgroundColor: "#F9F9F9",
                                    width: "633.59px",
                                    marginRight: 25,
                                }}
                            >
                                {/* <div
                  style={{
                    display: "flex",
                    backgroundColor: "#CEE1F2",
                    MozBorderRadiusTopleft: "15%",
                    MozBorderRadiusTopRight: "15%",
                    padding: 5,
                    fontSize: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Title
                      fontSize="18px"
                      color="black"
                      style={{
                        margin: "0 10px",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      Follow up
                    </Title>
                  </div>
                </div>
                <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "100%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Remark
                    </p>
                    <p
                      style={{
                        color: "#7B6294",
                        fontWeight: "normal",
                        fontSize: "20px",
                        lineHeight: "160%",
                      }}
                    >
                      <textarea
                        onChange={(e) => {
                          setRemarkText(e.target.value);
                        }}
                        value={remarkText}
                        lineHeight={10}
                        style={{
                          width: "100%",
                          marginTop: 10,
                          height: 100,
                        }}
                      />
                    </p>
                  </div>
                </div> */}
                                {/* <div
								style={{
									margin: 15,
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<div style={{ margin: 15, width: "100%" }}>
									<p
										style={{
											fontWeight: "normal",
											fontSize: "16px",
											lineHeight: "160%",
											color: "#808080",
										}}
									>
										Followed Medium
									</p>
									{["MainID", "By Call"].map((o) => {
										return (
											<Button
												style={{
													backgroundColor: "#AB54DB",
													opacity: "0.3",
													margin: 10,
												}}
											>
												<span
													style={{ color: "black" }}
												>
													{o}
												</span>
											</Button>
										);
									})}
								</div>
							</div> */}
                                {/* <div
                  style={{
                    margin: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ margin: 15, width: "100%" }}>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "160%",
                        color: "#808080",
                      }}
                    >
                      Status
                    </p>
                    <select
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      value={selectedStatus}
                      style={{
                        width: "100%",
                        padding: 10,
                        marginTop: 10,
                        height: "60px",
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        border: "1px solid #efefef",
                      }}
                    >
                      <option value={null}>Select status</option>
                      {statusValue.map((item) => {
                        return <option value={item.value}>{item.text}</option>;
                      })}
                    </select>
                  </div>
                </div> */}
                                {/* {selectedStatus == 8 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#CEE1F2",
                        MozBorderRadiusTopleft: "15%",
                        MozBorderRadiusTopRight: "15%",
                        padding: 5,
                        fontSize: 20,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Title
                          fontSize="18px"
                          color="black"
                          style={{
                            margin: "0 10px",
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "160%",
                          }}
                        >
                          Next Followup{" "}
                        </Title>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: 15,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: 15,
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "160%",
                            color: "#808080",
                          }}
                        >
                          Follow Up Medium
                        </p>
                        <select
                          onChange={(e) => {
                            setSelectedMedium(e.target.value);
                          }}
                          style={{
                            width: "100%",
                            padding: 10,
                            marginTop: 10,
                            height: "60px",
                            borderRadius: 10,
                            backgroundColor: "#fff",
                            border: "1px solid #efefef",
                          }}
                        >
                          <option value={null}>Select medium</option>
                          {mediumValue.map((item) => {
                            return (
                              <option value={item.value}>{item.text}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: 15,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: 15,
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "160%",
                            color: "#808080",
                          }}
                        >
                          dd/mm/yy
                        </p>
                        <DateField
                          type="date"
                          onChange={(value) => {
                            setFollowDate(value.target.value);
                          }}
                          value={followDate}
                          max="9999-12-31"
                        />
                      </div>
                      <div
                        style={{
                          margin: 15,
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "160%",
                            color: "#808080",
                          }}
                        >
                          Follow up Time{" "}
                        </p>
                        <DateField
                          type="time"
                          onChange={(value) => {
                            setFollowTime(value.target.value);
                          }}
                          value={followTime}
                          max="9999-12-31"
                        />
                      </div>
                    </div>
                  </>
                )} */}
                                <div
                                    style={{
                                        margin: 15,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ margin: 15, width: "100%" }}>
                                        <Button
                                            onClick={() => {
                                                submitFollowUp();
                                            }}
                                            style={{
                                                backgroundColor: "#522E92",
                                                color: "#FFF",
                                                borderRadius: 11,
                                                minWidth: 130,
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                )}
            </Dialog>
        </div>
    );
}
export default CustomizedDialogs;

const DateField = styled.input`
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    /* width: 100%; */
    border: 1px solid #f8fafc;
    height: 30px;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 14px;
    :focus {
        background: #fff;
        border: 1px solid #0048d9;
    }
`;
const SectionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    padding: 0 15px;
`;
const SectionBox = styled.div``;
const SectionTitle = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: 500;
    padding: 10px 15px;
    background: #cee1f2;
    margin-bottom: 10px;
`;
const FirstLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
    padding: 0 15px;
`;
const SectionDetailsBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0 15px;
`;
const SectionDetail = styled.div``;
const SectionDetailTitle = styled.div`
    color: #808080;
    font-size: 15px;
    margin-bottom: 4px;
`;
const SectionDetailDetailed = styled.div`
    color: #7b6294;
    font-size: 16px;
`;
const LastLine = styled.div`
    margin-top: 15px;
    padding: 0 15px;
`;
// const DetailedRemarksWrapper
const DetailedRemarksWrapper = styled.div`
    padding: 0 15px;
`;
const AdditionalInfoWrapper = styled.div``;
const AddiInfoLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
const FieldName = styled.div`
    color: #808080;
    font-size: 15px;
    margin-right: 8px;
    width: 110px;
`;
const FieldInput = styled.input`
    width: calc(100% - 120px);
    height: 22px;
    display: inline-block;
`;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
