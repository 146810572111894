import styled from "styled-components";

export default styled.h3`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	color: ${(props) => (props.color ? props.color : "#3ccb7f")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: left;
	width: ${(props) => (props.width ? props.width : "13%")};
	min-width: 0;
	min-height: 0;
  @media all and (max-width: 980px) {
  font-size: ${(props) => (props.fontSize ? "12px" : "")};
}
  @media all and (max-width: 480px) {
  font-size: ${(props) => (props.fontSize ? "14px" : "inherit")};
}
	/* border : "1px solid #E7E7E7" */

	/* @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 13px;
  }*/
	/* @media only screen and (max-width: 1280px) {
        font-size: 16px;
        width: 12%;
    }
    @media only screen and (max-width: 980px) {
        font-size: 15px;
        /* width: 11%; */
	/* } */

	/* @media only screen and (max-width: 1280px) {
        font-size: 16px;
    } */
`;
