import React, { useEffect, useState } from "react";
import "./style.css";
import right from "../../assets/images/menu-right.svg";
import { Link } from "react-router-dom";
import plusSquare from "../../assets/images/plus-square.svg";
import Image from "../Image";

function NavBarItem({ item, active, setLink }) {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (typeof active === "number") {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [active]);
	return (
		<li id={active === "true" ? "active" : ""} key={item.link}>
			<>
				{item.inner ? (
					<>
						<div
							className="nav"
							style={open ? { opacity: "1" } : null}
							onClick={() => {
								setOpen(!open);
							}}
						>
							<div className="left">
								<img src={item.img} alt="dashboard" />
								<h3>{item.text}</h3>
							</div>
							{item.inner ? (
								<div className="right">
									<img
										style={
											open
												? { transform: "rotate(90deg)" }
												: { transform: "rotate(0deg)" }
										}
										src={right}
										alt="right"
									/>
								</div>
							) : null}
						</div>
						<div
							className="container"
							style={
								open ? { display: "flex" } : { display: "none" }
							}
						>
							<span></span>
							<div style={{ width: "100%" }}>
								{item.inner.map((item, index) => {
									return (
										<Link
											to={item.link}
											onClick={() => {
												setLink(item.link);
											}}
											className="innerItemLink"
											key={item.link}
										>
											<div
												className="innerItem"
												id={
													active === index
														? "active"
														: ""
												}
											>
												<h3>{item.text}</h3>
												{item.add === true ? (
													<Link
														to={item.addLink}
														onClick={() => {
															setLink(item.link);
														}}
														style={{
															marginRight: "5px",
														}}
													>
														<Image
															src={plusSquare}
															alt="add"
														/>
													</Link>
												) : null}
											</div>
										</Link>
									);
								})}
							</div>
						</div>
					</>
				) : (
					<Link
						to={item.link}
						onClick={() => {
							setLink(item.link);
						}}
					>
						<div className="nav">
							<div className="left">
								<img src={item.img} alt="dashboard" />
								<h3>{item.text}</h3>
							</div>
						</div>
					</Link>
				)}
			</>
		</li>
	);
}

export default NavBarItem;
