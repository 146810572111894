import React, { useEffect, useState } from "react";
import Icon from "../../assets/images/list-icon.svg";
import Line from "../../assets/images/Line.svg";
import moment from "moment";
import TD from "../../components/TD";
import styled from "styled-components";

import { Popover } from "@mui/material";

function FollowUpList({
    setAssignModal,
    item,
    setSelectedLead,
    status,
    selectedDashboard,
}) {
    const [statusBackground, setStatusBackground] = useState();
    const [statusColor, setStatusColor] = useState();

    useEffect(() => {
        if (status === "Not interested") {
            // setStatusLabel("Not Intrested");
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        } else if (status === "Need Followup") {
            // setStatusLabel(status);
            setStatusBackground("#FFBB5426");
            setStatusColor("#FFBB54");
        } else if (
            status === "Interested" ||
            status === "Converted" ||
            status === "Student"
        ) {
            // setStatusLabel(status);
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
        } else {
            // setStatusLabel(status);
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        }
    }, [status]);

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event) => {
        setRemarkAnchorEl(event.currentTarget);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 30px",
                alignItems: "center",
                minHeight: "14px",
            }}
        >
            <TD style={{}} width="10%" color="#3ccb7f" fontSize="14px">
                {/* {item.lead_id} */}
                {/* {selectedDashboard === "meeting"
                    ? item?.lead_data?.lead_id
                    : item.lead_id} */}
                {item?.lead_id}
            </TD>

            <TD style={{}} width="16%" color="#3ccb7f" fontSize="14px">
                {/* {item.name} */}
                {/* {selectedDashboard === "meeting"
                    ? item?.lead_data?.name
                    : item.name} */}
                {item?.name}
            </TD>
            <TD width="14%" color="#3ccb7f" fontSize="14px">
                {/* {item.phone} */}
                {/* {selectedDashboard === "meeting"
                    ? item?.lead_data?.phone
                    : item.phone} */}
                {item?.phone}
            </TD>
            <TD
                width="16%"
                color="#3ccb7f"
                fontSize="14px"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
                {/* {item.email} */}
                {/* {selectedDashboard === "meeting"
                    ? item?.lead_data?.email
                    : item.email} */}
                {item?.email}
            </TD>

            <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                width="16%"
                color="#3ccb7f"
                fontSize="14px"
            >
                {selectedDashboard === "meeting" ||
                selectedDashboard === "pre_meeting" ||
                selectedDashboard === "post_meeting"
                    ? item?.meeting_type
                    : `${item?.lead_age?.total_days} days`}
                {/* {item.country} */}
            </TD>
            <TD width="10%" color="#3ccb7f" fontSize="14px">
                {/* {item.lead_source} */}
                {selectedDashboard === "meeting" ||
                selectedDashboard === "pre_meeting" ||
                selectedDashboard === "post_meeting"
                    ? item?.date_time
                    : item.lead_source}

                {/* <img
					src={item.lead_source_data.image}
					style={{ height: "40px", width: "40px" }}
					alt="Icon"
				/> */}
            </TD>

            <TD
                width="13%"
                fontSize="14px"
                style={
                    {
                        // cursor: "pointer",
                        // display: "flex",
                        // justifyContent: "center",
                    }
                }
            >
                {/* <StatusText color={statusColor} background={statusBackground}>
                    {item?.lead_stage}
                </StatusText> */}
                {item?.lead_stage}
            </TD>
            <TD
                width="13%"
                fontSize="14px"
                style={{
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
                onMouseEnter={handleRemarkPopoverOpen}
                onMouseLeave={handleRemarkPopoverClose}
            >
                {item.remark}
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={remarkOpen}
                    anchorEl={remarkAnchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handleRemarkPopoverClose}
                    disableRestoreFocus
                >
                    <DetailedRemarkDiv>
                        <DetailedRemarkText>{item.remark}</DetailedRemarkText>
                    </DetailedRemarkDiv>
                </Popover>
            </TD>
        </div>
    );
}

export default FollowUpList;

const Button = styled.div`
    background: ${(props) => (props.background ? props.background : "#d6d3ec")};
    border: 1px solid;
    border-color: ${(props) => (props.border ? props.border : "#d6d3ec")};
    border-radius: 8px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    width: 50%;
    /* margin-left: 20px; */
`;
const ButtonText = styled.span`
    /* color: #fff; */
    font-size: 16px; // display: flex;
    // justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : "#8561C6")};
    font-weight: 500;
    /* margin-left: 10px; */
`;
const StatusText = styled.p`
    /* color: ${(props) => (props.color ? props.color : "#fff")};
    background: ${(props) => (props.background ? props.background : "#fff")}; */
    width: 85%;
    border-radius: 8px;
    font-weight: 600;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 320px;
    padding: 10px;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
