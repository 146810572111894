import React from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";

function leadListItem({
    studentId,
    name,
    email,
    phone,
    assigned,
    converted,
    sucsess,
    university,
    ID,
}) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6EFF5",
                padding: "20px 0",
                // marginBottom: "20px",
                alignItems: "center",
            }}
        >
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="10%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {studentId}
                {ID}
            </TD>

            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="20%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {name}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="20%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {email}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="14%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {phone}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="10%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {assigned}
            </TD>
            <TD
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
                width="11%"
                color="#000" fontWeight="400"
                fontSize="14px"
            >
                {converted}
            </TD>

            <TD width="10%" color="#000" fontWeight="400" fontSize="14px">
                {Math.round(sucsess)}%
            </TD>

            {/* <TD width="5%">
				<a
					onClick={(e) => {
						e.preventDefault();
					}}
					href
				>
					<img src={Icon} style={{ display: "block" }} alt="Icon" />
				</a>
			</TD> */}
        </div>
    );
}

export default leadListItem;
