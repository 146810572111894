import moment from "moment";
import React from "react";
import styled from "styled-components";
import TD from "../../components/TD";

export default function SalesReportListItem({ item }) {
    return (
        <>
            <MainDiv
                className="item"
                // selected={selectedLead === item.id ? true : false}
                key={item.id}
            >
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    {moment(item.date).format("DD-MM-YYYY")}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    {item.name}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.total_leads}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    {item.total_admissions}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    AED {item.target}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    {item.target_achieved}%
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    // width="10%"
                >
                    AED {item.revenue_generated}
                </TD>
            </MainDiv>
        </>
    );
}
const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: ${(props) => (props.selected ? null : "1px solid #E6EFF5")};
    padding: 10px 20px;
    align-items: center;
`;
