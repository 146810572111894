import Title from "../../components/Title";
import React, { useState, useContext, useEffect } from "react";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Line from "../../assets/images/Line.svg";
import Pagination from "../../components/pagination/pagination";
import assignedListData from "../../components/leadmanager/assignedListData";
import AssignedListItem from "../../components/leadmanager/assignedListItem";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import FilterBox from "../../components/includes/FilterBox";
import NoDataFound from "../../components/noRecords/NoDataFound";
import MainPagination from "../../components/includes/MainPagination";
import ViewMoreModal from "../teamLeader/ViewMoreModal";
import searchBtn from "./../../assets/images/header-search.svg";
import moment from "moment";

// mui imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SweetAlert from "../../components/includes/SweetAlert";

function AssignedLeads() {
	const [count, setCount] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	// const [status, setStatus] = useState(listStatus(0).value);
	const [selectedStatus, setSelectedStatus] = useState();
	const [data, setData] = useState();
	const [isViewMore, setIsViewMore] = useState(false);
	const [selectedLead, setSelectedLead] = useState("");

	const [isLoading, setLoading] = useState(true);
	const [mainLoading, setMainLoading] = useState(false);
	const { state } = useContext(Context);
	const [isFilterBox, setFilterBox] = useState(false);
	const [universityList, setUniversityList] = useState();
	const [sortValue, setSortValue] = useState();
	const [sortUrl, setSortUrl] = useState("");
	const [universityValues, setUniversityValues] = useState();
	const [universityUrl, setUniversityUrl] = useState("");
	const [paginationData, setPaginationData] = useState({});
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [salesPersons, setSalesPersons] = useState([]);
	const [selectedSalesPerson, setSelectedSalesPerson] = useState("");

	const [sources, setSources] = useState([]);
	const [selectedSource, setSelectedSource] = useState("");

	const [searchItem, setSearchItem] = useState("");

	const [stages, setStages] = useState([]);
	const [selectedStage, setSelectedStage] = useState();

	// sweetalert states and functions
	const [isSweetAlert, setIsSweetAlert] = useState(false);
	const [sweetAlertContent, setSweetAlertContent] = useState({
		title: "",
		message: "",
		icon: "",
		confirmButtonText: "",
	});
	const [sweetAlertTitle, setSweetAlertTitle] = useState("");
	const [sweetAlertMessage, setSweetAlertMessage] = useState("");
	const [sweetAlertConfirmButtonText, setSweetAlertConfirmButtonText] =
		useState("");
	const handleSweetAlertConfirm = () => {
		alert("Hii");
	};

	const [listStatus] = useState([
		{
			id: 1,
			title: "All",
			value: "all",
		},

		{
			id: 3,
			title: "No Action",
			value: "new_leads",
		},
		{
			id: 4,
			title: "Introduction",
			value: "introduction",
		},
		{
			id: 5,
			title: "Pre Meeting",
			value: "pre_meeting",
		},
		{
			id: 6,
			title: "Meeting",
			value: "meeting",
		},
		{
			id: 7,
			title: "Post Meeting",
			value: "post_meeting",
		},
		{
			id: 8,
			title: "Admissions",
			value: "admission",
		},
		{
			id: 9,
			title: "Invalid",
			value: "invalid",
		},
		{
			id: 10,
			title: "Dead",
			value: "dead",
		},
	]);
	const [status, setStatus] = useState(listStatus[0].value);

	const getAssignedLeads = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/leads/lead/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					status: status,
					ordering: sortValue,
					university: universityValues,
					assigned: true,
					page: currentPage,
					start_date: fromDate,
					end_date: toDate,
					lead_source: selectedSource,
					sales_person: selectedSalesPerson,
					search: searchItem,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setData(data.data);
					setPaginationData(data.paginator);
					setLoading(false);
					// setSweetAlertContent({
					//     title: "Demo",
					//     message: "This is Demo",
					//     icon: "success",
					//     confirmButtonText: "Here",
					// });
					// setIsSweetAlert(true);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	// const getUniversity = () => {
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get(`/campus/university/`, {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setUniversityList(data.data);
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };
	const getSalesPersons = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig

			.get("/sales-persons/sales-persons/", {
				// .get("/sales-persons/sales-persons-list", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					// is_disabled: false,
					// choice: "assign",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSalesPersons(data.data);
					// setLoading(false);
				} else {
					// setLoading(false);
				}
			})
			.catch((error) => {});
	};
	const handleLeadSelect = (event) => {
		setSelectedSalesPerson(event.target.value);
	};

	const getSources = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig

			.get("leads/lead-source", {
				// .get("/sales-persons/sales-persons-list", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					// is_disabled: false,
					// choice: "assign",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSources(data.data);
					// setLoading(false);
				} else {
					// setLoading(false);
				}
			})
			.catch((error) => {});
	};

	const handleSourceSelect = (event) => {
		setSelectedSource(event.target.value);
	};

	// const getTeamLeaders = () => {
	//     // setLoading(true);
	//     let accessToken = state.user_details.access_token;
	//     baseConfig

	//         .get("sales-persons/sales-persons-list/?leader_only=true", {
	//             // .get("/sales-persons/sales-persons-list", {
	//             headers: {
	//                 Authorization: "Bearer " + accessToken,
	//             },
	//             params: {
	//                 // is_disabled: false,
	//                 // choice: "assign",
	//             },
	//         })
	//         .then((response) => {
	//             const { StatusCode, data } = response.data;
	//             if (StatusCode === 6000) {
	//                 // setStages(data);
	//                 // setLoading(false);
	//             } else {
	//                 // setLoading(false);
	//             }
	//         })
	//         .catch((error) => {});
	// };

	const handleStageSelect = (event) => {
		setSelectedStage(event.target.value);
	};

	// const getStages = () => {
	//     // setLoading(true);
	//     let accessToken = state.user_details.access_token;
	//     baseConfig

	//         .get("leads/lead-stages", {
	//             // .get("/sales-persons/sales-persons-list", {
	//             headers: {
	//                 Authorization: "Bearer " + accessToken,
	//             },
	//             params: {
	//                 // is_disabled: false,
	//                 // choice: "assign",
	//             },
	//         })
	//         .then((response) => {
	//             const { StatusCode, data } = response.data;
	//             if (StatusCode === 6000) {
	//                 setStages(data);
	//                 // setLoading(false);
	//             } else {
	//                 // setLoading(false);
	//             }
	//         })
	//         .catch((error) => {});
	// };

	// const handleStageSelect = (event) => {
	//     setSelectedStage(event.target.value);
	// };

	// const cat = [
	//     {
	//         value: 1,
	//         label: "All",
	//     },
	//     ...listStatus,
	// ];

	useEffect(() => {
		getAssignedLeads();
	}, [
		status,
		currentPage,
		selectedSalesPerson,
		selectedSource,
		toDate,
		searchItem,
	]);
	useEffect(() => {
		// getListStatus();
		getSalesPersons();
		getSources();
		// getStages();
		// getUniversity();
	}, []);
	useEffect(() => {
		if (toDate) {
			getAssignedLeads();
		}
	}, [fromDate, toDate]);

	const paginate = (pageNumber) => {
		if (pageNumber > 0 && pageNumber <= Math.ceil(data.length / count)) {
			setCurrentPage(pageNumber);
		}
	};

	const handleConfirm = () => {};

	return (
		<>
			{/* <SweetAlert
                sweetAlertContent={sweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleConfirm}
            /> */}
			{mainLoading === false ? (
				<Wrapper className="assigned">
					<div class="converted"
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "30px",
						}}
					>
						<div marginBottom="20px">
							<Title className="head">Lead Status</Title>
						</div>
						<CustomDateContainer>
							<EmptyDiv></EmptyDiv>

							<CustomDateFields>
								<DateFieldBox>
									<DateFieldText>From</DateFieldText>
									<DateInput
										onChange={(e) => {
											setFromDate(e.target.value);
											// setError(false);
										}}
										type="date"
										// name="From"
										// min={
										//   toDate ? moment(minFromDateValue).format("yyyy-MM-DD") : null
										// }
										// max={
										// 	toDate
										// 		? toDate
										// 		: new Date().toLocaleDateString(
										// 				"en-CA"
										// 		  )
										// }
										max={moment().format("yyyy-MM-DD")}
										value={fromDate}
									/>
								</DateFieldBox>
								<DateFieldBox>
									<DateFieldText>To</DateFieldText>
									<DateInput
										onChange={(e) => {
											setToDate(e.target.value);
										}}
										type="date"
										// min={"2023-04-05"}
										// name="To"
										// min={moment(fromDate).format(
										// 	"yyyy-MM-DD"
										// )}
										max={moment().format("yyyy-MM-DD")}
										// max={moment(maxToDateValue).format("yyyy-MM-DD")}
										// max={new Date().toLocaleDateString(
										// 	"en-CA"
										// )}
										disabled={fromDate ? false : true}
										value={toDate}
									/>
								</DateFieldBox>
							</CustomDateFields>
						</CustomDateContainer>
					</div>
					<FilterLine>
						<EmptyDiv></EmptyDiv>
						<FilterBoxesWrapper>
							<SearchDiv>
								<SearchForm className="search-container">
									<img src={searchBtn} alt="search" />
									<SeachInput
										className="input-box"
										type="text"
										name="search"
										placeholder="Search for something"
										onChange={(e) =>
											setSearchItem(e.target.value)
										}
									/>
								</SearchForm>
							</SearchDiv>
							<SelectBox>
								<MuiBox>
								<Box>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">
											Consultant
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedSalesPerson}
											label="Admission Consultant"
											onChange={handleLeadSelect}
											sx={{
												height: 50,
											}}
										>
											{salesPersons?.map((item) => (
												<MenuItem value={item.id}>
													{item.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
								</MuiBox>
							</SelectBox>
							<SelectBox>
								<MuiBox>
								<Box>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">
											Source
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedSource}
											label="Source"
											onChange={handleSourceSelect}
											sx={{
												height: 50,
											}}
										>
											{sources?.map((item) => (
												<MenuItem value={item.id}>
													{item.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
								</MuiBox>
							</SelectBox>
							{(fromDate !== "" ||
								selectedSalesPerson !== "" ||
								selectedSource !== "") && (
								<ClearFilterText
									onClick={() => {
										setFromDate("");
										setToDate("");
										setSelectedSalesPerson("");
										setSelectedSource("");
										// setFromDate("");
										// setToDate("");
										// getAssignedLeads();
									}}
								>
									Clear Filter
								</ClearFilterText>
							)}
							{/* <SelectBox>
                                <Box sx={{ minWidth: 250 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Stage
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedStage}
                                            label="Stage"
                                            onChange={handleStageSelect}
                                        >
                                            {stages?.map((item) => (
                                                <MenuItem value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </SelectBox> */}
						</FilterBoxesWrapper>
					</FilterLine>
				<div  class="tableScroll" >	
				<SelectDiv>
						{listStatus.length > 0 && (
							<DashboardButtonDiv>
								{listStatus.map((item, index) => (
									<DashboardButton
										key={index}
										background={
											status === item.value
												? "#f0f8fe"
												: "#fff"
										}
										style={{ marginRight: "10px" }}
										onClick={() => {
											// setIsTeamData(item.value);
											setStatus(item.value);
										}}
									>
										<ButtonName
											color={
												status === item.value
													? "#0FA76F"
													: "#9d9d9d"
											}
										>
											{item.title}
										</ButtonName>
									</DashboardButton>
								))}
							</DashboardButtonDiv>
						)}
					</SelectDiv>
            </div>	
					{/* <div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					> */}
					{/* {listStatus.length !== 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									height: "50px",
								}}
							>
								{listStatus.map((item, index) => (
									<a
										href="#"
										className={
											item.value == status
												? "menu-status"
												: "active"
										}
										onClick={() => {
											setStatus(item.value);
										}}
										style={{
											borderRadius:
												index == 0
													? "30px 0 0 0"
													: index == listStatus.length
													? "0 30px 0 0"
													: null,
										}}
									>
										<Text
											fontSize="18px"
											fontWeight="400"
											color="#000"
										>
											{item.title}
										</Text>
									</a>
								))}
							</div>
						)} */}
					{/* <div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "32%",
								alignItems: "center",
								// marginBlock: "20px",
							}}
						>
							<Text fontSize="15px">Show</Text>
							<div className="number">
								<Text
									fontSize="18px"
									fontWeight="400"
									color="#522E92"
								>
									{count}
								</Text>
								<div>
									<img
										onClick={() => {
											setCount(count + 1);
										}}
										src={add}
										alt="incerement"
									/>
									<img
										onClick={() => {
											if (count > 1) {
												setCount(count - 1);
											}
										}}
										src={add}
										alt="decrement"
										className="decrement"
									/>
								</div>
							</div>
							<Text fontSize="15px">Results</Text>
							<Button
								onClick={() => {
									setFilterBox(true);
								}}
								style={{
									display: "flex",
									alignItems: "center",
								}}
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
							>
								<img
									style={{ width: "12%" }}
									src={filter}
									alt="Logo"
								/>
								<Text
									style={{ marginLeft: "12px" }}
									color="white"
									fontSize="15px"
								>
									Filter
								</Text>
							</Button>
						</div> */}
					{/* </div> */}
					{/* <div style={{ marginBottom: "30px" }}>
                        <>
                            <Table
                                margin="0 0 30px"
                                borderRadius="20px"
                                padding="30px 0"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "0 20px 20px",
                                    }}
                                    className="table-head"
                                >
                                    <TD
                                        width="5%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        ID No.
                                    </TD>
                                    <TD
                                        width="12%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Lead Name{" "}
                                    </TD>

                                    <TD
                                        width="13%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Phone
                                    </TD>
                                    <TD
                                        width="10%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Source
                                    </TD>
                                    <TD
                                        width="12%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Nationality
                                    </TD>
                                    <TD
                                        width="12%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Team Leader
                                    </TD>
                                    <TD
                                        width="12%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Consultant
                                    </TD>
                                    <TD
                                        width="10%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Stage
                                    </TD>
                                    <TD
                                        width="100px"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    ></TD>
                                </div>

                                {isLoading === false ? (
                                    <div
                                        style={{ padding: "0 20px" }}
                                        className="table-body"
                                    >
                                        {data.length > 0 ? (
                                            <>
                                                {data.map((item) => {
                                                    return (
                                                        <AssignedListItem
                                                            studentId={
                                                                item.lead_id
                                                            }
                                                            name={item.name}
                                                            email={item.email}
                                                            phone={item.phone}
                                                            status={
                                                                item.lead_stage
                                                            }
                                                            source={
                                                                item.lead_source
                                                            }
                                                            assign_to={
                                                                item.assign_to
                                                            }
                                                            key={item.ID}
                                                            item={item}
                                                            setSelectedLead={
                                                                setSelectedLead
                                                            }
                                                            setIsViewMore={
                                                                setIsViewMore
                                                            }
                                                        />
                                                    );
                                                })}
                                                <div
                                                    style={{
                                                        marginTop: "20px",
                                                        marginRight: "-10px",
                                                    }}
                                                >
                                                    <MainPagination
                                                        paginationData={
                                                            paginationData
                                                        }
                                                        setCurrentPage={
                                                            setCurrentPage
                                                        }
                                                        currentPage={
                                                            currentPage
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <NoDataFound />
                                        )}
                                    </div>
                                ) : (
                                    <Div>
                                        <LoadingGif
                                            src={
                                                require("./../../assets/images/loading-buffering.gif")
                                                    .default
                                            }
                                            alt="Loading"
                                        />
                                    </Div>
                                )}
                            </Table>
                            {isFilterBox === true && (
                                <FilterBox
                                    isFilterBox={isFilterBox}
                                    setFilterBox={setFilterBox}
                                    universityList={universityList}
                                    setSortValue={setSortValue}
                                    sortValue={sortValue}
                                    getAssignedLeads={getAssignedLeads}
                                    universityValues={universityValues}
                                    setUniversityValues={setUniversityValues}
                                />
                            )}
                        </>
                    </div> */}

					{!isLoading ? (
						<div  class="tableScroll" style={{ marginBottom: "30px" }}>
							{data.length > 0 ? (
								<>
									<Table 
									minWidth="1280px"
										margin="0 0 30px"
										borderRadius="20px"
										padding="30px 0"
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												borderBottom:
													"1px solid #E6EFF5",
												padding: "0 20px 20px",
											}}
											className="table-head"
										>
											<TD
												width="5%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												ID No.
											</TD>
											<TD
												width="12%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Lead Name{" "}
											</TD>
											{/* <TD
                                        width="15%"
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="18px"
                                    >
                                        Email
                                    </TD> */}

											<TD
												width="13%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Phone
											</TD>
											<TD
												width="10%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Source
											</TD>
											<TD
												width="12%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Nationality
											</TD>
											<TD
												width="12%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Team Leader
											</TD>
											<TD
												width="12%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Consultant
											</TD>
											<TD
												width="10%"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											>
												Stage
											</TD>
											<TD
												width="100px"
												color="#808080"
												fontWeight="500"
												fontSize="18px"
											></TD>

											{/* <div style={{ width: "5%" }}></div> */}
										</div>
										<div
											style={{ padding: "0 20px" }}
											className="table-body"
										>
											{data.map((item) => {
												return (
													<AssignedListItem
														studentId={item.lead_id}
														name={item.name}
														email={item.email}
														phone={item.phone}
														status={item.lead_stage}
														source={
															item.lead_source
														}
														assign_to={
															item.assign_to
														}
														key={item.ID}
														item={item}
														setSelectedLead={
															setSelectedLead
														}
														setIsViewMore={
															setIsViewMore
														}
													/>
												);
											})}
										</div>
									</Table>
									<div
										style={{
											marginTop: "20px",
											marginRight: "-25px",
										}}
									>
										<MainPagination
											paginationData={paginationData}
											setCurrentPage={setCurrentPage}
											currentPage={currentPage}
										/>
									</div>
								</>
							) : (
								<NoDataFound />
							)}
						</div>
					) : (
						<Div>
							<LoadingGif
								src={
									require("./../../assets/images/loading-buffering.gif")
										.default
								}
								alt="Loading"
							/>
						</Div>
					)}

					{isViewMore && (
						<ViewMoreModal
							setIsViewMore={setIsViewMore}
							selectedLead={selectedLead}
						/>
					)}
					{/* <MainPagination
                        paginationData={paginationData}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    /> */}
				</Wrapper>
			) : null}
		</>
	);
}

export default AssignedLeads;

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;
const CustomDateFields = styled.div`
	display: flex;
	align-items: center;
	@media all and (max-width: 480px){
		flex-wrap: wrap;
}
`;
const DateInput = styled.input`
	/* display: inline-block !important;
    width: 200px !important;
    height: 42px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 8px !important; */
	width: 200px !important;
	padding: 6px;
	height: 42px;
	font-size: 15px;
	font-weight: 400;
	color: #000;
	-webkit-text-fill-color: #000;
	box-sizing: border-box;
	/* border: 1px solid #dfeaf2; */
	border: 1px solid rgb(102, 102, 102);
	border-radius: 8px;
	&:hover {
		background: #fbfbfb;
	}
	&:focus-visible {
		outline: none;
		background: #fbfbfb;
		border: 1px solid #2d60ff;
	}
`;
const DateFieldBox = styled.div`
	margin-right: 12px;
	&:last-child {
		margin-right: 0;
	}
	@media all and (max-width: 480px){
		margin-bottom: 20px;
}
`;
const DateFieldText = styled.span`
	display: block;
	margin-bottom: 8px;
	color:#0FA76F;
	font-size: 16px;
`;
const CustomDateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const EmptyDiv = styled.div``;
const LoaderWrapper = styled.div`
	/* border: 1px solid #000; */
	height: calc(100vh - 230px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
const LoadingIcon = styled.img`
	width: 70px;
	display: block;
	object-fit: cover;
`;
const EmptyText = styled.div`
	font-size: 26px;
	opacity: 0.6;
	font-weight: 500;
`;

const ClearFilterText = styled.div`
	/* align-self: flex-end; */
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #522e92;
	width: 100px;
	text-align: center;
	color: #fff;
	border-radius: 8px;
	cursor: pointer;
	font-weight: 500;
	margin-left: 8px;
`;
const FilterLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;
const FilterBoxesWrapper = styled.div`
	display: flex;
	align-items: center;
	@media all and (max-width: 640px){
		flex-wrap: wrap;
}
`;
const SelectBox = styled.div`
	/* width: 250px; */
	margin-left: 10px;

	& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
		color: #3ccb7f !important;
	}
	& .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3ccb7f !important;
}
@media all and (max-width: 640px){
	margin-bottom: 20px;
}
`;

const SearchDiv = styled.div``;
const SearchForm = styled.form`
	font-family: "Inter";
	font-size: 15px;
	/* padding: 9px 10px 14px 54px; */
	box-sizing: border-box;
	background: #f5f7fa;
	border-radius: 4px;
	width: 400px !important;
	border: 1px solid #c1c1c1;
	height: 50px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	@media all and (max-width: 1080px){
		width: 350px !important;

}
@media all and (max-width: 768px){
	width: 250px !important;
}
@media all and (max-width: 980px){
	width: 300px !important; 
}
@media all and (max-width: 480px){
	width: 290px !important;
}
@media all and (max-width: 360px){
	width: 250px !important;
}
`;
const SeachInput = styled.input`
	outline: none;
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin-left: 5px;
	width: 100%;
	height: 95%;
	font-size: 15px;
`;

const SelectDiv = styled.div`
	display: flex;
	margin: 15px 0;
	@media all and (max-width: 1280px){
		min-width: 1100px;
		
}
`;
const DashboardButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 5px;
	border-radius: 8px;
	// justify-content: space-between;
`;
const DashboardButton = styled.div`
	background-color: ${(props) =>
		props.background ? props.background : "#fff"};
	padding: 10px;
	border-radius: 8px;
	cursor: pointer;
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#000")};
	font-size: 16px;
	font-weight: 500;
	padding: 0 10px;
`;
const MuiBox = styled(Box)`
	min-width: 250px;
@media all and (max-width: 980px){
	min-width: 175px;
}
@media all and (max-width: 768px){
	min-width: 140px;
}
	@media all and (max-width:640px){
		min-width: 200px;
	}
	@media all and (max-width:460px){
		min-width: 150px;
	}
`