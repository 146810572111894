import dashboard from "../../assets/images/dashboard-menu.svg";
import lead from "../../assets/images/menu-leads.svg";
import admin from "../../assets/images/admin-menu.svg";
import sources from "../../assets/images/teams.svg";
import notice from "../../assets/images/notices.svg";

const navBarData = [
    {
        img: dashboard,
        text: "Dashboard",
        link: "/admission-consultant/",
    },
    {
        img: lead,
        text: "Leads",
        inner: [
            {
                text: "New Leads",
                link: "/admission-consultant/list-leads/",
            },
            {
                text: "Follow ups",
                link: "/admission-consultant/follow-up/",
            },

            {
                text: "Add Leads",
                link: "/admission-consultant/add-leads/",
            },
            {
                text: "Duplicate Leads",
                link: "/admission-consultant/duplicate-leads/",
            },
            // {
            //     text: "Sales Report",
            //     link: "/admission-consultant/sales-report/",
            // },
        ],
    },
    {
        img: lead,
        text: "Lead Status",
        inner: [
            {
                text: "Meetings",
                link: "/admission-consultant/meetings/",
            },
            {
                text: "Admissions",
                link: "/admission-consultant/admissions/",
            },
        ],
    },
    {
        img: notice,
        text: "Sales Report",
        link: "/admission-consultant/sales-report/",
    },
];
export default navBarData;
