import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Bar, Pie } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

function Card({ width, image, title, background, data }) {
	return (
		<MainContainer width={width}>
			<Left>
				<ImageDiv background={background}>
					<Image src={image} alt="logo" />
				</ImageDiv>
				<TitleDiv>
					<Title fontSize="35px">{data?.total}</Title>
				</TitleDiv>
				<TextLabel>{title}</TextLabel>
			</Left>
			<Right>
				<SubDiv margin="18px">
					<Text
						fontSize="16px"
						color="#8C8888"
						fontWeight="400"
						style={{
							width: "100px",
						}}
					>
						Today
					</Text>
					<Text fontSize="18px" color="#0FA76F">
						{data?.today}
					</Text>
				</SubDiv>
				<SubDiv margin="18px">
					<Text
						fontSize="16px"
						color="#8C8888"
						fontWeight="400"
						style={{
							width: "100px",
						}}
					>
						Weekly
					</Text>
					<Text fontSize="18px" color="#0FA76F">
						{data?.weekly}
					</Text>
				</SubDiv>
				<SubDiv margin="18px">
					<Text
						fontSize="16px"
						color="#8C8888"
						fontWeight="400"
						style={{
							width: "100px",
						}}
					>
						Monthly
					</Text>
					<Text fontSize="18px" color="#0FA76F">
						{data?.monthly}
					</Text>
				</SubDiv>
				<SubDiv>
					<Text
						fontSize="16px"
						color="#8C8888"
						fontWeight="400"
						style={{
							width: "100px",
						}}
					>
						Yearly
					</Text>
					<Text fontSize="18px" color="#0FA76F">
						{data?.yearly}
					</Text>
				</SubDiv>
			</Right>
		</MainContainer>
	);
}

export default Card;

const MainContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-radius: 12px;
	padding: 30px;
	@media all and (max-width: 480px) {
		padding: 16px;
	}
	@media all and (max-width: 360px) {
		width: 198px;
	}
`;

const Left = styled.div`
	width: 45%;
	@media all and (max-width: 1080px) {
		width: 48%;
	}
	@media all and (max-width: 980px) {
		width: 66%;
	}
`;

const Right = styled.div`
	width: 30%;
`;
const ImageDiv = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	display: inline-block;
	padding: 8px;
	border-radius: 8px;
`;
const TitleDiv = styled.div`
	margin: 15px 0;
`;

const Image = styled.img``;
const SubDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${(props) => (props.margin ? props.margin : "0")};
`;
const TextLabel = styled.h6`
	font-size: 18px;
	color: #8c8888;
	@media all and (max-width: 1380px) {
		font-size: 17px;
	}
	@media all and (max-width: 1280px) {
		font-size: 14px;
	}
	@media all and (max-width: 980px) {
		font-size: 13px;
	}
	@media all and (max-width: 480px) {
		min-width: 136px;
	}
	@media all and (max-width: 360px) {
		font-size: 11px;
		min-width: 137px;
	}
`;
