import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import TD from "../../components/TD";
import Title from "../../components/Title";

import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import { useHistory } from "react-router-dom";

import loaderGif from "../../assets/lottie/loading.gif";

function SelectTeamLeaderModal({ setSelectedModal, setLeader, leader, salesPersons }) {
    const [selectedLeader, setSelectedLeader] = useState(leader);
    const [salesPerson, setSalesPerson] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [teamLeaderList] = useState(salesPerson);
    const { state } = useContext(Context);

    const [search, setSearch] = useState(null);
    //list of sales Persons
    const getSalesPerson = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-persons-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    choice: "team_build",
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerson(data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    alert("some error occurred");
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getSalesPerson();
    }, [search]);

    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setSelectedModal(false);
                }}
            ></Overlay>
            <Modal>
                <ModalContainer>
                    <Top>
                        <Left>
                            <Title fontSize="28px">Add Team Leader</Title>
                            <div
                                style={{
                                    height: "20px",
                                }}
                            >
                                {selectedLeader !== null && (
                                    <MemberDiv>
                                        <Heading>Selected Leader : </Heading>
                                        <Heading color="#000">&nbsp; {selectedLeader?.name}</Heading>
                                    </MemberDiv>
                                )}
                            </div>
                        </Left>
                        <Right>
                            <SearchDiv>
                                <Form className="search-container">
                                    <SearchIcon src={require("./../../assets/images/search.svg").default} />
                                    <SearchField
                                        className="input-box"
                                        type="text"
                                        name="search"
                                        placeholder="Search for something"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </Form>
                            </SearchDiv>
                        </Right>
                    </Top>
                    <Bottom>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #E6EFF5",
                                padding: "0 30px 20px",
                            }}
                            className="table-head"
                        >
                            <TD width="10%" color="#808080" fontWeight="500" fontSize="18px">
                                Sl No.
                            </TD>
                            <TD width="40%" color="#808080" fontWeight="500" fontSize="18px">
                                Sales Person
                            </TD>
                            <TD width="50%" color="#808080" fontWeight="500" fontSize="18px">
                                Rank
                            </TD>
                        </div>
                        {!isLoading ? (
                            <>
                                <div>
                                    {salesPerson.map((item, i) => (
                                        <div
                                            key={item.id}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #E6EFF5",
                                                padding: "10px 30px",
                                                alignItems: "center",
                                            }}
                                            className="item"
                                        >
                                            <TD
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                                width="10%"
                                                color="#505887"
                                                fontSize="16px"
                                            >
                                                {i + 1}
                                            </TD>

                                            <TD
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                                width="40%"
                                                color="#505887"
                                                fontSize="16px"
                                            >
                                                {item.name}
                                            </TD>
                                            <TD
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                                width="50%"
                                                color="#505887"
                                                fontSize="16px"
                                            >
                                                <RankDiv>
                                                    <RankText>{Math.round(item.rank)}</RankText>
                                                    <ButtonDiv
                                                        onClick={() => {
                                                            setSelectedLeader(item);
                                                        }}
                                                        bordeColor={
                                                            selectedLeader?.id === item.id ? "#EDE9F5" : "#8561C6"
                                                        }
                                                        background={
                                                            selectedLeader?.id === item.id ? "#EDE9F5" : "#8561C6"
                                                        }
                                                    >
                                                        <ButtonText
                                                            color={selectedLeader?.id === item.id ? "#8561C6" : "#fff"}
                                                        >
                                                            {selectedLeader?.id === item.id ? "Selected" : "Select"}
                                                        </ButtonText>
                                                    </ButtonDiv>
                                                </RankDiv>
                                            </TD>
                                        </div>
                                    ))}
                                </div>
                                <ActionDiv>
                                    <ButtonDiv
                                        bordeColor="#fff"
                                        background="#fff"
                                        onClick={() => {
                                            setSelectedModal(false);
                                        }}
                                    >
                                        <ButtonText color="#FF0048">Cancel</ButtonText>
                                    </ButtonDiv>
                                    <ButtonDiv
                                        onClick={() => {
                                            setSelectedModal(false);
                                            setLeader(selectedLeader);
                                        }}
                                        bordeColor="#522E92"
                                        background="#522E92"
                                    >
                                        <ButtonText color="#fff">Save</ButtonText>
                                    </ButtonDiv>
                                </ActionDiv>
                            </>
                        ) : (
                            <LoaderContainer>
                                <LoadingIcon src={loaderGif} alt="icon" />
                            </LoaderContainer>
                        )}
                    </Bottom>
                </ModalContainer>
            </Modal>
        </BackContainer>
    );
}
export default SelectTeamLeaderModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    /* margin: 0 auto; */
    // background: #fff;
    width: 75%;
    max-height: 80vh;
    z-index: 101;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        display: none;
    }
    scrollbar-width: none;
`;

const ModalContainer = styled.div`
    background: #fff;
    border-radius: 12px;
    position: relative;
    padding: 40px;
`;
const MemberDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;
const Heading = styled.label`
    color: ${(props) => (props.color ? props.color : "#808080")};
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    display: block;
`;
const IndicatorText = styled(Heading)`
    margin-left: 15px;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Left = styled.div``;

const Right = styled.div``;

const SearchDiv = styled.div`
    /* margin-right: 30px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #aea7fd;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 95%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const Bottom = styled.div`
    margin-top: 15px;
`;
const RankDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const RankText = styled.span`
    color: #505887;
    font-size: 16px;
    font-weight: 600;
`;
const SelectButtton = styled.div``;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: ${(props) => (props.bordeColor ? props.bordeColor : "#fff")};
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    background-color: ${(props) => (props.background ? props.background : "#eeeaf4")};

    &:last-child {
        margin-right: 0;
    }
`;
const ButtonIcon = styled.img`
    margin-right: 4px;
`;
const ButtonText = styled.span`
    color: ${(props) => (props.color ? props.color : "#fff")};
`;
const ActionDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
`;

const LoaderContainer = styled.div`
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingIcon = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
