import React, { useState, useEffect, useContext } from "react";
import Title from "../../components/Title";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import AddIcon from "../../assets/images/addbutton.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import AddButton from "../../assets/images/Button.svg";
import Pagination from "../../components/pagination/pagination";
import SalesListItem from "../../components/leadmanager/salesListItem";
import salesListData from "../../components/leadmanager/salesListData";
import Input from "../../components/Input/input";
import EditPerson from "../../components/leadmanager/EditPerson";
import AddTeam from "../../components/leadmanager/AddTeam";
import ViewDetails from "../../components/leadmanager/ViewDetails";
import DefaultViewPopup from "../../components/leadmanager/DefaultViewPopup";
import DeletePopup from "../../components/leadmanager/DeletePopup";
import { ListSalesTeamService } from "../../service/salesTeam";
import styled from "styled-components";
import SalesTeamList from "./SalesTeamList";
import { useHistory } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";
import SelectTeamMemberModal from "./SelectTeamMemberModal";
import DeleteModal from "../../components/includes/DeleteModal";
import loadingIcon from "../../assets/lottie/loading.gif";

function SalesTeams() {
    const [file, setFile] = useState(null);

    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState("all");
    const [data, setData] = useState(salesListData);
    const [selectedTeamData, setSelectedTeamData] = useState({});
    const [selectedDashboard, setSelectedDashboard] = useState("New");
    const history = useHistory();
    const { state } = useContext(Context);
    const [loading, setLoading] = useState(true);
    // member details
    const [selectMemberModal, setSelectedMemberModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [salesPerson, setSalesPerson] = useState([]);
    const [deleteId, setDeleteId] = useState("");
    const [isModal, setModal] = useState(false);
    const [salesPersonSearch, setSalesPersonSearch] = useState("");
    const [tableStatus] = useState([
        {
            id: 1,
            title: "New",
        },
        {
            id: 2,
            title: "All",
        },
        {
            id: 3,
            title: "Active",
        },
        {
            id: 4,
            title: "Inactive",
        },
    ]);

    const [teamList, setTeamList] = useState([]);

    // const interested = [];
    // const not_interested = [];
    // const status_pending = [];
    // const follow_up = [];
    // const converted = [];

    // const getData1 = (item) => {
    // 	if (item.status === "not_intrested") {
    // 		not_interested.push(item);
    // 	}
    // 	if (item.status === "converted") {
    // 		converted.push(item);
    // 	}
    // 	if (item.status === "interested") {
    // 		interested.push(item);
    // 	}
    // 	if (item.status === "status_pending") {
    // 		status_pending.push(item);
    // 	}
    // 	if (item.follow_up === "follow_up") {
    // 		follow_up.push(item);
    // 	}
    // 	if (item.converted === "converted") {
    // 		converted.push(item);
    // 	}
    // };
    // salesListData.map(getData1);
    // // Get current posts
    // const indexOfLastPost = currentPage * count;
    // const indexOfFirstPost = indexOfLastPost - count;
    // const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    // const paginate = (pageNumber) => {
    // 	if (pageNumber > 0 && pageNumber <= Math.ceil(data.length / count)) {
    // 		setCurrentPage(pageNumber);
    // 	}
    // };

    // const checkModalStatus = () => {
    // 	if (
    // 		modalName === "add" ||
    // 		modalName === "edit" ||
    // 		modalName === "view" ||
    // 		modalName === "delete"
    // 	) {
    // 		return true;
    // 	}
    // 	return false;
    // };

    // const [salesTeams, setSalesTeams] = useState([]);

    // const getData = async () => {
    // 	let data = await ListSalesTeamService(state);
    // 	if (data.StatusCode === 6000) {
    // 		setSalesTeams(data?.data?.data);
    // 	} else if (data.StatusCode === 6001) {
    // 	}
    // };

    //list of sales Persons
    const getSalesPerson = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-persons-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    choice: "team_build",
                    search: salesPersonSearch,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerson(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    // api for team id
    const getTeamList = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/team/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    ranking: true,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamList(data.data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                // alert(error)
            });
    };

    // api add member to sales team
    const addMemberToTeam = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .post(
                `/sales-persons/add-team-members/${selectedTeamData.id}/`,
                {
                    members: members,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/leadmanager/salesteams/");
                    setSelectedMemberModal(false);
                    getSalesPerson();
                    getTeamList();
                } else {
                    alert("Error found");
                }
            })
            .catch((error) => {
                // alert(error);
            });
    };
    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/sales-persons/team/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    // getDesignation();
                    getTeamList();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    // getDesignation();
                    getTeamList();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getTeamList();
        // getSalesPerson();
    }, []);

    useEffect(() => {
        getSalesPerson();
    }, [salesPersonSearch]);

    return (
        <>
            {!loading ? (
                <Wrapper className="assigned">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "30px",
                        }}
                    >
                        <div>
                            <Title fontSize="35px" className="head">Sales Teams</Title>
                        </div>
                        <div
                        class="top"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Button
                            className="sales-button"
                                // href="/superadmin/add-lead-manager/"
                                onClick={() => {
                                    history.push(
                                        "/salesmanager/add-salesteams/"
                                    );
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                            >
                                <img
                                    class="add-icon"
                                    style={{ width: "20px" }}
                                    src={AddIcon}
                                    alt="Logo"
                                />
                                <Text
                                    className="add"
                                    style={{ marginLeft: "12px" }}
                                    color="white"
                                    fontSize="15px"
                                >
                                    Add Team
                                </Text>
                            </Button>
                        </div>
                    </div>
                    {/* <DashboardButtonDiv>
				{tableStatus.map((data) => (
					<DashboardButton
						key={data.id}
						background={
							selectedDashboard === data.title
								? "#522E92"
								: "#fff"
						}
						style={{ marginRight: "10px" }}
						onClick={() => {
							setSelectedDashboard(data.title);
						}}
					>
						<ButtonName
							color={
								selectedDashboard === data.title
									? "#fff"
									: "#000"
							}
						>
							{data.title}
						</ButtonName>
					</DashboardButton>
				))}
			</DashboardButtonDiv> */}
                <div class="tableScroll">
                    <Table margin="20px 0" borderRadius="30px" padding="30px 0">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #E6EFF5",
                                padding: "0 30px 20px",
                            }}
                            className="table-head"
                        >
                            {/* <TD
                                width="5%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                ID No.
                            </TD> */}
                            <TD
                                width="14%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Team Name
                            </TD>
                            <TD
                                width="11%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Leader
                            </TD>
                            <TD
                                width="12%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                No of Members
                            </TD>
                            <TD
                                width="7%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Rank
                            </TD>
                            <TD
                                width="10%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Status
                            </TD>

                            <TD
                                width="35%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Action
                            </TD>
                        </div>
                        {!loading && (
                            <div>
                                {teamList.map((item) => (
                                    <SalesTeamList
                                        key={item.id}
                                        item={item}
                                        status={item.is_active}
                                        setSelectedMemberModal={
                                            setSelectedMemberModal
                                        }
                                        setSelectedTeamData={
                                            setSelectedTeamData
                                        }
                                        setDeleteId={setDeleteId}
                                        setModal={setModal}
                                    />
                                ))}
                            </div>
                        )}
                    </Table>
                    </div>
                    {selectMemberModal && (
                        <SelectTeamMemberModal
                            setSelectedModal={setSelectedMemberModal}
                            selectedTeamData={selectedTeamData}
                            setMembersValue={setMembers}
                            membersValue={members}
                            salesPerson={salesPerson}
                            teamPage={true}
                            addMemberToTeam={addMemberToTeam}
                            setSalesPersonSearch={setSalesPersonSearch}
                            salesPersonSearch={salesPersonSearch}
                        />
                    )}
                    {isModal && (
                        <DeleteModal
                            isModal={isModal}
                            setModal={setModal}
                            deleteId={deleteId}
                            handleDeleteData={handleDeleteData}
                        />
                    )}
                </Wrapper>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
        </>
    );
}

export default SalesTeams;

const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    width: fit-content;
`;
const DashboardButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#fff"};
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
