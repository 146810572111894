import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import leadListData from "./leadListData";
import TD from "../TD";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import UploadInputLead from "../../components/UploadInput/UploadInputLead";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import moment from "moment";
import closeIcon from "../../assets/images/close.svg";
import loadingGif from "../../assets/lottie/loading.gif";
import Edit from "../../assets/images/edit.svg";
import { useHistory } from "react-router-dom";

export default function FollowUpModal({ setIsModal, selectedLead, getData1 }) {
    const [followDate, setFollowDate] = useState("");
    const [followTime, setFollowTime] = useState("");
    const [selectedMedium, setSelectedMedium] = useState("");
    const { state } = useContext(Context);
    const history = useHistory();

    const [leadData, setLeadData] = useState({});
    const [leadLoading, setLeadLoading] = useState(true);
    const [remarkText, setRemarkText] = useState();
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState();
    const [leadHistory, setLeadHistory] = useState();

    const [selectedStatus, setSelectedStatus] = useState("");

    const [leadNeedOptons, setLeadNeedOptions] = useState([]);

    const [employer, setEmployer] = useState();
    const [designation, setDesignation] = useState();
    const [age, setAge] = useState();

    const [selectedCVOption, setSelectedCVOption] = useState();
    const [selectedEligibility, setSelectedEligibility] = useState();
    const [selectedAffordability, setSelectedAffordability] = useState();
    const [selectedNeedOption, setSelectedNeedOption] = useState();
    const [selectedIntend, setSelectedIntend] = useState();

    const [leadStageRemarkText, setLeadStageRemarkText] = useState();
    const [leadStageOptons, setLeadStageOptions] = useState([]);
    const [selectedStage, setSelectedStage] = useState("");
    const [selectedStageFollowUpMedium, setSelectedStageFollowUpMedium] =
        useState();
    const [SelectedLeadStageDate, setSelectedLeadStageDate] = useState();
    const [SelectedLeadStageTime, setSelectedLeadStageTime] = useState();
    const [selectedMeetingType, setSelectedMeetingType] = useState();

    const [specialization, setSpecialization] = useState();
    const [fee, setFee] = useState();

    const [statusValue] = useState([
        {
            id: 1,
            value: 0,
            text: "Pending",
        },
        {
            id: 2,
            value: 2,
            text: "Interested",
        },
        {
            id: 3,
            value: 8,
            text: "Need Follow up",
        },
        {
            id: 4,
            value: 10,
            text: "Converted",
        },
        {
            id: 7,
            value: 4,
            text: "Not Interested",
        },
        {
            id: 8,
            value: 6,
            text: "Lost",
        },
    ]);
    const [statuses] = useState([
        { id: 1, value: "hot", name: "Hot" },
        { id: 1, value: "cold", name: "Cold" },
        { id: 1, value: "warm", name: "Warm" },
    ]);
    const [mediumValue] = useState([
        {
            id: 1,
            value: 2,
            text: "Call",
        },
        {
            id: 2,
            value: 4,
            text: "Message",
        },
        {
            id: 3,
            value: 6,
            text: "Email",
        },
        {
            id: 4,
            value: 8,
            text: "Direct",
        },
        {
            id: 7,
            value: 10,
            text: "Whatsapp",
        },
        {
            id: 8,
            value: 12,
            text: "Online",
        },
        {
            id: 9,
            value: 14,
            text: "Other",
        },
    ]);
    const [meetingTypes] = useState([
        { id: 2, name: "Online" },
        { id: 4, name: "Offline" },
    ]);
    const CV_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "no",
            name: "No",
        },
        {
            id: 6,
            value: "linkedin",
            name: "Linkedin",
        },
    ];
    const ELIGIBILITY_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "yes missing dox",
            name: "Yes Missing docs",
        },
        {
            id: 6,
            value: "no",
            name: "No",
        },
    ];
    const AFFORDABILITY_CHOICE = [
        {
            id: 2,
            value: "yes",
            name: "Yes",
        },
        {
            id: 4,
            value: "no",
            name: "No",
        },
        {
            id: 6,
            value: "no data",
            name: "No Data",
        },
        {
            id: 8,
            value: "undetermined",
            name: "Undetermined",
        },
    ];
    const INTEND_CHOICE = [
        {
            id: 2,
            value: "strong",
            name: "Strong",
        },
        {
            id: 4,
            value: "unclear",
            name: "Unclear",
        },
        {
            id: 6,
            value: "weak",
            name: "Weak",
        },
        {
            id: 8,
            value: "neutral",
            name: "Neutral",
        },
    ];
    const invalidReasons = [
        {
            id: 2,
            name: "Not Interested in First Call",
        },
        {
            id: 4,
            name: "Not Enquired",
        },
        {
            id: 6,
            name: "Other Program Enquired",
        },
        {
            id: 8,
            name: "Not Connecting",
        },
        {
            id: 10,
            name: "Not Eligible",
        },
    ];

    const [leadStageObject, setLeadStageObject] = useState({});

    const getLeadData = () => {
        // setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/${selectedLead}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadData(data.data);
                    setEmployer(data.data.employer);
                    setDesignation(data.data.designation);
                    setAge(data.data.age);
                    setSelectedCVOption(data.data.cv);
                    setSelectedEligibility(data.data.eligibility);
                    setSelectedAffordability(data.data.affordability);
                    setSelectedNeedOption(data.data.need);
                    setSelectedIntend(data.data.intend);
                    setSelectedStatus(data.data.status);
                    // setSelectedStage(data.data.lead_stage);
                    // setLeadStageRemarkText(data.data.lead_remark);
                    // setSelectedStatus(data.data.status);
                    // setEmployer(data.data.lead_remark);
                    // setEmployer(data.data.lead_remark);
                    // setEmployer(data.data.lead_remark);
                    // setEmployer(data.data.lead_remark);
                    // setEmployer(data.data.lead_remark);

                    setFee(data.data.fee_amount);
                    // setSelectedStatus(data.data.)
                    setTimeout(() => {
                        setLeadLoading(false);
                    }, 200);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadHistory = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-follow-up/${selectedLead}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadHistory(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadStageOptions = () => {
        setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-stages/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadStageOptions(data);
                    // setSelectedStatus(data.data.)
                    setLeadLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getLeadNeedOptions = () => {
        setLeadLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-needs/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadNeedOptions(data);
                    // setSelectedStatus(data.data.)
                    setLeadLoading(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    const submitFollowUp = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        formdata.append("lead", selectedLead);
        // formdata.append("remark", remarkText);
        // formdata.append("remark", null);

        // formdata.append("status", selectedStatus);

        // formdata.append("lead_stage", selectedStage);
        // formdata.append("lead_remark", leadStageRemarkText);

        formdata.append("employer", employer);
        formdata.append("designation", designation);
        formdata.append("age", age);
        formdata.append("cv", selectedCVOption);
        formdata.append("eligibility", selectedEligibility);
        formdata.append("affordability", selectedAffordability);
        formdata.append("need", selectedNeedOption);
        formdata.append("intend", selectedIntend);
        // formdata.append("fee_amount", fee);
        // formdata.append("lead_remark", leadStageRemarkText);
        // formdata.append("lead_remark", leadStageRemarkText);

        // if (selectedStatus == 8) {
        //     formdata.append("next_time", followTime);
        //     formdata.append("next_date", followDate);
        //     formdata.append("next_medium", selectedMedium);
        // }

        // formdata.append({ ...leadStageObject });
        // let data = {
        //     // formdata,
        //     lead: selectedLead,
        //     employer: employer,
        //     designation: designation,
        //     age: age,
        //     cv: selectedCVOption,
        //     eligibility: selectedEligibility,
        //     affordability: selectedAffordability,
        //     need: selectedNeedOption,
        //     intend: selectedIntend,
        //     lead_stage: selectedStage,
        //     ...leadStageObject,
        // };

        // baseConfig
        // .post(`sales-persons/follow-up/`, formdata, {
        //     headers: {
        //         Authorization: "Bearer " + accessToken,
        //     },
        // })
        let input = {
            lead: selectedLead,
            // employer: employer,
            // designation: designation,
            // age: age,
            // cv: selectedCVOption,
            // eligibility: selectedEligibility,
            // affordability: selectedAffordability,
            // need: selectedNeedOption,
            // intend: selectedIntend,
            ...leadStageObject,
        };

        if (employer !== null && employer !== "") {
            input["employer"] = employer;
        }
        if (designation !== null && designation !== "") {
            input["designation"] = designation;
        }
        if (age !== null && age !== "") {
            input["age"] = age;
        }
        if (
            selectedStage !== "Choose the stage" &&
            selectedStage !== "" &&
            selectedStage !== null
        ) {
            input["lead_stage"] = selectedStage;
        }

        if (
            selectedCVOption !== "Select status" &&
            selectedCVOption !== "" &&
            selectedCVOption !== null
        ) {
            input["cv"] = selectedCVOption;
        }
        if (
            selectedEligibility !== "Select status" &&
            selectedEligibility !== "" &&
            selectedEligibility !== null
        ) {
            input["eligibility"] = selectedEligibility;
        }
        if (
            selectedAffordability !== "Select status" &&
            selectedAffordability !== "" &&
            selectedAffordability !== null
        ) {
            input["affordability"] = selectedAffordability;
        }
        if (
            selectedNeedOption !== "Select status" &&
            selectedNeedOption !== "" &&
            selectedNeedOption !== null
        ) {
            input["need"] = selectedNeedOption;
        }
        if (
            selectedIntend !== "Select status" &&
            selectedIntend !== "" &&
            selectedIntend !== null
        ) {
            input["intend"] = selectedIntend;
        }
        if (
            selectedStatus !== "Select status" &&
            selectedStatus !== "" &&
            selectedStatus !== null
        ) {
            input["status"] = selectedStatus;
        }

        baseConfig
            .post(
                `sales-persons/follow-up/`,
                input,
                // {
                //     lead: selectedLead,
                //     employer: employer,
                //     designation: designation,
                //     age: age,
                //     cv: selectedCVOption,
                //     eligibility: selectedEligibility,
                //     affordability: selectedAffordability,
                //     need: selectedNeedOption,
                //     intend: selectedIntend,
                // 	if( selectedStage!=="Choose the stage"){

                // 		lead_stage: selectedStage,
                // 	}

                //     ...leadStageObject,
                // },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getData1();
                    setIsModal(false);
                } else {
                    alert(data?.message);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        if (followTime !== "") {
            setFollowTime(moment(followTime, ["h:mm A"]).format("HH:mm:ss"));
        }
    }, [followTime]);

    useEffect(() => {
        if (selectedLead) {
            getLeadData();
            getLeadHistory();
            getLeadStageOptions();
            getLeadNeedOptions();
            // getUniversities();
            // getCourses();
        }
    }, []);



    return (
        <BackContainer>
            <Overlay onClick={() => setIsModal(false)}></Overlay>
            <Modal>
                <CloseIconDiv>
                    <CloseIconImg
                        onClick={() => setIsModal(false)}
                        src={closeIcon}
                        alt="icon"
                    />
                </CloseIconDiv>
                <>
                    {!leadLoading ? (
                        <>
                            <SectionsWrapper>
                                <SectionBox>
                                    <SectionTitle
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        Basic Lead Info
                                        <IconContainer
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push(
                                                    `/admission-consultant/edit-lead/${selectedLead}/`
                                                );
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Icon src={Edit} alt="Icon" />
                                        </IconContainer>
                                    </SectionTitle>
                                    <FirstLine>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                ID No:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.lead_id}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Enquiry Date:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {moment(
                                                    leadData.enquiry_date
                                                ).format("DD-MMM-YYYY")}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Lead Age:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData?.lead_age?.total_days}{" "}
                                                days
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                    </FirstLine>
                                    <SectionDetailsBox>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Name:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.name}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Phone No:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.phone}
                                            </SectionDetailDetailed>
                                        </SectionDetail>

                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Email ID:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.email}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        {leadData.phone_2 && (
                                            <SectionDetail>
                                                <SectionDetailTitle>
                                                    Secondary Phone No:
                                                </SectionDetailTitle>
                                                <SectionDetailDetailed>
                                                    {leadData.phone_2}
                                                </SectionDetailDetailed>
                                            </SectionDetail>
                                        )}
                                        {/* <SectionDetail>
                                            <SectionDetailTitle>
                                                University:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.university_name}
                                            </SectionDetailDetailed>
                                        </SectionDetail> */}
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Program:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.program_name}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Country:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.country_name}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        {/* <SectionDetail>
                    <SectionDetailTitle>Nationality:</SectionDetailTitle>
                    <SectionDetailDetailed>
                      {leadData.nationality}
                    </SectionDetailDetailed>
                  </SectionDetail> */}
                                        {/* <SectionDetail>
											<SectionDetailTitle>
												Nationality
											</SectionDetailTitle>
											<SectionDetailDetailed>
												{leadData?.source_name}
											</SectionDetailDetailed>
										</SectionDetail> */}
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Lead Source:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData?.source_name}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                        {leadData.specialization && (
                                            <SectionDetail>
                                                <SectionDetailTitle>
                                                    Specialization:
                                                </SectionDetailTitle>
                                                <SectionDetailDetailed>
                                                    {leadData.specialization}
                                                </SectionDetailDetailed>
                                            </SectionDetail>
                                        )}
                                        {leadData.stage_name && (
                                            <SectionDetail>
                                                <SectionDetailTitle>
                                                    Lead Stage:
                                                </SectionDetailTitle>
                                                <SectionDetailDetailed>
                                                    {leadData.stage_name}
                                                </SectionDetailDetailed>
                                            </SectionDetail>
                                        )}
                                    </SectionDetailsBox>
                                    <LastLine>
                                        <SectionDetail>
                                            <SectionDetailTitle>
                                                Description:
                                            </SectionDetailTitle>
                                            <SectionDetailDetailed>
                                                {leadData.description}
                                            </SectionDetailDetailed>
                                        </SectionDetail>
                                    </LastLine>
                                </SectionBox>
                                <SectionBox>
                                    <SectionTitle>
                                        Additional Information
                                    </SectionTitle>
                                    <AdditionalInfoWrapper>
                                        <AddiInfoLine>
                                            <FieldName>Employer:</FieldName>
                                            <FieldInput
                                                type="text"
                                                onChange={(e) =>
                                                    setEmployer(e.target.value)
                                                }
                                                value={employer}
                                            />
                                        </AddiInfoLine>

                                        <AddiInfoLine>
                                            <FieldName>Designation:</FieldName>
                                            <FieldInput
                                                type="text"
                                                onChange={(e) =>
                                                    setDesignation(
                                                        e.target.value
                                                    )
                                                }
                                                value={designation}
                                            />
                                        </AddiInfoLine>
                                        <AddiInfoLine>
                                            <FieldName>Age:</FieldName>
                                            <FieldInput
                                                type="number"
                                                onChange={(e) =>
                                                    setAge(e.target.value)
                                                }
                                                value={age}
                                                onwheel="return false;"
                                            />
                                        </AddiInfoLine>
{/* 
                                        <AddiInfoLine>
                                            <FieldName>CV:</FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedCVOption(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedCVOption}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {CV_CHOICE.map((item) => {
                                                    return (
                                                        <option value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </AddiInfoLine> */}
                                        {/* <AddiInfoLine>
                                            <FieldName>Eligibility:</FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedEligibility(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedEligibility}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {ELIGIBILITY_CHOICE.map(
                                                    (item) => {
                                                        return (
                                                            <option
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </AddiInfoLine> */}

                                        <AddiInfoLine>
                                            <FieldName>
                                                Affordability:
                                            </FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedAffordability(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedAffordability}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {AFFORDABILITY_CHOICE.map(
                                                    (item) => {
                                                        return (
                                                            <option
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </AddiInfoLine>
                                        <AddiInfoLine>
                                            <FieldName>Need:</FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedNeedOption(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedNeedOption}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {leadNeedOptons.map((item) => {
                                                    return (
                                                        <option value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </AddiInfoLine>
                                        <AddiInfoLine>
                                            <FieldName>Intend:</FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedIntend(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedIntend}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {INTEND_CHOICE.map((item) => {
                                                    return (
                                                        <option value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </AddiInfoLine>
                                        <AddiInfoLine>
                                            <FieldName>Status:</FieldName>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedStatus(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedStatus}
                                                style={{
                                                    width: "calc(100% - 100px)",
                                                    padding: "0 5px",
                                                    height: "30px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    // border: "1px solid #efefef",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {statuses.map((item) => {
                                                    return (
                                                        <option
                                                            value={item.value}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </AddiInfoLine>
                                    </AdditionalInfoWrapper>
                                </SectionBox>
                                <SectionBox>
                                    <SectionTitle>Lead stage</SectionTitle>
                                    <DetailedRemarksWrapper>
                                        <div style={{ width: "100%" }}>
                                            <p
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "16px",
                                                    lineHeight: "160%",
                                                    color: "#808080",
                                                }}
                                            >
                                                Choose the stage
                                            </p>

                                            <Select
                                                onChange={(e) => {
                                                    // setSelectedStage(
                                                    //     leadStageOptons[
                                                    //         +e.target.value
                                                    //     ]
                                                    // );
                                                    // setSelectedStageAutoID(
                                                    //     e.target.data
                                                    // );
                                                    setSelectedStage(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedStage}
                                                style={{
                                                    width: "100%",
                                                    padding: "0 5px",
                                                    marginTop: 10,
                                                    height: "35px",
                                                    // borderRadius: 10,
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Choose the stage
                                                </option>
                                                {leadStageOptons.map(
                                                    (item, index) => {
                                                        return (
                                                            <option
                                                                value={item.id}
                                                                // auto_id={
                                                                //     item.auto_id
                                                                // }
                                                                // data={JSON.stringify(
                                                                //     item
                                                                // )}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </div>
                                        {/* {selectedStageAutoID &&
                                        selectedStageAutoID === 2 ? ( */}
                                        {leadStageOptons.map((data) => (
                                            <>
                                                {data.id === selectedStage && (
                                                    <>
                                                        {data.name ===
                                                        "Introduction" ? (
                                                            <>
                                                                <DateTimeWrapper>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Date:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        date: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.date
                                                                            }
                                                                            type="date"
                                                                        />
                                                                    </DTBox>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Time:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        time: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.time
                                                                            }
                                                                            type="time"
                                                                        />
                                                                    </DTBox>
                                                                </DateTimeWrapper>
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontWeight:
                                                                                "normal",
                                                                            fontSize:
                                                                                "16px",
                                                                            lineHeight:
                                                                                "160%",
                                                                            color: "#808080",
                                                                        }}
                                                                    >
                                                                        Remark
                                                                    </p>

                                                                    <textarea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            // setLeadStageRemarkText(
                                                                            //     e
                                                                            //         .target
                                                                            //         .value
                                                                            // );
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            // leadStageRemarkText
                                                                            leadStageObject?.remark
                                                                        }
                                                                        lineHeight={
                                                                            10
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                "inline-block",
                                                                            width: "100%",
                                                                            marginTop: 10,
                                                                            height: 60,
                                                                            minHeight: 50,
                                                                            resize: "vertical",
                                                                            padding:
                                                                                "8px",
                                                                            boxSizing:
                                                                                "border-box",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : data.name ===
                                                          "Invalid" ? (
                                                            <>
                                                                <AddiInfoMTLine>
                                                                    <FUPText>
                                                                        Reason:
                                                                    </FUPText>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            // setSelectedStageFollowUpMedium(
                                                                            // 	e
                                                                            // 		.target
                                                                            // 		.value
                                                                            // );
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    reason: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            // selectedStageFollowUpMedium
                                                                            leadStageObject?.reason
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            Reason
                                                                        </option>
                                                                        {invalidReasons.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </AddiInfoMTLine>
                                                                <TextAreaContainer>
                                                                    <TextAreaTitle>
                                                                        Remark's
                                                                    </TextAreaTitle>
                                                                    <TextArea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            // setSelectedStageFollowUpMedium(
                                                                            // 	e
                                                                            // 		.target
                                                                            // 		.value
                                                                            // );
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.remark
                                                                        }
                                                                    />
                                                                </TextAreaContainer>
                                                            </>
                                                        ) : data.name ===
                                                          "Pre Meeting Followup" ? (
                                                            <>
                                                                <AddiInfoMTLine>
                                                                    <FUPText>
                                                                        Followup
                                                                        Medium
                                                                    </FUPText>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            // setSelectedStageFollowUpMedium(
                                                                            // 	e
                                                                            // 		.target
                                                                            // 		.value
                                                                            // );
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    medium: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.medium
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            medium
                                                                        </option>
                                                                        {mediumValue.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </AddiInfoMTLine>
                                                                <DateTimeWrapper>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Date:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        date: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.date
                                                                            }
                                                                            type="date"
                                                                        />
                                                                    </DTBox>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Time:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        time: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.time
                                                                            }
                                                                            type="time"
                                                                        />
                                                                    </DTBox>
                                                                </DateTimeWrapper>
                                                                <TextAreaContainer>
                                                                    <TextAreaTitle>
                                                                        Remark's
                                                                    </TextAreaTitle>
                                                                    <TextArea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.remark
                                                                        }
                                                                    />
                                                                </TextAreaContainer>
                                                            </>
                                                        ) : data.name ===
                                                          "Meeting" ? (
                                                            <>
                                                                <AddiInfoMTLine>
                                                                    <FUPText>
                                                                        Meeting
                                                                        Type:
                                                                    </FUPText>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    meeting_type:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.meeting_type
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            meeting
                                                                            type
                                                                        </option>
                                                                        {meetingTypes.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </AddiInfoMTLine>
                                                                <DateTimeWrapper>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Date:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        date: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.date
                                                                            }
                                                                            type="date"
                                                                        />
                                                                    </DTBox>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Time:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        time: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.time
                                                                            }
                                                                            type="time"
                                                                        />
                                                                    </DTBox>
                                                                </DateTimeWrapper>
                                                                <TextAreaContainer>
                                                                    <TextAreaTitle>
                                                                        Remark's
                                                                    </TextAreaTitle>
                                                                    <TextArea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.remark
                                                                        }
                                                                    />
                                                                </TextAreaContainer>
                                                            </>
                                                        ) : data.name ===
                                                              "Post Meeting 1" ||
                                                          data.name ===
                                                              "Post Meeting 2" ||
                                                          data.name ===
                                                              "Post Meeting 3" ? (
                                                            <>
                                                                <AddiInfoMTLine>
                                                                    <FUPText>
                                                                        Followup
                                                                        Medium
                                                                    </FUPText>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    medium: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.medium
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            status
                                                                        </option>
                                                                        {mediumValue.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </AddiInfoMTLine>
                                                                <DateTimeWrapper>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Date:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        date: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.date
                                                                            }
                                                                            type="date"
                                                                        />
                                                                    </DTBox>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Time:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        time: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.time
                                                                            }
                                                                            type="time"
                                                                        />
                                                                    </DTBox>
                                                                </DateTimeWrapper>
                                                                <TextAreaContainer>
                                                                    <TextAreaTitle>
                                                                        Remark's
                                                                    </TextAreaTitle>
                                                                    <TextArea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.remark
                                                                        }
                                                                    />
                                                                </TextAreaContainer>
                                                            </>
                                                        ) : data.name ===
                                                          "Admission" ? (
                                                            <>
                                                                <AddStageInfoLine>
                                                                    {/* <FieldName>
                                                                        University:
                                                                    </FieldName>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    university:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.university
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            University
                                                                        </option>
                                                                        {universities?.length > 0 && universities?.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select> */}
                                                                </AddStageInfoLine>
                                                                {/* <AddStageInfoLine>
                                                                    <FieldName>
                                                                        Program:
                                                                    </FieldName>
                                                                    <Select
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    course: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.course
                                                                        }
                                                                        style={{
                                                                            width: "calc(100% - 160px)",
                                                                            padding:
                                                                                "0 5px",
                                                                            height: "30px",
                                                                            // borderRadius: 10,
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            // border: "1px solid #efefef",
                                                                            border: "1px solid #000",
                                                                        }}
                                                                    >
                                                                        <option
                                                                            value={
                                                                                null
                                                                            }
                                                                        >
                                                                            Select
                                                                            Program
                                                                        </option>
                                                                        {courses?.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Select>
                                                                </AddStageInfoLine> */}
                                                                <AddStageInfoLine>
                                                                    <FieldName>
                                                                        Specialization:
                                                                    </FieldName>
                                                                    <FieldInput
                                                                        type="text"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    specialization:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.specialization
                                                                        }
                                                                    />
                                                                </AddStageInfoLine>
                                                                <AddStageInfoLine>
                                                                    <FieldName>
                                                                        Fee:
                                                                    </FieldName>
                                                                    <FieldInput
                                                                        type="text"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    fee_amount:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.fee_amount
                                                                        }
                                                                    />
                                                                </AddStageInfoLine>
                                                            </>
                                                        ) : data.name ===
                                                          "Dead" ? (
                                                            <TextAreaContainer>
                                                                <TextAreaTitle>
                                                                    Remark's
                                                                </TextAreaTitle>
                                                                <TextArea
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setLeadStageObject(
                                                                            {
                                                                                ...leadStageObject,
                                                                                remark: e
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                    }}
                                                                    value={
                                                                        leadStageObject?.remark
                                                                    }
                                                                />
                                                            </TextAreaContainer>
                                                        ) : data.name ===
                                                          "Contact in Future" ? (
                                                            <>
                                                                <DateTimeWrapper>
                                                                    <DTBox>
                                                                        <DTText>
                                                                            Date:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        date: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.date
                                                                            }
                                                                            type="date"
                                                                        />
                                                                    </DTBox>
                                                                    <DTBox>
                                                                        {/* <DTText>
                                                                            Time:
                                                                        </DTText>
                                                                        <DateTimeField
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLeadStageObject(
                                                                                    {
                                                                                        ...leadStageObject,
                                                                                        time: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            value={
                                                                                leadStageObject?.time
                                                                            }
                                                                            type="time"
                                                                        /> */}
                                                                    </DTBox>
                                                                </DateTimeWrapper>
                                                                <TextAreaContainer>
                                                                    <TextAreaTitle>
                                                                        Remark's
                                                                    </TextAreaTitle>
                                                                    <TextArea
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setLeadStageObject(
                                                                                {
                                                                                    ...leadStageObject,
                                                                                    remark: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                        }}
                                                                        value={
                                                                            leadStageObject?.remark
                                                                        }
                                                                    />
                                                                </TextAreaContainer>
                                                            </>
                                                        ) : (
                                                            <TextAreaContainer>
                                                                <TextAreaTitle>
                                                                    Remark's
                                                                </TextAreaTitle>
                                                                <TextArea
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setLeadStageObject(
                                                                            {
                                                                                ...leadStageObject,
                                                                                remark: e
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                    }}
                                                                    value={
                                                                        leadStageObject?.remark
                                                                    }
                                                                />
                                                            </TextAreaContainer>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </DetailedRemarksWrapper>
                                </SectionBox>
                                <SectionBox>
                                    <SectionTitle>Lead History</SectionTitle>
                                    <HistoryTableWrapper>
                                        <TableHead>
                                            <THR>
                                                <TH>Date Added</TH>
                                                <TH>Action</TH>
                                                <TH>Date</TH>

                                                <TH>Remarks</TH>
                                            </THR>
                                        </TableHead>
                                        <TableBody>
                                            {leadHistory?.map((history) => (
                                                <TR>
                                                    <TBFD>
                                                        {history.date_added}
                                                    </TBFD>

                                                    <TBTD>
                                                        {history.stage_name}
                                                    </TBTD>
                                                    <TBFD>{history.date}</TBFD>
                                                    <TBD>{history.remark}</TBD>
                                                </TR>
                                            ))}
                                        </TableBody>
                                        <TableBody></TableBody>
                                    </HistoryTableWrapper>
                                </SectionBox>
                                {/* <SectionBox>
                                    <SectionTitle>Follow up</SectionTitle>
                                    <DetailedRemarksWrapper>
                                        <div style={{ width: "100%" }}>
                                            <p
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "16px",
                                                    lineHeight: "160%",
                                                    color: "#808080",
                                                }}
                                            >
                                                Remark
                                            </p>
                                            <p
                                                style={{
                                                    color: "#7B6294",
                                                    fontWeight: "normal",
                                                    fontSize: "20px",
                                                    lineHeight: "160%",
                                                }}
                                            >
                                                <textarea
                                                    onChange={(e) => {
                                                        setRemarkText(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={remarkText}
                                                    lineHeight={10}
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 10,
                                                        height: 60,
                                                        minHeight: 50,
                                                        resize: "vertical",
                                                        padding: "8px",
                                                        boxSizing: "border-box",
                                                    }}
                                                />
                                            </p>
                                        </div>

                                        <div style={{ width: "100%" }}>
                                            <p
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "16px",
                                                    lineHeight: "160%",
                                                    color: "#808080",
                                                }}
                                            >
                                                Status
                                            </p>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedStatus(
                                                        e.target.value
                                                    );
                                                }}
                                                value={selectedStatus}
                                                style={{
                                                    width: "100%",
                                                    padding: "0 5px",
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                    height: "35px",
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #000",
                                                }}
                                            >
                                                <option value={null}>
                                                    Select status
                                                </option>
                                                {statusValue.map((item) => {
                                                    return (
                                                        <option
                                                            value={item.value}
                                                        >
                                                            {item.text}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                        {selectedStatus == 8 && (
                                            <>
                                                <SectionTitle>
                                                    Next Follow up
                                                </SectionTitle>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "0 5px",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "normal",
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "160%",
                                                                color: "#808080",
                                                            }}
                                                        >
                                                            Follow Up Medium
                                                        </p>
                                                        <Select
                                                            onChange={(e) => {
                                                                setSelectedMedium(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                                padding: 10,
                                                                marginTop: 10,
                                                                height: "60px",
                                                                borderRadius: 10,
                                                                backgroundColor:
                                                                    "#fff",
                                                                border: "1px solid #efefef",
                                                            }}
                                                        >
                                                            <option
                                                                value={null}
                                                            >
                                                                Select medium
                                                            </option>
                                                            {mediumValue.map(
                                                                (item) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                item.value
                                                                            }
                                                                        >
                                                                            {
                                                                                item.text
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: "10px",
                                                        marginBottom: "40px",

                                                        padding: "0 5px",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "normal",
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "160%",
                                                                color: "#808080",
                                                            }}
                                                        >
                                                            Follow up date
                                                        </p>
                                                        <DateField
                                                            type="date"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFollowDate(
                                                                    value.target
                                                                        .value
                                                                );
                                                            }}
                                                            value={followDate}
                                                            max="9999-12-31"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "normal",
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "160%",
                                                                color: "#808080",
                                                            }}
                                                        >
                                                            Follow up Time{" "}
                                                        </p>
                                                        <DateField
                                                            type="time"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFollowTime(
                                                                    value.target
                                                                        .value
                                                                );
                                                            }}
                                                            value={followTime}
                                                            max="9999-12-31"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <AddiInfoFeeLine>
                                            <FieldName>Fee:</FieldName>
                                            <FieldInput
                                                type="number"
                                                value={fee}
                                                onChange={(e) =>
                                                    setFee(e.target.value)
                                                }
                                                onwheel="return false;"
                                            />
                                        </AddiInfoFeeLine>
                                    </DetailedRemarksWrapper>
                                </SectionBox> */}
                            </SectionsWrapper>
                            <ButtonDiv>
                                <SectionBox></SectionBox>
                                <SectionBox>
                                    <SubmitContainer>
                                        <div></div>

                                        <Button
                                            onClick={() => {
                                                // if (leadStageObject?leadStageObject.)

                                                submitFollowUp();
                                            }}
                                            style={{
                                                backgroundColor: "#0FA76F",
                                                color: "#FFF",
                                                borderRadius: 11,
                                                minWidth: 130,
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </SubmitContainer>
                                </SectionBox>
                            </ButtonDiv>
                        </>
                    ) : (
                        <LoaderContainer>
                            <LoadingIcon src={loadingGif} alt="icon" />
                        </LoaderContainer>
                    )}
                </>
            </Modal>
        </BackContainer>
    );
}

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0);
`;
const Modal = styled.div`
    width: 90%;
    max-height: 90vh;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    transition: 0.5s;
    /* z-index: 1001; */
    z-index: 101;
    overflow-y: scroll;
    padding: 30px;
    /* @media (max-width: 1280px) {
        width: 85%;
    } */
    /* @media (max-width: 1080px) {
        width: 60%;
    }
    @media (max-width: 980px) {
        width: 65%;
    } */
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 640px) {
        position: fixed;
        width: 100%;
        top: 75%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Overlay = styled.div`
    position: fixed;
    width: 100%;
    cursor: pointer;
    height: 100vh;
    background: #000;
    opacity: 0.3;
`;
const LoaderContainer = styled.div`
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LoadingIcon = styled.img`
    width: 65px;
    display: block;
    object-fit: cover;
`;
const DateField = styled.input`
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    /* width: 100%; */
    border: 1px solid #f8fafc;
    height: 30px;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 14px;
    :focus {
        background: #fff;
        border: 1px solid #0048d9;
    }
`;
const SectionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    padding: 0 15px;
`;
const SectionBox = styled.div``;
const SectionTitle = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: 500;
    padding: 10px 15px;
    background: #cee1f2;
    margin-bottom: 10px;
`;
const FirstLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
    padding: 0 15px;
`;
const SectionDetailsBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0 15px;
`;
const SectionDetail = styled.div``;
const SectionDetailTitle = styled.div`
    color: #808080;
    font-size: 15px;
    margin-bottom: 4px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
`;
const SectionDetailDetailed = styled.div`
    color:#3ccb7f;
    font-size: 16px;
`;
const LastLine = styled.div`
    margin-top: 15px;
    padding: 0 15px;
`;
// const DetailedRemarksWrapper
const DetailedRemarksWrapper = styled.div`
    padding: 0 15px;
`;
const AdditionalInfoWrapper = styled.div``;
const AddiInfoLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 15px;
`;
const FieldName = styled.div`
    color: #808080;
    font-size: 15px;
    margin-right: 8px;
    width: 110px;
`;
const FieldInput = styled.input`
    width: calc(100% - 120px);
    height: 30px;
    display: inline-block;
    padding: 0 10px;
    :focus{
        outline-color: #3ccb7f
    }
`;
const CloseIconDiv = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    z-index: 1002;
    top: 12px;
    right: 7px;
    cursor: pointer;
`;
const CloseIconImg = styled.img`
    width: 80%;
    height: 80%;
    display: block;
`;
const SubmitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const AddiInfoFeeLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`;
const Select = styled.select`
      :focus{
        outline-color: #3ccb7f
    }
`;
const AddiInfoMTLine = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const FUPText = styled.div`
    width: 150px;
    color: #808080;
    font-size: 16px;
    margin-right: 8px;
`;
const TextAreaContainer = styled.div`
    margin-top: 15px;
`;
const TextAreaTitle = styled.div`
    color: #808080;
    font-size: 16px;
    margin-right: 8px;
`;
const TextArea = styled.textarea`
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    height: 60px;
    min-height: 50px;
    resize: vertical;
    padding: 8px;
    box-sizing: border-box;
`;
const DateTimeWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 15px;
`;
const DTBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const DateTimeField = styled.input`
    width: calc(100% - 85px);
    height: 35px;
    padding: 0 10px;
`;
const DTText = styled.div`
    width: 75px;
    color: #808080;
    font-size: 16px;
    margin-right: 20px;
`;
const AddStageInfoLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`;
const ButtonDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
`;
const HistoryTableWrapper = styled.table`
    border: 1px solid;
    width: 98%;
    border-collapse: collapse;
    margin: 0 auto;
`;
const TableHead = styled.thead``;
const THR = styled.tr`
    &:first-child {
        border-top-left-radius: 10px !important;
    }
`;
const TH = styled.th`
    border: 1px solid;
    padding: 8px 0;
    &:first-child {
        border-top-left-radius: 10px !important;
    }
`;
const TableBody = styled.tbody``;
const TR = styled.tr``;
const TBD = styled.td`
    border: 1px solid;
    padding: 8px 6px;
`;
const TBFD = styled.td`
    border: 1px solid;
    padding: 8px 6px;
    white-space: nowrap;
`;
const TBTD = styled.td`
    border: 1px solid;
    padding: 8px 6px;
    white-space: nowrap;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 80%;
    display: block;
    object-fit: cover;
`;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
// const SectionBox = styled.div``;
