import styled from "styled-components";

export default styled.div`
	padding: ${(props) => (props.padding ? props.padding : "30px")};
	background: #fff;
	border-radius: ${(props) =>
		props.borderRadius ? props.borderRadius : "10px"};
	margin: ${(props) => (props.margin ? props.margin : "10px")};
	box-sizing: border-box;
	@media only screen and (max-width: 1380px) {
		min-width:1280px;
	}

	@media only screen and (max-width: 1280px) {
		padding: 15px;
		overflow-x: scroll;
    min-width: 1300px;
    width: 1160px;
	}
`;
