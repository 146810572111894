import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Legend,
	LabelList,
} from "recharts";
import shrinkIcon from "../../assets/images/exit-fullscreen.png";

export default function ExpandModal({ teams, setIsExpandModal }) {
	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setIsExpandModal(false);
				}}
			></Overlay>
			<Modal>
				<TitleBar>
					<TitleLeft>
						<TitleText>Team Performance</TitleText>
					</TitleLeft>
					<TitleRight>
						<ExpandIcon
							src={shrinkIcon}
							alt="icon"
							onClick={() => {
								setIsExpandModal(false);
							}}
						/>
					</TitleRight>
				</TitleBar>
				<ResponsiveContainer width="95%" height={360}>
					<BarChart
						data={teams}
						margin={{
							top: 30,
							right: 0,
							left: 0,
							bottom: 80,
						}}
					>
						<XAxis
							dataKey="name"
							tick={{ fontSize: 13 }}
							interval={0}
							angle={-90}
							textAnchor="end"
						/>
						<YAxis
							// dataKey="count"
							tick={{ fontSize: 14 }}
							domain={[0, 100]}
						/>
						<Legend
							wrapperStyle={{
								top: -10,
								left: 25,
							}}
						/>
						<Bar
							dataKey="target_achieved"
							fill="#794BD1"
							isAnimationActive={false}
							barSize={30}
							barGap={2}
							// barCategoryGap={2}
							minPointSize={5}
							name="Target Achieved"
						>
							<LabelList
								dataKey="target_achieved"
								// dataKey={
								//     Math.round(
								//         dashboardDetails.target_achieved *
								//             100
								//     ) / 100
								// }
								position="top"
								style={{
									fill: "#000",
									fontSize: 16,
								}}
							/>
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</Modal>
		</BackContainer>
	);
}
const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	// backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 23px;
	transition: 0.5s;
	z-index: 101;
	min-width: 880px;
	max-height: 80vh;
	min-height: 300px;
	box-sizing: border-box;
	padding: 35px 20px 0px 20px;
	box-sizing: border-box;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
`;
const TitleBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const TitleLeft = styled.div``;
const TitleText = styled.div``;
const TitleRight = styled.div`
	display: flex;
	align-items: center;
`;
const ExpandIcon = styled.img`
	display: block;
	width: 18px;
	object-fit: cover;
	cursor: pointer;
`;
