import React, { useState } from "react";
import Icon from "../../assets/images/list-icon.svg";
import Line from "../../assets/images/Line.svg";
import moment from "moment";
import TD from "../../components/TD";
import styled from "styled-components";

function NewLeadList({ setAssignModal, item, setSelectedLead, setIsViewMore }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                alignItems: "center",
            }}
        >
            <TD style={{}} width="6%" color="#000" fontSize="14px">
                {item.lead_id}
            </TD>

            <TD style={{}} width="16%" color="#000" fontSize="14px">
                {item.name}
            </TD>
            <TD style={{}} width="14%" color="#000" fontSize="14px">
                {/* {moment(item?.enquiry_date, "DD/MM/YYYY")} */}
                {item?.enquiry_date
                    ? moment(item?.enquiry_date).format("DD/MM/YYYY")
                    : null}

                {/* {item?.enquiry_date.toISOString().split("T")[0]} */}
            </TD>
            <TD width="14%" color="#000" fontSize="14px">
                {item.phone}
            </TD>
            <TD
                width="17%"
                color="#000"
                fontSize="14px"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
                {item.email}
            </TD>

            {/* <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                width="16%"
                color="#000"
                fontSize="14px"
            >
                {item?.university}
            </TD> */}
            <TD
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                width="16%"
                color="#000"
                fontSize="14px"
            >
                {item?.program}
            </TD>
            <TD width="10%" color="#000" fontSize="14px">
                {item.lead_source}

                {/* <img
					src={item.lead_source_data.image}
					style={{ height: "40px", width: "40px" }}
					alt="Icon"
				/> */}
            </TD>

            <TD
                width="10%"
                style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                }}
                onClick={() => {
                    setAssignModal(true);
                    setSelectedLead(item);
                    // setIsCheck([id]);
                }}
            >
                <Button background="#0FA76F">
                    <ButtonText color="#fff">Assign</ButtonText>
                </Button>
            </TD>
            <TD
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                }}
                width="8%"
                color="#0FA76F"
                fontWeight="500"
                fontSize="14px"
                onClick={() => {
                    setIsViewMore(true);
                    setSelectedLead(item);
                }}
            >
                View More
            </TD>

            {/* <TD width="5%">
				<a
					onClick={(e) => {
						e.preventDefault();
					}}
				>
					<img src={Icon} style={{ display: "block" }} alt="Icon" />
				</a>
			</TD> */}
        </div>
    );
}

export default NewLeadList;

const Button = styled.div`
    background: ${(props) => (props.background ? props.background : "#d6d3ec")};
    border: 1px solid;
    border-color: ${(props) => (props.border ? props.border : "#d6d3ec")};
    border-radius: 8px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    width: 50%;
    /* margin-left: 20px; */
    @media all and (max-width: 980px){
        width: 40%;
        height: 35px;
}
`;
const ButtonText = styled.span`
    /* color: #fff; */
    font-size: 14px; // display: flex;
    // justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : "#8561C6")};
    font-weight: 500;
    @media all and (max-width: 980px){
        font-size: 12px;
}
    /* margin-left: 10px; */
`;
