import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title.js";
import search from "../../assets/images/header-search.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Program from "./Program/Program";
import DeleteModal from "../../components/includes/DeleteModal";
import { Modal } from "@material-ui/core";
import Input from "../../components/Input/input";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";

function AddProgram() {
	const { state } = useContext(Context);
	const [open, setOpen] = useState(false);
	const [leadProgram, setLeadProgram] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [searchItem, setSearchItem] = useState("");
	const [selectedProgram, setSelectedProgram] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const [isModal, setModal] = useState(false);
	const [editId, setEditId] = useState("");
	const [programName, setProgramName] = useState("");
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleDeleteData = (pk) => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.delete(`/leads/program/${pk}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setModal(false);
					getLeadProgram();
				} else if (StatusCode == 6001) {
					alert(data.message);
					getLeadProgram();
				}
			})
			.catch((error) => {});
	};
	const handleSubmit = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("name", programName);

		var method = editId ? baseConfig.put : baseConfig.post;
		var URL = editId ? `/leads/program/${editId}/` : `/leads/programs/`;

		method(URL, formdata, {
			headers: {
				Authorization: "Bearer " + accessToken,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					// history.push("/superadmin/list-campus/");
					getLeadProgram();
					handleClose();
					setProgramName("");

					if (editId) {
						setEditId("");
					}
				} else if (6001) {
					alert(data.data.message);
				}
			})
			.catch((error) => {
				alert(error.response.data.data.message);
			});
	};

	const getLeadProgram = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/leads/programs/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLeadProgram(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getLeadProgram();
	}, []);
	return (
		<Wrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
				}}
				className="top-title-wrapper"
			>
				<div>
					<Title className="programhead" fontSize="35px">
						Program
					</Title>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					class="top-title-large-right"
				>
					<Button
						className="programaddbutton"
						onClick={(e) => {
							e.preventDefault();
							handleOpen();
						}}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						backgroundColor="#0FA76F"
						fontSize="15px"
					>
						<img className="plus" src={addbutton} alt="Logo" />
						<Text
							className="programaddtext"
							style={{ marginLeft: "12px" }}
							color="white"
							fontSize="15px"
						>
							Add Program
						</Text>
					</Button>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				{!isLoading ? (
					<Containerr
					// style={{
					// 	// flexWrap: "wrap",
					// 	// borderRadius: "23px",
					// 	// display: "flex",
					// 	// // height: "10% ",
					// 	display: "grid",
					// 	gridTemplateColumns: "1fr 1fr 1fr",
					// 	gridGap: "30px",
					// }}
					// width="100%"
					>
						{leadProgram?.map((data) => {
							{
								console.log(setDeleteId, "delid");
							}
							return (
								<Program
									data={data}
									// width="30%"
									fontSize="18px"
									setDeleteId={setDeleteId}
									setModal={setModal}
									setEditId={setEditId}
									handleOpen={handleOpen}
									setSelectedProgram={setSelectedProgram}
									selectedProgram={selectedProgram}
									programName={programName}
									leadProgram={leadProgram}
								/>
							);
						})}
					</Containerr>
				) : (
					<Div>
						<LoadingGif
							src={
								require("./../../assets/images/loading-buffering.gif")
									.default
							}
							alt="Loading"
						/>
					</Div>
				)}
			</div>
			{isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)}
			<Modal open={open} onClose={handleClose}>
				<ModalContainer>
					<Container
						style={{ flexWrap: "wrap ", borderRadius: "23px" }}
						width="100%"
					>
						<form id="form">
							<div>
								<Title
									style={{ marginBottom: "10px" }}
									fontSize="20px"
								>
									Add new Program
								</Title>

								<Text
									color="#808080"
									fontSize="15px"
									style={{ marginTop: "20px" }}
								>
									Program Name
								</Text>
								<Input
									value={programName}
									onChange={(value) => setProgramName(value)}
									width="100%"
									type="name"
									placeholder="Enter Program name here"
								/>
							</div>
							{/* <div style={{ display: "flex", marginTop: "2%" }}>
                        <Input onChange={(value) => handleChangeValue("isQuality", value)} width="26px" type="checkbox" />

                        <Text style={{ marginTop: "2%" }} color="#7B6294" fontSize="15px">Quality Source</Text>

                    </div> */}
							<div
								style={{
									display: "flex",
									marginTop: "21px",
									justifyContent: "space-between",
									gap: "10px",
								}}
							>
								<Button
									onClick={(e) => {
										e.preventDefault();
										handleSubmit();
										// document.getElementById("form").submit();
									}}
									style={{
										display: "flex",
										alignItems: "center",
										width: "40%",
									}}
									bacerkgroundColor="#5A2D8F"
									fontSize="15px"
									padding="15px 50px"
								>
									<Text color="white" fontSize="15px">
										Submit
									</Text>
								</Button>

								<Button
									onClick={() => {
										handleClose();
									}}
									style={{
										display: "flex",
										alignItems: "center",
										width: "40%",
									}}
									backgroundColor="#F4F4F4"
									fontSize="15px"
									padding="15px 50px"
								>
									<Text color="#0FA76F" fontSize="15px">
										Cancel
									</Text>
								</Button>
							</div>
						</form>
					</Container>
				</ModalContainer>
			</Modal>
		</Wrapper>
	);
}

export default AddProgram;

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	margin-left: 500px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;
const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	max-height: 85vh;
	min-height: 40vh;
	overflow-y: scroll;
	background: #fff;
	border: 1px solid #fff;
	border-radius: 12px;
	box-shadow: 24px;
	padding: 20px;
`;
const Containerr = styled.div`
	border-radius: 23px;

	height: 10%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr !important;
	grid-gap: 30px;
	width: 100%;
	@media all and (max-width: 980px) {
		grid-template-columns: 1fr 1fr !important;
	}

	@media all and (max-width: 640px) {
		grid-template-columns: 1fr !important;
	}
`;
