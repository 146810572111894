import React from "react";
import styled from "styled-components";

function NoDataFound({ hght }) {
    return (
        <div
            className="no-result"
            style={{
                minHeight: hght ? null : "calc(100vh - 200px)",
                height: hght ? hght : "80%",
                // width: "100%",
                // background: "red",
            }}
        >
            <text className="no-result-text">No results found </text>
        </div>
    );
}

export default NoDataFound;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
