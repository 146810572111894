import styled from "styled-components";

export default styled.div`
    padding: 20px 2.5%;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    height: 100%;
    /* overflow: auto; */
    margin-top: 80px;
    @media all and (max-width: 1280px){
        overflow: hidden;
}
    @media (max-width: 1080px) {
       
    }
    @media all and (max-width: 480px){
        padding: 2.5% 0.5%;
}
`;
