import React, { useEffect, useState } from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import deleteIcon from "../../assets/images/action-delete.svg";
import edit from "../../assets/images/edit.svg";
import eye from "../../assets/images/action-eye.svg";
import Image from "../../components/Image";
import Popup from "./Popup";

function SalesListItem({
	studentId,
	name,
	leader,
	members,
	rank,
	status,
	onCallBack,
}) {
	const [statusLabel, setStatusLabel] = useState(status);
	const [statusBackground, setStatusBackground] = useState("#FF5B5B26");
	const [statusColor, setStatusColor] = useState("#03AC5B");
	const [isOpen, setIsOpen] = useState(false);

	const togglePopup = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (status === "Active") {
			setStatusLabel("Active");
			setStatusBackground("#FF5B5B26");
			setStatusColor("#03AC5B");
		} else if (status === "Inactive") {
			setStatusLabel("Inactive");
			setStatusBackground("#FFBB5426");
			setStatusColor("#808080");
		} else if (status === "Active") {
			setStatusLabel("Active");
			setStatusBackground("#2ED6A326");
			setStatusColor("#00A389");
		}
	}, [status]);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				borderBottom: "1px solid #E6EFF5",
				padding: "20px 0",
				alignItems: "center",
			}}
		>
			<TD style={{}} width="10%" color="#505887" fontSize="16px">
				{studentId}
			</TD>

			<TD style={{}} width="10%" color="#505887" fontSize="16px">
				{name}
			</TD>
			<TD
				width="10%"
				color="#505887"
				fontSize="16px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{leader}
			</TD>
			<TD width="10%" color="#505887" fontSize="16px">
				{members}
			</TD>

			<TD
				style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				width="10%"
				color="#505887"
				fontSize="16px"
			>
				{rank}
			</TD>

			{/* <TD
				width="10%"
				color={statusColor}
				fontSize="16px"
				style={{
					background: statusBackground,
					padding: "9px",
					textAlign: "center",
					borderRadius: "10px",
				}}
			>
				{statusLabel}
			</TD> */}
			<TD
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
				}}
				width="10%"
				color="#505887"
				fontSize="16px"
			>
				<a
					onClick={(e) => {
						e.preventDefault();
						onCallBack("edit");
					}}
					style={{
						display: "block",
						width: "20px",
					}}
					href="#"
					className="action-edit"
				>
					<Image width="100%" src={edit} alt="edit" />
				</a>

				<a
					onClick={(e) => {
						e.preventDefault();
						onCallBack("view");
					}}
					style={{
						display: "block",
						width: "20px",
					}}
					href="#"
					className="action-eye"
				>
					<Image width="100%" src={eye} alt="eye" />
				</a>

				<a
					onClick={(e) => {
						e.preventDefault();
						onCallBack("delete");
					}}
					style={{
						display: "block",
						width: "20px",
					}}
					href="#"
				>
					<Image
						onClick={togglePopup}
						width="100%"
						src={deleteIcon}
						alt="delete"
					/>
				</a>
			</TD>
		</div>
	);
}

export default SalesListItem;
