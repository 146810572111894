import React, { useEffect, useState } from "react";
import styled from "styled-components";

function FilterBox({
	setFilterBox,
	setSortValue,
	sortValue,
	getAssignedLeads,
	setSelectedLeadAssign,
	selectedLeadAssign,
	setSelectedLeadConverted,
	selectedLeadConverted,
	setSelectedPercentage,
	selectedPercentage,
}) {
	const [leadAssign, setLeadAssign] = useState([
		{
			id: 1,
			value: 100,
		},
		{
			id: 2,
			value: 200,
		},
		{
			id: 3,
			value: 300,
		},
		{
			id: 4,
			value: 400,
		},
		{
			id: 5,
			value: 500,
		},
		{
			id: 6,
			value: 600,
		},
	]);

	const [leadConverted, setLeadConverted] = useState([
		{
			id: 1,
			value: 100,
		},
		{
			id: 2,
			value: 200,
		},
		{
			id: 3,
			value: 300,
		},
		{
			id: 4,
			value: 400,
		},
		{
			id: 5,
			value: 500,
		},
		{
			id: 6,
			value: 600,
		},
	]);

	const [successRate, setSuccessRate] = useState([
		{
			id: 1,
			percentage: 10,
		},
		{
			id: 2,
			percentage: 20,
		},
		{
			id: 3,
			percentage: 30,
		},
		{
			id: 4,
			percentage: 40,
		},
		{
			id: 5,
			percentage: 50,
		},
		{
			id: 6,
			percentage: 60,
		},
		{
			id: 7,
			percentage: 70,
		},
		{
			id: 8,
			percentage: 80,
		},
		{
			id: 9,
			percentage: 90,
		},
		{
			id: 10,
			percentage: 100,
		},
	]);

	// function disableScrolling(){
	//     var x=window.scrollX;
	//     var y=window.scrollY;
	//     window.onscroll=function(){window.scrollTo(x, y);};
	// }

	// function enableScrolling(){
	//     window.onscroll=function(){};
	// }

	// function disableScrolling() {
	//     var x = window.scrollX;
	//     var y = window.scrollY;
	//     window.onscroll = function () {
	//         window.scrollTo(x, y);
	//     };
	// }

	// useEffect(() => {
	//     // disableScrolling();
	// }, []);

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setFilterBox(false);
				}}
			></Overlay>
			<Modal>
				<Container>
					<Div>
						<Title>Filter by</Title>
						<img
							onClick={() => {
								setFilterBox(false);
							}}
							style={{ cursor: "pointer" }}
							src={
								require("./../../assets/images/close.svg")
									.default
							}
							alt="icon"
						/>
					</Div>
					<div style={{ height: "500px", overflow: "scroll" }}>
						<Body borderBottom="6px solid #f2f2f2">
							<Heading>Name</Heading>
							<div
								onClick={() => {
									setSortValue("name");
								}}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<img
									src={
										require("./../../assets/images/a-z.svg")
											.default
									}
									alt="icon"
								/>
								<SubHeading
									style={{
										color:
											sortValue === "name"
												? "#0FA76F"
												: "#515151",
									}}
								>
									From A to Z
								</SubHeading>
								{sortValue === "name" && (
									<img
										src={
											require("./../../assets/images/tick.svg")
												.default
										}
										alt="icon"
									/>
								)}
							</div>
							<div
								onClick={() => {
									setSortValue("-name");
								}}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<img
									src={
										require("./../../assets/images/z-a.svg")
											.default
									}
									alt="icon"
								/>
								<SubHeading
									style={{
										color:
											sortValue === "-name"
												? "#0FA76F"
												: "#515151",
									}}
								>
									From Z to A
								</SubHeading>
								{sortValue === "-name" && (
									<img
										src={
											require("./../../assets/images/tick.svg")
												.default
										}
										alt="icon"
									/>
								)}
							</div>
						</Body>
						<Body borderBottom="6px solid #f2f2f2">
							<Heading>Leads assigned</Heading>
							<Box>
								{leadAssign.map((data) => (
									<Card
										onClick={() => {
											setSelectedLeadAssign(data.value);
											if (
												selectedLeadAssign ===
												data.value
											) {
												setSelectedLeadAssign("");
											}
										}}
									>
										<AssignText
											backgroundColor={
												selectedLeadAssign ===
													data.value && "#0FA76F"
											}
											style={{
												color:
													selectedLeadAssign ===
													data.value
														? "#fff"
														: "#515151",
											}}
										>
											{data.value}+
										</AssignText>
									</Card>
								))}
							</Box>
						</Body>

						<Body borderBottom="6px solid #f2f2f2">
							<Heading>Leads Converted</Heading>
							<Box>
								{leadConverted.map((data) => (
									<Card
										onClick={() => {
											setSelectedLeadConverted(
												data.value
											);
											if (
												selectedLeadConverted ===
												data.value
											) {
												setSelectedLeadConverted("");
											}
										}}
									>
										<AssignText
											backgroundColor={
												selectedLeadConverted ===
													data.value && "#0FA76F"
											}
											style={{
												color:
													selectedLeadConverted ===
													data.value
														? "#fff"
														: "#515151",
											}}
										>
											{data.value}+
										</AssignText>
									</Card>
								))}
							</Box>
						</Body>
						<Body>
							<Heading>Success Rate</Heading>
							<Box>
								{successRate.map((data) => (
									<Card
										onClick={() => {
											setSelectedPercentage(
												data.percentage
											);
											if (
												selectedPercentage ===
												data.percentage
											) {
												setSelectedPercentage("");
											}
										}}
									>
										<AssignText
											backgroundColor={
												selectedPercentage ===
													data.percentage && "#0FA76F"
											}
											style={{
												color:
													selectedPercentage ===
													data.percentage
														? "#fff"
														: "#515151",
											}}
										>
											{data.percentage}%
										</AssignText>
									</Card>
								))}
							</Box>

							<Button
								onClick={() => {
									getAssignedLeads();
									setFilterBox(false);
								}}
								style={{
									// display: "flex",
									alignItems: "center",
								}}
								backgroundColor="#0FA76F"
								fontSize="15px"
							>
								<ButtonText
									// style={{ marginLeft: "12px" }}
									color="white"
									fontSize="15px"
								>
									Apply Filters
								</ButtonText>
							</Button>
						</Body>
					</div>
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default FilterBox;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	// background: rgba(0, 0, 0, 0.2);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	right: 40px;
	top: 20%;
	/* max-height: 70vh; */
	position: absolute;
	border-radius: 5px;
	transition: 0.5s;
	z-index: 101;
	box-shadow: -1.41438px 1.41438px 25px rgba(0, 0, 0, 0.16);
`;
const Container = styled.div`
	// height: 130px;
	width: 300px;
	max-height: 70vh;

	// padding: 30px 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Title = styled.h4`
	font-size: 20px;
	color: #808080;
`;
const Div = styled.div`
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	background-color: #f1f8fe;
	padding: 20px 15px;
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
	background-color: #fff;
	padding: 15px;
	// border-radius 0 0 5px 5px;
	overflow: scroll;
	border-bottom: ${(props) =>
		props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
	font-size: 16px;
	color: #515151;
	font-weight: 600;
`;
const SubHeading = styled.h4`
	font-size: 13px;
	color: #515151;
	margin: 0 10px;
`;
const Box = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;
const Card = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33%;
	margin-top: 10px;
`;
const AssignText = styled.h4`
	font-size: 13px;
	margin: 0 10px;
	height: 35px;
	width: 70px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#f3f3f3"};
`;
const Button = styled.div`
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#522e92"};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "white")};
	// padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
	border: none;
	border-radius: 8px;
	text-align: center;
	display: inline-block;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	// width: 200px;
	cursor: pointer;
	margin-top: 20px;
`;
const ButtonText = styled.h4`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
