import React, { useEffect, useState } from "react";
import Container from "../Container";
import uploadImage from "../../assets/images/upload-image.svg";
import Image from "../Image";
import Text from "../Text";

function UploadInputLead({ file, setFile, accept }) {
	useEffect(() => {
		const dropContainer = document.getElementById("upload");
		const fileInput = document.getElementById("file1");
		dropContainer.ondragover = dropContainer.ondragenter = function (evt) {
			evt.preventDefault();
		};
		dropContainer.ondrop = function (evt) {
			// pretty simple -- but not for IE :(
			fileInput.files = evt.dataTransfer.files;

			setFile(fileInput.files);

			evt.preventDefault();
		};
	}, []);

	return (
		<div id="upload">
			<Container
				style={{ border: "2px dashed #EAEAEA", textAlign: "center" }}
				width="auto"
				margin="12px 0 0"
			>
				<Image
					width="80px"
					height="80px"
					src={uploadImage}
					alt="upload"
				/>

				<input
					type="file"
					id="file1"
					style={{ display: "none" }}
					onChange={(e) => {
						setFile(e.target.files);
					}}
					accept={accept}
				/>
				{file ? (
					<Text
						style={{ marginTop: "20px" }}
						textAlign="center"
						fontSize="16px"
						fontWeight="400"
					>
						{file[0]?.name}
					</Text>
				) : null}
				<Text
					style={{ marginTop: "20px" }}
					textAlign="center"
					fontSize="16px"
					color="#DADADA"
					fontWeight="400"
				>
					Drop your image here,{" "}
					<a
						href="#"
						style={{ color: "grey" }}
						onClick={(e) => {
							e.preventDefault();
							const input = document.getElementById("file1");
							input.click();
						}}
					>
						or browse
					</a>
				</Text>
			</Container>
		</div>
	);
}

export default UploadInputLead;
