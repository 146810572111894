import configApi from "./config";

/**
 * Get method
 * @param url
 * @returns {Promise<R>}
 */

const get = (url, options = {}) => {
	return new Promise((resolve, reject) => {
		let accessToken = options.user_details.access_token;
		let baseURL = configApi.API_ENDPOINT + url;
		fetch(baseURL, {
			...options,
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/**
 * Post method
 * @param url
 * @param data
 * @param method
 * @returns {Promise<R>}
 */
const post = (url, options = {}, method = "POST", data) => {
	return new Promise(async (resolve, reject) => {
		let accessToken = options.state;
		let baseURL = configApi.API_ENDPOINT + url;
		fetch(baseURL, {
			...options,
			method: method,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	get,
	post,
	put: (url, data) => post(url, data, "PUT"),
	delete: (url) => post(url, {}, "DELETE"),
};
