import styled from "styled-components";

export default styled.div`
    padding: ${(props) => (props.padding ? props.padding : "30px")};
    background: ${(props) => (props.background ? props.background : "#fff")};
    width: ${(props) => (props.width ? props.width : "25%")};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "10px"};
    margin: ${(props) => (props.margin ? props.margin : "10px")};
    box-sizing: border-box;
    grid-template-columns: ${(props) => (props.gridTemplateColumns ? props.gridTemplateColumns : "1fr 1fr 1fr")};

    @media all and (max-width: 1280px) {
        ${(props) => props.type === "dashboard" && `padding: 15px;`}
        
    }
    @media all and (max-width: 980px){
        ${(props) =>
          props.className === "stage" || props.className === "source"
          ? "grid-template-columns: 1fr 1fr !important"
        : ""}
         ${(props) => props.type === "lead-distribution" && `padding: 15px;`}
          ${(props) => props.type === "leaddistribution" && `width: 100%;`}

      display: ${(props) =>
  props.className === "chart-container" ? "none" : (props.display ? props.display : "block")};
      width: ${(props) =>
  props.className === "chart-one" ? "85%" : (props.width ? props.width : "auto")} !important;
    ${(props) => props.className === "source"
          ? "grid-template-columns: 1fr 1fr !important"
        : ""}
   
      }
@media all and (max-width: 640px){

  ${(props) => props.className === "lead-converted-container" && `padding: 10px;`}
  ${(props) => props.className === "teamperformance" && `padding: 10px;`}
  ${(props) => props.className === "teamperformance" && `height: unset;`}
  ${(props) => props.className === "leaddistribution" && `padding:10px`}
  ${(props) => props.className === "source"
          ? "grid-template-columns: 1fr !important"
        : ""}
}
@media all and (max-width: 360px) {
  ${(props) =>
    props.className === "container"
      ? `padding: 8px;`
      : props.className === "form-container"
      ? `padding: 2px;`
      : ''
  }
}
@media all and (max-width: 480px) {
    ${(props) =>
      props.className === "source"
        ? 'padding: 10px;'
        : props.className === "form-container"
        ? 'padding: 2px;'
        : ''}
  }
 /* @media only screen and (max-width: 1440px) {
    width: 30%;
    margin-right: 5%;
    :nth-child(3n) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 15px;
  }
  @media only screen and (max-width: 980px) {
    width: 48%;
    margin-right: 2%;
    :nth-child(2n) {
      margin-right: 0;
    }
    :nth-child(3n) {
      margin-right: 2%;
    }
  }
  
  @media only screen and (max-width: 580px) {
    width: 100%;
    margin-right: 0%;
  } */
`;
