import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../contexts/Store";

export const SuperAdminRoute = ({ component: Component, ...rest }) => {
	const { state } = useContext(Context);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (state.user_details.role === "superuser") {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/superadmin/",
							}}
						/>
					);
				}
			}}
		/>
	);
};
