import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import moment from "moment";

export default function NotificationModal({ notifications }) {
    const { state } = useContext(Context);

    if (!Array.isArray(notifications)) {
        // Handle the case when notifications is not an array (e.g., show a message)
        return <div>No notifications available.</div>;
    }

    return (
        <MainWrapper>
            {notifications.map((notification, index) => (
                <NoticationItem key={index}>
                    <MessageContent>
                        {notification.message_content}
                    </MessageContent>
                    <DateAndTime>
                        {moment(notification.date).format("dddd Do MMMM YYYY")}
                    </DateAndTime>
                </NoticationItem>
            ))}
        </MainWrapper>
    );
}

const MainWrapper = styled.div`
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 13px;
    position: absolute;
    top: 40px;
    /* bottom: -20px; */
    right: -50%;
    width: 350px;
    max-height: 360px;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 12px 25px 3px rgba(0, 0, 0, 0.09);
    overflow-y: scroll;
`;
const NoticationItem = styled.div`
    padding: 10px 0 15px 0;
    border-top: 0.5px solid #c1c1c1;
    &:first-child {
        border-top: none;
    }
`;
const MessageContent = styled.p`
    color: #404040;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
`;
const DateAndTime = styled.p`
    color: #404040;
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    margin-top: 8px;
`;
