import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import TD from "../../components/TD";
import NewLeadList from "./NewLeadList";
import { useParams } from "react-router-dom";
import FollowUpList from "./FollowUpList";
import { Bar } from "react-chartjs-2";
import NoDataFound from "../../components/noRecords/NoDataFound";
import searchImage from "./../../assets/images/header-search.svg";
import Text from "../../components/Text";
import addbutton from "../../assets/images/addbutton.svg";
import loadingIcon from "../../assets/lottie/loading.gif";
import filter from "../../assets/images/filters.svg";
import FilterBox from "../../components/includes/FilterBox";
import TeamLeaderFilterBox from "./TeamLeaderFilterBox";
import MainPagination from "../../components/includes/MainPagination";
import { Popover } from "@material-ui/core";

function FollowUp() {
    const [search, setsearch] = useState("");
    const { tabStatus } = useParams();
    const [dashboardDetails, setDashboardDetails] = useState([]);

    // Filter purpose
    const [isFilterBox, setFilterBox] = useState(false);
    const [universityList, setUniversityList] = useState([]);
    const [sortValue, setSortValue] = useState();
    const [universityValues, setUniversityValues] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});

    const [selectedTeamMember, setSelectedTeamMember] = useState({});
    const [overViewDetails, setOverViewDetails] = useState({});

    const [members, setMembers] = useState([]);
    const { state } = useContext(Context);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubLoading, setIsSubLoading] = useState(false);

    const [tableStatus] = useState([
        {
            id: 1,
            title: "Overview",
            value: "overview",
        },
        {
            id: 2,
            title: "All",
            value: "all",
        },
        {
            id: 3,
            title: "No Action",
            value: "new_leads",
        },
        {
            id: 4,
            title: "Introduction",
            value: "introduction",
        },
        {
            id: 5,
            title: "Pre Meeting",
            value: "pre_meeting",
        },
        {
            id: 6,
            title: "Meeting",
            value: "meeting",
        },
        {
            id: 7,
            title: "Post Meeting",
            value: "post_meeting",
        },
        {
            id: 8,
            title: "Admissions",
            value: "admission",
        },
        {
            id: 9,
            title: "Invalid",
            value: "invalid",
        },
        {
            id: 10,
            title: "Dead",
            value: "dead",
        },
    ]);
    const [selectedDashboard, setSelectedDashboard] = useState(tableStatus[0].value);
    const [leadData, setLeadData] = useState([]);

    const overViewData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "Converted",
                    data: [20, 30],
                    backgroundColor: "#9F99E8",
                    border: "none",
                    width: "100px",
                },
                {
                    label: "ConvertedInterested",
                    data: [20, 3],
                    backgroundColor: "#AEA7FD",
                    border: "none",
                    width: "100px",
                },
                {
                    label: "Not Interested",
                    data: [4, 30],
                    backgroundColor: "#C5CCFF",
                    border: "none",
                    width: "100px",
                },
            ],
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        },
        scales: {
            y: {
                title: "No od",
                ticks: {
                    min: 10,
                    stepSize: 10,
                    max: 50,
                    showLabelBackdrop: false,
                    beginAtZero: false,
                },
                title: {
                    display: true,
                    text: `No. Of Leads `,
                    color: "#522E92",
                    font: {
                        size: 18,
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };


    const listTeamMember = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        let team_id = state.user_details.team_Id;
        baseConfig
            .get("/sales-persons/sales-persons-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    team: team_id,
                    search: search,
                    ranking: true,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setMembers(data.data);
                    setTimeout(() => {
                        if (data.data.length > 0) {
                            setSelectedTeamMember(data?.data[0]);
                        }
                        setIsLoading(false);
                    }, 300);
                } else {
                    alert("Some Error Occured,Try again later");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const getDashboard = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/general/sales-team-leader-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDashboardDetails(data.data);
              
                    // setTeams(data.teams);

                    // const user_details = {
                    //     // is_verified: true,
                    //     // access_token: response.access,
                    //     // role: response.userrole,
                    //     team_Id: data?.data?.id,
                    // };

                    // dispatch({
                    //     type: "UPDATE_USER_DETAILS",
                    //     user_details,
                    // });
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 200);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };

    const getLeadData = () => {
        setIsSubLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/lead/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    sales_person: selectedTeamMember.id,
                    status: selectedDashboard,
                    search: search,
                    ordering: sortValue,
                    university: universityValues,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadData(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setIsSubLoading(false);
                    }, 200);
                } else {
                    alert("Some error occured,please try again later");
                    setIsSubLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
                // setIsSubLoading(false);
            });
    };

    // const getLeadMeetingData = () => {
    //     let accessToken = state.user_details.access_token;
    //     baseConfig
    //         .get("/sales-persons/follow-up/", {
    //             headers: {
    //                 Authorization: "Bearer " + accessToken,
    //             },
    //             params: {
    //                 sales_person: selectedTeamMember.id,
    //                 // status: selectedDashboard,
    //             },
    //         })
    //         .then((response) => {
    //             const { StatusCode, data } = response.data;

    //             if (StatusCode === 6000) {
    //                 setLeadData(data.data);
    //             } else {
    //             }
    //         })
    //         .catch((error) => {});
    // };

    const getOverViewData = () => {
        setIsSubLoading(true);

        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/sales-persons/sales-person/${selectedTeamMember.id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    // sales_person: selectedTeamMember.id,
                    // status: selectedDashboard,
                    // search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
            
                if (StatusCode === 6000) {
                    setOverViewDetails(data.data);
                    setTimeout(() => {
                        setIsSubLoading(false);
                    }, 200);
                    
                } else {
                    alert("Some error occured,please try again later");

                    setIsSubLoading(false);
                }
            })
            .catch((error) => {
                setIsSubLoading(false);
                alert(error);
            });
           
    };

    useEffect(() => {
        listTeamMember();
        getDashboard();
        // getUniversity();
    }, []);

    useEffect(() => {
        // if (
        //     selectedDashboard === "followup" ||
        //     selectedDashboard === "converted" ||
        //     selectedDashboard === "all"
        // ) {
        //     getLeadData();
        // } else if (selectedDashboard === "meeting") {
        //     getLeadMeetingData();
        // } else if (selectedDashboard === "overview") {
        //     getOverViewData();
        // }
        //  else if (selectedDashboard === "converted") {
        // 	getLeadData();
        // }
        if (selectedDashboard === "overview") {
            if (selectedTeamMember.id) {
                getOverViewData();
            }
        } else {
            getLeadData();
        }
    }, [selectedTeamMember, selectedDashboard, search, currentPage]);

    useEffect(() => {
        getLeadData();
    }, [sortValue, universityValues]);

    // useEffect(() => {
    //     setSelectedDashboard(tabStatus);
    // }, [tabStatus]);

    // Filter popover onClick states and functions
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter popover

    const DetailCard = ({ text, icon, count,per }) => {
      
        return (
            <SmallCard background="#f9f9f9">
                <SmallTop>
                    <Count>{count?count:"0"}{per?per:""}</Count>
                    <Img src={icon} alt="icon" />
                </SmallTop>
                <SmallBottom>
                    <ContentText>{text}</ContentText>
                </SmallBottom>
            </SmallCard>
        );
    };

    return (
        <>
            {!isLoading ? (
                <Wrapper className="assigned">
                    <H1>Team Performance</H1>

                    <TeamDetailBox>
                        <DetailCard
                            text="Target Achieved"
                            icon={require("./../../assets/images/goal.svg").default}
                            count={`${Math.round(dashboardDetails?.target_achieved * 100) / 100}%`}
                        />
                        <DetailCard
                            text="Revenue Generated"
                            icon={require("./../../assets/images/goal.svg").default}
                            count={`INR ${dashboardDetails?.revenue_generated}`}

                            // count={dashboardDetails?.revenue_generated}
                        />
                        <DetailCard
                            text="Target"
                            icon={require("./../../assets/images/new-target.svg").default}
                            count={`INR ${dashboardDetails?.target}`}
                        />
                        <DetailCard
                            text="Admissions"
                            icon={require("./../../assets/images/admission-icon.svg").default}
                            count={dashboardDetails?.total_lead_admission}
                        />
                        <DetailCard
                            text="Leads"
                            icon={require("./../../assets/images/leads-icon.svg").default}
                            count={dashboardDetails?.total_lead_assigned}
                        />
                        {/* <DetailCard
								text="Network Leads"
								icon={
									require("./../../assets/images/leads-icon.svg")
										.default
								}
								count={dashboardDetails?.network_leads}
							/> */}
                        {/* <DetailCard
                            text="Network Leads"
                            icon={
                                require("./../../assets/images/network-lead.svg")
                                    .default
                            }
                            count={dashboardDetails?.network_leads}
                        />
                        <DetailCard
                            text="Meetings"
                            icon={
                                require("./../../assets/images/meeting.svg")
                                    .default
                            }
                            count={dashboardDetails?.total_meetings}
                        /> */}
                        <DetailCard
                            text="Invalid Leads"
                            icon={require("./../../assets/images/invalid-lead-1.svg").default}
                            count={dashboardDetails?.total_lead_invalid}
                        />
                    </TeamDetailBox>
                    <TopContainer>
                        <Left>
                            <H1>Team Members</H1>
                        </Left>
                        {/* <Right>
                    <SearchDiv>
                        <Form className="search-container">
                            <SearchIcon
                                src={
                                    require("./../../assets/images/search.svg")
                                        .default
                                }
                            />
                            <SearchField
                                className="input-box"
                                type="text"
                                name="search"
                                placeholder="Search for something"
                                onChange={(e) => setsearch(e.target.value)}
                            />
                        </Form>
                    </SearchDiv>
                </Right> */}
                    </TopContainer>
                    <BodyContainer>
                        {members.map((data) => (
                            <CardLeft
                                key={data.id}
                                selected={data.id === selectedTeamMember.id ? true : false}
                                color={data.id === selectedTeamMember.id ? "#d7d7d7" : "#d7d7d7"}
                                bgColor={data.id === selectedTeamMember.id ? "#f1edf9" : "#ffffff"}
                                onClick={() => {
                                    setSelectedTeamMember(data);
                                }}
                            >
                                <ProfileDiv>
                                    {data && data.image? 
                                        <ProfileImg src={data.image} alt="image" />
                                         : (
                                        <ProfileImg
                                             src={require("./../../assets/images/profile-icon.svg").default}
                                            alt="image"
                                            />
                                    )} 
                                    <ProfileDetails>
                                        <ProfileTop>
                                            <Rank>{data.rank?data.rank:"0"}</Rank>
                                            <RankText>Rank</RankText>
                                        </ProfileTop>
                                        <ProfileBottom>
                                            <Name>{data.name?data.name:"0"}</Name>
                                        </ProfileBottom>
                                    </ProfileDetails>
                                </ProfileDiv>
                            </CardLeft>
                        ))}
                    </BodyContainer>
                    {/* <SearchAndOptionsContainer> */}
                    <div Class="scroll">
                    <MemberDiv>
                   
                        <MemberLeft>
                            <ProfileDiv>
                                {selectedTeamMember?.image ? (
                                    <ProfileImg src={selectedTeamMember.image} alt="image" />
                                    ) : (
                                        <ProfileImg
                                            src={require("./../../assets/images/profile-icon.svg").default}
                                            alt="image"
                                        />
                                )}
                                <ProfileDetails>
                                    <ProfileTop>
                                        <Rank>{selectedTeamMember.rank}</Rank>
                                        <RankText>Rank</RankText>
                                    </ProfileTop>
                                    <ProfileBottom>
                                        <Name>{selectedTeamMember.name}</Name>
                                    </ProfileBottom>
                                </ProfileDetails>
                            </ProfileDiv>
                        </MemberLeft>
                        {/* <MemberTab> */}
                        <DashboardButtonDiv>
                            {tableStatus.map((data) => (
                                <DashboardButton
                                    key={data.id}
                                    background={selectedDashboard === data.value ? "#0FA76F" : "#fff"}
                                    onClick={() => {
                                        setSelectedDashboard(data.value);
                                    }}
                                >
                                    <ButtonName color={selectedDashboard === data.value ? "#fff" : "#B4B4B4"}>
                                        {data.title}
                                    </ButtonName>
                                </DashboardButton>
                            ))}
                        </DashboardButtonDiv>
                        {/* </MemberTab> */}
                        <MemberRight />
                       
                    </MemberDiv>
                    </div>

                    {selectedDashboard !== "overview" && (
                        <SearchBoxContainer>
                            <div style={{}} className="top-title-search-div ">
                                <form className="search-container follow-search-container">
                                    <img src={searchImage} alt="search" />
                                    <input
                                        className="input-box follow-search-box"
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setsearch(e.target.value)}
                                    />
                                </form>
                            </div>
                            <FilterBoxWrapper>
                                <Button
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0px 20px",
                                        height: "50px",
                                    }}
                                    backgroundColor="#FFF"
                                    fontSize="15px"
                                    // href="/superadmin/add-campus"
                                    // onClick={() => {
                                    //     if (!isFilterBox) {
                                    //         moveToTop();
                                    //         toggleActiveBox();
                                    //     } else {
                                    //         moveToBottom();
                                    //         toggleActiveBox();
                                    //     }
                                    // }}
                                    aria-describedby={filterAnchorId}
                                    variant="contained"
                                    onClick={handleClickFilter}
                                >
                                    <img style={{ width: "20%" }} src={filter} alt="Logo" />
                                    <Text style={{ marginLeft: "12px" }} color="#0FA76F" fontSize="15px">
                                        Filter
                                    </Text>
                                </Button>
                                {/* {isFilterBox && ( */}
                                <Popover
                                    id={filterAnchorId}
                                    open={openFilter}
                                    anchorEl={filterAnchorEl}
                                    onClose={handleCloseFilter}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    {/* <TeamLeaderFilterBox
                                        isFilterBox={isFilterBox}
                                        setFilterBox={setFilterBox}
                                        universityList={universityList}
                                        setSortValue={setSortValue}
                                        sortValue={sortValue}
                                        getAssignedLeads={getLeadData}
                                        universityValues={universityValues}
                                        setUniversityValues={
                                            setUniversityValues
                                        }
                                        moveToBottom={moveToBottom}
                                    /> */}
                                    <Container>
                                        <Div>
                                            <Title>Filter by</Title>
                                            <img
                                                onClick={() => {
                                                    handleCloseFilter();
                                                }}
                                                style={{ cursor: "pointer" }}
                                                src={require("./../../assets/images/close.svg").default}
                                                alt="icon"
                                            />
                                        </Div>
                                        <div
                                            style={{
                                                minHeight: "250px",
                                                maxHeight: "70vh",
                                                overflow: "scroll",
                                            }}
                                        >
                                            <Body borderBottom="6px solid #f2f2f2">
                                                <Heading>Name</Heading>
                                                <div
                                                    onClick={() => {
                                                        if (sortValue === "name") {
                                                            setSortValue("");
                                                        } else {
                                                            setSortValue("name");
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "10px 0 0",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <img
                                                        src={require("./../../assets/images/a-z.svg").default}
                                                        alt="icon"
                                                    />
                                                    <SubHeading
                                                        style={{
                                                            color: sortValue === "name" ? "#552E91" : "#515151",
                                                        }}
                                                    >
                                                        From A to Z
                                                    </SubHeading>
                                                    {sortValue === "name" && (
                                                        <img
                                                            src={require("./../../assets/images/tick.svg").default}
                                                            alt="icon"
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (sortValue === "-name") {
                                                            setSortValue("");
                                                        } else {
                                                            setSortValue("-name");
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "10px 0 0",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <img
                                                        src={require("./../../assets/images/z-a.svg").default}
                                                        alt="icon"
                                                    />
                                                    <SubHeading
                                                        style={{
                                                            color: sortValue === "-name" ? "#552E91" : "#515151",
                                                        }}
                                                    >
                                                        From Z to A
                                                    </SubHeading>
                                                    {sortValue === "-name" && (
                                                        <img
                                                            src={require("./../../assets/images/tick.svg").default}
                                                            alt="icon"
                                                        />
                                                    )}
                                                </div>
                                            </Body>
                                            {/* <Body borderBottom="6px solid #f2f2f2">
                                                <Heading>University</Heading>
                                                {universityList.map((data) => (
                                                    <div
                                                        onClick={() => {
                                                            setUniversityValues(data.id);
                                                            if (universityValues === data.id) {
                                                                setUniversityValues("");
                                                            }
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "10px 0 0",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <SubHeading
                                                            style={{
                                                                color:
                                                                    universityValues === data.id
                                                                        ? "#552E91"
                                                                        : "#515151",
                                                            }}
                                                        >
                                                            {data.name}
                                                        </SubHeading>
                                                        {universityValues === data.id && (
                                                            <img
                                                                src={require("./../../assets/images/tick.svg").default}
                                                                alt="icon"
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </Body> */}
                                            <Body>
                                                {/* <Heading>Source</Heading>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<SubHeading>From A to Z</SubHeading>
								<img
									src={
										require("./../../assets/images/tick.svg")
											.default
									}
									alt="icon"
								/>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									padding: "10px 0 0",
									cursor: "pointer",
								}}
							>
								<SubHeading>From Z to A</SubHeading>
								<img
									src={
										require("./../../assets/images/tick.svg")
											.default
									}
									alt="icon"
								/>
							</div> */}

                                                {/* <Button
                                                    onClick={() => {
                                                        getLeadData();
                                                        handleCloseFilter();
                                                    }}
                                                    style={{
                                                        // display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                    bacerkground="#5A2D8F"
                                                    fontSize="15px"
                                                >
                                                    <ButtonText
                                                        // style={{ marginLeft: "12px" }}
                                                        color="white"
                                                        fontSize="15px"
                                                    >
                                                        Apply Filters
                                                    </ButtonText>
                                                </Button> */}

                                                <ButtonsBody>
                                                    <ResetButton
                                                        onClick={() => {
                                                            setSortValue("");
                                                            setUniversityValues("");
                                                        }}
                                                    >
                                                        Reset
                                                    </ResetButton>
                                                    <CloseButton
                                                        onClick={() => {
                                                            handleCloseFilter();
                                                        }}
                                                    >
                                                        Close
                                                    </CloseButton>
                                                </ButtonsBody>
                                            </Body>
                                        </div>
                                    </Container>
                                </Popover>
                                {/* )} */}
                            </FilterBoxWrapper>
                        </SearchBoxContainer>
                    )}
                    {/* </SearchAndOptionsContainer> */}
                    {!isSubLoading ? (
                        selectedDashboard === "overview" ? (
                          
                            <OverViewContainer>
                                 
                                <CardRight>
                                    <DetailCard
                                        text="Target achieved"
                                        icon={require("./../../assets/images/goal.svg").default}
                                        count={overViewDetails.target_achieved }
                                        per={"%"}
                                    />
                                    <DetailCard
                                        text="Target"
                                        icon={require("./../../assets/images/new-target.svg").default}
                                        count={overViewDetails.target}
                                    />
                                    <DetailCard
                                        text="Admissions"
                                        icon={require("./../../assets/images/admission-icon.svg").default}
                                        count={overViewDetails.total_lead_admission}
                                    />
                                    <DetailCard
                                        text="Leads"
                                        icon={require("./../../assets/images/leads-icon.svg").default}
                                        count={overViewDetails.total_lead_assigned}
                                    />
                                    <DetailCard
                                        text="Introduction"
                                        icon={require("./../../assets/images/follow-icon.svg").default}
                                        count={overViewDetails.total_lead_introduction}
                                    />
                                    <DetailCard
                                        text="Meetings"
                                        icon={require("./../../assets/images/meeting.svg").default}
                                        count={overViewDetails.total_lead_meeting}
                                    />
                                    <DetailCard
                                        text="Dead"
                                        icon={require("./../../assets/images/reject.svg").default}
                                        count={overViewDetails.total_lead_dead}
                                    />
                                </CardRight>
                                {/* <GraphDiv>
						<Bar
							data={overViewData}
							id="canvas"
							options={options}
						/>
					</GraphDiv> */}
                            </OverViewContainer>
                        ) : (
                            <>
                                <TableContainer id="move-div">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid #E6EFF5",
                                            padding: "0 30px 20px",
                                        }}
                                        className="table-head"
                                    >
                                        <TD width="10%" color="#808080" fontWeight="500" fontSize="18px">
                                            ID No.
                                        </TD>
                                        <TD width="16%" color="#808080" fontWeight="500" fontSize="18px">
                                            Lead Name
                                        </TD>
                                        <TD width="14%" color="#808080" fontWeight="500" fontSize="18px">
                                            Phone
                                        </TD>
                                        <TD width="16%" color="#808080" fontWeight="500" fontSize="18px">
                                            Email
                                        </TD>
                                        <TD width="16%" color="#808080" fontWeight="500" fontSize="18px">
                                            {selectedDashboard === "meeting" ||
                                            selectedDashboard === "pre_meeting" ||
                                            selectedDashboard === "post_meeting"
                                                ? "Meeting Type"
                                                : "Lead Age"}
                                        </TD>

                                        <TD width="10%" color="#808080" fontWeight="500" fontSize="18px">
                                            {selectedDashboard === "meeting" ||
                                            selectedDashboard === "pre_meeting" ||
                                            selectedDashboard === "post_meeting"
                                                ? "Date & time"
                                                : "Source"}
                                        </TD>
                                        <TD
                                            style={
                                                {
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                    // justifyContent: "center",
                                                }
                                            }
                                            width="13%"
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="18px"
                                        >
                                            Stage
                                        </TD>
                                        <TD
                                            style={
                                                {
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                    // justifyContent: "center",
                                                }
                                            }
                                            width="13%"
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="18px"
                                        >
                                            Remarks
                                        </TD>
                                    </div>
                                    <div
                                        style={{
                                            // padding: "0px 30px",
                                            borderBottom: "1px solid #E6EFF5",
                                        }}
                                        className="table-body"
                                    >
                                        {leadData.length > 0 ? (
                                            leadData.map((item) => {
                                                return (
                                                    <FollowUpList
                                                        key={item.id}
                                                        // setAssignModal={setAssignModal}
                                                        item={item}
                                                        // status={item.status}
                                                        status={
                                                            selectedDashboard === "meeting"
                                                                ? item?.lead_data?.status
                                                                : item?.status
                                                        }
                                                        selectedDashboard={selectedDashboard}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <NoDataFound hght="200px" />
                                        )}
                                    </div>
                                </TableContainer>
                                <MainPagination
                                    paginationData={paginationData}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                />
                            </>
                        )
                    ) : (
                        <SubLoaderContainer>
                            <LoadingGif src={loadingIcon} alt="icon" />
                        </SubLoaderContainer>
                    )}
                </Wrapper>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
            {/* {isFilterBox && (
                <FilterBox
                    isFilterBox={isFilterBox}
                    setFilterBox={setFilterBox}
                    universityList={universityList}
                    setSortValue={setSortValue}
                    sortValue={sortValue}
                    getAssignedLeads={getLeadData}
                    universityValues={universityValues}
                    setUniversityValues={setUniversityValues}
                />
            )} */}
        </>
    );
}

export default FollowUp;

const TeamDetailBox = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 20px;
    margin-top: 10px; */

    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-top: 15px;
    padding: 16px;

    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(200px, 1fr);
    overflow-x: auto;
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Left = styled.div``;
const H1 = styled.h1`
    font-size: 28px;
    font-family: "Inter";
    font-weight: 500;
    @media all and (max-width: 980px){
        font-size: 20px;
}
@media all and (max-width: 360px){
    font-size: 16px;
}
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;
const SearchDiv = styled.div`
    /* margin-right: 30px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #aea7fd;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;

const Button = styled.div`
    background: ${(props) => (props.background ? props.background : "#d6d3ec")};
    border: 1px solid;
    border-color: ${(props) => (props.border ? props.border : "#d6d3ec")};
    border-radius: 8px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 25px;
    margin-left: 20px;
`;
const ButtonText = styled.span`
    /* color: #fff; */
    font-size: 16px; // display: flex;
    // justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : "#3ccb7f")};
    font-weight: 500;
    /* margin-left: 10px; */
`;
const Plus = styled(ButtonText)`
    font-size: 22px; // display: flex;
`;

const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    /* align-items: center; */
    margin-bottom: 30px;
    @media all and (max-width: 980px){
        grid-template-columns: 1fr 1fr 1fr;
}
@media all and (max-width: 768px){
     grid-template-columns: 1fr 1fr;
}
    @media all and (max-width: 640px){
        grid-template-columns: 1fr;
}
`;
const CardLeft = styled.div`
    background: ${(props) => (props.bgColor ? "#fff" : "#ffffff")};
    border: 1px solid;
    border-color: ${(props) => (props.selected ? "#0FA76F" : "#fff")};
    box-sizing: border-box;
    /* box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.09); */
    border-radius: 12px;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: 0.5s;
    /* transform: ${(props) => (props.selected ? "translateY(-2px) scale(1.005) translateZ(0)" : null)}; */

    &:hover {
        transform: translateY(-2px) scale(1.005) translateZ(0);
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-transform: translateY(-2px) scale(1.005) translateZ(0);
    }
`;

const ProfileDiv = styled.div`
    display: flex;
    align-items: center;
`;
const ProfileImg = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 50%;
`;
const ProfileDetails = styled.div`
    margin-left: 10px;
`;
const ProfileTop = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
`;
const Rank = styled.span`
    color: #4caa02;
    font-size: 16px;
    background: #eaffda;
    border-radius: 50px;
    padding: 6px;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const RankText = styled.span`
    color: #999999;
    font-size: 16px;
`;
const ProfileBottom = styled.div``;
const Name = styled.h4`
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    @media all and (max-width: 360px){
        font-size: 14px;
}
`;

const SearchAndOptionsContainer = styled.div`
    background: #fff;
    border-radius: 12px;
`;
const MemberDiv = styled.div`
    background: #fff;
    padding: 20px;
    /* border-radius: 12px 12px 0 0; */
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  @media all and (max-width: 1380px){
    min-width: 1180px;
}
`;
const MemberLeft = styled.div``;

const MemberTab = styled.div``;
const MemberRight = styled.div``;
const SearchBoxContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* position: relative; */
    /* background: #fff; */
    /* border-radius: 12px; */

    /* border-radius: 0 0 12px 12px; */
    /* padding: 0 20px 20px 20px; */
    .follow-search-box {
        /* background: #fff; */
    }
    .follow-search-container {
        /* background: #fff; */
    }
`;
const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    width: fit-content;
    background: #fff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px;
    transition: 0.5s;
`;
const DashboardButton = styled.div`
    background-color: ${(props) => (props.background ? props.background : "#fff")};
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
        ${ButtonName} {
            transform: translateY(-2px) scale(1.005) translateZ(0);
            /* box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07); */
        }
    }
`;
const TableContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #fff;
    padding-top: 20px;
    border-radius: 8px;
`;
const OverViewContainer = styled.div`
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
`;
const CardRight = styled.div`
    /* width: 75%; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    @media all and (max-width: 1280px){
        grid-template-columns: 1fr 1fr 1fr;
}
@media all and (max-width: 480px){
    grid-template-columns: 1fr ;
}

    /* background: #ffffff;
    border-radius: 12px;
    margin-top: 10px;

    display: grid;
    grid-gap: 16px;
    padding: 16px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(180px, 1fr);
    overflow-x: auto; */
`;

const SmallCard = styled.div`
    background: ${(props) => (props.background ? props.background : "#fff")};
    padding: 20px;
    border-radius: 8px;
    /* width: 31%; */
`;
const SmallTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;
const SmallBottom = styled.div``;
const Count = styled.h3`
    font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
    color: #3ccb7f;
    margin-bottom: 5px;
    font-weight: 500;
     @media all and (max-width: 980px) {
    font-size: ${(props) => (props.fontSize ? "16px" : "14px")}; /* Fallback to default */
  }
`;
const ContentText = styled.p`
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    color: #000;
  @media all and (max-width: 980px) {
    font-size: ${(props) => (props.fontSize ? "16px" : "14px")}; /* Fallback to default */
  }
`;
const GraphDiv = styled.div`
    margin-top: 20px;
    height: 300px;
`;
const Img = styled.img`
    height: 25px;
    width: 25px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
const SubLoaderContainer = styled.div`
    height: calc(100vh - 500px);
    /* height: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FilterBoxWrapper = styled.div`
    position: relative;
`;

const Container = styled.div`
    // height: 130px;
    width: 300px;
    max-height: 50vh;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 20px 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : null)};
`;
const Heading = styled.h4`
    font-size: 16px;
    color: #515151;
    font-weight: 600;
`;
const SubHeading = styled.h4`
    font-size: 13px;
    color: "#515151";
    margin: 0 10px;
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : null)};
`;
const ResetButton = styled.div`
    width: 60%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #0FA76F;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #0FA76F;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
