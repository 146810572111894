import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Bar, Pie } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TD from "../../components/TD";

function PendingFeeTable({ width, image, title, background }) {
	return (
		<MainContainer width={width}>
			<Div>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="10%"
					style={{ marginLeft: "20px" }}
				>
					ID No.
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Student Name
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					University
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Date Of Joining
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Course Fee
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="20%"
					// style={{ background: "red" }}
				>
					Status
				</TH>
			</Div>
			<Body>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="10%"
					style={{ marginLeft: "20px" }}
				>
					50
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					John
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					SSM
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					12/12/2020
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					20,000
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
				>
					<StatusDiv>
						<Status>Pending</Status>
					</StatusDiv>
				</TH>
			</Body>
			<Body>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="10%"
					style={{ marginLeft: "20px" }}
				>
					50
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					John
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					SSM
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					12/12/2020
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
					// style={{ background: "red" }}
				>
					20,000
				</TH>
				<TH
					color="#7B6294"
					fontSize="15px"
					height="60px"
					fontWeight="500"
					width="20%"
				>
					<StatusDiv>
						<Status>Pending</Status>
					</StatusDiv>
				</TH>
			</Body>
		</MainContainer>
	);
}

export default PendingFeeTable;

const MainContainer = styled.div`
	background-color: #fff;
	// width: ${(props) => (props.width ? props.width : "100%")};
	border: 1px solid #e7e7e7;
	overflow: scroll;
	border-radius: 10px;
	margin-top: 15px;
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f1f8fe;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #cce7ff;
`;

const TH = styled.div`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: left;
	width: ${(props) => (props.width ? props.width : "15%")};
	// padding: 10px 0;
	height: ${(props) => (props.height ? props.height : "60px")};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #e7e7e7;
`;
const StatusDiv = styled.div`
	background-color: #ebe7ef;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 50%;
`;

const Status = styled.h4`
	font-size: 15px;
	font-weight: 500;
`;
