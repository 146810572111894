import React, { useState } from "react";
import "./style.css";
import styled from "styled-components";

const CarBrand = ({ data }) => {
	const [teamMember, setTeamMember] = useState([
		{
			id: 1,
			name: "John",
			leader: true,
		},
		{
			id: 2,
			name: "John",
			leader: false,
		},
		{
			id: 3,
			name: "John",
			leader: false,
		},
		{
			id: 4,
			name: "John",
			leader: false,
		},
		{
			id: 5,
			name: "John",
			leader: false,
		},
		{
			id: 6,
			name: "John",
			leader: false,
		},
		// {
		// 	id: 7,
		// 	name: "John",
		// 	leader: false,
		// },
		// {
		// 	id: 8,
		// 	name: "John",
		// 	leader: false,
		// },
	]);
	return (
		<MainContainer>
			{data.map((item, index) => (
				<Div>
					<Text>{item.name}</Text>
					{item.leader && <LeaderText>Leader</LeaderText>}
				</Div>
			))}
		</MainContainer>
	);
};
export default CarBrand;

const MainContainer = styled.div`
	display: grid;
	margin-bottom: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 15px;
	width: 100%;
`;
const Div = styled.div`
	background-color: #fff;
	height: 50px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	padding: 0 15px;
	// margin-bottom: 15px;
	justify-content: space-between;
`;
const Text = styled.h4`
	color: #000;
	font-weight: 600;
`;
const LeaderText = styled.h4`
	color: #c3b66d;
	font-weight: 400;
`;
