import "./../selectInput/style.css";
import Text from "../Text";

import styled from "styled-components";
function SelectInput({
    name,
    placeholder,
    type,
    options,
    onChange,
    selectValue,
    selectName,
    value,
    isError,
    errorMessage,
    fieldName,
    defaultValue,
    disabled,
}) {
    return (
        <div className="type">
            <Text color="#0FA76F" fontSize="15px">
                {name}
            </Text>

            {/* <input type={type} list="search" placeholder={placeholder} /> */}
            <select
            className="add-input"
                disabled={disabled}
                type={type}
                list="search"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    border:
                        isError === true &&
                        name === fieldName &&
                        "1px solid  red",
                }}
            >
                <option value={defaultValue}>{defaultValue}</option>
                {options?.map((option) => {
                    return (
                        <option
                            value={
                                selectValue ? option[selectValue] : option.value
                            }
                        >
                            {selectName ? option[selectName] : option.name}
                        </option>
                    );
                })}
            </select>
            {isError === true && name === fieldName && (
                <ErrorText>{errorMessage}</ErrorText>
            )}

            {/* <datalist id="search"> */}

            {/* </datalist> */}
        </div>
    );
}

const ErrorText = styled.p`
    color: red;
`;

export default SelectInput;
