import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Bar, Pie } from "react-chartjs-2";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Card from "./../../components/salesPerson/Card";
import style from "./style.css";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import moment from "moment";

//svg
import Student from "./../../assets/images/student.svg";
import Network from "./../../assets/images/online-learning.svg";
import Like from "./../../assets/images/like.svg";
import Uneducated from "./../../assets/images/uneducated.svg";
import Pending from "./../../assets/images/pending.svg";
import Switch from "./../../assets/images/switch.svg";
import Followers from "./../../assets/images/followers.svg";
import Memory from "./../../assets/images/memory.svg";
import FollowupTable from "./FollowupTable";

function LeadDashboard({ data, isLoading }) {
	// const [data, setData] = useState({});
	const { state } = useContext(Context);
	// const [isLoading, setLoading] = useState(true);
	const [followupToday, setFollowupToday] = useState([]);
	const [todayDate, setTodayDate] = useState("");
	const [followupMeeting, setFollowupMeeting] = useState([]);

	const today = new Date();
	useEffect(() => {
		setTodayDate(moment(today).format("YYYY-MM-DD"));
	}, [todayDate]);

	// const getSalesPerson = () => {
	// 	setLoading(true);
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get("/general/sales-person-dashboard/", {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setLoading(false);
	// 				setData(data);
	// 			} else {
	// 				setLoading(false);
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	// ?followup_date=${todayDate}

	const todayFollowup = () => {
		if (todayDate !== "") {
			let accessToken = state.user_details.access_token;
			baseConfig
				.get(`/leads/lead/?followup_date=${todayDate}`, {
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				})
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setFollowupToday(data.data);
					} else {
					}
				})
				.catch((error) => {});
		}
	};

	const todayMeeting = () => {
		if (todayDate !== "") {
			let accessToken = state.user_details.access_token;
			baseConfig
				.get(
					`/leads/lead/?followup_date=${todayDate}&followup_direct=true`,
					{
						headers: {
							Authorization: "Bearer " + accessToken,
						},
					}
				)
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setFollowupMeeting(data.data);
					} else {
					}
				})
				.catch((error) => {});
		}
	};

	useEffect(() => {
		// getSalesPerson();
		todayFollowup();
		todayMeeting();
	}, [todayDate]);

	return (
		<>
			{isLoading === false ? (
				<MainContainer>
					<TargetContainer>
						{/* <Title fontSize="35px">Target Status</Title> */}
						<SmallContainer>
							<SmallCard>
								<SmallTop>
									<Count>{data?.target_achieved}%</Count>
									<IconDiv>
										<RankIcon
											src={
												require("./../../assets/images/target.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
								</SmallTop>
								<SmallBottom>
									<ContentText>Target Achieved</ContentText>
								</SmallBottom>
							</SmallCard>

							<SmallCard>
								<SmallTop>
									<Count>INR {data?.target}</Count>
									<IconDiv>
										<RankIcon
											src={
												require("./../../assets/images/follow-icon.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
								</SmallTop>
								<SmallBottom>
									<ContentText>Target</ContentText>
								</SmallBottom>
							</SmallCard>

							<SmallCard>
								<SmallTop>
									<Count>INR {data?.revanue_generated}</Count>
									<IconDiv>
										<RankIcon
											src={
												require("./../../assets/images/follow-icon.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
								</SmallTop>
								<SmallBottom>
									<ContentText>Revenue Generated</ContentText>
								</SmallBottom>
							</SmallCard>

							<SmallCard>
								<SmallTop>
									<Count>
										{data?.stage_counts?.admission}
									</Count>
									<IconDiv>
										<RankIcon
											src={
												require("./../../assets/images/follow-icon.svg")
													.default
											}
											alt="icon"
										/>
									</IconDiv>
								</SmallTop>
								<SmallBottom>
									<ContentText>Admissions</ContentText>
								</SmallBottom>
							</SmallCard>
						</SmallContainer>
					</TargetContainer>

					<Middle>
						<Title fontSize="35px" className="statusheader">
							Latest Status
						</Title>
						<MiddleContainer>
							<MiddleCard>
								<Box>
									<ImageDiv background="#e3f6fe">
										<Image src={Like} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data.stage_counts?.introduction}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Introduction
								</Text>
							</MiddleCard>
							<MiddleCard>
								<Box>
									<ImageDiv background="#fffae6">
										<Image src={Uneducated} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data?.stage_counts?.meeting}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Meeting
								</Text>
							</MiddleCard>
							<MiddleCard>
								<Box>
									<ImageDiv background="#e5fadb">
										<Image src={Pending} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data?.stage_counts?.admission}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Admission
								</Text>
							</MiddleCard>
							<MiddleCard>
								<Box>
									<ImageDiv background="#f9edfe">
										<Image src={Switch} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data?.stage_counts?.contact_in_future}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Contact in future
								</Text>
							</MiddleCard>
							<MiddleCard>
								<Box>
									<ImageDiv background="#ffe8e3">
										<Image src={Followers} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data?.stage_counts?.invalid}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Invalid
								</Text>
							</MiddleCard>
							<MiddleCard>
								<Box>
									<ImageDiv background="#eef0fc">
										<Image src={Memory} />
									</ImageDiv>
									<Title fontSize="35px" color="#000">
										{data?.stage_counts?.dead}
									</Title>
								</Box>
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#8C8888"
								>
									Dead
								</Text>
							</MiddleCard>
						</MiddleContainer>
					</Middle>
					<TopContainer>
						<Card
							width="auto"
							image={Student}
							title="Total Assigned Leads"
							background="#e7fad9"
							data={data.assigned_leads}
						/>
						<Card
							width="auto"
							image={Network}
							title="Total Leads From Network"
							background="#FFE8E2"
							data={data.network_leads}
						/>
					</TopContainer>

					{followupToday.length > 0 ||
						(followupMeeting.length > 0 && (
							<Title fontSize="35px">Follow ups</Title>
						))}
					{/* <Bottom>
            {followupToday.length > 0 && (
              <BottomTable>
                <Title fontSize="25px">Follow up Today</Title>

                <FollowupTable data={followupToday} />
              </BottomTable>
            )}
            {followupMeeting.length > 0 && (
              <BottomTable>
                <Title fontSize="25px">Follow up Today</Title>

                <FollowupTable data={followupMeeting} />
              </BottomTable>
            )}
          </Bottom> */}
				</MainContainer>
			) : (
				<Div>
					<LoadingGif
						src={
							require("./../../assets/images/loading-buffering.gif")
								.default
						}
						alt="Loading"
					/>
				</Div>
			)}
		</>
	);
}

export default LeadDashboard;

const MainContainer = styled.div`
	margin-top: 20px;
	@media all and (max-width: 360px) {
		margin-left: 10px;
	}
`;

const TopContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	@media all and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;
const TargetContainer = styled.div`
	margin-top: 25px;
	margin-bottom: 25px;
`;
const Middle = styled.div`
	margin-top: 3%;
	margin-bottom: 3%;
`;
const MiddleContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-top: 15px;
	@media all and (max-width: 980px) {
		grid-template-columns: 1fr 1fr 1fr;
		margin-bottom: 50px;
	}
	@media all and (max-width: 640px) {
		grid-template-columns: 1fr 1fr;
	}
	@media all and (max-width: 480px) {
		grid-template-columns: 1fr;
	}
`;
const MiddleCard = styled.div`
	background: #fff;
	// width: 12%;
	padding: 15px;
	border-radius: 10px;
	@media all and (max-width: 1080px) {
		padding: 10px;
	}
	@media all and (max-width: 980px) {
		padding: 18px;
	}
	@media all and (max-width: 360px) {
		width: 198px;
	}
`;
const Box = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;

const ImageDiv = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	display: inline-block;
	border-radius: 50px;
	height: 60px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media all and (max-width: 1080px) {
		width: 50px;
		height: 50px;
	}
`;
const Image = styled.img``;
const Bottom = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	margin-top: 20px;
`;
const BottomTable = styled.div``;

const ArrowDiv = styled.div`
	background: #fff;
	display: flex;
	align-items: center;
	width: 30%;
	justify-content: center;
	border-radius: 10px;
	height: 50px;
	margin-top: 10px;
`;
const Arrow = styled.h4`
	color: #522e92;
	font-size: 15px;
	font-weight: 500;
	margin-right: 15px;
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;

const SmallContainer = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap; */
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	/* width: 40%; */
	margin-top: 15px;
	@media all and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media all and (max-width: 480px) {
		grid-template-columns: 1fr;
	}
`;
const SmallCard = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	padding: 20px;
	border-radius: 8px;
	@media all and (max-width: 360px) {
		width: 198px;
	}
	/* width: 31%; */
`;
const SmallTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
`;

const Count = styled.h3`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
	color: #0fa76f;
	margin-bottom: 5px;
	font-weight: 500;
	@media all and (max-width: 980px) {
		font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	}
`;
const IconDiv = styled.div`
	height: 25px;
	width: 25px;
	background: #ebf5ff;
	border-radius: 50%;
	padding: 10px;
	@media all and (max-width: 980px) {
		height: 20px;
		width: 20px;
	}
`;
const RankIcon = styled.img`
	/* margin-bottom: 20px; */
	height: 100%;
	width: 100%;
`;
const SmallBottom = styled.div``;
const ContentText = styled.p`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
	color: #000;
`;

// const Title = styled.div``;
