import Title from "../../components/Title";
import React, { useEffect, useState, useContext } from "react";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Pagination from "../../components/pagination/pagination";
import LeadListItem from "../../components/leadmanager/leadListItem";
import LeadListData from "../../components/leadmanager/leadListData";
import { ListSalesPersonService } from "../../service/salesPerson";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import SalesPersonFilter from "./SalesPersonFilter";
import styled from "styled-components";
import MainPagination from "../../components/includes/MainPagination";

function AddSalesPerson() {
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [isFilterBox, setFilterBox] = useState(false);
    const [sortValue, setSortValue] = useState();
    const [sortUrl, setSortUrl] = useState("");
    const [selectedLeadAssign, setSelectedLeadAssign] = useState();
    const [selectedLeadConverted, setSelectedLeadConverted] = useState();
    const [leadAssignUrl, setLeadAssignUrl] = useState("");
    const [leadConvertedUrl, setLeadConvertedUrl] = useState("");
    const [successUrl, setSuccessUrl] = useState("");
    const [selectedPercentage, setSelectedPercentage] = useState();
    const [paginationData, setPaginationData] = useState({});
    const { state } = useContext(Context);

    const [salesPerson, setSalesPersons] = useState([]);

    const getAssignedLeads = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(
                // `/sales-persons/sales-persons/?${sortUrl}${leadAssignUrl}${leadConvertedUrl}${successUrl}`,
                `/sales-persons/sales-persons/`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                    params: {
                        page: currentPage,
                        ordering: sortValue,
                        lead_assigned: selectedLeadAssign,
                        succuss_rate: selectedPercentage,
                        lead_converted:selectedLeadConverted,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPersons(data.data);
                    setLoading(false);
                    setPaginationData(data.paginator);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getAssignedLeads();
    }, [currentPage]);

    function disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }
    function enableScrolling() {
        window.onscroll = function () {};
    }

    useEffect(() => {
        if (isFilterBox === true) {
            disableScrolling();
        }
        if (isFilterBox === false) {
            enableScrolling();
        }
    }, [isFilterBox]);

    // useEffect(() => {
    // 	if (sortValue) {
    // 		setSortUrl(`&ordering=${sortValue}`);
    // 	} else {
    // 		setSortUrl("");
    // 	}

    // 	if (selectedLeadAssign) {
    // 		setLeadAssignUrl(`&lead_assigned=${selectedLeadAssign}`);
    // 	} else {
    // 		setLeadAssignUrl("");
    // 	}

    // 	if (selectedLeadConverted) {
    // 		setLeadConvertedUrl(`&ordering=${selectedLeadConverted}`);
    // 	} else {
    // 		setLeadConvertedUrl("");
    // 	}

    // 	if (selectedPercentage) {
    // 		setSuccessUrl(`&succuss_rate=${selectedPercentage}`);
    // 	} else {
    // 		setSuccessUrl("");
    // 	}
    // }, [
    // 	sortValue,
    // 	selectedLeadAssign,
    // 	selectedLeadConverted,
    // 	selectedPercentage,
    // ]);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div>
                    <Title className="head">Admission Consultants</Title>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}
                >
                    {/* <Text
						style={{ marginTop: "10px", margin: "auto 30px" }}
						fontSize="15px"
					>
						Show
					</Text>
					<div className="number">
						<Text fontSize="18px" fontWeight="400" color="#522E92">
							{count}
						</Text>
						<div>
							<img
								onClick={() => {
									setCount(count + 1);
								}}
								src={add}
								alt="incerement"
							/>
							<img
								onClick={() => {
									if (count > 1) {
										setCount(count - 1);
									}
								}}
								src={add}
								alt="decrement"
								className="decrement"
							/>
						</div>
					</div>{" "}
					<Text style={{ margin: "auto 30px" }} fontSize="15px">
						Results
					</Text> */}
                    <Button
                    className="admission-consultant"
                        // href="/superadmin/add-lead-manager/"
                        onClick={() => {
                            setFilterBox(true);
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        bacerkgroundColor="#5A2D8F"
                        fontSize="15px"
                    >
                        <img  class ="filter-icon" style={{ width: "20px" }} src={filter} alt="Logo" />
                        <Text className="filter" style={{ marginLeft: "12px" }} color="white" fontSize="15px">
                            Filter
                        </Text>
                    </Button>
                </div>
            </div>

            <div class="tableScroll" style={{ marginBottom: "30px"  }}>
                <Table margin="0" borderRadius="30px" padding="30px 0">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #E6EFF5",
                            padding: "0 30px 20px",
                        }}
                        className="table-head"
                    >
                        <TD width="10%" color="#808080" fontWeight="500" fontSize="15px">
                            ID No.
                        </TD>
                        <TD width="20%" color="#808080" fontWeight="500" fontSize="15px">
                            Admission Consultant
                        </TD>
                        <TD width="20%" color="#808080" fontWeight="500" fontSize="15px">
                            Email
                        </TD>
                        <TD width="14%" color="#808080" fontWeight="500" fontSize="15px">
                            Phone
                        </TD>
                        <TD width="10%" color="#808080" fontWeight="500" fontSize="15px">
                            Assigned Leads
                        </TD>
                        <TD width="11%" color="#808080" fontWeight="500" fontSize="15px">
                            Converted Leads
                        </TD>

                        <TD width="10%" color="#808080" fontWeight="500" fontSize="15px">
                            Success Rate{" "}
                        </TD>
                        {/* <div style={{ width: "5%" }}></div> */}
                    </div>
                    {isLoading === false ? (
                        <div style={{ padding: "0 30px" }} className="table-body">
                            {salesPerson.map((item) => {
                                return (
                                    <LeadListItem
                                        ID={item.admin_id}
                                        name={item.name}
                                        email={item.user_data?.email}
                                        phone={item.phone}
                                        assigned={item.total_lead_assigned}
                                        converted={item.total_lead_admission}
                                        source={item.source}
                                        sucsess={item.success_rate}
                                        key={item.id}
                                        sourceImage={item.sourceImage}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <Div>
                            <LoadingGif
                                src={require("./../../assets/images/loading-buffering.gif").default}
                                alt="Loading"
                            />
                        </Div>
                    )}
                </Table>
                {isFilterBox === true && (
                    <SalesPersonFilter
                        isFilterBox={isFilterBox}
                        setFilterBox={setFilterBox}
                        setSortValue={setSortValue}
                        sortValue={sortValue}
                        getAssignedLeads={getAssignedLeads}
                        setSelectedLeadAssign={setSelectedLeadAssign}
                        selectedLeadAssign={selectedLeadAssign}
                        setSelectedLeadConverted={setSelectedLeadConverted}
                        selectedLeadConverted={selectedLeadConverted}
                        selectedPercentage={selectedPercentage}
                        setSelectedPercentage={setSelectedPercentage}
                    />
                )}
                <div style={{ marginTop: "20px" }}>
                    <MainPagination
                        paginationData={paginationData}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </Wrapper>
    );
}

export default AddSalesPerson;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
