import React from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import moment from "moment";

function DashboardListItem({
	studentId,
	date,
	name,
	course,
	specialisation,
	batch,
	data,
}) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				borderBottom: "1px solid #E6EFF5",
				paddingBottom: "20px",
				marginBottom: "20px",
				alignItems: "center",
			}}
		>
			<TD color="#505887" fontSize="16px" width="8%">
				#{data.lead_id}
			</TD>
			<TD color="#505887" fontSize="16px">
				{moment(data.date_added).format("DD MMMM yyyy, hh:mm A")}
			</TD>
			<TD color="#505887" fontSize="16px">
				{data.name}
			</TD>
			<TD color="#505887" fontSize="16px">
				{data.course_data}
			</TD>
			<TD color="#505887" fontSize="16px">
				{data.qualification}
			</TD>
			<TD color="#505887" fontSize="16px">
				{data.phone}
			</TD>
			<TD width="5%">
				<a
					onClick={(e) => {
						e.preventDefault();
					}}
					href
				>
					<img src={Icon} style={{ display: "block" }} alt="Icon" />
				</a>
			</TD>
		</div>
	);
}

export default DashboardListItem;
