import React, { useEffect, useState, useContext } from "react";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import addbutton from "../../assets/images/addbutton.svg";
import add from "../../assets/images/add.svg";
import filter from "../../assets/images/filter.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import leadListData from "../../components/leadGeneratorAdmin/leadListData";
import LeadListItem from "../../components/leadGeneratorAdmin/leadListItem";
import Pagination from "../../components/pagination/pagination";
import moment from "moment";
import styled from "styled-components";
import loadingGif from "../../assets/lottie/loading.gif";

import {
    AddSourceService,
    ListLeadsService,
    ListSourceService,
} from "../../service/leads";
import { useHistory } from "react-router-dom";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import MainPagination from "../../components/includes/MainPagination";
import SalesPerson from ".";
import AssignedLeadListItem from "../salesPerson/AssignedLeadListItem";
import { Popover } from "@mui/material";

function Leads() {
    const [leadProgram, setLeadProgram] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({});
    const { state } = useContext(Context);
    const [leads, setLeads] = useState([]);
    const [selectedLead, setSelectedLead] = useState();
    const [deleteId, setDeleteId] = useState("");
    const [isModal, setModal] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [search, setSearch] = useState();
    const [courses, setCourses] = useState([]);

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    // const minFromDateValue = moment(toDate).subtract(1, "month").calendar();
    // const maxToDateValue = moment(fromDate).add(1, "month").calendar();
    // new Date().toLocaleDateString("en-CA")

    // For Filter Purpose
    const [universityList, setUniversityList] = useState([]);
    const [sortValue, setSortValue] = useState("");
    const [universityValues, setUniversityValues] = useState("");
    const [dateOrder, setDateOrder] = useState("");
    const [courseValues, setCourseValues] = useState("");

    const dateOrders = [
        { name: "New to old", value: "new" },
        { name: "Old to new", value: "old" },
    ];
    // Filter popover onClick states and functions
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter

    const getData1 = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    status: "new_leads",
                    start_date: fromDate,
                    end_date: toDate,
                    search: search,
                    ordering: sortValue,
                    university: universityValues,
                    date_order: dateOrder,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeads(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    const getLeadProgram = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/leads/programs/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLeadProgram(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getLeadProgram();
	}, []);
    

    useEffect(() => {
        if (toDate) {
            getData1();
        }
    }, [fromDate, toDate]);
    

    return (
        <Wrapper className="leads">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Title fontSize="30px" fontWeight="700">
                    New Leads
                </Title>
                <CustomDateContainer>
                    <EmptyDiv></EmptyDiv>
                    <CustomDateFields>
                        <DateFieldBox>
                            <DateFieldText>From</DateFieldText>
                            <DateInput
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                    // setError(false);
                                }}
                                type="date"
                                name="From"
                                // min={
                                //   toDate ? moment(minFromDateValue).format("yyyy-MM-DD") : null
                                // }
                                max={
                                    toDate
                                        ? toDate
                                        : new Date().toLocaleDateString("en-CA")
                                }
                                value={fromDate}
                            />
                        </DateFieldBox>
                        <DateFieldBox>
                            <DateFieldText>To</DateFieldText>
                            <DateInput
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                                type="date"
                                name="To"
                                min={fromDate}
                                // max={moment(maxToDateValue).format("yyyy-MM-DD")}
                                max={new Date().toLocaleDateString("en-CA")}
                                disabled={fromDate ? false : true}
                                value={toDate}
                            />
                        </DateFieldBox>
                        {fromDate &&
                            toDate &&
                            fromDate !== "dd/mm/yyyy" &&
                            toDate !== "dd/mm/yyyy" && (
                                <ClearFilterText
                                    onClick={() => {
                                        setFromDate("dd/mm/yyyy");
                                        setToDate("dd/mm/yyyy");
                                        // setFromDate("");
                                        // setToDate("");
                                        getData1();
                                    }}
                                >
                                    Clear Filter
                                </ClearFilterText>
                            )}
                    </CustomDateFields>
                </CustomDateContainer>

                {/* <div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: "45%",
					}}
				>
					<Text fontSize="16px" fontWeight="500" color="#7B6294">
						Show
					</Text>
					<div className="number">
						<Text fontSize="18px" fontWeight="400" color="#522E92">
							{count}
						</Text>
						<div>
							<img
								onClick={() => {
									setCount(count + 1);
								}}
								src={add}
								alt="incerement"
							/>
							<img
								onClick={() => {
									if (count > 1) {
										setCount(count - 1);
									}
								}}
								src={add}
								alt="decrement"
								className="decrement"
							/>
						</div>
					</div>
					<Text fontSize="16px" fontWeight="500" color="#7B6294">
						Results
					</Text>

					<Button
						style={{
							display: "flex",
							alignItems: "center",
						}}
						backgroundColor="#FFF"
						fontSize="15px"
						// href="/superadmin/add-campus"
						padding="10px 50px"
					>
						<img src={filter} alt="Logo" />
						<Text
							style={{ marginLeft: "12px" }}
							color="#5A2D8F"
							fontSize="15px"
						>
							Filter
						</Text>
					</Button>
				</div> */}
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                }}
            >
                <SearchDiv>
                    <Form className="search-container">
                        <SearchIcon
                            src={
                                require("./../../assets/images/search.svg")
                                    .default
                            }
                        />
                        <SearchField
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for something"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Form>
                </SearchDiv>
                <FilterButtonWrapper>
                    <Button
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 20px",
                            height: "50px",
                            marginLeft: "10px",
                        }}
                        backgroundColor="#FFF"
                        fontSize="15px"
                        aria-describedby={filterAnchorId}
                        variant="contained"
                        // onClick={handleClickFilter}
                        onClick={handleClickFilter}
                        // href="/superadmin/add-campus"
                    >
                        <img
                            style={{
                                width: "18px",
                                filter: "brightness(0.5)",
                            }}
                            src={filter}
                            alt="Logo"
                        />
                        <Text
                            style={{
                                marginLeft: "12px",
                                fontWeight: "500",
                            }}
                            color="#0fa76f"
                            fontSize="16px"
                        >
                            Filter
                        </Text>
                    </Button>
                    <Popover
                        id={filterAnchorId}
                        open={openFilter}
                        anchorEl={filterAnchorEl}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Container>
                            <Div>
                                <Title fontSize="20px">Filter by</Title>
                                <img
                                    style={{
                                        cursor: "pointer",
                                        width: "16px",
                                    }}
                                    onClick={handleCloseFilter}
                                    src={
                                        require("./../../assets/images/close.svg")
                                            .default
                                    }
                                    alt="icon"
                                />
                            </Div>
                            <div
                                style={{
                                    minHeight: "250px",
                                    maxHeight: "70vh",
                                    overflow: "scroll",
                                }}
                            >
                                <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>Name</Heading>
                                    <div
                                        onClick={() => {
                                            if (sortValue === "name") {
                                                setSortValue("");
                                            } else {
                                                setSortValue("name");
                                            }
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px 0 0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={
                                                require("./../../assets/images/a-z.svg")
                                                    .default
                                            }
                                            alt="icon"
                                        />
                                        <SubHeading
                                            style={{
                                                color:
                                                    sortValue === "name"
                                                        ? "#0fa76f"
                                                        : "#515151",
                                            }}
                                        >
                                            From A to Z
                                        </SubHeading>
                                        {sortValue === "name" && (
                                            <img
                                                src={
                                                    require("./../../assets/images/tick.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                        )}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (sortValue === "-name") {
                                                setSortValue("");
                                            } else {
                                                setSortValue("-name");
                                            }
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "10px 0 0",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={
                                                require("./../../assets/images/z-a.svg")
                                                    .default
                                            }
                                            alt="icon"
                                        />
                                        <SubHeading
                                            style={{
                                                color:
                                                    sortValue === "-name"
                                                        ? "#0fa76f"
                                                        : "#515151",
                                            }}
                                        >
                                            From Z to A
                                        </SubHeading>
                                        {sortValue === "-name" && (
                                            <img
                                                src={
                                                    require("./../../assets/images/tick.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                        )}
                                    </div>
                                </Body>
                                <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>Date</Heading>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {dateOrders.map((item) => (
                                            <div
                                                onClick={() => {
                                                    setDateOrder(item.value);
                                                    if (
                                                        dateOrder === item.value
                                                    ) {
                                                        setDateOrder("");
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px 0 0",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {/* <img
                                                    src={
                                                        require("./../../assets/images/a-z.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                /> */}
                                                <SubHeading
                                                    style={{
                                                        color:
                                                            dateOrder ===
                                                            item.value
                                                                ? "#0fa76f"
                                                                : "#515151",
                                                    }}
                                                >
                                                    {item.name}
                                                </SubHeading>
                                                {dateOrder === item.value && (
                                                    <img
                                                        src={
                                                            require("./../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="icon"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Body>
                                {/* <Body borderBottom="6px solid #f2f2f2">
                                    <Heading>University</Heading>
                                    {universities.map((data) => (
                                        <div
                                            onClick={() => {
                                                setUniversityValues(data.id);
                                                if (
                                                    universityValues === data.id
                                                ) {
                                                    setUniversityValues("");
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "10px 0 0",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <SubHeading
                                                style={{
                                                    color:
                                                        universityValues ===
                                                        data.id
                                                            ? "#552E91"
                                                            : "#515151",
                                                }}
                                            >
                                                {data.name}
                                            </SubHeading>
                                            {universityValues === data.id && (
                                                <img
                                                    src={
                                                        require("./../../assets/images/tick.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Body>
                                {universityValues && courses && (
                                    <Body borderBottom="6px solid #f2f2f2">
                                        <Heading>Course</Heading>
                                        {courses.map((data) => (
                                            <div
                                                onClick={() => {
                                                    setCourseValues(data.id);
                                                    if (
                                                        courseValues === data.id
                                                    ) {
                                                        setCourseValues("");
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px 0 0",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <SubHeading
                                                    style={{
                                                        color:
                                                            courseValues ===
                                                            data.id
                                                                ? "#552E91"
                                                                : "#515151",
                                                    }}
                                                >
                                                    {data.name}
                                                </SubHeading>
                                                {courseValues === data.id && (
                                                    <img
                                                        src={
                                                            require("./../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="icon"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </Body>
                                )} */}
                                <ButtonsBody>
                                    <ResetButton
                                        onClick={() => {
                                            setSortValue("");
                                            setUniversityValues("");
                                            setDateOrder("");
                                            setCourseValues("");

                                            // handleCloseFilter();
                                        }}
                                    >
                                        Reset
                                    </ResetButton>
                                    <CloseButton
                                        onClick={() => {
                                            handleCloseFilter();
                                        }}
                                    >
                                        Close
                                    </CloseButton>
                                </ButtonsBody>
                            </div>
                        </Container>
                    </Popover>
                </FilterButtonWrapper>
            </div>
            {!isLoading ? (
                <>
                    {leads.length > 0 ? (
                        <>
                            {/* <Text
                        fontSize="20px"
                        fontWeight="400"
                        color="#000"
                        style={{ marginTop: "10px" }}
                    ></Text> */}
                            <Table
                                margin="30px 0"
                                borderRadius="30px"
                                padding="30px 0"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "0 20px 20px 20px",
                                    }}
                                    className="table-head"
                                >
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="105px"
                                        width="10%"
                                    >
                                        ID No.
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="15%"
                                    >
                                        Lead Name
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        width="15%"
                                    >
                                        Phone
                                    </TD>
                                    {/* <TD
										color="#808080"
										fontWeight="500"
										fontSize="16px"
										// width="175px"
										width="20%"
									>
										Email
									</TD> */}
                                    {/* <TD color="#808080" fontWeight="500" fontSize="16px" width="10%" >
						Country
					</TD> */}
                                    {/* <TD color="#808080" fontWeight="500" fontSize="16px" width="175px">
            Country
          </TD>
          <TD color="#808080" fontWeight="500" fontSize="16px" width="150px">
            Source
          </TD> */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="14%"
                                    >
                                        Enq. Date
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        width="12%"
                                    >
                                        Lead Age
                                    </TD>
                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="17%"
                                    >
                                        University
                                    </TD> */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="17%"
                                    >
                                        Program
                                    </TD>
                                    {/* <TD
                    color="#808080"
                    fontWeight="500"
                    fontSize="16px"
                    // width="175px"
                    width="15%"
                  >
                    Country
                  </TD> */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="15%"
                                    >
                                        Lead Source
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        width="10%"
                                    >
                                        Followup
                                    </TD>
                                    {/* <div style={{ width: "155px" }} /> */}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        width="155px"
                                    >
                                        Actions
                                    </TD>
                                    {/* <div style={{ width: "5%" }}></div> */}
                                </div>
                                <div className="table-body">
                                    {leads.map((item) => {
                                        return (
                                            <AssignedLeadListItem
                                                ID={item.lead_id}
                                                name={item.name}
                                                university={
                                                    item.university_data
                                                }
                                                course={item.course_data}
                                                country={item.country}
                                                phone={item.phone}
                                                source={item.lead_source}
                                                program={leadProgram.program}
                                                qualification={
                                                    item.qualification
                                                }
                                                key={item.id}
                                                sourceImage={
                                                    item.lead_source_data?.image
                                                }
                                                selectedLead={selectedLead}
                                                setSelectedLead={
                                                    setSelectedLead
                                                }
                                                item={item}
                                                getData1={getData1}
                                                // type={type}
                                            />
                                        );
                                    })}
                                </div>
                            </Table>
                        </>
                    ) : (
                        <LoaderWrapper>
                            <EmptyText>No leads found</EmptyText>
                        </LoaderWrapper>
                    )}
                </>
            ) : (
                <LoaderWrapper>
                    <LoadingIcon src={loadingGif} alt="icon" />
                </LoaderWrapper>
            )}
            {/* <Pagination
				postsPerPage={count}
				totalPosts={leadListData.length}
				paginate={paginate}
				currentPage={currentPage}
				currentPosts={currentPosts}
			/> */}
            <MainPagination
                paginationData={paginationData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        </Wrapper>
    );
}

export default Leads;

const CustomDateFields = styled.div`
    display: flex;
    align-items: center;
`;
const DateInput = styled.input`
    /* display: inline-block !important;
    width: 200px !important;
    height: 42px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 8px !important; */
    width: 200px !important;
    padding: 6px;
    height: 42px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    -webkit-text-fill-color: #000;
    box-sizing: border-box;
    /* border: 1px solid #dfeaf2; */
    border: 1px solid rgb(102, 102, 102);
    border-radius: 8px;
    &:hover {
        background: #fbfbfb;
    }
    &:focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #3ccb7f;
    }
`;
const DateFieldBox = styled.div`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const DateFieldText = styled.span`
    display: block;
    margin-bottom: 8px;
    color: #0fa76f;;
    font-size: 16px;
`;
const CustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const EmptyDiv = styled.div``;
const LoaderWrapper = styled.div`
    /* border: 1px solid #000; */
    height: calc(100vh - 230px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingIcon = styled.img`
    width: 70px;
    display: block;
    object-fit: cover;
`;
const EmptyText = styled.div`
    font-size: 26px;
    opacity: 0.6;
    font-weight: 500;
`;

const ClearFilterText = styled.div`
    align-self: flex-end;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0fa76f;
    width: 100px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
`;

const SearchDiv = styled.div`
    /* margin-right: 20px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #3ccb7f;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const FilterButtonWrapper = styled.div``;
const Container = styled.div`
    // height: 130px;
    width: 280px;
    max-height: 360px;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
// const Title = styled.h4`
//     font-size: 20px;
//     color: #808080;
// `;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 15px 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
    font-size: 16px;
    color: #515151;
    font-weight: 600;
`;
const SubHeading = styled.h4`
    font-size: 13px;
    color: "#515151";
    margin: 0 10px;
`;
const FButton = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "#522e92"};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "white")};
    // padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
    border: none;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 200px;
    cursor: pointer;
    // margin-top: 20px;
`;
const ButtonText = styled.h4`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#505887")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
const ResetButton = styled.div`
    width: 60%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #0fa76f;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #0fa76f;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
